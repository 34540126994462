import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState
} from 'react';
import { useQueryClient } from 'react-query';
import { NotFoundErrorPage } from 'src/components/notFoundErrorPage';

export interface CriticalAppError {
  code: number;
  message?: string;
}
type CriticalAppErrorContextValue = {
  criticalAppError?: CriticalAppError | null;
  setCriticalAppError?: Dispatch<SetStateAction<CriticalAppError>>;
  resetCriticalAppErrorContext?: () => void;
};

export const CriticalAppErrorContext =
  createContext<CriticalAppErrorContextValue>({});

const getErrorComponent = (
  children: ReactNode,
  criticalAppError?: CriticalAppError
): ReactNode => {
  switch (criticalAppError?.code) {
    // we want to add more pages here in the future in order to differentiate,
    // 403 that come from within the clinic and 403s that we want to show as 404 (this will be dealt with in the BE)
    case 404:
    case 403:
      return <NotFoundErrorPage />;
    default:
      return children;
  }
};

export const CriticalAppErrorContextProvider: FC = ({ children }) => {
  const [criticalAppError, setCriticalAppError] =
    useState<CriticalAppError | null>(null);
  const queryClient = useQueryClient();
  const resetCriticalAppErrorContext = () => {
    queryClient.removeQueries();
    setCriticalAppError(null);
  };
  return (
    <CriticalAppErrorContext.Provider
      value={{
        criticalAppError,
        setCriticalAppError,
        resetCriticalAppErrorContext
      }}
    >
      {getErrorComponent(children, criticalAppError)}
    </CriticalAppErrorContext.Provider>
  );
};
