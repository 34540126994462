import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { useQueryClient } from 'react-query';
import Dialog from '../../display/Dialog';
import { DialogProps } from '../../display/Dialog/Dialog';
import { DocumentEditorParams } from 'src/types/documents';
import { DocumentEditorDialog } from 'src/modules/documents/DocumentEditorDialog';
import {
  AuthContext,
  DialogProviderContext,
  DocumentEditorProviderContext
} from './types';
import {
  initialAuthState,
  authReducer,
  logout,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithGoogle,
  loginViaGoogleProvider
} from './utils/auth';
import firebase from 'src/utils/firebase';
import { useToast } from '../ToastProvider';
import { useTranslation } from 'react-i18next';
import { createGetMeFunction } from './utils/createGetMeFunction';
import { envConfig } from 'src/config';

const GlobalContext = createContext<
  AuthContext & DialogProviderContext & DocumentEditorProviderContext
>(null);

const GlobalProvider: FC = ({ children }): ReactElement => {
  // Auth
  const [authState, dispatchAuthState] = useReducer(
    authReducer,
    initialAuthState
  );
  const queryClient = useQueryClient();
  const { openToast } = useToast();
  const { t } = useTranslation();

  const getMe = createGetMeFunction({
    t,
    openToast,
    isAuthenticated: authState.isAuthenticated
  });
  const { refetch: refetchMe } = getMe();

  useEffect(() => {
    if (!envConfig.isStorybookActive) {
      return firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const { data: me } = await refetchMe();
          if (me?.userType === 'staff') {
            dispatchAuthState({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                error: null,
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  jobTitle: '',
                  avatar: user.photoURL,
                  email: user.email,
                  displayName: user.displayName || user.email,
                  role: 'admin',
                  location: '',
                  username: '',
                  posts: '',
                  coverImg: '',
                  followers: '',
                  description: ''
                }
              }
            });
            return;
          }
        }
        await logout();
        dispatchAuthState({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            error: null,
            user: null
          }
        });

        queryClient.removeQueries();
      });
    }
  }, [dispatchAuthState, refetchMe]);

  // Dialog
  const [dialogs, setDialogs] = useState<DialogProps[]>([]);

  const openDialog = (props: DialogProps) => {
    setDialogs((prevState) => [...prevState, { ...props, open: true }]);
  };

  const closeDialog = () => {
    setDialogs((prevState) => prevState.slice(0, prevState.length - 1));
  };

  const closeAllDialogs = () => {
    setDialogs([]);
  };

  // Document editor
  const [isDocumentsModalOpen, setDocumentsModalOpen] = useState(false);
  const [documentEditorParams, setDocumentEditorParams] =
    useState<DocumentEditorParams>({});

  return (
    <GlobalContext.Provider
      value={{
        // Auth
        ...authState,
        method: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        loginViaGoogleProvider,
        logout,
        // Dialog
        openDialog,
        closeDialog,
        closeAllDialogs,
        // Document editor
        documentEditorParams,
        isDocumentsModalOpen,
        setDocumentEditorParams,
        setDocumentsModalOpen
      }}
    >
      {children}

      {/* Dialog */}
      {dialogs.map((dialogProps, index) => (
        <Dialog
          key={`dialog-${index}`}
          {...dialogProps}
          onClose={closeDialog}
        />
      ))}

      {/* Document editor */}
      {authState.isAuthenticated && (
        <DocumentEditorDialog
          documentEditorInfo={documentEditorParams}
          isDocumentsModalOpen={isDocumentsModalOpen}
          setDocumentsModalOpen={setDocumentsModalOpen}
          setDocumentEditorParams={setDocumentEditorParams}
        />
      )}
    </GlobalContext.Provider>
  );
};

export function useAuth(): AuthContext {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      'Auth context is only available inside GlobalContext.Provider'
    );
  }
  return context;
}

export function useDialog(): DialogProviderContext {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      'Dialog context is only available inside GlobalContext.Provider'
    );
  }
  return context;
}

export function useDocumentEditor(): DocumentEditorProviderContext {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      'Editor context is only available inside GlobalContext.Provider'
    );
  }
  return context;
}

export default GlobalProvider;
