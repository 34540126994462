import dayjs from 'dayjs';
import i18n from 'src/i18n/i18n';
import { Clinic } from 'src/types/clinic';
import { formatDateAndTime, getDateFormat } from 'src/utils/dateAndTIme';
import { DocumentTypes, TemplateImageProps } from 'src/types/documents';
import { Patient } from 'src/types/patient';
import { StaffMember } from 'src/types/staff';
import {
  Appointment,
  LabOrder,
  LabOrderBillTo,
  Panel
} from 'src/types/appointment';
import { DbUser } from 'src/types/user';
import { getDefaultClinicLogo } from 'src/utils/defaultImages';
import {
  ConsentTemplateFieldsData,
  FillConsentTemplateFieldsParams,
  OrdersTemplateFields
} from './types';

export const getTemplateFieldsData = ({
  templateType,
  patient,
  clinic,
  patientPrimaryCareTeamMember,
  patientLabOrder,
  labOrdersId,
  panels,
  loggedStaffMember
}: {
  templateType: DocumentTypes;
  patient: Patient;
  clinic: Clinic;
  patientPrimaryCareTeamMember?: StaffMember;
  patientLabOrder?: LabOrder;
  labOrdersId?: string[];
  panels?: Panel[];
  loggedStaffMember?: DbUser;
}): Record<string, string | Record<string, string>[] | TemplateImageProps> => {
  switch (templateType) {
    case DocumentTypes.CONSENT:
      return fillConsentTemplateFields({
        patient
      });
    case DocumentTypes.ORDERS:
      return fillOrdersTemplateFields({
        patient,
        clinic,
        patientLabOrder,
        labOrdersId,
        panels,
        patientPrimaryCareTeamMember,
        loggedStaffMember
      });
    default:
      break;
  }
};

export const fillOrdersTemplateFields = ({
  patient,
  clinic,
  appointment,
  patientLabOrder,
  labOrdersId,
  panels,
  patientPrimaryCareTeamMember,
  loggedStaffMember
}: {
  patient: Patient;
  clinic: Clinic;
  appointment?: Appointment;
  patientLabOrder?: LabOrder;
  labOrdersId?: string[];
  panels?: Panel[];
  patientPrimaryCareTeamMember?: StaffMember;
  loggedStaffMember?: DbUser;
}): OrdersTemplateFields => {
  const { personalInfo } = patient || {};

  const clinicLogoForTemplate = getDefaultClinicLogo({
    organizationStringId:
      loggedStaffMember?.user?.clinic?.organization?.stringId,
    isForDocument: true
  });

  const labOrders =
    appointment?.panelIds?.map((panelId) => {
      const panel = panels?.find(({ id }) => id === panelId);

      if (panel?.name && panel?.code) {
        return {
          name: panel.name,
          code: panel.code
        };
      }
    }) || [];

  const nonCycleOrders = labOrdersId
    ?.map((panelId) => {
      return panels?.find(({ id }) => id === panelId);
    })
    ?.map((panel) => {
      return {
        name: panel?.name || '',
        code: panel?.code || ''
      };
    });

  const { billTo } = patientLabOrder || {};
  const billToPatient = billTo === LabOrderBillTo.PATIENT;

  const staffLicense = `${patientPrimaryCareTeamMember?.licenseNumber || ''}`;

  return {
    clinic_logo: {
      image_url: clinicLogoForTemplate || '',
      width: 100,
      height: 100
    },
    external_id: patientLabOrder?.externalId || '',
    is_urgent: patientLabOrder?.isUrgent ? i18n.t('YES') : i18n.t('NO'),
    order_date: formatDateAndTime(dayjs().toDate()),
    bill_to:
      patientLabOrder?.billTo === LabOrderBillTo.PATIENT
        ? i18n.t(LabOrderBillTo.PATIENT)
        : i18n.t(LabOrderBillTo.CLINIC),
    order_reason: i18n.t(patientLabOrder?.purpose) || '',
    is_fasting: patientLabOrder?.isFasting ? i18n.t('YES') : i18n.t('NO'),
    clinic_patient_address: billToPatient
      ? patient?.personalInfo?.address
      : clinic?.address || '',
    clinic_patient_city: billToPatient
      ? patient?.personalInfo?.city
      : clinic?.city || '',
    clinic_patient_zip: billToPatient
      ? patient?.personalInfo?.postalCode
      : clinic?.postalCode || '',
    clinic_patient_state: billToPatient
      ? patient?.personalInfo?.state
      : clinic?.state || '',
    clinic_patient_phone: billToPatient
      ? patient?.personalInfo?.phoneNumber
      : clinic?.phoneNumber || '',

    clinic_doctor_id: patientPrimaryCareTeamMember?.id || '',
    doctors_last_name: patientPrimaryCareTeamMember?.lastName || '',
    doctors_first_name: patientPrimaryCareTeamMember?.firstName || '',
    doctors_license: staffLicense,

    patient_gender: personalInfo?.sexAtBirth
      ? i18n.t(personalInfo?.sexAtBirth)
      : '',
    patient_phone: personalInfo?.phoneNumber || '',
    patient_email: personalInfo?.email || '',
    patient_clinic_id: `${personalInfo?.id || ''}`,
    patient_last_name: personalInfo?.lastName || '',
    patient_first_name: personalInfo?.firstName || '',
    staff_surname: '',
    staff_firstname: '',
    signing_date_time: '',
    todays_date: '',
    patient_dob: dayjs(personalInfo?.dateOfBirth).format(getDateFormat()),
    orders: labOrders || nonCycleOrders
  };
};

export const fillConsentTemplateFields = ({
  patient
}: FillConsentTemplateFieldsParams): ConsentTemplateFieldsData => {
  const { personalInfo } = patient || {};

  const patientDateOfBirth = personalInfo?.dateOfBirth
    ? dayjs(personalInfo?.dateOfBirth).format(getDateFormat())
    : i18n.t('N/A');

  const consentTemplateFieldsData: ConsentTemplateFieldsData = {
    patient_last_name: personalInfo?.lastName || '',
    patient_first_name: personalInfo?.firstName || '',
    patient_dob: patientDateOfBirth,
    patient_address: personalInfo.address || '',
    patient_city: personalInfo.city || '',
    patient_state: personalInfo.state || '',
    patient_ssn: personalInfo?.ssn || '',
    todays_date: dayjs().format(getDateFormat())
  };

  return consentTemplateFieldsData;
};
