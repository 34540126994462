import { FC } from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import EditableTitle from 'src/components/data-entry/EditableTitle/EditableTitle';
import usePatientsApi from '../../../hooks/usePatientsApi';

export const PatientFlags: FC<{ patientId: string }> = ({ patientId }) => {
  const { getPatientById, updatePatientFlags } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);
  const { mutate: handleUpdateFlags } = updatePatientFlags();

  return (
    <EditableTitle
      value={patient?.flags || ''}
      placeholder="Click here to add Red Flags"
      onChange={async (newValue) => {
        if (newValue === patient.flags) return;
        handleUpdateFlags({
          patientId,
          patientFlags: newValue
        });
      }}
      startIcon={<FlagIcon color="error" />}
    />
  );
};
