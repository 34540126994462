import { css, Select, SelectProps, styled } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { Colors } from 'src/components/styles/colors';
import { radii, shadows, spacings } from 'src/components/styles/constants';
import Checkbox from '../Checkbox';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import { fonts } from 'src/components/styles/fonts';
import { SelectMenuItem } from '../Select/Select';
import { makeShouldForwardProps } from 'src/components/utils';

export interface MultiSelectOption {
  label: string;
  value: string;
}

export enum MultiSelectVariant {
  DEFAULT = 'default',
  FILLED = 'filled'
}

interface MultiSelectProps extends Omit<SelectProps, 'variant'> {
  variant?: MultiSelectVariant;
}

const shouldForwardProp = makeShouldForwardProps([
  'disableUnderline',
  'variant'
]);
const StyledSelect = styled(
  ({ variant: _variant, ...props }: MultiSelectProps) => <Select {...props} />,
  { shouldForwardProp }
)<MultiSelectProps>`
  ${({ variant }) => {
    switch (variant) {
      case MultiSelectVariant.FILLED:
        return css`
          background: ${Colors.lightGray};
          height: 100%;

          border-radius: 0;
          .MuiSelect-icon.MuiSelect-iconFilled {
            color: ${Colors.emperor};
          }

          .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input {
            padding: ${spacings.small} ${spacings.medium};
          }

          .MuiOutlinedInput-notchedOutline {
            display: none;
          }
        `;

      case MultiSelectVariant.DEFAULT:
      default:
        return css`
          .MuiOutlinedInput-notchedOutline {
            border: none;
          }

          &.MuiInputBase-root {
            font: ${fonts.input};
            border-radius: ${radii.medium};
            padding: ${spacings.medium};
            cursor: pointer;
            color: ${Colors.silverChalice};
            background-color: ${Colors.white};
            border: 1px solid ${Colors.gray};
          }

          .MuiSelect-select {
            padding: unset;
            height: unset;
            color: ${Colors.emperor};

            &::placeholder {
              color: ${Colors.gray};
              opacity: 1;
              width: 100%;
            }
          }

          &:hover,
          &.Mui-focused {
            border-color: ${Colors.gray};
            box-shadow: ${shadows.input};
          }

          &.Mui-error {
            border-color: ${Colors.redRibbon};
          }

          &.Mui-disabled {
            border-color: ${Colors.gray};
            box-shadow: none;

            .MuiInputAdornment-root {
              color: ${Colors.gray};
            }
          }

          .MuiInputAdornment-root {
            color: ${Colors.gray};

            .MuiSvgIcon-root {
              width: 20px;
            }
          }

          .MuiFormHelperText-root {
            margin: 3px ${spacings.none} ${spacings.none} ${spacings.none};
            color: ${Colors.gray};
            font: ${fonts.caption};

            &.Mui-error {
              color: ${Colors.redRibbon};
            }
          }

          /* Disable autofill color */
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px ${Colors.white} inset !important;
            box-shadow: 0 0 0 30px ${Colors.white} inset !important;
          }
        `;
    }
  }}
`;

const MultiSelect: FC<
  Omit<MultiSelectProps, 'onChange' | 'value'> & {
    onChange: (value: string[]) => void;
    value: string[];
    options: MultiSelectOption[];
    withCheckbox?: boolean;
  }
> = ({ ...props }) => {
  const { value, renderValue, placeholder, withCheckbox, onChange, options } =
    props || {};
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const eventValue = event.target.value; // The new value entered by the user
    const newValue =
      typeof eventValue === 'string' ? eventValue.split(',') : eventValue;
    onChange(newValue);
  };

  return (
    <StyledSelect
      {...props}
      multiple
      onChange={handleChange}
      disableUnderline={true}
      renderValue={(val: string[]) => {
        if (val.length > 0) {
          if (renderValue) {
            return renderValue(val);
          } else {
            return val.join(', ');
          }
        } else {
          return placeholder;
        }
      }}
      fullWidth
    >
      {placeholder && (
        <SelectMenuItem disabled value="">
          {placeholder}
        </SelectMenuItem>
      )}
      {options?.map((option, index) => (
        <SelectMenuItem key={index} value={option.value}>
          {withCheckbox ? (
            <Flex alignItems="center" gap={spacings.large}>
              <Box paddingBottom={spacings.xsmall}>
                <Checkbox size="small" checked={value.includes(option.value)} />
              </Box>
              <Typography>{option.label}</Typography>
            </Flex>
          ) : (
            option.label
          )}
        </SelectMenuItem>
      ))}
    </StyledSelect>
  );
};

export default MultiSelect;
