const enBillingTranslations = {
  PENDING: 'Pending',
  QUOTES: 'Quotes',
  PAID: 'Paid',
  INVOICE_NUMBER: 'Invoice no.',
  PAID_DATE: 'Paid date',
  METHOD: 'Method',
  BILLING_NAVBAR_TITLE: 'Billing',
  RECENT_INVOICES: 'Recent invoices',
  SEARCH_BY_PATIENT: 'Search by patient',
  ALL_STATUS: 'All statuses',
  QUOTE: 'Quote',
  REFUNDED: 'Refunded',
  DRAFT: 'Draft',
  CANCELED: 'Canceled',
  NAVIGATION_BILLING: 'Billing',
  PATIENT_BILLING_PROFILE: 'Patient billing profile',
  PAYMENT_PROFILE: 'Payment profile',
  CURRENCY: 'Currency',
  INSURANCE_INFO: 'Insurance info',
  MEMBER_NUMBER: 'Member No.',
  GROUP_NUMBER: 'Group No.',
  EFFECTIVE_DATE: 'Effective date',
  HEALTH_PROVIDER: 'Health provider',
  ADD_NEW_INVOICE: 'Add new invoice',
  INVOICE: 'Invoice',
  PAID_TO: 'Paid to',
  BILL_TO: 'Bill to',
  BALLANCE_DUE: 'Balance due',
  ISSUED: 'Issued',
  DUE: 'Due',
  NEW_INVOICE: 'New invoice',
  CREATE_INVOICE_SUCCESS_TOAST_TITLE: 'Invoice created successfully',
  ITEM_CODE: 'Item code',
  ITEM_DESCRIPTION: 'Item description',
  PRICE: 'Price',
  QTY: 'Qty',
  DISCOUNT: 'Discount',
  TOTAL: 'Total',
  VAT: 'VAT',
  ADD_ITEM: 'Add item',
  ENTER_DISCOUNT: 'Enter discount',
  ENTER_PRICE: 'Enter price',
  ENTER_DESCRIPTION: 'Enter description',
  VALUE_IN_PERCENTAGE: 'Value in percentage',
  NOTES_GOES_HERE: 'Notes goes here',
  UPDATE_INVOICE_SUCCESS_TOAST_TITLE: 'Invoice updated successfully',
  UPDATE_INVOICE_STATUS_ERROR_TOAST_TITLE: 'Error updating invoice status',
  UPDATE_INVOICE_STATUS_SUCCESS_TOAST_TITLE: 'Invoice status updated',
  UPDATE_INVOICE_METHOD_SUCCESS_TOAST_TITLE: 'Invoice method updated',
  CASH: 'Cash',
  CREDIT: 'Credit',
  WIRE: 'Wire'
};

export default enBillingTranslations;
