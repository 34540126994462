import { WebViewerInstance } from '@pdftron/webviewer';
import {
  CreatePatientDocumentPayload,
  CreatePatientDocumentPayloadServer,
  Document,
  DocumentExtension,
  PatientDocument,
  annotationsSubjects
} from 'src/types/documents';
import { getWebViewerSaveDocumentAction } from './getWebViewerSaveDocumentAction';
import { UseMutateFunction } from 'react-query';
import { DbUser } from 'src/types/user';
import { getUpdatedSignatureTemplateKeys } from './getUpdatedSignatureTemplateKeys';
import { getWebViewerAuthSignatureAction } from './getWebViewerAuthSignatureAction';
import { DialogProps } from 'src/components/display/Dialog/Dialog';
import { ReactNode } from 'react';
import { zIndices } from 'src/components/styles/constants';
import { getExtension } from 'src/utils/general';

interface InitWebviewerDocParams {
  instance: WebViewerInstance;
  isClinicDocument: boolean;
  chosenTemplate?: Document;
  documentToEdit?: PatientDocument;
  patientId: string;
  createDocument: UseMutateFunction<
    CreatePatientDocumentPayloadServer,
    Error,
    CreatePatientDocumentPayload,
    unknown
  >;
  updateDocument: UseMutateFunction<
    string,
    Error,
    { documentId: string; document: CreatePatientDocumentPayload },
    unknown
  >;
  documentId: string;
  documentMetadata: string;
  loggedStaffMember: DbUser;
  documentIsBeingSavedLoader: () => void;
  closeDialog: () => void;
  openDialog: ({
    header,
    headerIcon,
    closable,
    onClose
  }: Omit<DialogProps, 'open'>) => void;
  templateId: string;
  orderTemplateId: string;
  handleToolbarGroupChangeAuthChildren: ReactNode;
}

const loadDocumentIntoViewer = async ({
  instance,
  isClinicDocument,
  chosenTemplate,
  documentToEdit
}: {
  instance: WebViewerInstance;
  isClinicDocument: boolean;
  chosenTemplate?: Document;
  documentToEdit?: PatientDocument;
}) => {
  const docToLoad = isClinicDocument
    ? chosenTemplate?.url
    : documentToEdit?.url;

  const extension = getExtension(docToLoad);

  instance.UI.loadDocument(docToLoad, {
    extension,
    onError: () => {
      instance.UI.closeElements(['errorModal']);
      // If the document fails to load, try to load the other extension
      instance.UI.closeDocument().then(() => {
        // If the document is a PDF, try to load a DOCX and vice versa
        const fallbackExtension =
          extension === DocumentExtension.PDF
            ? DocumentExtension.DOCX
            : DocumentExtension.PDF;
        instance.UI.loadDocument(docToLoad, {
          extension: fallbackExtension
        });
      });
    }
  });
};

const AddRemoveSaveDocumentBtn = async ({
  instance,
  documentToEdit,
  chosenTemplate,
  patientId,
  documentId,
  documentMetadata,
  createDocument,
  updateDocument,
  loggedStaffMember,
  closeDialog,
  documentIsBeingSavedLoader,
  shouldRemoveEvent
}: {
  instance: WebViewerInstance;
  documentToEdit: PatientDocument;
  patientId: string;
  createDocument: UseMutateFunction<
    CreatePatientDocumentPayloadServer,
    Error,
    CreatePatientDocumentPayload,
    unknown
  >;
  updateDocument: UseMutateFunction<
    string,
    Error,
    { documentId: string; document: CreatePatientDocumentPayload },
    unknown
  >;
  chosenTemplate: Document;
  documentId: string;
  documentMetadata: string;
  loggedStaffMember: DbUser;
  documentIsBeingSavedLoader: () => void;
  closeDialog: () => void;
  shouldRemoveEvent?: boolean;
}) => {
  const { documentViewer, annotationManager } = instance.Core;

  const onDocumentLoaded = async () => {
    const prevAnnotations = annotationManager.getAnnotationsList();

    annotationManager.deleteAnnotations(prevAnnotations || [], { force: true });

    const initialAnnotations = annotationManager.getAnnotationsList();

    annotationManager.importAnnotations(documentToEdit?.annotations || '');

    instance.UI.setHeaderItems((header) => {
      // Delete the saveDocumentButton from the prev header if had one
      header.delete('saveDocumentButton');

      return getWebViewerSaveDocumentAction({
        header,
        webViewerInstance: instance,
        templateName: chosenTemplate?.name,
        patientId,
        templateId: chosenTemplate?.id,
        documentId,
        templateMetadata: documentMetadata,
        createDocument,
        updateDocument,
        isUpdate: !!documentId,
        documentToEdit,
        loggedStaffMember,
        initialAnnotations,
        chosenTemplate,
        documentIsBeingSavedLoader,
        closeDialog
      });
    });

    if (documentMetadata?.length) {
      await documentViewer
        .getDocument()
        .applyTemplateValues(JSON.parse(documentMetadata));
    }
  };

  if (shouldRemoveEvent) {
    await documentViewer.removeEventListener(
      'documentLoaded',
      onDocumentLoaded
    );
  } else {
    await documentViewer.addEventListener('documentLoaded', onDocumentLoaded);
  }
};

const AddUpdateSignatureDetailsEvent = async ({
  instance,
  loggedStaffMember,
  chosenTemplate,
  orderTemplateId,
  templateId,
  documentMetadata,
  shouldRemoveEvent
}: {
  instance: WebViewerInstance;
  loggedStaffMember: DbUser;
  chosenTemplate: Document;
  templateId: string;
  orderTemplateId: string;
  documentMetadata: string;
  shouldRemoveEvent?: boolean;
}) => {
  const { documentViewer, annotationManager } = instance.Core;

  const onAnnotationChanged = (_annotations, _action, { imported }) => {
    annotationManager.exportAnnotations();

    if (
      _action === 'add' &&
      !imported &&
      _annotations[0]?.Subject === annotationsSubjects.signature
    ) {
      const updatedKeysToUse = getUpdatedSignatureTemplateKeys({
        loggedStaffMember: loggedStaffMember?.user,
        isLabOrderTemplate:
          (chosenTemplate?.id || templateId) === orderTemplateId
      });

      const parsedMetadata = JSON.parse(documentMetadata || '{}');

      const updatedMetadata = { ...parsedMetadata };

      Object.keys(parsedMetadata).forEach((key) => {
        if (updatedKeysToUse[key]) {
          updatedMetadata[key] = updatedKeysToUse[key];
        }
      });

      documentViewer.getDocument().applyTemplateValues(updatedMetadata);
    }
  };

  if (shouldRemoveEvent) {
    await annotationManager.removeEventListener(
      'annotationChanged',
      onAnnotationChanged
    );
  } else {
    await annotationManager.addEventListener(
      'annotationChanged',
      onAnnotationChanged
    );
  }
};

const setDocumentXFDFRetriever = async ({
  instance,
  documentToEdit,
  chosenTemplate
}: {
  instance: WebViewerInstance;
  documentToEdit: PatientDocument;
  chosenTemplate: Document;
}) => {
  await instance.Core.documentViewer.setDocumentXFDFRetriever(async () => {
    return (
      documentToEdit?.annotations || chosenTemplate?.defaultAnnotations || ''
    );
  });
};

const hideViewToolbarGroup = (instance: WebViewerInstance) => {
  instance.UI.setToolbarGroup(instance.UI.ToolbarGroup.VIEW, false);
};

const disableFillAndSignToolbarGroup = (instance: WebViewerInstance) => {
  instance.UI.disableElements(['dropdown-item-toolbarGroup-FillAndSign']);
};

const handleToolbarGroupChange = ({
  instance,
  chosenTemplate,
  openDialog,
  children,
  shouldRemoveEvent
}: {
  instance: WebViewerInstance;
  chosenTemplate: Document;
  openDialog: ({
    header,
    headerIcon,
    closable,
    onClose
  }: Omit<DialogProps, 'open'>) => void;
  children: ReactNode;
  shouldRemoveEvent?: boolean;
}) => {
  const { ToolbarGroup, closeElements, openElements } = instance.UI;

  const onGroupChanged = async (e: any) => {
    if (e.detail === ToolbarGroup.FILL_AND_SIGN) {
      closeElements(['toolsHeader']);
      closeElements(['toolsOverlay']);
    }

    if (chosenTemplate?.requiresSignature) {
      getWebViewerAuthSignatureAction({
        event: e,
        webViewerInstance: instance,
        callback: () => {
          openDialog({
            children,
            maxWidth: 'sm',
            sx: { zIndex: zIndices.highest }
          });
        }
      });
    } else {
      openElements(['toolsHeader']);
      openElements(['toolsOverlay']);
    }
  };
  if (shouldRemoveEvent) {
    instance.UI.removeEventListener(
      instance.UI.Events.TOOLBAR_GROUP_CHANGED,
      onGroupChanged
    );
  } else {
    instance.UI.addEventListener(
      instance.UI.Events.TOOLBAR_GROUP_CHANGED,
      onGroupChanged
    );
  }
};

export const initNewDocumentInEditor = async ({
  openDialog,
  handleToolbarGroupChangeAuthChildren,
  isClinicDocument,
  instance,
  chosenTemplate,
  documentToEdit,
  orderTemplateId,
  patientId,
  documentId,
  closeDialog,
  createDocument,
  documentIsBeingSavedLoader,
  documentMetadata,
  loggedStaffMember,
  templateId,
  updateDocument
}: InitWebviewerDocParams) => {
  // Start of removing previous event listeners

  await AddRemoveSaveDocumentBtn({
    chosenTemplate,
    closeDialog,
    createDocument,
    documentId,
    documentIsBeingSavedLoader,
    documentMetadata,
    documentToEdit,
    instance,
    loggedStaffMember,
    patientId,
    updateDocument,
    shouldRemoveEvent: true
  });

  await AddUpdateSignatureDetailsEvent({
    instance,
    chosenTemplate,
    documentMetadata,
    loggedStaffMember,
    orderTemplateId,
    templateId,
    shouldRemoveEvent: true
  });

  handleToolbarGroupChange({
    chosenTemplate,
    instance,
    openDialog,
    children: handleToolbarGroupChangeAuthChildren,
    shouldRemoveEvent: true
  });

  // End of removing previous event listeners

  // Start loading new document

  loadDocumentIntoViewer({
    isClinicDocument,
    instance,
    chosenTemplate,
    documentToEdit
  });

  await setDocumentXFDFRetriever({
    instance,
    documentToEdit,
    chosenTemplate
  });

  if (
    !chosenTemplate?.requiresSignature &&
    chosenTemplate?.id !== orderTemplateId
  ) {
    disableFillAndSignToolbarGroup(instance);
  }

  await AddRemoveSaveDocumentBtn({
    instance,
    documentToEdit,
    chosenTemplate,
    patientId,
    documentMetadata,
    documentId,
    createDocument,
    updateDocument,
    loggedStaffMember,
    closeDialog,
    documentIsBeingSavedLoader
  });

  await AddUpdateSignatureDetailsEvent({
    instance,
    chosenTemplate,
    documentMetadata,
    loggedStaffMember,
    orderTemplateId,
    templateId
  });

  handleToolbarGroupChange({
    chosenTemplate,
    instance,
    openDialog,
    children: handleToolbarGroupChangeAuthChildren
  });

  hideViewToolbarGroup(instance);
};
