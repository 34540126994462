import { Document } from 'src/types/documents';
import axios from 'axios';
import dayjs from 'dayjs';
import { longDateTimeMsFormat } from 'src/utils/dateAndTIme';

export const getDocumentAsPdfFileFromURL = async ({
  template
}: {
  template: Document;
}): Promise<File> => {
  const response = await axios.get(template?.url, {
    responseType: 'blob'
  });

  const blob = response.data;

  const fileNameWithDate = `${
    template?.name || 'no_named_document'
  }_${dayjs().format(longDateTimeMsFormat)}`;

  const pdfFile = new File([blob], fileNameWithDate, {
    type: 'application/pdf'
  });

  return pdfFile;
};
