import { GridColDef, GridEditCellProps } from '@mui/x-data-grid-premium';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Table from 'src/components/display/Table';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { fontWeights } from 'src/components/styles/fonts';
import RemoveIcon from '@mui/icons-material/Remove';
import InputField from 'src/components/data-entry/InputField';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import Select from 'src/components/data-entry/Select';
import MultiSelect, {
  MultiSelectOption,
  MultiSelectVariant
} from 'src/components/data-entry/MultiSelect/MultiSelect';
import useCodes from 'src/hooks/useCodes';
import { EncounterCodeRow, EncounterDiagnosis } from 'src/types/encounter';
import { EncounterServiceCode, Modifier, NDCCode } from 'src/types/codes';
import { DxOptionMappedType } from './appointmentTabs/CodingTab';
import { SelectOption } from 'src/components/data-entry/Select/Select';
import { InputFieldVariant } from 'src/components/data-entry/InputField/InputField';
import { Option } from 'src/types/option';
import { RowHeights } from 'src/components/display/Table/Table';
import { PageSizes } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box';

const dosageUnitOptionsStub: SelectOption<string>[] = [
  { label: 'mg', value: 'mg' },
  { label: 'ml', value: 'ml' },
  { label: 'g', value: 'g' },
  { label: 'mcg', value: 'mcg' },
  { label: 'unit', value: 'unit' }
]; // TODO - these will need to be grabbed from the BE *or* be defined in the FE

interface EncounterServiceCodeAutocompleteOption
  extends Omit<Option, 'value'>,
    EncounterServiceCode {
  label: string;
}

const CustomEditCellComponent: FC<{
  editCellParams: GridEditCellProps;
  EditorComponent: FC<{
    value: number | string | EncounterDiagnosis[] | Modifier[] | unknown;
    onChange?: (newValue: string) => void;
    onChangeMulti?: (newValues: EncounterDiagnosis[] | Modifier[]) => void;
  }>;
}> = ({ editCellParams, EditorComponent }) => {
  const { id, value, field, api } = editCellParams;

  const handleValueChange = (newValue: string) => {
    api.setEditCellValue({
      id,
      field,
      value: newValue
    });
  };

  const handleChangeMulti = (newValues: EncounterDiagnosis[] | Modifier[]) => {
    api.setEditCellValue({
      id,
      field,
      value: newValues
    });
  };
  return (
    <EditorComponent
      onChangeMulti={handleChangeMulti}
      onChange={handleValueChange}
      value={value}
    />
  );
};

interface ModifierMappedType {
  [key: string]: Modifier;
}

const CodingTable: FC<{
  rows: EncounterCodeRow[];
  onChange: (rows: EncounterCodeRow[]) => void;
  dxPointerOptions: DxOptionMappedType;
}> = ({ rows, onChange: updateForm, dxPointerOptions }) => {
  const { getNDCCodes, getModifiers, getEncounterServiceCodes } = useCodes();
  const {
    data: ndcOptions,
    isLoading: isLoadingNdcOptions,
    isFetching: isFetchingNdcOptions
  } = getNDCCodes();
  const {
    data: modifierOptions,
    isLoading: isLoadingModifierOptions,
    isFetching: isFetchingModifierOptions
  } = getModifiers();
  const {
    data: serviceCodeOptions,
    isLoading: isLoadingServiceCodeOptions,
    isFetching: isFetchingServiceCode
  } = getEncounterServiceCodes();

  const { t } = useTranslation();

  const dxPointerSelectOptions: MultiSelectOption[] = useMemo(
    () =>
      Object.keys(dxPointerOptions).map((option) => ({
        value: dxPointerOptions[option]?.index.toString(),
        label: dxPointerOptions[option]?.label
      })),
    [dxPointerOptions]
  );

  const modifierMappedOptions: ModifierMappedType = useMemo(() => {
    const modifierMappedOptions: ModifierMappedType = {};
    modifierOptions?.forEach((option) => {
      modifierMappedOptions[option.id] = option;
    });
    return modifierMappedOptions;
  }, [modifierOptions]);

  const modifierSelectOptions: MultiSelectOption[] = useMemo(
    () =>
      Object.keys(modifierMappedOptions).map((option) => ({
        value: modifierMappedOptions[option].id,
        label: `${modifierMappedOptions[option].billingCode} - ${modifierMappedOptions[option].serviceDescription}`
      })),
    [modifierMappedOptions]
  );

  const serviceCodeSelectOptions: EncounterServiceCodeAutocompleteOption[] =
    useMemo(
      () =>
        serviceCodeOptions?.map((option) => ({
          ...option,
          label: `${option.billingCode} - ${option.serviceDescription}`
        })),
      [serviceCodeOptions]
    );

  const renderModifierValue = useCallback(
    (values: string[]) => {
      const valueArray: string[] = [];
      values?.forEach((val) => {
        if (modifierMappedOptions[val]) {
          valueArray.push(modifierMappedOptions[val].billingCode);
        }
      });
      return valueArray.join(', ');
    },
    [modifierMappedOptions]
  );

  const renderDxPointerValue = useCallback(
    (values: string[]) => {
      const valueArray: string[] = [];
      values?.forEach((val) => {
        if (dxPointerOptions[val]) {
          valueArray.push(dxPointerOptions[val].label);
        }
      });
      return valueArray.join(', ');
    },
    [dxPointerOptions]
  );

  const isLoading =
    isLoadingNdcOptions ||
    isLoadingModifierOptions ||
    isLoadingServiceCodeOptions ||
    isFetchingNdcOptions ||
    isFetchingModifierOptions ||
    isFetchingServiceCode;

  const columns: GridColDef<EncounterCodeRow>[] = [
    {
      flex: 1,
      field: 'code',
      headerName: t('CPT_OR_HCPCS'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <Autocomplete<EncounterServiceCode>
              onChange={(_, selectedOption: EncounterServiceCode) => {
                onChange(selectedOption?.billingCode);
                const newRows = rows.map((row) => {
                  if (row.id === params.id) {
                    return { ...row, billingService: selectedOption };
                  }
                  return row;
                });
                return updateForm(newRows);
              }}
              freeListWidth
              fullWidth
              value={value as EncounterServiceCode}
              options={serviceCodeSelectOptions}
              renderInput={(props) => {
                return (
                  <InputField
                    {...props}
                    variant={InputFieldVariant.FILLED}
                    autoFocus
                    fullWidth
                    InputProps={props.InputProps}
                  />
                );
              }}
            />
          )}
        />
      ),
      renderCell: ({ row: { billingService } }) => billingService?.billingCode
    },
    {
      flex: 3,
      field: 'description',
      headerName: t('DESCRIPTION'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <Autocomplete<EncounterServiceCode>
              onChange={(_, selectedOption: EncounterServiceCode) => {
                onChange(selectedOption?.serviceDescription);
                const newRows = rows.map((row) => {
                  if (row.id === params.id) {
                    return { ...row, billingService: selectedOption };
                  }
                  return row;
                });
                return updateForm(newRows);
              }}
              freeListWidth
              fullWidth
              value={value as EncounterServiceCode}
              options={serviceCodeSelectOptions}
              renderInput={(props) => {
                return (
                  <InputField
                    {...props}
                    variant={InputFieldVariant.FILLED}
                    autoFocus
                    fullWidth
                    InputProps={props.InputProps}
                  />
                );
              }}
            />
          )}
        />
      ),
      renderCell: ({ row: { billingService } }) => (
        <Box width={'100%'} maxHeight={40} overflow="auto">
          <Typography whiteSpace="pre-wrap">
            {billingService?.serviceDescription}
          </Typography>
        </Box>
      )
    },
    {
      flex: 1,
      field: 'modifiers',
      headerName: t('MODIFIER'),
      editable: true,
      renderEditCell: (params) => {
        const { id, field } = params;
        if (params.value === '') {
          params.api.setEditCellValue({ id, field, value: [] });
          return;
        }
        return (
          <CustomEditCellComponent
            editCellParams={params}
            EditorComponent={({
              value,
              onChangeMulti
            }: {
              value: Modifier[];
              onChangeMulti: (newValues: Modifier[]) => void;
            }) => (
              <MultiSelect
                onChange={(newValue: string[]) => {
                  const chosenOptions: Modifier[] = newValue?.map(
                    (value) => modifierMappedOptions?.[value]
                  );
                  return onChangeMulti(chosenOptions);
                }}
                renderValue={renderModifierValue}
                variant={MultiSelectVariant.FILLED}
                placeholder={t('PICK_MODIFIERS')}
                value={value?.map((value: Modifier) => value?.id) || []}
                options={modifierSelectOptions}
              />
            )}
          />
        );
      },
      renderCell: ({ row: { modifiers } }) =>
        modifiers?.map((mod: Modifier) => mod?.billingCode).join(', ')
    },
    {
      flex: 1,
      field: 'dxPointers',
      headerName: t('DX_POINTER'),
      editable: true,
      renderEditCell: (params) => {
        const { id, field } = params;
        if (params.value === '') {
          params.api.setEditCellValue({ id, field, value: [] });
          return;
        }
        return (
          <CustomEditCellComponent
            editCellParams={params}
            EditorComponent={({
              value,
              onChangeMulti
            }: {
              value: EncounterDiagnosis[];
              onChangeMulti: (newValues: EncounterDiagnosis[]) => void;
            }) => (
              <MultiSelect
                onChange={(newValue: string[]) => {
                  const chosenOptions: EncounterDiagnosis[] = newValue?.map(
                    (value) => dxPointerOptions?.[value]
                  );
                  return onChangeMulti(chosenOptions);
                }}
                renderValue={renderDxPointerValue}
                variant={MultiSelectVariant.FILLED}
                placeholder={t('PICK_DX')}
                value={
                  value?.map((value: EncounterDiagnosis) =>
                    value.index.toString()
                  ) || []
                }
                options={dxPointerSelectOptions}
              />
            )}
          />
        );
      },
      renderCell: ({ row: { dxPointers: dxPointers } }) =>
        dxPointers?.map((pointer) => pointer?.label).join(', ')
    },
    {
      flex: 1,
      field: 'serviceUnit',
      headerName: t('SERVICE_UNIT'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <InputField
              onChange={(e) => onChange(e.target.value)}
              value={value}
              type="number"
              variant={InputFieldVariant.FILLED}
              autoFocus
            />
          )}
        />
      ),
      renderCell: ({ row: { serviceUnit } }) => serviceUnit
    },
    {
      flex: 2,
      field: 'ndcCode',
      headerName: t('NDC'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <Autocomplete<NDCCode>
              onChange={(_, selectedOption: NDCCode) => {
                return onChange(selectedOption?.code);
              }}
              fullWidth
              value={value as NDCCode}
              options={ndcOptions}
              renderInput={(props) => (
                <InputField
                  {...props}
                  variant={InputFieldVariant.FILLED}
                  autoFocus
                  fullWidth
                  InputProps={props.InputProps}
                />
              )}
            />
          )}
        />
      ),
      renderCell: ({ row: { ndcCode } }) => ndcCode
    },
    {
      flex: 1,
      field: 'dosage',
      headerName: t('DOSAGE'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <InputField
              onChange={(e) => onChange(e.target.value)}
              value={value}
              type="number"
              variant={InputFieldVariant.FILLED}
              autoFocus
            />
          )}
        />
      ),
      renderCell: ({ row: { dosage } }) => dosage
    },
    {
      flex: 1,
      field: 'dosageUnit',
      headerName: t('DOSAGE_UNIT'),
      editable: true,
      renderEditCell: (params) => (
        <CustomEditCellComponent
          editCellParams={params}
          EditorComponent={({ value, onChange }) => (
            <Select
              defaultOption={t('PICK_UNIT')}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              options={dosageUnitOptionsStub}
              variant="filled"
            />
          )}
        />
      ),
      renderCell: ({ row: { dosageUnit } }) => dosageUnit
    },
    {
      flex: 1,
      field: 'actions',
      headerName: t('ACTIONS'),
      renderCell: ({ row }) => {
        return (
          <Flex justifyContent="center" alignContent="center">
            <MiniIconButton
              onClick={() => {
                const newRows = rows.filter((r) => r.id !== row.id);
                updateForm(newRows);
              }}
              icon={<RemoveIcon />}
              iconColor="emperor"
              bgColor="alto"
            />
          </Flex>
        );
      }
    }
  ];

  const displayedColumns: GridColDef<EncounterCodeRow>[] = columns.map(
    (column) => ({
      ...column,
      renderHeader: () => (
        <Typography fontWeight={fontWeights.extraBold}>
          {column.headerName}
        </Typography>
      ),
      filterable: false,
      hideable: false
    })
  );

  const handleRowUpdate = (
    newRow: EncounterCodeRow,
    oldRow: EncounterCodeRow
  ) => {
    const newRows = rows.map((row) => {
      if (row.id === oldRow.id) {
        return newRow;
      }
      return row;
    });
    updateForm(newRows);
    return newRow;
  };

  return (
    <Table
      loading={isLoading}
      processRowUpdate={handleRowUpdate}
      disableColumnSelector
      hideFilterToolbar
      paginationModel={{ page: 0, pageSize: PageSizes.LARGE }}
      rows={rows}
      getRowHeight={() => RowHeights.md}
      columns={displayedColumns}
      autoHeight
    />
  );
};

export default CodingTable;
