import { FC } from 'react';
import {
  Slider as MuiSlider,
  SliderProps as MuiLabelProps,
  styled,
  css
} from '@mui/material/';
import { Colors } from 'src/components/styles/colors';
import { fonts } from 'src/components/styles/fonts';
import Box from 'src/components/layout/Box/Box';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';

interface SliderProps extends MuiLabelProps {
  label?: string;
  value?: number;
}

const StyledLabel = styled(InputLabel)`
  margin: 0;
`;

const StyledSlider = styled(MuiSlider)<SliderProps>`
  ${({ disabled, onClick }) =>
    disabled &&
    !onClick &&
    css`
      cursor: auto;
    `}
  .Mui-disabled {
    color: initial;
    pointer-events: all;
    cursor: ${({ onClick }) => (!onClick ? 'default' : 'pointer')};
  }
  .MuiSlider-rail {
    color: ${Colors.cupid};
    opacity: 0.5;
  }
  .MuiSlider-track {
    color: ${({ value, max }) =>
      value > max ? Colors.thunderBird2 : Colors.cupid};
  }
  .MuiSlider-thumb {
    color: ${({ value, max }) =>
      value > max ? Colors.thunderBird2 : Colors.cupid};
    width: 10px;
    height: 10px;

    &:hover {
      box-shadow: none;
    }

    .MuiSlider-valueLabel {
      top: 30px;
      background-color: ${Colors.transparent};
      color: ${Colors.emperor};
      font: ${fonts.caption};
    }
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: none;
    }
  }
  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-markLabel {
    color: ${Colors.alto};
    font: ${fonts.caption};
    top: 18px;

    &::before {
      content: '';
      height: 6px;
      background-color: ${Colors.alto};
      width: 1px;
      display: block;
      margin: auto;
    }
  }
`;

const Slider: FC<SliderProps> = ({
  label,
  disabled,
  onChange,
  ...otherProps
}) => {
  return (
    <Box>
      {label && <StyledLabel label={label} />}
      <StyledSlider
        {...otherProps}
        disabled={disabled}
        onChange={disabled ? null : onChange}
        valueLabelFormat={() => otherProps.value}
      />
    </Box>
  );
};

export default Slider;
