const enNavbarTranslations = {
  DASHBOARD_NAVBAR_TITLE: 'Dashboard',
  CALENDAR_NAVBAR_TITLE: 'Calendar',
  INBOX_NAVBAR_TITLE: 'Inbox',
  PATIENTS_NAVBAR_TITLE: 'Patients',
  STATISTICS_NAVBAR_TITLE: 'Statistics',
  LANGUAGE_SWITCHER: 'Language Switcher',
  NOTIFICATIONS: 'Notifications',
  MARK_ALL_AS_READ: 'Mark all as read',
  MISSED_CALL: 'Missed call',
  CLINIC_FEED_TITLE: 'Clinic Feed'
};

export default enNavbarTranslations;
