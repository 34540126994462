import { AxiosError } from 'axios';
import { TOptions } from 'i18next';
import {
  createContext,
  ReactNode,
  ReactElement,
  useContext,
  useCallback,
  useState
} from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import Toast, { ToastType } from 'src/components/display/Toast/Toast';
import { supportEmailAddress } from 'src/utils/general';
interface HandleQueryResultParams {
  data?: unknown;
  error?: AxiosError;
  actionName: string;
}

interface GetToastInfoParams extends HandleQueryResultParams {
  t: (keys: TFuncKey, options?: TOptions | string) => string;
}

interface GetToastInfoResponse {
  toastType: ToastType;
  title: string;
  children?: string;
}

const getToastInfo = ({
  error,
  actionName,
  t
}: GetToastInfoParams): GetToastInfoResponse | null => {
  if (error) {
    switch (error?.response?.status) {
      case 400: {
        let children = '';
        switch (actionName) {
          case t('ACTION_TITLE_DELETE_APPOINTMENT'): {
            children = t('SUBTITLE_CANNOT_DELETE_APPOINTMENT_IN_THE_PAST');
            break;
          }
          default: {
            children = t('REPORT_TO_EMBIE_MAIL', {
              email: supportEmailAddress
            });
            break;
          }
        }
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_400_TOAST_TEMPLATE', { action: actionName }),
          children
        };
      }
      case 401:
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_401_TOAST_TEMPLATE', { action: actionName }),
          children: t('PLEASE_LOG_IN')
        };
      case 403:
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_403_TOAST_TEMPLATE', { action: actionName }),
          children: t('CONTACT_CLINIC_ADMIN')
        };
      case 404:
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_404_TOAST_TEMPLATE', { action: actionName }),
          children: t('REPORT_TO_EMBIE_MAIL', {
            email: supportEmailAddress
          })
        };
      case 409: {
        let children = '';
        switch (actionName) {
          case t('ACTION_TITLE_UPDATE_CYCLE'): {
            children = t('SUBTITLE_ONGOING_CYCLE_ALREADY_EXISTS');
            break;
          }
          case t('ACTION_TITLE_CREATE_EXAM_REPORT'): {
            children = t(
              'SUBTITLE_CANNOT_HAVE_TWO_FOLLICULAR_EXAMS_IN_ONE_DAY'
            );
            break;
          }
          case t('ACTION_TITLE_CREATE_PATIENT'): {
            children = t('SUBTITLE_PATIENT_ALREADY_EXISTS');
            break;
          }
          case t('ACTION_TITLE_CREATE_STAFF_MEMBER'): {
            children = t('SUBTITLE_STAFF_MEMBER_ALREADY_EXISTS');
            break;
          }
          default: {
            children = t('ONE_ALEADY_EXISTS');
          }
        }
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_409_TOAST_TEMPLATE', { action: actionName }),
          children
        };
      }
      case 422: {
        let children = '';
        switch (actionName) {
          case t('ACTION_TITLE_UPDATING_PRESCTIPTION_DOSAGE'): {
            children = t('SUBTITLE_CANNOT_CREATE_PRESCRIPTION_GAP');
            break;
          }
          default: {
            children = t('CANNOT_BE_PERFORMED');
            break;
          }
        }
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_422_TOAST_TEMPLATE', { action: actionName }),
          children
        };
      }
      case 429:
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_429_TOAST_TEMPLATE', { action: actionName }),
          children: t('SLOW_DOWN')
        };

      case 500:
      default:
        return {
          toastType: ToastType.ERROR,
          title: t('REQUEST_500_TOAST_TEMPLATE', { action: actionName }),
          children: t('TRY_AGAIN_IF_PERSISTS_CONTACT_SUPPORT')
        };
    }
  }
  return null;
};

type ProviderContext = {
  openToast: ({
    type,
    title
  }: {
    type: ToastType;
    title: ReactNode;
    children?: ReactNode;
  }) => void;
  handleQueryResultToast: ({
    data,
    error,
    actionName
  }: HandleQueryResultParams) => void;
};

const ToastContext = createContext<ProviderContext>(
  null as unknown as ProviderContext
);

function ToastProvider({
  children = ''
}: {
  children?: ReactNode;
}): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [toastProps, setToastProps] = useState<{
    toastType: ToastType;
    title: ReactNode;
    children: ReactNode;
  }>({
    toastType: ToastType.INFO,
    title: '',
    children: ''
  });

  const openToast = useCallback(
    ({
      type,
      title,
      children = ''
    }: {
      type: ToastType;
      title: ReactNode;
      children?: ReactNode;
    }) => {
      setToastProps({ toastType: type, title, children });
      setOpen(true);
    },
    []
  );

  const handleQueryResultToast = ({
    data,
    error,
    actionName
  }: HandleQueryResultParams) => {
    const toastInfoResult = getToastInfo({
      data,
      error,
      actionName,
      t
    });

    if (!toastInfoResult) return;

    const { toastType, title, children } = toastInfoResult;
    openToast({
      type: toastType,
      title,
      children
    });

    return;
  };

  const handleClose = useCallback(() => setOpen(false), []);
  const contextValue = {
    openToast,
    handleQueryResultToast
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast open={open} handleClose={handleClose} {...toastProps} />
    </ToastContext.Provider>
  );
}

export function useToast(): ProviderContext {
  const result = useContext(ToastContext);
  if (!result) {
    throw new Error('Toast context is only available inside ToastProvider');
  }
  return result;
}

export default ToastProvider;
