import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import { convertTaskClientToServer } from 'src/modules/patients/utils/conversions';
import { AppError } from 'src/types/global';
import {
  AddChecklistTemplateType,
  ChecklistTemplate,
  Task
} from 'src/types/task';
import { TaskServer } from 'src/types/patient-server';
import {
  addChecklistTemplateRequest,
  createTaskRequest,
  deleteTaskRequest,
  getChecklistTemplatesRequest,
  getTasksByStaffId,
  getTasksByStaffPatientId,
  updateTaskRequest
} from 'src/api/tasks.api';
import { queryKeys, querySubKeys } from './queryKeys';

function useTasks() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    deleteTask: () =>
      useMutation<string, AppError, { taskId: string; patientId: string }>(
        ({ taskId, patientId }) => deleteTaskRequest(taskId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_DELETE_TASK')
            }),

          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('DELETE_TASK_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.TASKS]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].TASKS
            ]);
          }
        }
      ),
    createTask: () =>
      useMutation<string, AppError, TaskServer>(createTaskRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_TASK')
          }),

        onSuccess: (patientId) => {
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].TASKS
          ]);
          openToast({
            title: t('CREATE_TASK_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
        }
      }),
    updateTask: () =>
      useMutation<string, AppError, Partial<Task>>(
        (task) => updateTaskRequest(convertTaskClientToServer(task)),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_TASK')
            }),

          onSuccess: (_, { patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].TASKS
            ]);
            queryClient.invalidateQueries([queryKeys.TASKS]);
            openToast({
              title: t('UPDATE_TASK_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    getTasksAssignedToStaff: (
      staffId: string,
      options?: UseQueryOptions<Task[], AppError>
    ) =>
      useQuery<Task[], AppError>(
        [queryKeys.TASKS, staffId],
        () => getTasksByStaffId(staffId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_ASSIGNED_TO_STAFF')
            }),

          ...options
        }
      ),
    getTasksByStaffPatientId: (
      staffId: string,
      patientId: string,
      options?: UseQueryOptions<Task[], AppError>
    ) =>
      useQuery<Task[], AppError>(
        [queryKeys.TASKS, patientId, staffId],
        () => getTasksByStaffPatientId(staffId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_BY_PATIENT')
            }),

          ...options
        }
      ),
    getChecklistTemplates: (patientId) =>
      useQuery<ChecklistTemplate[], AppError>(
        [queryKeys.TASKS, patientId],
        () => getChecklistTemplatesRequest(patientId)
      ),
    addChecklistTemplateToPatient: () =>
      useMutation<
        Task[],
        AppError,
        AddChecklistTemplateType & { onSuccess: () => void }
      >((taskTemplateToAdd) => addChecklistTemplateRequest(taskTemplateToAdd), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_ADD_CHECKLIST_TEMPLATE')
          }),
        onSuccess: (_, { patientId, onSuccess }) => {
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].TASKS
          ]);
          openToast({
            title: t('ADD_CHECKLIST_TEMPLATE_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          onSuccess();
        }
      })
  };
}

export default useTasks;
