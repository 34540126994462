import { FC } from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  styled,
  SvgIcon
} from '@mui/material';

import { ReactComponent as CheckedIcon } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as CircleIcon } from '../../../assets/icons/circle.svg';
import { spacings } from 'src/components/styles/constants';
import { fonts } from '../../styles/fonts';
import { Colors } from '../../styles/colors';

type LabelPlacement = 'start' | 'end' | 'top' | 'bottom';
interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  labelPlacement?: LabelPlacement;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  gap: ${spacings.medium};
  margin: 0;
  .MuiFormControlLabel-label {
    user-select: none;
    font: ${fonts.label};
    color: ${Colors.emperor};
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const StyledCheckbox = styled(MuiCheckbox)`
  &:hover {
    background-color: transparent;
  }
`;

const Checkbox: FC<CheckboxProps> = ({
  label,
  labelPlacement = 'start',
  ...otherProps
}) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          color="default"
          icon={
            <SvgIcon>
              <CircleIcon />
            </SvgIcon>
          }
          checkedIcon={
            <SvgIcon>
              <CheckedIcon />
            </SvgIcon>
          }
          {...otherProps}
          disableRipple
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default Checkbox;
