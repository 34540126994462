import {
  ALL_ROOMS_OPTION,
  AllPurposesOption,
  Appointment,
  AppointmentPurposeFilterOptions,
  BusyTime
} from './../../../types/appointment';

export const getUnifiedBusyTimes = (
  busyTimes: BusyTime[]
): Partial<BusyTime>[] => {
  const sortedBusyTimes = busyTimes.sort(
    ({ startTime: a }: BusyTime, { startTime: b }: BusyTime) =>
      a.isBefore(b) ? -1 : 1
  );

  const unifiedBusyTimes: Partial<BusyTime>[] = [];

  for (let i = 0; i < sortedBusyTimes.length; i++) {
    const { startTime, endTime } = sortedBusyTimes[i];

    let currentEndTime = endTime;

    for (let j = i + 1; j < sortedBusyTimes.length; j++) {
      const { startTime, endTime } = sortedBusyTimes[j];
      if (
        startTime.isSameOrBefore(currentEndTime, 'minute') &&
        endTime.isAfter(currentEndTime)
      ) {
        currentEndTime = endTime;
      }

      if (startTime.isAfter(currentEndTime)) {
        i = j - 1;
        break;
      }

      i = j;
    }

    unifiedBusyTimes.push({
      startTime,
      endTime: currentEndTime,
      status: 'busy'
    });
  }

  return unifiedBusyTimes;
};

export const getFilteredAppointments = (
  appointments: Appointment[],
  filterByAppointment: AppointmentPurposeFilterOptions,
  filterByStaff: string[],
  filterByRoom: string
): Appointment[] => {
  if (!filterByAppointment && !filterByStaff && !filterByRoom) {
    return appointments;
  }

  let filteredAppointments = appointments;

  if (filterByAppointment != AllPurposesOption.ALL_TYPES) {
    filteredAppointments = filteredAppointments.filter(
      (appointment) => appointment.appointmentPurpose === filterByAppointment
    );
  }

  if (filterByStaff.length > 0) {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        filterByStaff.filter((staffToFilterBy) =>
          appointment.staffIds?.includes(staffToFilterBy)
        ).length > 0
    );
  }

  if (filterByRoom !== ALL_ROOMS_OPTION) {
    filteredAppointments = filteredAppointments.filter((appointment) => {
      return appointment?.room === filterByRoom;
    });
  }
  return filteredAppointments;
};
