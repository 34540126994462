import { AxiosResponse } from 'axios';
import {
  convertInvoiceClientToServer,
  convertInvoiceServerToClient
} from '../modules/billing/utils/conversions';
import {
  AllStatusOptions,
  BillingProfile,
  BillingStats,
  InvoiceServer,
  Invoice,
  StatusesOptions,
  InvoiceStatus,
  InvoiceMethod
} from '../types/billing';
import { Trend } from '../types/global';
import axiosApiInstance from '../utils/axios';

export const getBillingStatsRequest = async (): Promise<BillingStats> => {
  return {
    paid: {
      amount: 203366,
      trend: Trend.ABOVE,
      trendPercentage: 10
    },
    quotes: {
      amount: 115386,
      trend: Trend.BELOW,
      trendPercentage: 20
    },
    pending: {
      amount: 23366
    }
  };
};

export const getInvoicesRequest = async (
  status?: StatusesOptions,
  patientId?: string
): Promise<Invoice[]> => {
  const params = new URLSearchParams();

  if (status && status !== AllStatusOptions.ALL_STATUS) {
    params.append('status', status);
  }
  const paramsString = params.toString();
  const queryString = paramsString ? `?${paramsString}` : '';

  let response: AxiosResponse<InvoiceServer[]>;

  if (patientId) {
    response = await axiosApiInstance.get<InvoiceServer[]>(
      `/invoices/patients/${patientId}${queryString}`
    );
  } else {
    response = await axiosApiInstance.get<InvoiceServer[]>(
      `/invoices${queryString ? `?${queryString}` : ''}`
    );
  }

  return response.data.map((invoice) => convertInvoiceServerToClient(invoice));
};

export const getInvoiceByIdRequest = async (
  invoiceId: string
): Promise<Invoice> => {
  const response = await axiosApiInstance.get<InvoiceServer>(
    `/invoices/${invoiceId}`
  );

  return convertInvoiceServerToClient(response.data);
};

export const getBillingProfileRequest = async (
  patientId: string
): Promise<BillingProfile> => {
  return {
    patientId,
    firstName: 'John',
    lastName: 'Doe',
    address: '1234 Main St',
    city: 'San Francisco',
    paymentProfile: 'NHS',
    country: 'USA',
    currency: 'USD',
    healthProvider: {
      name: 'NHS',
      memberNumber: 'i0000010108y',
      groupNumber: '123456',
      effectiveDate: new Date()
    }
  };
};

export const createInvoiceRequest = async (invoice: Invoice) => {
  const response = await axiosApiInstance.post<string>(
    '/invoices',
    convertInvoiceClientToServer(invoice)
  );
  return response.data;
};

export const updateInvoiceRequest = async (invoice: Invoice) => {
  const response = await axiosApiInstance.patch<string>(
    `/invoices/${invoice.id}`,
    convertInvoiceClientToServer(invoice)
  );
  return response.data;
};

export const updateInvoiceStatusRequest = async (
  id: string,
  status: InvoiceStatus
) => {
  const response = await axiosApiInstance.patch<string>(`/invoices/${id}`, {
    status
  });
  return response.data;
};

export const updateInvoiceMethodRequest = async (
  id: string,
  method: InvoiceMethod
) => {
  const response = await axiosApiInstance.patch<string>(`/invoices/${id}`, {
    method
  });
  return response.data;
};
