import { FC, useEffect, useLayoutEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/data-entry/TextArea';
import IconButton from 'src/components/display/IconButton';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { iconSizes, spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import EncounterDetailsSection from '../EncounterDetailsSection';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from 'src/components/display/Button';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import { INote } from 'src/types/cycle';
import DiagnosticsSelector from '../DiagnosticsSelector';
import useEncounters from 'src/hooks/useEncounters';
import {
  Encounter,
  EncounterDiagnosis,
  EncounterLockStatus
} from 'src/types/encounter';
import { DevTool as ReactHookFormDevTool } from '@hookform/devtools';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

interface EncounterNotesProps {
  encounterNotes?: string;
  actionsTaken?: string;
  results?: string;
  amendments?: INote[];
  diagnoses?: EncounterDiagnosis[];
  createdByStaffId?: string;
}

const EncounterNotesTab: FC<{
  appointmentId: string;
  patientId: string;
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
}> = ({ appointmentId, patientId, onDirtyFormChange }) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { getPatientById } = usePatientsApi();
  const {
    getEncounterByAppointmentId,
    updateEncounter,
    generateEncounterActionsTaken,
    updateEncounterLockStatus
  } = useEncounters();
  const { mutate: updateLockStatusMutation } = updateEncounterLockStatus();
  const { mutate: updateEncounterMutation } = updateEncounter();
  const { data: encounter, isLoading: isLoadingEncounter } =
    getEncounterByAppointmentId(appointmentId);
  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const {
    data: generatedActionsTaken,
    refetch: generateActionsTaken,
    isFetching: isLoadingActionsTaken
  } = generateEncounterActionsTaken(encounter?.id);
  // const documents = [mockDocument, mockDocument]; // TODO - create a request that grabs related documents with the PatientDocument type

  const defaultValues: Partial<Encounter> = {
    encounterNotes: encounter?.encounterNotes || '',
    actionsTaken: encounter?.actionsTaken || '',
    results: encounter?.results || '',
    amendments: [],
    diagnoses: encounter?.diagnoses || [],
    createdByStaffId: encounter?.createdByStaffId
  };

  const { control, formState, watch, setError, reset, setValue } =
    useForm<EncounterNotesProps>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    reset(defaultValues);
  }, [encounter]);

  const { errors, isDirty } = formState;

  const { actionsTaken, diagnoses, ...formToSend } = watch();

  const isDiagnosisArrayValid = !diagnoses.filter((dx) => dx === null).length;

  useEffect(() => {
    if (isDirty) {
      onDirtyFormChange(true);
    }
  }, [isDirty]);

  /**
   * This is a layout effect because I want the functionality to trigger only whenever the dep array is changes,
   * and not on the initial render. In that case this means, that even if the generated summary already exists in the request data
   * I don't want it loaded into the textbox, unless the user has pressed the generate button which will refetch the summary
   */
  useLayoutEffect(() => {
    if (!actionsTaken && generatedActionsTaken) {
      setValue('actionsTaken', generatedActionsTaken, { shouldDirty: true });
    }
  }, [isLoadingActionsTaken]);

  if (isLoadingEncounter || isLoadingPatient) {
    return <Loader />;
  }

  const { isLocked } = encounter;

  const handleLockToggle = () => {
    if (isLocked) {
      updateLockStatusMutation({
        patientId,
        encounterId: encounter.id,
        newStatus: EncounterLockStatus.UNLOCKED
      });
    } else {
      handleSubmit({ lockAfterSave: true });
    }
  };

  const handleSubmit = ({ lockAfterSave }: { lockAfterSave: boolean }) => {
    if (!isDiagnosisArrayValid) {
      setError('diagnoses', {
        type: 'custom',
        message: t('NO_NULL_DIAGNOSES_ERROR')
      });
      return;
    }
    const encounterToSend: Partial<Encounter> = {
      ...formToSend,
      id: encounter.id,
      appointmentId,
      diagnoses,
      actionsTaken
    };

    updateEncounterMutation(
      {
        isLockingAfterSave: lockAfterSave,
        ...encounterToSend
      },
      {
        onSuccess: lockAfterSave
          ? () =>
              updateLockStatusMutation({
                patientId,
                encounterId: encounter.id,
                newStatus: EncounterLockStatus.LOCKED
              })
          : closeDialog
      }
    );

    onDirtyFormChange(false);
  };

  const isGenerateActionsTakenButtonDisabled =
    !!actionsTaken || isLoadingActionsTaken || isLocked;
  const isSubmitButtonDisabled = isLoadingEncounter || isLocked;

  return (
    <Flex
      flexDirection="column"
      gap={spacings.xxlarge}
      paddingBottom={spacings.large}
    >
      <Flex gap={spacings.xxxlarge}>
        <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
          <Flex gap={spacings.xlarge}>
            <Controller
              name="createdByStaffId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EncounterDetailsSection
                  patientFullName={getFullName(patient?.personalInfo)}
                  encounterDate={encounter?.encounterDate}
                  encounterId={encounter?.displayId}
                  disabled={isLocked}
                  performedBy={value}
                  performedByHeader={t('RENDERING_PROVIDER')}
                  onPerformedByChange={onChange}
                />
              )}
            />
            {
              // TODO - Bring this back when ready
              /* <InsuranceDetailsSection
              primaryInsurance="Arizona Medicare (07350422)"
              secondaryInsurance="Arizona Medicaid (42210261)"
              eligibility={true}
            /> */
            }
          </Flex>
          <Flex flexDirection="column">
            <Controller
              name="diagnoses"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DiagnosticsSelector
                  disabled={isLocked}
                  error={!!errors?.diagnoses}
                  helperText={errors?.diagnoses?.message}
                  values={value}
                  onChange={({
                    diagnoses
                  }: {
                    diagnoses: Array<EncounterDiagnosis | null>;
                  }) => onChange(diagnoses)}
                />
              )}
            />
          </Flex>
          {
            // TODO - Bring amendments & documents back when ready
            /* <Flex flexDirection="column">
            <Flex
              justifyContent="flex-start"
              gap={spacings.medium}
              alignItems="center"
            >
              <Typography variant="h2" fontWeight={weights.extraBold}>
                {t('AMENDMENTS')}
              </Typography>
              <Chips
                variant={ChipsVariants.NOTES}
                value={amendments}
                disabled={isLocked}
                chipsInputLabel={t('ADD_COMMENT')}
                allowAddingNewOptions
                onAddChip={(newChip) => {
                  setAmendments([
                    ...amendments,
                    {
                      value: newChip,
                      createdByStaffId: me.user.id,
                      createdAt: new Date()
                    }
                  ]);
                  setNotesHaveChanged(true);
                }}
                onRemoveChip={(_, index) => {
                  setAmendments(
                    amendments.filter((_, currIndex) => currIndex !== index)
                  );
                  setNotesHaveChanged(true);
                }}
              />
            </Flex>
            <NotesList
              notes={amendments}
              onRemoveNote={(_, index) => {
                setAmendments(
                  amendments.filter((_, currIndex) => currIndex !== index)
                );
                // handle saving the removed amendment
              }}
              fullWidth={false}
            />
          </Flex> */
          }
          {/* <Flex flexDirection="column" gap={spacings.medium}>
            <Typography variant="h2" fontWeight={weights.extraBold}>
              {t('DOCUMENTS')}
            </Typography>
            <Flex flexDirection="column" gap={spacings.small}>
              {documents.map((document, index) => (
                <DocumentSummaryDisplay
                  key={index}
                  status={document.status}
                  title={document.title}
                  statusColor={document.color}
                  onClickEdit={() => {
                    // open the document editor for the appropriate document
                  }}
                />
              ))}
            </Flex>
          </Flex> */}
        </Flex>
        <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
          <Controller
            name="encounterNotes"
            control={control}
            disabled={isLocked}
            render={({ field: { ref, ...field } }) => (
              <TextArea
                {...field}
                resize="vertical"
                error={!!errors.encounterNotes}
                helperText={errors.encounterNotes?.message}
                ref={ref}
                label={
                  <Typography fontWeight={fontWeights.extraBold}>
                    {t('ENCOUNTER_NOTES').toUpperCase()}
                  </Typography>
                }
                placeholder={t('ENCOUNTER_NOTES')}
                fullWidth
                minRows={8}
                maxRows={8}
              />
            )}
          />
          <Controller
            name="actionsTaken"
            control={control}
            render={({ field: { ref, value, ...field } }) => (
              <TextArea
                {...field}
                error={!!errors.actionsTaken}
                helperText={errors.actionsTaken?.message}
                ref={ref}
                value={value}
                disabled={isLocked}
                label={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Typography fontWeight={fontWeights.extraBold}>
                      {t('ACTIONS_TAKEN').toUpperCase()}
                    </Typography>
                    <IconButton
                      disabled={isGenerateActionsTakenButtonDisabled}
                      icon={
                        isLoadingActionsTaken ? (
                          <Loader size={iconSizes.small} />
                        ) : (
                          <RefreshIcon />
                        )
                      }
                      bgColor="black"
                      iconColor="white"
                      iconSize="xsmall"
                      onClick={() => {
                        generateActionsTaken();
                      }}
                    />
                  </Flex>
                }
                placeholder={t('ACTIONS_TAKEN')}
                fullWidth
                minRows={8}
                maxRows={8}
              />
            )}
          />
          <Controller
            name="results"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextArea
                {...field}
                error={!!errors.results}
                helperText={errors.results?.message}
                ref={ref}
                disabled={isLocked}
                label={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Typography fontWeight={fontWeights.extraBold}>
                      {t('RESULTS').toUpperCase()}
                    </Typography>
                    <IconButton
                      icon={<RefreshIcon />}
                      bgColor="black"
                      iconColor="white"
                      iconSize="xsmall"
                      disabled={isLocked}
                      onClick={() => {
                        //TODO - Refresh results template
                      }}
                    />
                  </Flex>
                }
                placeholder={t('RESULTS')}
                fullWidth
                minRows={3}
                maxRows={3}
              />
            )}
          />
        </Flex>
      </Flex>
      <Flex gap={spacings.xlarge} justifyContent="flex-end">
        <Button
          type="submit"
          onClick={() => handleSubmit({ lockAfterSave: false })}
          disabled={isSubmitButtonDisabled}
        >
          {t('SAVE_DRAFT')}
        </Button>
        <Button
          startIcon={isLocked ? <LockOpenIcon /> : <LockIcon />}
          onClick={handleLockToggle}
        >
          {isLocked ? t('UNLOCK_NOTE') : t('LOCK_NOTE')}
        </Button>
      </Flex>
      <ReactHookFormDevTool control={control} />
    </Flex>
  );
};

export default EncounterNotesTab;
