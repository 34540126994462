import { convertEncounterServerToClient } from './../modules/patients/utils/conversions';
import { EncounterServer } from './../types/patient-server';
import axiosApiInstance from 'src/utils/axios';
import {
  Encounter,
  EncounterCodeRow,
  EncounterInsuranceInfo,
  EncounterLockStatus,
  EncounterLockStatusActions,
  UpdateEncounterCodeRowsRequestDto
} from './../types/encounter';

export async function getEncounterByAppointmentIdRequest(
  appointmentId: string
): Promise<Encounter> {
  const response = await axiosApiInstance.get<EncounterServer>(
    `encounters/appointment/${appointmentId}`
  );

  return convertEncounterServerToClient(response.data);
}

export async function getEncounterCodeRowsByAppointmentIdRequest(
  appointmentId: string
): Promise<EncounterCodeRow[]> {
  const codeRows = await axiosApiInstance.get<EncounterCodeRow[]>(
    `encounters/appointment/${appointmentId}/service-codes`
  );

  return codeRows.data;
}

export async function updateEncounterCodeRowsRequest(
  updateEncounterDto: UpdateEncounterCodeRowsRequestDto
): Promise<void> {
  await axiosApiInstance.post<void>(
    `encounters/${updateEncounterDto.encounterId}/service-codes`,
    updateEncounterDto
  );
}

export async function updateEncounterRequest(
  encounter: Partial<Encounter>,
  isLockingAfterSave?: boolean
): Promise<void> {
  await axiosApiInstance.patch<void>(
    `encounters/${encounter.id}`,
    encounter,
    isLockingAfterSave
      ? {
          params: { isLockingAfterSave }
        }
      : null
  );
}

export async function getEncounterInsuranceInfoRequest(
  _appointmentId: string
): Promise<EncounterInsuranceInfo> {
  // const response = await axiosApiInstance.get<EncounterInsuranceInfo>(
  //   `encounters/${encounterId}/insurance`
  // );
  return {
    primaryInsurance: 'Arizona Medicare (07350422)',
    secondaryInsurance: 'Arizona Medicaid (42210261)',
    eligibility: true
  };
}

export async function generateEncounterActionsTakenRequest(
  encounterId: string
): Promise<string> {
  const response = await axiosApiInstance.get<string>(
    `encounters/${encounterId}/actions-taken`
  );
  return response.data;
}

export async function updateEncounterLockStatusRequest({
  encounterId,
  newStatus,
  patientId
}: {
  encounterId: string;
  newStatus: EncounterLockStatus;
  patientId: string;
}): Promise<Encounter> {
  const response = await axiosApiInstance.post<EncounterServer>(
    `encounters/${encounterId}/${
      newStatus === EncounterLockStatus.LOCKED
        ? EncounterLockStatusActions.LOCK
        : EncounterLockStatusActions.UNLOCK
    }`,
    { patientId }
  );

  return convertEncounterServerToClient(response.data);
}
