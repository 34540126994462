import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';

import {
  createThawReportRequest,
  getThawReportRequest,
  updateGameteThawedRequest
} from 'src/api/eggAndEmbryo.api';
import { UpdateEgg, UpdateGameteThawed } from 'src/types/eggAndEmbryo';
import { AppError } from 'src/types/global';
import { GameteThawReport, ThawReportForm } from 'src/types/cycle';
import { ThawReportFormServer } from 'src/types/patient-server';
import { queryKeys, querySubKeys } from './queryKeys';

function useGameteThaw() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const queryClient = useQueryClient();

  return {
    getGameteThawReport: (
      patientId: string,
      cycleId: string,
      options?: UseQueryOptions<GameteThawReport, AppError>
    ) =>
      useQuery<GameteThawReport, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].CYCLES,
          cycleId,
          querySubKeys[queryKeys.PATIENTS].THAW_REPORT
        ],
        () => getThawReportRequest(patientId, cycleId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_GAMETE_THAW_REPORT')
            }),
          ...options
        }
      ),
    createThawReport: () =>
      useMutation<ThawReportFormServer, AppError, ThawReportForm>(
        (report: ThawReportForm) => createThawReportRequest(report),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_THAW_REPORT')
            }),
          onSuccess: (_, { patientId, cycleId }) => {
            openToast({
              title: t('CREATE_THAW_REPORT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            closeDialog();

            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CRYO_GAMETE
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].THAW_REPORT
            ]);
          }
        }
      ),
    updateGameteThawed: () =>
      useMutation<
        ThawReportFormServer,
        AppError,
        {
          patientId: string;
          gameteId: string;
          cycleId: string;
          gamete: UpdateGameteThawed;
        }
      >(
        ({
          gamete,
          gameteId,
          patientId
        }: {
          patientId: string;
          gameteId: string;
          gamete: UpdateEgg;
        }) => updateGameteThawedRequest(patientId, gameteId, gamete),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_GAMETE_THAWED')
            }),
          onSuccess: (_, { cycleId, patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].THAW_REPORT
            ]);
          }
        }
      )
  };
}

export default useGameteThaw;
