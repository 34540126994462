import { FC } from 'react';
import { styled, TextareaAutosize, TextareaAutosizeProps } from '@mui/material';

import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import { InputFieldProps } from '../InputField';
import { makeShouldForwardProps } from 'src/components/utils';
import { fonts } from 'src/components/styles/fonts';

interface TextAreaProps
  extends TextareaAutosizeProps,
    Pick<
      InputFieldProps,
      'label' | 'helperText' | 'error' | 'fullWidth' | 'labelProps'
    > {
  resize?: 'auto' | 'none' | 'vertical' | 'horizontal';
}

const shouldForwardProp = makeShouldForwardProps([
  'resize',
  'helperText',
  'fullWidth'
]);
const StyledTeaxtArea = styled(TextareaAutosize, {
  shouldForwardProp
})<TextAreaProps>`
  border-color: ${Colors.gray};
  border-radius: ${radii.medium};
  resize: ${({ resize }) => resize ?? 'none'};
  outline: none;
  padding: ${spacings.medium};
  box-sizing: border-box;
  font: ${fonts.input};
`;

const StyledInputLabel = styled(InputLabel)`
  flex-direction: column;
  margin: 0;
`;

const TextArea: FC<TextAreaProps> = ({
  children,
  resize,
  label,
  error,
  ref,
  labelProps,
  ...props
}) => {
  return (
    <StyledInputLabel label={label} error={error} rowGap={labelProps?.rowGap}>
      <StyledTeaxtArea ref={ref} resize={resize} {...props}>
        {children}
      </StyledTeaxtArea>
    </StyledInputLabel>
  );
};

export default TextArea;
