import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { ChannelTypes } from 'src/types/inbox';
import ApplicationsMessenger from '../../messenger';
import PatientHeader from '../PatientHeader';
import { patientTaskTypeToTitleMap } from 'src/types/task';

interface Props {
  channelId: string;
  chatType: ChannelTypes;
  patientId?: string;
}
const MessageInboxOverview = ({ channelId, patientId, chatType }: Props) => {
  return (
    <>
      {channelId && (
        <Flex flexDirection="column" flex={1} gap={spacings.small}>
          <Flex justifyContent="space-between">
            <Typography variant="h1" color={Colors.emperor}>
              {patientTaskTypeToTitleMap[0]}
            </Typography>
          </Flex>
          <PatientHeader patientId={patientId} />
          <ApplicationsMessenger
            chatType={chatType}
            patientId={patientId}
            width="100%"
            maxWidth="unset"
          />
        </Flex>
      )}
    </>
  );
};

export default MessageInboxOverview;
