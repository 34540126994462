import { Box } from '@mui/material';
import Loader from 'src/components/display/Loader';

import { iconSizes } from 'src/components/styles/constants';

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Loader size={iconSizes.xlarge} />
    </Box>
  );
}

export default AppInit;
