import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Loader from 'src/components/display/Loader/Loader';
import { PatientMiniCard } from '../common/PatientMiniCard';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import { PatientConsentFormsTable } from './PatientConsentFormsTable';
import { PatientGeneralDocumentsTable } from './PatientGeneralDocumentsTable';
import useAppointments from 'src/hooks/useAppointments';
import { useDocumentEditor } from 'src/components/components-api/GlobalProvider/GlobalProvider';

export const PatientForms: FC<{}> = () => {
  const { patientId } = useParams();
  const { getPatientOverview } = usePatientsApi();
  const { getPatientAppointments } = useAppointments();
  const { t } = useTranslation();

  const { data: patientOverview, isLoading: isLoadingPatientOverview } =
    getPatientOverview(patientId);

  const { isLoading: isLoadingAppointments } =
    getPatientAppointments(patientId);

  const { setDocumentEditorParams, setDocumentsModalOpen } =
    useDocumentEditor();

  const commonProps = {
    setDocumentsModalOpen: () => setDocumentsModalOpen(true),
    setDocumentEditorInfo: setDocumentEditorParams
  };
  if (isLoadingPatientOverview || isLoadingAppointments) return <Loader />;

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_FORMS_PAGE_TITLE')}</title>
      </Helmet>
      <Box width="100%">
        <Box width="70%" marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>

        <Flex gap={spacings.large} marginBottom={spacings.large} flex={1}>
          <Flex gap={spacings.large} flex={1}>
            <Box flex={1}>
              <PatientMiniCard
                marginBottom={spacings.large}
                properties={patientOverview?.patientProperties}
                basicInfo={patientOverview?.patientBasicInfo}
                patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
                patientId={patientId}
                header={t('PATIENT_FORMS')}
              />
              <PatientConsentFormsTable
                patientId={patientId}
                {...commonProps}
              />
              <PatientGeneralDocumentsTable
                patientId={patientId}
                {...commonProps}
              />
            </Box>

            {patientOverview?.partnerInfo && (
              <Box flex={1}>
                <PatientMiniCard
                  isPartner
                  marginBottom={spacings.large}
                  patientId={patientOverview?.partnerInfo?.id}
                  patientDisplayId={
                    patientOverview?.partnerInfo?.basicInfo?.displayId
                  }
                  properties={patientOverview?.partnerInfo?.properties}
                  basicInfo={patientOverview?.partnerInfo?.basicInfo}
                  header={t('PARTNER_FORMS')}
                />
                <PatientConsentFormsTable
                  patientId={patientOverview?.partnerInfo?.id}
                  isPartner
                  {...commonProps}
                />
                <PatientGeneralDocumentsTable
                  patientId={patientOverview?.partnerInfo?.id}
                  isPartner
                  {...commonProps}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
