import { useQuery, UseQueryOptions } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/components-api/ToastProvider';
import {
  getClinicExternalOrderBundles,
  getClinicInfoById
} from '../api/clinics.api';
import { Clinic } from 'src/types/clinic';
import { AppError } from 'src/types/global';
import { queryKeys, querySubKeys } from './queryKeys';
import { ExternalOrderBundle } from 'src/types/appointment';

function useClinicsApi() {
  const { t } = useTranslation();
  const { handleQueryResultToast } = useToast();
  return {
    getClinicInfo: (
      clinicId: string,
      options?: UseQueryOptions<Clinic, Error>
    ) =>
      useQuery<Clinic, AppError>(
        [queryKeys.CLINICS, clinicId],
        () => getClinicInfoById(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CLINIC')
            }),
          ...options
        }
      ),
    getClinicExternalOrderBundles: () =>
      useQuery<ExternalOrderBundle[], AppError>(
        [
          queryKeys.CLINICS,
          querySubKeys[queryKeys.CLINICS].EXTERNAL_ORDER_BUNDLES
        ],
        () => getClinicExternalOrderBundles()
      )
  };
}

export default useClinicsApi;
