import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions } from 'react-query';
import { useParams } from 'react-router-dom';
import Chips from 'src/components/data-entry/Chips';
import { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import { CycleNote, CycleNotesToCreate } from 'src/types/cycle';
import { AppError } from 'src/types/global';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import IconButton from 'src/components/display/IconButton';
import { ReactComponent as NoteIcon } from '../../../assets/icons/note.svg';
import useMeApi from 'src/hooks/useMeApi';
import {
  EggAndEmbryoNotes,
  EggAndEmbryoNotesToCreate
} from 'src/types/eggAndEmbryo';

interface Props {
  initialNotes: CycleNote[] | EggAndEmbryoNotes[];
  onUpsertNotes: (
    variables: CycleNotesToCreate | EggAndEmbryoNotesToCreate,
    options?: MutateOptions<void, AppError, CycleNotesToCreate, unknown>
  ) => Promise<EggAndEmbryoNotesToCreate>;
  date: string;
}
const NotesWrapper: FC<Props> = ({
  initialNotes,
  onUpsertNotes,
  date
}: Props) => {
  const [open, setOpen] = useState(false);
  const [notesHaveChanged, setNotesHaveChanged] = useState(false);
  const [notesToDelete, setNotesToDelete] = useState<string[]>([]);
  const [notes, setNotes] = useState<CycleNote[] | EggAndEmbryoNotes[]>(
    initialNotes || []
  );

  const { getMe } = useMeApi();
  const { data: me } = getMe();
  const { patientId, cycleId } = useParams();
  const { t } = useTranslation();

  const handleRemoveChip = (index: number) => {
    const chipsToSave = [...notes];
    setNotesToDelete((notesToDelete) => [...notesToDelete, notes[index].id]);
    chipsToSave.splice(index, 1);
    setNotes(chipsToSave);
    setNotesHaveChanged(true);
  };

  const handleAddChip = (newChip: string) => {
    handleAddNote(newChip);
    setNotesHaveChanged(true);
  };
  const handleAddNote = (noteToAdd: string): void => {
    setNotes((notes) => [
      ...notes,
      {
        value: noteToAdd,
        createdByStaffId: me.user.id,
        createdAt: dayjs().toDate()
      }
    ]);
  };
  return (
    <Chips
      value={notes}
      variant={ChipsVariants.NOTES}
      open={open}
      onClose={async () => {
        if (!notesHaveChanged) return;
        const notesToCreate = [...notes]
          .filter(({ id }) => !id)
          .map(({ value }) => value);
        await onUpsertNotes({
          date,
          cycleId,
          patientId,
          notesToCreate,
          notesToDelete
        });
        setOpen(false);
        setNotesToDelete([]);
        setNotesHaveChanged(false);
      }}
      allowAddingNewOptions
      chipsInputLabel={`${t('NOTES').toUpperCase()} ${dayjs(date).format(
        getDateFormat()
      )}`}
      onAddChip={handleAddChip}
      onRemoveChip={(_, index) => {
        handleRemoveChip(index);
      }}
      customAddButton={
        <IconButton iconSize="xsmall" icon={<NoteIcon />} bgColor="mineShaft" />
      }
    />
  );
};

export default NotesWrapper;
