/**
 * @description
+1 23 456 7890
+12 123 456 7890
+123 123 456 7890
+1-123-456-7890
+12-123-456-7890
+123-123-456-7890
+1 (123) 456 7890
+12 (123) 456 7890
+123 (123) 456 7890
+1-(123)-456-7890
+12-(123)-456-7890
+123-(123)-456-7890
123 456 7890
123-456-7890
(123) 456 7890
(123)-456-7890
123-456 7890
123 456-7890
+1 123-456 7890
+12 123-456 7890
+123 123-456 7890
+1-123 456 7890
+12-123 456 7890
+123-123 456 7890
(123)-456 7890
(123) 456-7890
+1 (123)-456 7890
+12 (123)-456 7890
+123 (123)-456 7890
+1-(123) 456 7890
+12-(123) 456 7890
+123-(123) 456 7890
+1 1234567890
+12 1234567890
+123 1234567890
1234567890
+1 (123)4567890
+12 (123)4567890
+123 (123)4567890
123 (456)7890
+1-1234567890
+12-1234567890
+123-1234567890
123-4567890
(123)4567890
 **/

export const phoneRegex =
  /^(\+?\d{1,3})?[-\s]?(\(?\d{3}\)?)?[-\s]?\d{3}[-\s]?\d{4}$/;

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
