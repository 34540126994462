import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import Button from 'src/components/display/Button';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, shadows, spacings } from 'src/components/styles/constants';
import useMeApi from 'src/hooks/useMeApi';
import useMessagesApi from 'src/hooks/useMessages';
import useTasks from 'src/hooks/useTasks';
import {
  ChannelTypes,
  InboxData,
  InboxTypes,
  MessageRecent
} from 'src/types/inbox';
import { Task } from 'src/types/task';
import SelectChatPatient from './messages/SelectChatPatient';
import AddIcon from '@mui/icons-material/Add';
import Popover from 'src/components/display/Popover';
import { ReactComponent as Profile } from 'src/assets/icons/profile.svg';
import InboxRecentsList from './InboxRecentsList';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { SxProps } from '@mui/material';

const InboxSidebar: FC<{ sx?: SxProps }> = ({ sx }) => {
  const { setSelectedInboxItem, selectedInboxTab, setSelectedInboxTab } =
    useContext(SidebarContext);

  const { t } = useTranslation();
  const { getMe } = useMeApi();

  const ref = useRef<HTMLElement>(null);

  const { data: me } = getMe();
  const { openDialog } = useDialog();

  const { getInboxRecents, markAsRead } = useMessagesApi();
  const { getTasksAssignedToStaff } = useTasks();

  const { data: inboxPatientRecentData } = getInboxRecents(
    ChannelTypes.Patient
  );
  const { data: inboxInternalRecentData } = getInboxRecents(
    ChannelTypes.Internal
  );

  const userId = me?.user?.id;
  const { data: tasksData } = getTasksAssignedToStaff(userId, {
    enabled: !!userId
  });
  const { mutate: markAsReadMutate } = markAsRead();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const isMessageFromPatientUnread = (message: MessageRecent) => {
    return (
      !message.readAt && message.senderPatientId && message.staffId == userId
    );
  };

  const handleOpenNewChatDialog = (type: ChannelTypes) => {
    openDialog({
      header: t('CHOOSE_PATIENT'),
      children: (
        <SelectChatPatient
          selectedNewChatType={type}
          setSelectedItem={setSelectedInboxItem}
          setSelectedTab={setSelectedInboxTab}
        />
      ),
      fullWidth: true,
      maxWidth: 'lg'
    });
  };

  const handleSelectChatType = (type: ChannelTypes) => {
    setIsPopoverOpen(false);
    handleOpenNewChatDialog(type);
  };

  const inboxMappingData: InboxData = {
    patientInbox: {
      shouldDisplayAlertIcon: inboxPatientRecentData?.some((message) =>
        isMessageFromPatientUnread(message)
      ),
      label: t('PATIENT_INBOX'),
      value: inboxPatientRecentData?.length,
      data: inboxPatientRecentData,
      type: InboxTypes.Message,
      handleClickItem: (item: MessageRecent) => {
        const message = item as MessageRecent;
        setSelectedInboxItem({
          type: InboxTypes.Message,
          channelId: message.channelId,
          patientId: message.patientId
        });
        if (!message.readAt && message.senderStaffId != userId) {
          markAsReadMutate({ message });
        }
      }
    },
    internalInbox: {
      shouldDisplayAlertIcon: inboxInternalRecentData?.some(
        (message: MessageRecent) =>
          !message.readAt &&
          message.senderStaffId &&
          message.senderStaffId != userId
      ),
      label: t('INTERNAL_INBOX'),
      value: inboxInternalRecentData?.length,
      data: inboxInternalRecentData,
      type: InboxTypes.Message,
      handleClickItem: (item: MessageRecent) => {
        const message = item;
        setSelectedInboxItem({
          type: InboxTypes.Message,
          channelId: message.channelId,
          patientId: message.patientId
        });
        if (!message.readAt && message.senderStaffId !== userId) {
          markAsReadMutate({ message });
        }
      }
    },
    tasks: {
      type: InboxTypes.Task,
      label: t('TASKS'),
      value: tasksData?.length,
      data: tasksData,
      handleClickItem: (item: Task) => {
        const task = item;
        setSelectedInboxItem({
          type: InboxTypes.Task,
          patientId: task.patientId
        });
      }
    }
  };
  return (
    <Flex
      marginY={{ xs: spacings.large, lg: 0 }}
      boxShadow={{ xs: shadows.default, lg: 'none' }}
      borderRadius={{ xs: radii.xlarge, lg: 0 }}
      height={{ xs: 'auto', lg: '100%' }}
      boxSizing="border-box"
      sx={sx}
      flexDirection="column"
      padding={`${spacings.xxlarge} ${spacings.xlarge} ${spacings.xlarge} ${spacings.xlarge}`}
      bgcolor={Colors.dawnPink}
      gap={spacings.small}
      flex={1}
      width="350px"
      minWidth="350px"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h1" color={Colors.emperor}>
          {t('INBOX')}
        </Typography>
        <Box ref={ref}>
          <Button
            id="new-message-button"
            startIcon={<AddIcon />}
            onClick={() => setIsPopoverOpen(true)}
          >
            {t('NEW_MESSAGE')}
          </Button>
        </Box>
      </Flex>
      <Popover
        hideToolbar
        anchorEl={ref.current}
        disableScrollLock
        onClose={() => setIsPopoverOpen(false)}
        open={isPopoverOpen}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box m={1}>
          <Button
            id="new-message-option-internal"
            startIcon={<Profile />}
            bgColor="transparent"
            textColor="black"
            onClick={() => handleSelectChatType(ChannelTypes.Internal)}
          >
            {t('INTERNAL')}
          </Button>
        </Box>
        <Box m={1}>
          <Button
            id="new-message-option-patient"
            startIcon={<Profile />}
            bgColor="transparent"
            textColor="black"
            onClick={() => handleSelectChatType(ChannelTypes.Patient)}
          >
            {t('PATIENT')}
          </Button>
        </Box>
      </Popover>
      <InboxRecentsList
        setSelectedTab={setSelectedInboxTab}
        inboxData={inboxMappingData}
        selectedTab={selectedInboxTab}
        setSelectedItem={setSelectedInboxItem}
      />
    </Flex>
  );
};

export default InboxSidebar;
