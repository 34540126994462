import dayjs from 'dayjs';
import { DbUser } from 'src/types/user';
import { formatDateAndTime, getDateFormat } from 'src/utils/dateAndTIme';
import { getFullName } from 'src/utils/general';

export const getUpdatedSignatureTemplateKeys = ({
  loggedStaffMember,
  isLabOrderTemplate = false
}: {
  loggedStaffMember: DbUser['user'];
  isLabOrderTemplate?: boolean;
}) => {
  const staffLicense = loggedStaffMember?.licenseNumber || '';
  const staffZip = loggedStaffMember?.zip || '';

  const updatedLabOrderTemplateSignatureKeys = {
    staff_firstname: loggedStaffMember?.firstName || '',
    staff_surname: loggedStaffMember?.lastName || '',
    signing_date_time: formatDateAndTime(dayjs().toDate()) || '',
    todays_date: dayjs().format(getDateFormat()) || ''
  };

  const newStaffKeys = {
    ...updatedLabOrderTemplateSignatureKeys,
    staff_display_name: getFullName(loggedStaffMember),
    staff_license: `${staffLicense}`,
    clinic_doctor_id: `${loggedStaffMember?.id || ''}`,
    staff_address: loggedStaffMember?.address || '',
    doctors_first_name: loggedStaffMember?.firstName || '',
    doctors_last_name: loggedStaffMember?.lastName || '',
    doctors_license: `${staffLicense}`,
    staff_city: loggedStaffMember?.city || '',
    staff_zip: staffZip ? `${staffZip}` : '',
    staff_state: loggedStaffMember?.state || '',
    staff_phone: loggedStaffMember?.phone || '',
    todays_date: dayjs().format(getDateFormat()) || ''
  };

  return isLabOrderTemplate
    ? updatedLabOrderTemplateSignatureKeys
    : newStaffKeys;
};
