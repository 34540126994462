import React, { FC, forwardRef } from 'react';
import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';

export type BoxProps = MuiBoxProps;

const Box: FC<BoxProps> = forwardRef(({ children, ...otherProps }, ref) => {
  return (
    <MuiBox ref={ref} {...otherProps}>
      {children}
    </MuiBox>
  );
});

export default Box;
