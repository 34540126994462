import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getMeRequest } from 'src/api/me.api';
import { ToastType } from 'src/components/display/Toast/Toast';
import { queryKeys } from 'src/hooks/queryKeys';
import { EmbieError } from 'src/types/global';
import { DbUser } from 'src/types/user';

export const createGetMeFunction = ({
  t,
  openToast,
  isAuthenticated
}: {
  t: TFunction;
  openToast: ({
    type,
    title
  }: {
    type: ToastType;
    title: ReactNode;
    children?: ReactNode;
  }) => void;
  isAuthenticated: boolean;
  options?: UseQueryOptions<DbUser, Error>;
}) => {
  const getMe = (options?: UseQueryOptions<DbUser, Error>) =>
    useQuery<DbUser, AxiosError<EmbieError>>([queryKeys.ME], getMeRequest, {
      onError: (error) => {
        const toastProps = {
          title: t('GET_ME_ERROR_TOAST_TITLE'),
          children: t('TRY_AGAIN_LATER'),
          type: ToastType.ERROR
        };

        if (error.response?.status === 401) {
          toastProps.title =
            error.response?.data?.message || t('EMAIL_IS_NOT_RECOGNIZED');
        }

        openToast(toastProps);
      },
      enabled: isAuthenticated,
      ...options
    });

  return getMe;
};
