const enAuthTranslations = {
  SIGN_IN_TITLE: 'Sign in',
  SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
  SIGN_IN_SUBTITLE: 'Fill in the fields below to sign into your account.',
  DONT_HAVE_ACCOUNT_TITLE: "Don't have an account, yet?",
  SIGN_UP_CTA: 'Sign up here',
  EMAIL_FIELD_REQUIRED: 'Please enter an email address',
  EMAIL_FIELD_LABEL: 'Email',
  PASSWORD_FIELD_REQUIRED: 'Please enter a password',
  PASSWORD_FIELD_LABEL: 'Password',
  RECOVER_PASSWORD: 'Recover Password',
  RECOVER_PASSWORD_MSG:
    'Enter the email used for registration to reset your password.',
  TRY_SIGN_IN_AGAIN_MSG: 'Want to try to sign in again?',
  TRY_SIGN_IN_AGAIN_CTA: 'Click here',
  SENT_PASSWORD_REST_MSG:
    'The password reset instructions have been sent to your email',
  CHECK_EMAIL_MSG: 'Check your email for further instructions',
  CONTINUE_TO_LOGIN_CTA: 'Continue to login',
  REGISTER_PAGE_TITLE: 'Register',
  CREATE_ACCOUNT_TITLE: 'Create account',
  CREATE_ACCOUNT_SUBTITLE:
    'Fill in the fields below to sign up for an account.',
  SIGN_IN_CTA: 'Sign in here',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  REGISTER_WITH_GOOGLE: 'Register with Google',
  OR_REGISTER_CTA: 'Or',
  EMAIL_IS_NOT_RECOGNIZED: 'Access Denied: email is not a recognized account'
};

export default enAuthTranslations;
