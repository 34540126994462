import {
  convertEmbryologyReportClientToServer,
  convertThawReportFormClientToServer,
  convertUpdateEggClientToServer
} from 'src/modules/patients/utils/conversions';
import { GameteThawReport, ThawReportForm } from 'src/types/cycle';
import {
  EggAndEmbryoNotesToCreate,
  EggsAndEmbryosReport,
  EmbryologyReport,
  UpdateEgg,
  UpdateGameteThawed
} from 'src/types/eggAndEmbryo';
import {
  EggsAndEmbryosReportServer,
  GameteThawReportServer,
  ThawReportFormServer
} from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';

export async function updateGameteThawedRequest(
  patientId: string,
  gameteId: string,
  gamete: UpdateGameteThawed
): Promise<ThawReportFormServer> {
  const response = await axiosApiInstance.patch<ThawReportFormServer>(
    `/gamete-thaw/${gameteId}/patient/${patientId}/`,
    gamete
  );
  return response.data;
}

export async function createEmbryologyReportRequest(
  report: EmbryologyReport
): Promise<EggsAndEmbryosReportServer> {
  const response = await axiosApiInstance.post<EggsAndEmbryosReportServer>(
    `/egg-and-embryo/patient`,
    convertEmbryologyReportClientToServer(report)
  );
  return response.data;
}

export async function upsertNotesRequest(
  notesToCreate: EggAndEmbryoNotesToCreate
): Promise<EggAndEmbryoNotesToCreate> {
  const response = await axiosApiInstance.post<EggAndEmbryoNotesToCreate>(
    `/egg-and-embryo-notes`,
    notesToCreate
  );

  return response.data;
}
export const getEggsAndEmbryosReportRequest = async (
  patientId: string,
  cycleId: string
): Promise<EggsAndEmbryosReport> => {
  const response = await axiosApiInstance.get<EggsAndEmbryosReportServer>(
    `/egg-and-embryo/patient/${patientId}/cycles/${cycleId}`
  );
  return response.data;
};

export async function updateEggRequest(
  patientId: string,
  eggId: string,
  egg: UpdateEgg
): Promise<EggsAndEmbryosReportServer> {
  const response = await axiosApiInstance.patch<EggsAndEmbryosReportServer>(
    `/egg-and-embryo/${eggId}/patient/${patientId}/`,
    convertUpdateEggClientToServer(egg)
  );
  return response.data;
}

export async function createThawReportRequest(
  thawReport: ThawReportForm
): Promise<ThawReportFormServer> {
  const response = await axiosApiInstance.post<ThawReportFormServer>(
    `/gamete-thaw`,
    convertThawReportFormClientToServer(thawReport)
  );

  return response.data;
}

export const getThawReportRequest = async (
  patientId: string,
  cycleId: string
): Promise<GameteThawReport> => {
  const response = await axiosApiInstance.get<GameteThawReportServer>(
    `/gamete-thaw/patients/${patientId}/cycles/${cycleId}`
  );
  return response.data;
};
