import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

import Box from '../../components/layout/Box';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { spacings } from '../../components/styles/constants';
import { PatientFlags } from '../patients/medicalHistory/PatientFlags';
import Flex from '../../components/layout/Flex';
import { PatientMiniCard } from '../patients/common/PatientMiniCard';
import usePatientsApi from '../../hooks/usePatientsApi';
import Typography from '../../components/display/Typography';
import Card from '../../components/display/Card';
import MiniIconButton from '../../components/display/MiniIconButton';
import { usePopover } from '../../components/components-api/PopoverProvider';
import { AddProgressNote } from './AddProgressNote';
import { FeedActivityTable } from './FeedActivityTable';

export const PatientFeed: FC = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const { getPatientOverview } = usePatientsApi();
  const { openPopover, closePopover } = usePopover();

  const { data: patientOverview } = getPatientOverview(patientId);

  return (
    <Box>
      <Helmet>
        <title>{t('PATIENT_FEED_PAGE_TITLE')}</title>
      </Helmet>

      <Box>
        <Box width="70%" marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>
        <Flex gap={spacings.large}>
          <Box flex={1}>
            <PatientMiniCard
              marginBottom={spacings.large}
              properties={patientOverview?.patientProperties}
              basicInfo={patientOverview?.patientBasicInfo}
              patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
              patientId={patientId}
              header={t('PATIENT_FEED')}
            />
          </Box>
          {patientOverview?.partnerInfo && (
            <Box flex={1}>
              <PatientMiniCard
                isPartner
                marginBottom={spacings.large}
                patientDisplayId={
                  patientOverview?.partnerInfo?.basicInfo?.displayId
                }
                patientId={patientOverview?.partnerInfo?.id}
                properties={patientOverview?.partnerInfo?.properties}
                basicInfo={patientOverview?.partnerInfo?.basicInfo}
                header={t('PARTNER')}
              />
            </Box>
          )}
        </Flex>
        <Card paddingBlock={spacings.small} height="calc(100vh - 420px)">
          <FeedActivityTable
            patientId={patientId}
            toolbar={
              <Flex
                alignItems="center"
                gap={spacings.medium}
                marginBottom={spacings.medium}
              >
                <Typography fontWeight={800} variant="h2">
                  {t('ACTIVITY_FEED')}
                </Typography>
                <MiniIconButton
                  id="add-tasks-button"
                  icon={<AddIcon />}
                  onClick={(ev) =>
                    openPopover({
                      children: (
                        <AddProgressNote
                          patientId={patientId}
                          onClose={closePopover}
                        />
                      ),
                      anchorEl: ev.currentTarget,
                      header: t('ADD_PROGRESS_NOTE'),
                      paperPadding: spacings.large,
                      anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                      },
                      transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top'
                      }
                    })
                  }
                />
              </Flex>
            }
          />
        </Card>
      </Box>
    </Box>
  );
};
