import { Clinic } from 'src/types/clinic';
type UserRole = 'admin' | 'customer' | 'subscriber';

export interface User {
  id: string;
  avatar: string;
  email: string;
  displayName: string;
  jobTitle: string;
  username: string;
  location: string;
  role: UserRole;
  posts: string;
  coverImg: string;
  followers: string;
  description: string;
  [key: string]: any;
}

export enum UserTypes {
  staff = 'staff',
  patients = 'patients'
}

enum PermissionRole {
  SUPER_ADMIN = 'Super admin',
  OPERATIONS = 'Operations',
  FINANCE = 'Finance',
  SR_DOCTOR = 'Sr Doctor',
  SR_NURSE = 'Sr Nurse',
  DOCTOR = 'Doctor',
  NURSE = 'Nurse',
  ADMIN_SECRETARY = 'Admin/Secretary',
  EMBRYOLOGIST = 'Embryologist',
  CONUSELOR_THERAPIST = 'Counselor/Therapist',
  ANESTHESIOLOGIST = 'Anesthesiologist'
}

export interface DbUser {
  userType: UserTypes;
  user: {
    authId: string;
    id: string;
    firstName: string;
    lastName: string;
    clinicId: string;
    jobTitle?: string;
    licenseNumber?: string;
    connectedToCareTeam?: boolean;
    phone?: string;
    licenseImageLink?: string;
    idImageLink?: string;
    displayName?: string;
    email?: string;
    pictureId?: string;
    address?: string;
    clinic?: Clinic;
    city?: string;
    state?: string;
    zip?: string;
    role?: PermissionRole;
    npi?: string;
  };
}

export interface DbUserServer {
  userType: UserTypes;
  user: {
    authId: string;
    id: number;
    firstName: string;
    lastName: string;
    clinicId: string;
    jobTitle?: string;
    licenseNumber?: string;
    connectedToCareTeam?: boolean;
    phone?: string;
    licenseImageLink?: string;
    idImageLink?: string;
    displayName?: string;
    email?: string;
    npi?: string;
  };
}
