import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useFeatureFlag } from 'configcat-react';

import { spacings, zIndices } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Flex, { FlexProps } from 'src/components/layout/Flex/Flex';
import {
  useDialog,
  useDocumentEditor
} from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { ReactComponent as TaskIcon } from '../../../assets/icons/check 1.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as ExamIcon } from '../../../assets/icons/ultrasound.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as NotesIcon } from '../../../assets/icons/notes.svg';
import { AddChecklistMenu } from '../overview/AddChecklistMenu';
import { AddNewReportMenu } from '../reportForms/AddNewReportMenu';
import { AddDocumentForm } from './AddDocumentForm';
import useCycle from 'src/hooks/useCycle';
import Menu, { MenuItemProps } from '../../../components/display/Menu/Menu';
import AddEditAppointment from 'src/modules/calendar/AddEditAppointment';
import { AddExternalOrderForm } from './AddExternalOrderForm';
import { ReactComponent as PrescriptionIcon } from '../../../assets/icons/medication.svg';
import { CycleWizardVariant } from '../../../types/cycle';
import { useCycleWizard } from 'src/components/components-api/CycleWizardProvider';
import { FeatureFlags } from 'src/types/featureFlags';
import { AddProgressNote } from 'src/modules/feed/AddProgressNote';
interface StyledFlexContainerProps extends FlexProps {
  disabled?: boolean;
}

export const StyledFlexContainer = styled(Flex)<StyledFlexContainerProps>`
  position: relative;
  background-color: ${Colors.white};
  z-index: ${zIndices.medium};
  align-items: center;
  gap: ${spacings.medium};
  padding: ${spacings.medium};
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          &:hover {
            background-color: ${Colors.alto};
            cursor: pointer;
          }
        `}
`;

export const PatientActionsMenu: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { getCurrentCycle } = useCycle();
  const { openDialog, closeDialog } = useDialog();
  const { openWizard } = useCycleWizard();
  // TODO: remove feature flag as part of EMB-372
  const {
    value: enableAddPrescription,
    loading: enableAddPrescriptionLoading
  } = useFeatureFlag(FeatureFlags.ENABLE_ADD_PRESCRIPTION, false);

  const {
    data: currentCycle,
    isLoading: isLoadingCurrentCycle,
    isFetching: isFetchingCurrentCycle
  } = getCurrentCycle(patientId);

  const isLoading = isLoadingCurrentCycle || isFetchingCurrentCycle;

  const { setDocumentEditorParams, setDocumentsModalOpen } =
    useDocumentEditor();

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items = [
      {
        label: t('EXTERNAL_ORDER'),
        icon: <StarBorderIcon sx={{ color: Colors.mineShaft }} />,
        action: () => {
          openDialog({
            header: t('CREATE_AN_EXTERNAL_ORDER'),
            fullWidth: true,
            maxWidth: 'md',
            children: <AddExternalOrderForm patientId={patientId} />
          });
        }
      },
      {
        label: t('TASK'),
        icon: <TaskIcon />,
        subMenu: {
          children: <AddChecklistMenu patientId={patientId} />
        }
      },
      {
        label: t('DOCUMENT'),
        icon: <DocumentIcon />,
        action: () => {
          openDialog({
            header: t('CHOOSE_A_DOCUMENT'),
            fullWidth: true,
            maxWidth: 'xs',
            children: (
              <AddDocumentForm
                patientId={patientId}
                onSuccess={(documentId: string) => {
                  setDocumentEditorParams({
                    patientId,
                    templateId: null,
                    documentId,
                    isForceSave: true,
                    isDocumentClosable: false
                  });
                  setDocumentsModalOpen(true);
                }}
              />
            )
          });
        }
      },
      ...(enableAddPrescription
        ? [
            {
              label: t('PRESCRIPTION'),
              icon: <PrescriptionIcon color={Colors.mineShaft} />,
              action: () => {
                openWizard({
                  variant: CycleWizardVariant.ADD_PRESCRIPTION,
                  patientId
                });
              }
            }
          ]
        : []),
      {
        label: t('EXAMS'),
        icon: <ExamIcon color={Colors.mineShaft} />,
        subMenu: {
          children: <AddNewReportMenu patientId={patientId} examsOnly />
        }
      },
      {
        label: t('APPOINTMENT'),
        icon: <CalendarIcon color={Colors.mineShaft} />,
        action: () => {
          openDialog({
            header: t('ADD_APPOINTMENT'),
            children: <AddEditAppointment patientId={patientId} />,
            fullWidth: true,
            maxWidth: 'lg'
          });
        }
      },
      {
        label: t('ADD_PROGRESS_NOTE'),
        icon: <NotesIcon color={Colors.mineShaft} />,
        action: () => {
          openDialog({
            header: t('ADD_PROGRESS_NOTE'),
            children: (
              <AddProgressNote patientId={patientId} onClose={closeDialog} />
            ),
            maxWidth: 'lg'
          });
        }
      }
    ];

    return items;
  }, [currentCycle]);

  if (enableAddPrescriptionLoading) {
    return null;
  }

  return <Menu items={menuItems} loading={isLoading} />;
};
