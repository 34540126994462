import { useEffect, useState } from 'react';
import { IdleTimerContext, useIdleTimer, IIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Button from 'src/components/display/Button';
import Flex from 'src/components/layout/Flex';
import Center from 'src/components/layout/Center';

const PROMPT_LENGTH = 1000 * 60 * 10;
const IDLE_LENGTH = 1000 * 60 * 30;

interface Props {
  children: React.ReactNode;
}

const SessionTerminationProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog } = useDialog();
  const { logout, isAuthenticated } = useAuth();
  const [shouldShowPrompt, setShouldShowPrompt] = useState(true);

  let idleTimer: IIdleTimer | null = null;

  const onIdle = async () => {
    await logout();
    closeDialog();
    window.location.reload();
  };

  const handleActivate = () => {
    idleTimer?.reset();
    setShouldShowPrompt(true);
    closeDialog();
  };

  const onPrompt = async () => {
    if (shouldShowPrompt) {
      setShouldShowPrompt(false);
      openDialog({
        header: t('LOGOUT_TITLE'),
        children: (
          <Box marginTop={spacings.large}>
            <Flex flexDirection="column">
              <Typography>{t('LOGOUT_CONTENT')}</Typography>
              <Center sx={{ marginTop: spacings.medium }}>
                <Button onClick={handleActivate}>
                  {t('LOGOUT_ACTION_TITLE')}
                </Button>
              </Center>
            </Flex>
          </Box>
        )
      });
    }
  };

  idleTimer = useIdleTimer({
    onPrompt,
    onIdle,
    promptBeforeIdle: PROMPT_LENGTH,
    timeout: IDLE_LENGTH,
    crossTab: true,
    syncTimers: 200
  });

  useEffect(() => {
    if (isAuthenticated) {
      idleTimer?.activate();
    } else {
      idleTimer?.pause();
    }
  }, [isAuthenticated]);

  return (
    <IdleTimerContext.Provider value={idleTimer}>
      {children}
    </IdleTimerContext.Provider>
  );
};

export default SessionTerminationProvider;
