import React, { FC, useEffect, useState, useMemo } from 'react';
import { SxProps } from '@mui/material/styles';
import { SearchOutlined } from '@mui/icons-material';
import { CloseOutlined } from '@mui/icons-material';
import { css, styled } from '@mui/material';

import {
  debounce as debounceFunction,
  makeShouldForwardProps
} from 'src/components/utils';
import IconButton from 'src/components/display/IconButton/IconButton';
import { alpha, Colors } from 'src/components/styles/colors';
import { shadows } from '../../styles/constants';
import InputField, { InputFieldProps } from '../InputField';

export enum SearchFieldVariants {
  DEFAULT = 'DEFAULT',
  STYLED = 'STYLED'
}
interface SearchFieldProps
  extends Omit<InputFieldProps, 'onChange' | 'variant'> {
  onChange: (searchTerm: string) => void;
  onSearchTermClear?: () => void;
  searchFieldVariant?: SearchFieldVariants;
  debounce?: number | false;
  startIcon?: React.ReactElement;
  preserveClearIcon?: boolean;
  value?: string;
  sx?: SxProps;
}

const shouldForwardProp = makeShouldForwardProps(['searchFieldVariant']);
const StyledInputField = styled(InputField, {
  shouldForwardProp
})<InputFieldProps & { searchFieldVariant?: SearchFieldVariants }>`
  .MuiButtonBase-root {
    padding: unset;
  }

  .MuiInputBase-root {
    padding: 10px;
    padding-right: 10px !important; // overrides MUI's inline style
    ${({ searchFieldVariant }) =>
      searchFieldVariant === SearchFieldVariants.STYLED &&
      css`
        border: none;
        border-radius: 30px;
        box-shadow: ${shadows.default};
      `}

    input.MuiAutocomplete-input {
      padding: 0;
    }

    & > .MuiInputAdornment-root {
      color: ${Colors.emperor};
      height: 18px;
      border-radius: 50%;
      padding: 8px;
      background-color: ${Colors.cupid}${alpha[50]};
    }

    & > .MuiInputAdornment-positionEnd {
      background-color: unset;
      padding: 0;
    }
  }

  .MuiInputBase-root.Mui-focused {
    box-shadow: ${shadows.default};
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${Colors.emperor};
`;

enum DebounceRates {
  short = 100,
  long = 300
}

const SearchField: FC<SearchFieldProps> = ({
  onChange,
  onSearchTermClear,
  debounce = DebounceRates.short,
  value = '',
  placeholder = 'Search',
  startIcon = <SearchOutlined />,
  preserveClearIcon = false,
  searchFieldVariant = SearchFieldVariants.STYLED,
  ...otherProps
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(value);

  const onSearchTermChanged = useMemo(() => {
    if (!debounce || !onChange) {
      return (searchTerm: string) => onChange?.(searchTerm);
    }

    return debounceFunction((searchTerm: string) => {
      onChange(searchTerm);
    }, debounce);
  }, [debounce, onChange]);

  useEffect(() => setSearchTerm(value), [value]);

  return (
    <StyledInputField
      {...otherProps}
      searchFieldVariant={searchFieldVariant}
      placeholder={placeholder}
      value={searchTerm}
      startIcon={startIcon}
      endIcon={
        preserveClearIcon || searchTerm?.length > 0 ? (
          <StyledIconButton
            size="small"
            bgColor="transparent"
            onClick={() => {
              setSearchTerm('');
              onSearchTermChanged('');
              onSearchTermClear?.();
            }}
            icon={<CloseOutlined />}
          />
        ) : undefined
      }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const term = event.target.value;
        setSearchTerm(term);
        onSearchTermChanged(term);
      }}
    />
  );
};

export default SearchField;
