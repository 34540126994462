import {
  InputLabel as MuiInputLabel,
  styled,
  InputLabelProps as MuiInputLabelProps
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { fonts } from 'src/components/styles/fonts';
import { makeShouldForwardProps } from 'src/components/utils';

interface InputLabelProps extends MuiInputLabelProps {
  label: ReactNode | string;
  rowGap?: string | number;
}

const StyledInputLabel = styled(MuiInputLabel, {
  shouldForwardProp: makeShouldForwardProps(['rowGap'])
})<{ rowGap?: string | number }>`
  color: ${Colors.emperor};
  font: ${fonts.label};
  padding: ${spacings.none};
  margin-bottom: ${spacings.large};
  position: relative;
  transform-origin: unset;
  transform: unset;
  transition: unset;
  display: flex;
  row-gap: ${({ rowGap }) => rowGap || spacings.large};

  &.Mui-focused {
    color: ${Colors.gray};
  }

  &.Mui-error {
    color: ${Colors.redRibbon};

    fieldset {
      border-color: ${Colors.redRibbon};
    }

    .MuiFormHelperText-root {
      color: ${Colors.redRibbon};
    }
  }

  &.Mui-disabled {
    color: ${Colors.gray2};
  }

  fieldset {
    border-color: ${Colors.gray};
  }
`;

const InputLabel: FC<InputLabelProps> = ({
  children,
  label,
  ...otherProps
}) => {
  return (
    <StyledInputLabel {...otherProps}>
      {label}
      {children}
    </StyledInputLabel>
  );
};

export default InputLabel;
