import axiosApiInstance from 'src/utils/axios';
import { MedicalQuestion, RecursiveMedicalAnswer } from 'src/types/qna';
import { ServerReturnPayload } from 'src/types/global';

export async function getPatientNextQuestionByCategoryId(
  patientId: string,
  categoryId: number,
  questionId: number | null
): Promise<MedicalQuestion> {
  const response = await axiosApiInstance.get<MedicalQuestion>(
    `/medical-questions/${categoryId}/next/${patientId}/staff?${
      questionId !== null ? `currentQuestionId=${questionId}` : ''
    }`
  );

  return response.data;
}

export async function postAnswerForQuestion(
  patientId: string,
  questionId: number,
  answer: RecursiveMedicalAnswer
): Promise<ServerReturnPayload> {
  const response = await axiosApiInstance.post(
    `/medical-answers/${patientId}/${questionId}/staff`,
    {
      answer,
      skipRemoveUnusedKeys: true
    }
  );

  return response.data;
}

export async function getAllMedicalQuestionsRequest(
  patientId: string
): Promise<Record<string, MedicalQuestion[]>> {
  const queryParams = new URLSearchParams({
    patientId
  });
  const response = await axiosApiInstance.get('/medical-questions', {
    params: queryParams
  });
  return response.data;
}

export async function deleteAnswerForQuestionRequest({
  patientId,
  questionId,
  answerKey
}: {
  patientId: string;
  questionId: number;
  answerKey?: string;
}): Promise<ServerReturnPayload> {
  const url =
    `/medical-answers/${patientId}/${questionId}` +
    (answerKey ? `?answerKey=${encodeURIComponent(answerKey)}` : '');

  const response = await axiosApiInstance.delete(url);

  return response.data;
}
