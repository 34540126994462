import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { Priority } from './priority';

export interface PatientInsurance {
  id?: string;
  patientId: string;
  relationshipToInsured: RelationshipToEnsured;
  registeredPartnerForTreatment?: boolean;
  insuranceId: string;
  payerId: string;
  payerName?: string;
  insurancePolicyGroupOrFecaNumber?: string;
  employerName?: string;
  insuredFirstName?: string;
  insuredLastName?: string;
  insuredGender?: Gender;
  insuredDob?: Date;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  imageIdCardFrontFile?: ImageFile[] | ImageFile;
  imageIdCardBackFile?: ImageFile[] | ImageFile;
  imageIdCardFront?: string;
  imageIdCardBack?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isUninsured?: boolean;
  insurancePriority: Priority;
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female'
}

export enum RelationshipToEnsured {
  SELF = 'Self',
  SPOUSE = 'Spouse',
  OTHER = 'Other'
}

export const relationshipToEnsuredEntries = Object.entries(
  RelationshipToEnsured
);

export interface InsurancePayer {
  id: string;
  name: string;
  type?: string;
}
