import {
  browserSessionPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth';
import firebase from 'src/utils/firebase';
import { Action, AuthState } from '../types';
import { envConfig } from 'src/config';

const auth = envConfig.isStorybookActive ? null : getAuth();

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  error: null,
  user: null
};

export const authReducer = (state: AuthState, action: Action): AuthState => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user, error } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      error,
      user
    };
  }

  return state;
};

export const signInWithEmailAndPassword = (
  email: string,
  password: string
): Promise<any> => {
  setPersistence(auth, browserSessionPersistence);
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const signInWithGoogle = (): Promise<any> => {
  const provider = new firebase.auth.GoogleAuthProvider();
  setPersistence(auth, browserSessionPersistence);

  return firebase.auth().signInWithPopup(provider);
};

export const loginViaGoogleProvider = (
  token: string
): Promise<firebase.auth.UserCredential> => {
  setPersistence(auth, browserSessionPersistence);
  const credential = firebase.auth.GoogleAuthProvider.credential(token);
  return firebase.auth().signInWithCredential(credential);
};

export const createUserWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<any> => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const logout = (): Promise<void> => {
  return firebase.auth().signOut();
};
