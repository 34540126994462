import { styled } from '@mui/material';
import { Input } from '@mui/material';
import { FC, useState } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import IconButton from 'src/components/display/IconButton/IconButton';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { fontWeights } from 'src/components/styles/fonts';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography/Typography';
import Button from 'src/components/display/Button/Button';
import Chip from '../Chips/Chip';

interface FileUploadProps extends DropzoneProps {
  onUploadFiles: (newFiles: File[]) => void;
  submitOnFileUpload?: boolean;
  hideChosenFile?: boolean;
}

const StyledDropzoneContainer = styled(Flex)`
  background-color: ${Colors.platinumGray};
  border: 1px solid ${Colors.gray};
  border-radius: ${radii.small};
  padding: ${spacings.large};
  cursor: pointer;
`;

const StyledChip = styled(Chip)`
  .MuiChip-label {
    padding: 0;
    padding-block: ${spacings.small};
  }
`;

const FileUpload: FC<FileUploadProps> = ({
  onUploadFiles,
  maxFiles,
  submitOnFileUpload,
  hideChosenFile,
  ...otherProps
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  const acceptedFilesString = otherProps?.accept
    ? Object.values(otherProps?.accept)
        .flatMap((fileTypes) =>
          fileTypes.map((type) => type.slice(1).toUpperCase())
        )
        .join(', ')
    : '';

  return (
    <>
      <Dropzone
        useFsAccessApi={false}
        onDrop={(files) => {
          setFiles(files);
          if (submitOnFileUpload) {
            onUploadFiles(files);
          }
        }}
        maxFiles={maxFiles}
        onDropRejected={(rejectedFiles) => {
          if (rejectedFiles.length > maxFiles) {
            setHelperText(`${t('MAX_FILES_ALLOWED')}: ${maxFiles}`);
          } else {
            setHelperText(t('ERROR_UPLOADING_FILES'));
          }
        }}
        {...otherProps}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()}>
            <Input inputProps={{ ...getInputProps() }} />
            <StyledDropzoneContainer
              alignItems="center"
              justifyContent="center"
              gap={spacings.medium}
            >
              <Box>
                <IconButton
                  bgColor="transparent"
                  icon={<UploadIcon color={Colors.mineShaft} />}
                />
              </Box>
              <Flex flexDirection="column">
                <Typography fontWeight={fontWeights.black}>
                  {t('CHOOSE_FILES')}
                </Typography>
                <Typography>{`${acceptedFilesString} ${t(
                  'MAX_SIZE_10MB'
                )}`}</Typography>
              </Flex>
            </StyledDropzoneContainer>
          </Box>
        )}
      </Dropzone>
      {helperText && (
        <Typography color={Colors.mauvelous}>{helperText}</Typography>
      )}
      {!hideChosenFile && files?.length > 0 && (
        <Flex flexDirection="column" marginTop={spacings.medium}>
          <Typography>{t('FILES_CHOSEN')}:</Typography>
          <Flex gap={spacings.small} flexWrap="wrap" marginTop={spacings.small}>
            {files.map((file, index) => (
              <Box key={`${file.name}-${index}`} maxWidth="150px">
                <StyledChip label={file.name} />
              </Box>
            ))}
          </Flex>
        </Flex>
      )}
      {!submitOnFileUpload && (
        <Box marginTop={spacings.large}>
          <Button
            fullWidth
            disabled={!files.length}
            onClick={() => {
              onUploadFiles(files);
              setFiles([]);
            }}
          >
            {t('UPLOAD')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default FileUpload;
