import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckListIcon } from '../../../assets/icons/check-list.svg';
import { Colors } from 'src/components/styles/colors';
import { AddChecklistFromTemplateForm } from './AddChecklistFromTemplateForm';
import { AddOneTodoItem } from './AddOneTodoItem';
import Menu, { MenuItemProps } from '../../../components/display/Menu/Menu';
import { spacings } from '../../../components/styles/constants';

export const AddChecklistMenu: FC<{
  anchorEl?: Element;
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items: MenuItemProps[] = [
      {
        label: t('CHECKLIST_TEMPLATE'),
        icon: <CheckListIcon color={Colors.mineShaft} />,
        subMenu: {
          children: <AddChecklistFromTemplateForm patientId={patientId} />,
          paperPadding: spacings.large
        }
      },
      {
        label: t('ONE_OFF_TO_DO_ITEM'),
        icon: <CheckListIcon color={Colors.mineShaft} />,
        subMenu: {
          children: <AddOneTodoItem patientId={patientId} />,
          paperPadding: spacings.large
        }
      }
    ];

    return items;
  }, []);

  return <Menu items={menuItems} />;
};
