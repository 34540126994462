import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { FieldValues } from 'react-hook-form';
import { MedicalQnACategory } from './qna';
import {
  CycleStatus,
  FertilizationRate,
  IrregularMenstrual,
  KpiCycleStatus,
  PregnancyRate,
  ProtocolTypes,
  TreatmentTypes,
  ValueType
} from './cycle';
import { AppointmentTypes } from './appointment';
import { YesOrNo } from './global';
import { StaffMember } from './staff';

export const MINIMUM_CHARS_FOR_PATIENT_SEARCH = 2;

export interface PersonName {
  firstName: string;
  lastName: string;
}

export interface Person extends PersonName {
  id?: string;
  internalId?: string;
  email?: string;
  avatar?: string;
}

export enum PersonSex {
  MALE = 'Male',
  FEMALE = 'Female'
}

export const personSexEntries = Object.entries(PersonSex);

// TODO: Add translations
export enum PersonMaritalStatuses {
  SINGLE = 'Single S',
  MARRIED = 'Married M',
  CIVIL_PARTNERSHIP = 'In Civil Partnership CP',
  DIVORCED = 'Divorced D',
  WIDOWED = 'Widowed W',
  SEPARATED = 'Separated P',
  NOT_DISCLOSED = 'Not disclosed N'
}

// TODO: Add translations
export enum PatientRoles {
  'Patient' = 'Patient',
  'Partner' = 'Partner',
  'Surrogate' = 'Surrogate',
  'Egg Donor' = 'Egg Donor',
  'Sperm Donor' = 'Sperm Donor',
  'Intended Mother' = 'Intended Mother',
  'Intended Father' = 'Intended Father',
  'Mitochondrial Donor' = 'Mitochondrial Donor',
  'PNT Only Sperm donor' = 'PNT Only Sperm donor',
  'Egg Sharer' = 'Egg Sharer',
  'Partner TO Donor' = 'Partner TO Donor'
}

export enum PersonEthnicity {
  UNKNOWN = 'Unknown',
  WHITE = 'White',
  BLACK = 'Black',
  OTHER = 'Other',
  ASIAN = 'Asian',
  HISPANIC = 'Hispanic',
  NORTH_AMERICAN_NATIVE = 'North American Native'
}

export enum TreatmentReasons {
  DONOR = 'DONOR',
  FERTILITY_PRESERVATION = 'FERTILITY_PRESERVATION',
  FERTILITY_TREATMENT = 'FERTILITY_TREATMENT',
  GESTATIONAL_SURROGATE = 'GESTATIONAL_SURROGATE',
  INTENDED_PARENT = 'INTENDED_PARENT',
  OB_GYN = 'OB_GYN'
}

export interface BasicInfo extends PersonName {
  displayId: string;
  age: {
    years: number;
    months: number;
  };
  dateOfBirth: Date;
  middleInitial?: string;
  image: string;
  maritalStatus: string;
  sex: string;
  phoneNumber: string;
  ethnicity?: string;
  pictureId?: string;
}

export interface HormonalDataDetails {
  value: number;
  unit: string | null;
  min: number | null;
  max: number | null;
}

export interface HormonalData {
  [name: string]: HormonalDataDetails;
}

export interface PatientProperties {
  cycle?: IrregularMenstrual | number;
  ttc?: {
    years: number;
    months: number;
  };
  amh?: number;
  bmi: number;
  height: number;
  weight: number;
}

interface PatientOverviewPartnerData {
  basicInfo: BasicInfo;
  properties: PatientProperties;
  spermAnalysis?: SpermAnalysis;
  hormonalData?: HormonalData;
  id?: string;
}

export interface PatientOverview {
  hormonalData?: HormonalData;
  spermAnalysis?: SpermAnalysis;
  patientBasicInfo: BasicInfo;
  patientProperties: PatientProperties;
  partnerInfo?: PatientOverviewPartnerData;
  patientAnswers?: MedicalQnACategory[];
  partnerAnswers?: MedicalQnACategory[];
  lastLogin: Date;
}

export interface SpermAnalysis {
  concentration: number;
  volume: number;
  motility?: number;
  morphology: number;
}

export interface PatientPersonalInfo {
  id?: string;
  image?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address?: string;
  city?: string;
  state?: string;
  country: string;
  countryOfBirth?: string;
  postalCode?: string;
  dateOfBirth: Date;
  ssn?: string;
  idNumber?: string;
  idImage?: ImageFile;
  idType?: IdDocumentType;
  isIdConfirmed?: boolean;
  gender?: PersonSex;
  ethnicity?: PersonEthnicity;
  sexAtBirth?: PersonSex;
  patientRole?: PatientRoles;
  timeZone?: string;
  currentCycleId?: string;
  marriedToPartner?: string;
  pictureId?: string;
  patientImage?: string;
  lastNameAtBirth?: string;
  nickname?: string;
  hasDisability?: YesOrNo;
  language?: string;
  staff?: StaffMember;
}

export interface Patient {
  clinicId: string;
  hasPartner: boolean;
  mainReasonForTreatment?: TreatmentReasons;
  maritalStatus?: PersonMaritalStatuses;
  personalInfo: PatientPersonalInfo;
  partnerInfo?: PatientPersonalInfo;
  primaryPhysician: string;
  generalPhysician?: GeneralPhysician;
  nextOfKin?: NextOfKin;
  createdAt: Date;
  flags?: string;
  patientStatus?: PatientChartStatus;
  patientSince?: Date;
  isUninsured: boolean;
}

export interface NextOfKin {
  nextOfKinName?: string;
  nextOfKinEmail?: string;
  nextOfKinRelationship?: NextOfKinRelationship;
  nextOfKinPhoneNumber?: string;
  nextOfKinAddress?: string;
}

export enum NextOfKinRelationship {
  MOTHER = 'Mother',
  FATHER = 'Father',
  BROTHER = 'Brother',
  SISTER = 'Sister',
  AUNT = 'Aunt',
  UNCLE = 'Uncle',
  SON = 'Son',
  DAUGHTER = 'Daughter',
  COUSIN = 'Cousin',
  OTHER = 'Other'
}

export enum IdDocumentType {
  DRIVERS_LICENSE = 'Drivers License',
  STATE_ISSUED_ID = 'State-Issued ID',
  PASSPORT = 'Passport'
}

export interface PatientIdentificationInfo {
  idNumber: string;
  countryOfBirth: string;
  idDocumentType: IdDocumentType;
  idImage: ImageFile[];
  isConfirmed: boolean;
  deleteImage?: boolean;
}

export enum PrimaryDiagnosis {
  MALE_INFERTILITY = 'Male Infertility N46.9',
  HISTORY_OF_ENDOMETRIOSIS = 'History of endometriosis N80.9',
  TUBAL_LIGATION = 'Tubal ligation (not reversed) Z98.51',
  TUBAL_HYDROSALPINX = 'Tubal hydrosalpinx (in place) N70.91',
  OTHER_TUBAL_DISEASE = 'Other tubal disease N97.1',
  UTERINE_FACTOR = 'Uterine factor N97.2',
  OVULATION_DISORDERS = 'Ovulation disorders N97.0',
  FREEZE_ALL = 'Freeze-all (for fertility preservation, or not) Z31.62',
  INDICATION_FOR_USE_OF_GESTATIONAL_CARRIER = 'Indication for use of gestational carrier Z31.7',
  RECURRENT_PREGNANCY_LOSS = 'Recurrent pregnancy loss N96',
  UNEXPLAINED = 'Unexplained N97.9'
}

export enum DiagnosisCategory {
  PRIMARY_DIAGNOSIS = 'Primary Diagnosis',
  GENERAL_HISTORY = 'General History',
  PREVIOUS_CYCLE_OBSERVATIONS = 'Previous Cycle Observations',
  PREVIOUS_PREGNANCIES = 'Previous pregnancies'
}

export interface PatientDiagnosis {
  category: DiagnosisCategory;
  values: string[];
  patientId: string;
  updatedAt?: string;
}

export interface SingleDiagnosis {
  value: string;
  createdAt: string;
}

export interface PatientDiagnosisResult {
  category: DiagnosisCategory;
  values: SingleDiagnosis[];
}

export enum ArrowDirection {
  none = 'NONE',
  up = 'UP',
  down = 'DOWN'
}

export interface PatientKpisResponse {
  patientStatus: PatientStatus;
  fertilizationRate: FertilizationRate;
  pregnancyRate: PregnancyRate;
  cycleStatus: KpiCycleStatus;
}

export interface PatientStatus {
  active: ValueType;
  onboarding: ValueType;
}

export interface TreatmentHistory {
  date: Date;
  treatment: TreatmentTypes;
  protocol: ProtocolTypes;
  beta: number;
  outcome?: string;
  notes?: string[];
  status: CycleStatus;
}

interface PregnancyReport {
  pregnancyStatus: string;
}

export interface PatientHistory {
  previousCycles: TreatmentHistory[];
  previousPregnancies: PregnancyReport[];
}

export enum MedicationTypes {
  MENAPUR = 'menapur',
  GONAL_F = 'gonalf',
  CETROTIDE = 'cetrotide',
  OVITRELL = 'ovitrell',
  LUPRON = 'lupron',
  PROGESTERONE_SUP_400 = 'progesterone'
}

export enum TreatmentGridHeaders {
  FOLLICLES = 'FOLLICLES',
  ENDOMETRIUM = 'endometrium',
  E2 = 'e2',
  Progesterone = 'pg',
  LH = 'lh',
  FSH = 'fsh',
  NOTES = 'notes',
  WITNESSED_TASKS = 'witnessedTasks'
}

export interface LabResult {
  id: string;
  testId: string;
  value: string;
  patientId: string;
  cycleId?: string;
  date: string;
  comment?: string;
  measurementType?: string;
  labTest?: {
    category: string;
    id: string;
    labCode: string;
    min?: number;
    max?: number;
    name: string;
    sampleTYpe: string[];
    tatHours: number;
    units?: string;
  };
  externalLabImage?: ImageFile;
}

interface EstrodialData {
  date: Date;
  value: number;
}

export type EstrodialHistory = EstrodialData[];

export interface Basics {
  patientId: string;
  height?: number;
  weight?: number;
  ttcYears?: number;
  ttcMonths?: number;
  cycle?: number | IrregularMenstrual;
}

export interface DashboardSummary {
  messages: number;
  tasks: number;
  appointments: Record<string, Record<AppointmentTypes, number>>;
}

export interface PatientFormValues extends FieldValues {
  primaryPhysician: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  city: string;
  state?: string;
  zip: string;
  country: string;
  ssn?: string;
  dateOfBirth: Date;
  lastNameAtBirth: string;
  nickname: string;
  hasDisability: YesOrNo;
  language: string;
  ethnicity: PersonEthnicity;
  maritalStatus: PersonMaritalStatuses;
  sexAtBirth: PersonSex;
  treatmentReason: TreatmentReasons;
  hasPartner: boolean;
  partnerFirstName: string;
  partnerLastName: string;
  partnerEmail: string;
  partnerPhoneNumber: string;
  partnerAddress: string;
  partnerCity: string;
  partnerState?: string;
  partnerZip: string;
  partnerCountry: string;
  partnerSsn: string;
  partnerDateOfBirth: Date;
  partnerEthnicity: PersonEthnicity;
  partnerGender: PersonSex;
}

export enum Allergies {
  LATEX = 'Latex',
  EGGS = 'Eggs',
  SOYA = 'Soya',
  NUTS = 'Nuts',
  LOCAL_ANESTHESIA = 'Local Anesthesia',
  GENERAL_ANAESTHETIC = 'General Anaesthetic',
  MEDICATIONS = 'Medications',
  NO_ALLERGIES = 'No Allergies',
  OTHER = 'Other'
}

export interface PatientAllergies {
  allergies: Allergies[];
  medications: MedicationTypes[];
  reasonForMedication: string;
  otherReason?: string;
}

export enum Locations {
  CLINIC = 'Clinic'
}

export interface GeneralPhysician {
  gpName?: string;
  gpEmail?: string;
  gpPhoneNumber?: string;
  gpAddress?: string;
  referringDoctor?: string;
}

export enum GeneticStatus {
  NOT_BIOPSIED = 'Not biopsied',
  BIOPSIED = 'Biopsied',
  EUPLOID = 'Euploid',
  ANEUPLOID = 'Aneuploid',
  AFFECTED = 'Affected',
  NOT_AFFECTED = 'Not affected',
  MOSAIC = 'Mosaic',
  CHAOTIC = 'Chaotic',
  NO_RESULT = 'No Result'
}

export enum PatientChartStatus {
  PATIENT = 'patient',
  POTENTIAL_PATIENT = 'potential_patient',
  INACTIVE_PATIENT = 'inactive_patient',
  ARCHIVED = 'archived',
  VIP = 'vip',
  DEMO = 'demo'
}
