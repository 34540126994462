import { FC } from 'react';
import Grid from '@mui/material/Grid';
import { spacings } from 'src/components/styles/constants';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const Empty: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('EMPTY_PAGE_TITLE')}</title>
      </Helmet>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="column"
        justifyContent="start"
        alignItems="stretch"
        spacing={spacings.xxlarge}
      />
    </>
  );
};
