import { Path, PathValue, RegisterOptions } from 'react-hook-form';
import { SelectOption } from '../Select/Select';

export enum SchemaFieldType {
  input = 'input',
  number = 'number',
  select = 'select',
  date = 'date',
  chips = 'chips'
}

interface InputScheme<T> extends BaseProps {
  type: SchemaFieldType.input;
  defaultValue?: PathValue<T, Path<T>>;
}

interface NumberScheme<T> extends BaseProps {
  type: SchemaFieldType.number;
  defaultValue?: PathValue<T, Path<T>>;
}

interface SelectScheme<T> extends BaseProps {
  type: SchemaFieldType.select;
  options: SelectOption<string>[];
  defaultValue?: PathValue<T, Path<T>>;
}
interface DateScheme<T> extends BaseProps {
  type: SchemaFieldType.date;
  defaultValue?: PathValue<T, Path<T>>;
}
interface ChipsScheme<T> extends BaseProps {
  type: SchemaFieldType.chips;
  defaultValue?: PathValue<T, Path<T>>;
}

export type SchemaProps<T = any> =
  | InputScheme<T>
  | SelectScheme<T>
  | NumberScheme<T>
  | DateScheme<T>
  | ChipsScheme<T>;

interface BaseProps {
  key: string;
  type: SchemaFieldType;
  rules?: RegisterOptions;
  props?: Record<string, unknown>;
  errorMessages?: Record<string, string>;
  show?: Record<string, string>;
  label?: string;
  tooltip?: React.ReactNode;
}
