import { FC, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { FeedActivity, FeedActivityEventType } from 'src/types/feed';
import { StaffMember } from 'src/types/staff';
import dayjs from 'dayjs';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import { longDateFormat, shortTimeFormat } from 'src/utils/dateAndTIme';
import { dateColumn } from 'src/components/display/Table/Table';
import { DoctorChips } from '../patients/common/DoctorChips';
import { FlagActivityButton } from './FlagActivityButton';
import { getFullName } from 'src/utils/general';
import i18n from 'src/i18n/i18n';
import { Colors } from 'src/components/styles/colors';
import { ReactComponent as CycleIcon } from '../../assets/icons/cycle.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as BillingIcon } from '../../assets/icons/billing.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';
import { ReactComponent as MedicalHistoryIcon } from '../../assets/icons/medical-history.svg';
import { ReactComponent as NotesIcon } from '../../assets/icons/notes.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import IconButton from 'src/components/display/IconButton';
import { SvgIcon, styled } from '@mui/material';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import { useNavigate } from 'react-router-dom';
import { patientRoutes } from 'src/router/routes';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { UserTypes } from 'src/types/user';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import {
  getOptionsFilterOperators,
  getTypedFilterOperators
} from 'src/components/display/Table/utils';

interface EventTypeIconProps {
  eventType: FeedActivityEventType;
}

const getIconBgColor = (
  eventType: FeedActivityEventType
): keyof typeof Colors => {
  switch (eventType) {
    case FeedActivityEventType.APPOINTMENT:
      return 'poloBlue';
    case FeedActivityEventType.BILLING:
      return 'riptide';
    case FeedActivityEventType.CYCLE:
      return 'halfBaked';
    case FeedActivityEventType.DOCUMENTS:
      return 'brandy';
    case FeedActivityEventType.MEDICAL_CHART:
      return 'viola';
    case FeedActivityEventType.PATIENT_INFORMATION:
      return 'whiteSand';
    case FeedActivityEventType.PROGRESS_NOTE:
    default:
      return 'cupid';
  }
};

const getEventIcon = (eventType: FeedActivityEventType) => {
  switch (eventType) {
    case FeedActivityEventType.APPOINTMENT:
      return <CalendarIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.ENCOUNTER:
      return <CalendarIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.BILLING:
      return <BillingIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.CYCLE:
      return <CycleIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.DOCUMENTS:
      return <DocumentIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.MEDICAL_CHART:
      return <MedicalHistoryIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.PATIENT_INFORMATION:
      return <ProfileIcon color={Colors.mineShaft} />;
    case FeedActivityEventType.PROGRESS_NOTE:
    default:
      return <NotesIcon color={Colors.mineShaft} />;
  }
};

const StyledIconButton = styled(IconButton)`
  cursor: default;
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
  gap: ${spacings.medium};
  align-items: center;
`;

const EventTypeIcon: FC<EventTypeIconProps> = ({ eventType }) => {
  return (
    <StyledIconButton
      bgColor={getIconBgColor(eventType)}
      iconSize="medium"
      icon={<SvgIcon>{getEventIcon(eventType)}</SvgIcon>}
    />
  );
};

const useGetFeedColumns = (doctors: StaffMember[]) => {
  const navigate = useNavigate();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const getColumns = useCallback((): GridColDef<FeedActivity>[] => {
    const baseColumns: GridColDef<FeedActivity>[] = [
      {
        field: 'patientName',
        flex: 2,
        headerName: i18n.t('PATIENT_NAME').toUpperCase(),
        filterable: false,
        sortable: true,
        renderCell: ({ row }) => {
          const avatar = getDefaultAvatar({
            userId: row.patientId,
            userType: UserTypes.patients,
            pictureId: row.pictureId
          });
          return (
            <StyledFlex
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                navigate(patientRoutes.getByIdLink(row.patientId));
              }}
            >
              <Avatar image={avatar} size={AvatarSizes.SMALL} />
              <Typography>{row.patientName}</Typography>
            </StyledFlex>
          );
        }
      },
      {
        field: 'icon',
        headerName: i18n.t('ACTIVITY_DETAILS').toUpperCase(),
        renderHeader: () => null,
        width: 40,
        filterable: false,
        sortable: false,
        renderCell: ({ row: activity }) => (
          <EventTypeIcon eventType={activity.eventType} />
        )
      },
      {
        field: 'eventType',
        width: 150,
        filterOperators: getTypedFilterOperators(FeedActivityEventType),
        headerName: i18n.t('EVENT_TYPE').toUpperCase(),
        renderCell: ({ row: { eventType } }) => (
          <Typography>{i18n.t(eventType)}</Typography>
        )
      },
      {
        field: 'name',
        flex: 2,
        headerName: i18n.t('NAME').toUpperCase(),
        renderCell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        field: 'summary',
        flex: 3,
        headerName: i18n.t('SUMMARY').toUpperCase(),
        renderCell: ({ row: { summary } }) => (
          <Box width="100%" maxHeight={60} overflow="auto">
            <Typography whiteSpace="pre-wrap">{summary}</Typography>
          </Box>
        )
      },
      {
        ...dateColumn,
        field: 'updatedAt',
        flex: 1,
        headerName: i18n.t('UPDATED_ON').toUpperCase(),
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row: { updatedAt } }) => (
          <Flex flexDirection="column">
            <Typography>{dayjs(updatedAt).format(longDateFormat)}</Typography>
            <Typography>{dayjs(updatedAt).format(shortTimeFormat)}</Typography>
          </Flex>
        )
      },
      {
        field: 'updatedByStaff',
        flex: 1,
        headerName: i18n.t('UPDATED_BY').toUpperCase(),
        filterOperators: getOptionsFilterOperators(
          (doctors || []).map((doctor) => {
            return {
              label: getFullName(doctor),
              value: doctor.id
            };
          })
        ),
        headerAlign: 'center',
        align: 'left',
        valueGetter: (value) => {
          const selectedDoctors = doctors?.filter(({ id }) => value === id);
          return selectedDoctors?.map((doctor) => getFullName(doctor));
        },
        renderCell: ({ row }) => (
          <DoctorChips
            id="activity-updated-by-doctor"
            value={[row.updatedByStaff]}
            showSelectedValue
            shouldFetchStaffMembersNames={false}
          />
        )
      },
      {
        field: 'isFlagged',
        width: 80,
        headerName: i18n.t('FLAG').toUpperCase(),
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        renderCell: ({ row }) => (
          <FlagActivityButton isFlagged={row.isFlagged} activityId={row.id} />
        )
      }
    ];

    return baseColumns;
  }, [doctors, navigate, getDefaultAvatar]);

  return getColumns;
};

export default useGetFeedColumns;
