import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import Drawer, { DrawerProps } from '../display/Drawer/Drawer';

type ProviderContext = {
  openDrawer: (props: Omit<DrawerProps, 'open'>) => void;
  closeDrawer: () => void;
  closeAllDrawers: () => void;
};

const DrawerContext = createContext<ProviderContext | null>(null);

function DrawerProvider({
  children = null
}: {
  children?: ReactNode;
}): ReactElement {
  const [drawers, setDrawers] = useState<DrawerProps[]>([]);

  const openDrawer = (props: Omit<DrawerProps, 'open'>) => {
    setDrawers((prevState) => [
      ...prevState,
      { ...props, open: true, disableScrollLock: true }
    ]);
  };

  const closeDrawer = () => {
    setDrawers((prevState) => prevState.slice(0, -1));
  };

  const closeAllDrawers = () => {
    setDrawers([]);
  };

  // Disable body scrolling when drawer is open
  useEffect(() => {
    if (drawers.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [drawers]);

  return (
    <DrawerContext.Provider
      value={{ openDrawer, closeDrawer, closeAllDrawers }}
    >
      {children}
      {drawers.map((drawerProps, index) => (
        <Drawer
          key={`drawer-${index}`}
          {...drawerProps}
          onClose={closeDrawer}
        />
      ))}
    </DrawerContext.Provider>
  );
}

export function useDrawer(): ProviderContext {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('Drawer context is only available inside DrawerProvider');
  }
  return context;
}

export default DrawerProvider;
