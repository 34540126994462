import { Skeleton, SkeletonProps, Slider, styled } from '@mui/material';
import { FC } from 'react';
import { Colors } from 'src/components/styles/colors';

type SliderVariant =
  | 'avatar-big'
  | 'avatar-small'
  | 'text-small'
  | 'text-big'
  | 'slider';

interface SkeletonLoaderProps extends Omit<SkeletonProps, 'variant'> {
  variant?: SliderVariant;
  isRandom?: boolean;
}

const StyledSkeleton = styled(Skeleton)`
  background-color: ${Colors.alto};
`;

const StyledSkeletonSlider = styled(Slider)`
  .MuiSlider-rail,
  .MuiSlider-track,
  .MuiSlider-thumb {
    color: ${Colors.alto};
  }

  .MuiSlider-thumb {
    width: 10px;
    height: 10px;
  }
`;

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  isRandom = true,
  variant,
  ...props
}) => {
  switch (variant) {
    case 'avatar-big':
      return (
        <StyledSkeleton
          variant="circular"
          width="100px"
          height="100px"
          {...props}
        />
      );
    case 'avatar-small':
      return (
        <StyledSkeleton
          variant="circular"
          width="50px"
          height="50px"
          {...props}
        />
      );
    case 'text-small':
      return <StyledSkeleton height="15px" {...props} />;
    case 'text-big':
      return <StyledSkeleton height="40px" {...props} />;
    case 'slider':
      return (
        <StyledSkeletonSlider
          disabled
          min={0}
          max={100}
          value={isRandom ? Math.floor(Math.random() * 100) : 50}
        />
      );
    default:
      return <StyledSkeleton />;
  }
};

export default SkeletonLoader;
