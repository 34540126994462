export enum MedicalQuestionTypes {
  NUMBER = 'Number',
  DATE = 'Date',
  MULTI_CHOICE = 'Multi Choice',
  SINGLE_CHOICE = 'Single Choice',
  FREE_TEXT = 'Free Text',
  MULTI_FREE_TEXT = 'Multi Free Text',
  YES_NO = 'Yes/No',
  REPEAT_TEMPLATE = 'Repeat Template',
  REPEAT_NUMBER = 'Repeat Number'
}

type MedicalQuestionDetails = Record<string, MedicalQuestionTypes>;

export interface MedicalQuestion {
  id: number;
  text: string;
  type: MedicalQuestionTypes;
  details?: MedicalQuestionDetails;
  answerKey?: string;
  answerOptions: string[];
  category: string;
  categoryId: number;
  categorySerial: number;
  serializedId: string;
  allQuestionsDone?: boolean;
}

export type MedicalAnswer = string[] | string;

export type RecursiveMedicalAnswer =
  | MedicalAnswer
  | Record<string, Record<string, MedicalAnswer>>;

interface MedicalQnA {
  question: MedicalQuestion;
  answer: MedicalAnswer;
}

export interface MedicalQnACategory {
  category: string;
  categoryId: number;
  questions: MedicalQnA[];
}

enum MedicalHistoryQuestionCategories {
  GENERAL = 7,
  PREGNANCY = 4,
  GYNECOLOGY = 6,
  MALE_FERTILITY = 8,
  SURGICAL = 5,
  CURRENT_MEDICATIONS = 2,
  ALLERGIES = 1,
  VACCINATIONS = 3,
  FAMILY = 9,
  LIFESTYLE = 10
}

export const MALE_ONLY_CATEGORIES = [
  MedicalHistoryQuestionCategories.MALE_FERTILITY
];
export const FEMALE_ONLY_CATEGORIES = [
  MedicalHistoryQuestionCategories.PREGNANCY,
  MedicalHistoryQuestionCategories.GYNECOLOGY
];
export const DEFAULT_CATEGORY_ORDER = {
  1: MedicalHistoryQuestionCategories.GENERAL,
  2: MedicalHistoryQuestionCategories.PREGNANCY,
  3: MedicalHistoryQuestionCategories.GYNECOLOGY,
  4: MedicalHistoryQuestionCategories.MALE_FERTILITY,
  5: MedicalHistoryQuestionCategories.SURGICAL,
  6: MedicalHistoryQuestionCategories.CURRENT_MEDICATIONS,
  7: MedicalHistoryQuestionCategories.ALLERGIES,
  8: MedicalHistoryQuestionCategories.VACCINATIONS,
  9: MedicalHistoryQuestionCategories.FAMILY,
  10: MedicalHistoryQuestionCategories.LIFESTYLE
};
