import { Range } from 'react-date-range';
import { Cycle } from './cycle';

export interface PrescriptionsRx {
  id?: string;
  medicationRxId: string;
  medicationRx?: MedicationRx;
  quantity: number;
  refill: number;
  createdByStaffId?: string;
  description?: string;
}

export interface MedicationRx {
  id: string;
  medicationId: string;
  description: string;
  medication?: Medication;
}

export interface Medication {
  id: string;
  internalId?: string;
  name: string;
  refrigerated?: boolean;
  about?: string;
  sideEffects?: string;
  instructions?: string;
  websiteUrl?: string;
  pdfUrl?: string;
  videoUrl?: string;
  videoLocation?: string;
  dragCategory?: string;
  medicationRx?: MedicationRx[];
}

export interface PrescriptionRxForm {
  patientName?: string;
  deliveryDate: Date;
  prescriptionsRx?: PrescriptionsRx[];
}

export interface MedicalProtocolForm {
  patientName?: string;
  patientId?: string;
  prescribingProvider?: string;
  cycleDayOne?: Date;
  medicationProtocols?: Prescription[];
  medicationsToDelete?: string[];
}

export enum MedicationUnits {
  AMPUL = 'Ampul',
  APPLICATOR = 'Applicator',
  AS_DIRECTED = 'As directed',
  BOTTLE = 'Bottle',
  BOX = 'Box',
  CAPLET = 'Caplet',
  CAPSULE = 'Capsule',
  CLICKS = 'Clicks',
  DEVICE = 'Device',
  DROP = 'Drop',
  EACH = 'Each',
  FOAM = 'Foam',
  GELCAP = 'Gelcap',
  GRAM = 'Gram',
  INSERT = 'Insert',
  KIT = 'Kit',
  LANCET = 'Lancet',
  MCG = 'mcg',
  MEQ = 'meq',
  MG = 'mg',
  ML = 'ml',
  NEEDLE = 'Needle',
  PACK = 'Pack',
  PACKET = 'Packet',
  PATCH = 'Patch',
  PEN = 'Pen',
  PEN_INJECTOR = 'Pen injector',
  PEN_NEEDLE = 'Pen needle',
  SUPPOSITORY = 'Suppository',
  SYRINGE = 'Syringe',
  TABLET = 'Tablet',
  TUBE = 'Tube',
  UNIT = 'Unit',
  VIAL = 'Vial'
}

export enum MedicationIntakeMethods {
  A_SMALL_AMOUNT = 'A small amount',
  AS_DIRECTED = 'As directed',
  BY_MOUTH = 'By mouth',
  DISSOLVED_IN_WATER = 'Dissolved in water',
  INTRAMUSCULARLY = 'Intramuscularly',
  PATCH = 'Patch',
  SUBCUTANEOUSLY = 'Subcutaneously',
  VAGINALLY = 'Vaginally',
  VIA_APPLICATOR = 'Via applicator'
}

export enum FrequencyOptions {
  TAKE_DAILY = 'Take Daily',
  EVERY_OTHER_DAY = 'Every Other Day',
  EVERY_THREE_DAYS = 'Every 3 Days'
}

export enum OtherInstructions {
  AS_DIRECTED = 'As directed',
  WITH_MEALS = 'With meals',
  BETWEEN_MEALS = 'Between meals',
  AFTER_MEALS = 'After meals',
  ONE_HOUR_BEFORE_MEALS = 'One hour before meals',
  WITH_A_GLASS_OF_WATER = 'With a glass of water',
  BEFORE_EXERCISE = 'Before exercise'
}

export interface Prescription {
  id?: string;
  medicationId: string;
  dosage: number;
  endDate?: Date;
  startDate?: Date;
  instructions?: string;
  medicationName?: string;
  patientId?: string;
  cycleId?: string;
  protocolPhase?: string;
  timesPerDay?: number;
  unit: MedicationUnits;
  frequency?: FrequencyOptions;
  route?: MedicationIntakeMethods;
  otherInstructions?: OtherInstructions;
  createdByStaffId?: string;
  timeRange?: Range;
  time: Date[];
  cycle?: Cycle;
}

export interface UpdatePrescriptionDosage {
  dosage: number;
  prescriptionId: string;
  patientId: string;
  cycleId: string;
  startDate: Date;
  endDate?: Date;
}

export interface MedicalForm {
  medicationProtocols: Prescription[];
  cycleId?: string;
  patientId: string;
  prescriptionsRx?: PrescriptionsRx[];
  prescribingProvider: string;
  deliveryDate: Date;
}
