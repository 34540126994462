import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import PatientHeader from '../PatientHeader';
import { Task } from 'src/types/task';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { TasksTable } from 'src/modules/tasksTable/TasksTable';
import Box from 'src/components/layout/Box';
import { useMemo } from 'react';
import useTasks from 'src/hooks/useTasks';
import useMeApi from 'src/hooks/useMeApi';

interface Props {
  patientId: string;
}
const TaskInboxOverview = ({ patientId }: Props) => {
  const { t } = useTranslation();

  const { getTasksByStaffPatientId } = useTasks();
  const { getMe } = useMeApi();

  const { data: me } = getMe();
  const {
    data: tasks,
    isLoading: isTasksLoading,
    isFetching: isTasksFetching
  } = getTasksByStaffPatientId(me?.user?.id, patientId, {
    enabled: !!me?.user?.id
  });

  const isLoading = isTasksLoading || isTasksFetching;

  const todaysTasks = useMemo(
    () =>
      tasks?.filter(
        (task: Task) =>
          task.dueDate?.toDateString() === new Date().toDateString()
      ),
    [tasks]
  );
  const restOfTasks = useMemo(
    () => tasks?.filter((task: Task) => !todaysTasks?.includes(task)),
    [tasks]
  );

  return (
    <Flex flexDirection="column" flex={1} gap={spacings.small} overflow="auto">
      <Typography variant="h1" color={Colors.emperor}>
        {t('TASKS')}
      </Typography>
      <Flex flexDirection="column" flex={1} gap={spacings.small}>
        <PatientHeader patientId={`${patientId}`} />
        {/* There is a bug when the width is 100%, it's a quick fix for this until finding another solution */}
        <Box width="99%" marginTop={spacings.xlarge}>
          {todaysTasks?.length > 0 && (
            <Flex flexDirection="column">
              <Typography>{t('TODAY_TASKS')}</Typography>
              <TasksTable
                tasks={todaysTasks || []}
                loading={isLoading}
                tableHeight="90%"
                pageSize={10}
              />
            </Flex>
          )}
          {restOfTasks?.length > 0 && (
            <Flex flexDirection="column">
              <Typography>{t('ADDITIONAL_TASKS')}</Typography>
              <TasksTable
                tasks={restOfTasks || []}
                loading={isLoading}
                tableHeight="500px"
                pageSize={15}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default TaskInboxOverview;
