import { MouseEvent } from 'react';
import { GridCellParams } from '@mui/x-data-grid-premium';
import { ActivityDetails } from './ActivityDetails';
import { spacings } from 'src/components/styles/constants';
import { TFunction } from 'i18next';
import { PopoverProps } from 'src/components/display/Popover/Popover';

export const handleCellClick = ({
  params,
  event,
  openPopover,
  t
}: {
  params: GridCellParams;
  event: MouseEvent;
  openPopover: ({}: Omit<PopoverProps, 'open'>) => void;
  t: TFunction;
}) => {
  if (params.field !== 'isFlagged') {
    openPopover({
      children: <ActivityDetails activity={params.row} />,
      anchorEl: event.currentTarget,
      header: t('ACTIVITY_DETAILS'),
      paperPadding: spacings.large,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'top'
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top'
      }
    });
  }
};
