import { FC, useMemo } from 'react';
import { Colors } from 'src/components/styles/colors';
import { useTranslation } from 'react-i18next';
import { AddEditLabsReportForm } from '../../AddEditLabsReportForm';
import { AddEditFollicularExamForm } from '../medicalHistory/AddEditFollicularForm';
import { AddEditOutcomeReportForm } from './AddEditOutcomeReportForm';
import { AddEditEmbryologyReportForm } from './AddEditEmbryologyReportForm';
import { AddEditSemenAnalysisReportForm } from './AddEditSemenAnalysisReportForm';
import { AddEditTransferReportForm } from './AddEditTransferReportForm';
import { AddEditEggRetrievalReportForm } from './AddEditEggRetrievalReportForm';
import { AddEditIUIReportForm } from './AddEditIUIReportForm';
import { ReactComponent as LabsIcon } from '../../../assets/icons/labs.svg';
import { ReactComponent as UltrasoundIcon } from '../../../assets/icons/ultrasound.svg';
import { ReactComponent as EmbryoIcon } from '../../../assets/icons/embryo.svg';
import { ReactComponent as SpermIcon } from '../../../assets/icons/sperm.svg';
import { ReactComponent as EmbryoTransformIcon } from '../../../assets/icons/transfe-uterus.svg';
import { ReactComponent as TestIcon } from '../../../assets/icons/pregnancy-test.svg';
import { ReactComponent as UterusIcon } from '../../../assets/icons/transfe-uterus.svg';
import { ReactComponent as DrChairIcon } from '../../../assets/icons/dr-chair.svg';
import { TreatmentTypes } from 'src/types/cycle';
import { AddEditThawReportForm } from './AddEditThawReportForm';
import { AddEditUltrasoundReportForm } from '../medicalHistory/AddEditUltrasoundReportForm';
import { AddEditPhysicalExamFemaleForm } from '../medicalHistory/AddEditPhysicalExamFemaleForm';
import useCycle from 'src/hooks/useCycle';
import Menu, { MenuItemProps } from '../../../components/display/Menu/Menu';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

export const AddNewReportMenu: FC<{
  patientId: string;
  cycleId?: string;
  examsOnly?: boolean;
}> = ({ patientId, cycleId, examsOnly }) => {
  const { openDialog } = useDialog();
  const { t } = useTranslation();
  const { checkCycleHasEmbryologyReport } = useCycle();
  const { getCycleById } = useCycle();

  const {
    data: hasEmbryologyReport,
    isLoading: isLoadingCheckEmbryosReport,
    isFetching: isFetchingCheckEmbryosReport
  } = checkCycleHasEmbryologyReport(cycleId, patientId, {
    enabled: !!(cycleId && patientId)
  });

  const {
    data: cycle,
    isLoading: isLoadingCycle,
    isFetching: isFetchingCycle
  } = getCycleById(cycleId, {
    enabled: !!cycleId
  });

  const isLoading =
    isLoadingCheckEmbryosReport ||
    isLoadingCycle ||
    isFetchingCheckEmbryosReport ||
    isFetchingCycle;

  const showEggRetrievalReport =
    cycle?.treatmentType !== TreatmentTypes.FET &&
    cycle?.treatmentType !== TreatmentTypes.MOCK_CYCLE_ERA &&
    cycle?.treatmentType !== TreatmentTypes.IUI &&
    cycle?.treatmentType !== TreatmentTypes.OVULATION_INDUCTION;

  const showSemenReport =
    cycle?.treatmentType !== TreatmentTypes.FET &&
    cycle?.treatmentType !== TreatmentTypes.MOCK_CYCLE_ERA &&
    cycle?.treatmentType !== TreatmentTypes.OVULATION_INDUCTION &&
    cycle?.treatmentType !== TreatmentTypes.EGG_FREEZING;

  const showEmbryologyReport =
    cycle?.treatmentType !== TreatmentTypes.FET &&
    cycle?.treatmentType !== TreatmentTypes.MOCK_CYCLE_ERA &&
    cycle?.treatmentType !== TreatmentTypes.IUI &&
    cycle?.treatmentType !== TreatmentTypes.OVULATION_INDUCTION &&
    !hasEmbryologyReport;

  const showEmbryoTransferReport =
    cycle?.treatmentType !== TreatmentTypes.IVF &&
    cycle?.treatmentType !== TreatmentTypes.EGG_FREEZING &&
    cycle?.treatmentType !== TreatmentTypes.IUI &&
    cycle?.treatmentType !== TreatmentTypes.OVULATION_INDUCTION;

  const showOutcomeReport =
    cycle?.treatmentType !== TreatmentTypes.IVF &&
    cycle?.treatmentType !== TreatmentTypes.EGG_FREEZING &&
    cycle?.treatmentType !== TreatmentTypes.MOCK_CYCLE_ERA &&
    !cycle?.outcomeDate;

  const showIUIReport = cycle?.treatmentType === TreatmentTypes.IUI;

  const showThawReport = cycle?.treatmentType === TreatmentTypes.FET;

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items = [
      {
        label: t('FOLLICULAR_EXAM'),
        icon: <UltrasoundIcon color={Colors.mineShaft} />,
        action: () =>
          openDialog({
            header: t('ADD_FOLLICULAR_SCAN'),
            children: (
              <AddEditFollicularExamForm
                patientId={patientId}
                cycleId={cycleId}
              />
            ),
            maxWidth: 'lg',
            fullWidth: true
          })
      }
    ];

    if (examsOnly) {
      items.push(
        {
          label: t('ULTRASOUND'),
          icon: <UltrasoundIcon color={Colors.mineShaft} />,
          action: () =>
            openDialog({
              header: t('ULTRASOUND_EXAMINATION'),
              children: <AddEditUltrasoundReportForm patientId={patientId} />,
              maxWidth: 'lg',
              fullWidth: true
            })
        },
        {
          label: t('PHYSICAL_EXAMINATION_FEMALE'),
          icon: <DrChairIcon color={Colors.mineShaft} />,
          action: () =>
            openDialog({
              header: t('ADD_PHYSICAL_EXAMINATION_FEMALE'),
              children: <AddEditPhysicalExamFemaleForm patientId={patientId} />,
              maxWidth: 'lg',
              fullWidth: true
            })
        }
      );
    } else {
      items.push({
        label: t('BLOODS'),
        icon: <LabsIcon />,
        action: () =>
          openDialog({
            header: t('ADD_LABS'),
            children: (
              <AddEditLabsReportForm patientId={patientId} cycleId={cycleId} />
            )
          })
      });

      if (showEggRetrievalReport) {
        items.push({
          label: t('EGG_RETRIEVAL'),
          icon: <EmbryoIcon />,
          action: () =>
            openDialog({
              header: t('ADD_EGG_RETRIEVAL_REPORT'),
              children: (
                <AddEditEggRetrievalReportForm
                  patientId={patientId}
                  cycleId={cycleId}
                />
              ),
              fullWidth: true,
              maxWidth: 'lg'
            })
        });
      }

      if (showThawReport) {
        items.push({
          label: t('THAW_REPORT'),
          icon: <EmbryoIcon />,
          action: () =>
            openDialog({
              header: t('ADD_THAW_REPORT'),
              children: (
                <AddEditThawReportForm
                  patientId={patientId}
                  cycleId={cycleId}
                />
              ),
              fullWidth: true,
              maxWidth: 'sm'
            })
        });
      }

      if (showEmbryologyReport) {
        items.push({
          label: t('EMBRYOLOGY_REPORT'),
          icon: <EmbryoIcon />,
          action: () =>
            openDialog({
              header: t('ADD_EMBRYOLOGY_REPORT'),
              children: (
                <AddEditEmbryologyReportForm
                  patientId={patientId}
                  cycleId={cycleId}
                />
              )
            })
        });
      }

      if (showEmbryoTransferReport) {
        items.push({
          label: t('EMBRYO_TRANSFER'),
          icon: <EmbryoTransformIcon />,
          action: () =>
            openDialog({
              header: t('ADD_TRANSFER_REPORT'),
              children: (
                <AddEditTransferReportForm
                  patientId={patientId}
                  cycleId={cycleId}
                />
              ),
              fullWidth: true,
              maxWidth: 'lg'
            })
        });
      }

      if (showOutcomeReport) {
        items.push({
          label: t('OUTCOME'),
          icon: <TestIcon />,
          action: () =>
            openDialog({
              header: t('OUTCOME_REPORT'),
              children: (
                <AddEditOutcomeReportForm
                  patientId={patientId}
                  cycleId={cycleId}
                />
              ),
              maxWidth: 'md',
              fullWidth: true
            })
        });
      }
    }

    if (showIUIReport) {
      items.push({
        label: t('IUI'),
        icon: <UterusIcon />,
        action: () =>
          openDialog({
            header: t('IUI_REPORT'),
            children: (
              <AddEditIUIReportForm patientId={patientId} cycleId={cycleId} />
            ),
            maxWidth: 'md',
            fullWidth: true
          })
      });
    }

    if (showSemenReport || examsOnly) {
      items.push({
        label: t('SEMEN_ANALYSIS'),
        icon: <SpermIcon />,
        action: () =>
          openDialog({
            header: t('ADD_SEMEN_ANALYSIS_REPORT'),
            children: (
              <AddEditSemenAnalysisReportForm
                patientId={patientId}
                cycleId={cycleId}
              />
            ),
            fullWidth: true,
            maxWidth: 'lg'
          })
      });
    }

    return items;
  }, [hasEmbryologyReport, cycle]);

  return <Menu items={menuItems} loading={isLoading} />;
};
