import { Colors } from 'src/components/styles/colors';
import { DocumentSignatureStatus, DocumentStatus } from 'src/types/documents';

export const getDocumentSignatureStatus = ({
  isDocumentCompleted,
  isInitialVendorEmailSent
}: {
  isDocumentCompleted: boolean;
  isInitialVendorEmailSent: boolean | null;
}): DocumentSignatureStatus => {
  if (isDocumentCompleted) {
    if (isInitialVendorEmailSent) {
      return { status: DocumentStatus.SENT, color: Colors.riptide };
    } else {
      return { status: DocumentStatus.SIGNED, color: Colors.alto2 };
    }
  } else {
    return { status: DocumentStatus.PENDING, color: Colors.cupid };
  }
};
