import { DiagnosisCategory } from 'src/types/patient';

export const getDiagnosisCategory = (value: string): DiagnosisCategory => {
  // return value as DiagnosisCategory;
  switch (value) {
    case 'General history':
      return DiagnosisCategory.GENERAL_HISTORY;
    case 'Primary Diagnosis':
      return DiagnosisCategory.PRIMARY_DIAGNOSIS;
    case 'Previous Cycle Observations':
      return DiagnosisCategory.PREVIOUS_CYCLE_OBSERVATIONS;
    case 'Previous pregnancies':
      return DiagnosisCategory.PREVIOUS_PREGNANCIES;
    default:
      return DiagnosisCategory.GENERAL_HISTORY;
  }
};
