import React, { useEffect, useRef } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { styled } from '@mui/material';
import { Collapse } from '@mui/material';
import { SchemaProps } from './types';
import { isFieldVisible } from './utils';
import ElementParser from './ElementParser';

interface FormBuilderProps<T extends FieldValues = Record<string, any>> {
  methods: UseFormReturn<T, any>;
  schema: SchemaProps[];
  collapseDuration?: number | 'auto';
  onSave: () => void;
  children?: React.ReactNode;
}

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const FormBuilder = <T extends FieldValues = Record<string, any>>({
  methods,
  schema,
  collapseDuration = 'auto',
  onSave,
  children
}: FormBuilderProps<T>): React.ReactElement => {
  const formValues = methods.watch();
  const formElementsRefs = useRef(
    schema.map(() => React.createRef<HTMLDivElement>())
  );
  const errorKeys = Object.keys(methods.formState.errors);

  useEffect(() => {
    if (errorKeys.length && formElementsRefs.current) {
      const index = schema.findIndex((item) => errorKeys.includes(item.key));
      formElementsRefs.current?.[index]?.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [methods.formState.submitCount]);

  return (
    <StyledForm onSubmit={(e) => e.preventDefault()}>
      {schema?.map((field, index) => {
        const show = isFieldVisible(field, formValues);

        return (
          <Collapse
            in={show}
            key={index}
            unmountOnExit
            timeout={collapseDuration}
          >
            <ElementParser<T>
              elmRef={formElementsRefs.current?.[index]}
              schema={field}
              methods={methods}
              onSave={onSave}
              shouldUnregister={!show}
            />
          </Collapse>
        );
      })}
      {children}
    </StyledForm>
  );
};

export default FormBuilder;
