import { FC, useState } from 'react';
import Chips, { ChipsProps } from 'src/components/data-entry/Chips/Chips';
import Box from 'src/components/layout/Box/Box';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import { SvgIcon, css, styled } from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography/Typography';
import { getAvatarUrl, getFullName } from 'src/utils/general';

import { ReactComponent as AddPersonIcon } from '../../../assets/icons/add-person.svg';
import { UserTypes } from 'src/types/user';
import Flex from 'src/components/layout/Flex/Flex';
import i18n from 'src/i18n/i18n';
import { makeShouldForwardProps } from 'src/components/utils';
import useStaffMembers from 'src/hooks/useStaffMembers';
import staffDefaultAvatar from '../../../assets/images/doctor-default-avatar.jpg';

interface DoctorSelectionProps extends Omit<ChipsProps, 'value'> {
  id: string;
  value: string[];
  showSelectedValue?: boolean;
  label?: string;
  addLabel?: string;
  shouldFetchStaffMembersNames?: boolean;
  disabled?: boolean;
}

const shouldForwardProp = makeShouldForwardProps(['isSelected']);
const StyledDoctorOptionContainer = styled(Box, {
  shouldForwardProp
})<{ isSelected: boolean }>`
  cursor: pointer;
  padding: ${spacings.medium};
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${Colors.alto};
        `
      : ''}
  &:hover {
    background-color: ${Colors.platinumGray};
  }
`;

export const DoctorChips: FC<DoctorSelectionProps> = ({
  onAddChip,
  value,
  showSelectedValue,
  label,
  addLabel = i18n.t('SELECT_STAFF'),
  isLoading: _isLoading,
  id,
  shouldFetchStaffMembersNames,
  disabled = false,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { getStaffMembersNames } = useStaffMembers();
  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: doctors,
    isLoading: isLoadingStaffMembers,
    isFetching: isFetchingStaffMembers
  } = getStaffMembersNames({
    enabled: shouldFetchStaffMembersNames
  });

  const isLoading = isLoadingStaffMembers || isFetchingStaffMembers;

  const selectedDoctors = doctors?.filter(({ id }) => value.includes(id));

  const doctorsFullNames = selectedDoctors?.map((doctor) =>
    getFullName(doctor)
  );

  const filteredDoctors = doctors?.filter(
    ({ jobTitle, isActive, ...rest }) =>
      isActive &&
      (getFullName(rest)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        jobTitle?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Box>
      {label && (
        <Flex marginBottom={spacings.large}>
          <Typography variant="label">{label}</Typography>
        </Flex>
      )}
      <Chips
        {...otherProps}
        isLoading={isLoading}
        chipsInputLabel={t('SELECT_STAFF')}
        closeOnSelection
        onSearch={setSearchTerm}
        onClose={() => setSearchTerm('')}
        customOptions
        value={value}
        disabled={disabled}
        customAddButton={
          <Flex flexWrap="wrap" gap={spacings.medium} id={id}>
            {doctorsFullNames?.length ? (
              doctorsFullNames.map((doctorFullName, index) => {
                const currDoctor = selectedDoctors?.[index];

                return (
                  <Avatar
                    id={`${id}-doctor-avatar`}
                    key={index}
                    fillColor="emperor"
                    backgroundColor="dawnPink"
                    alignAvatar="left"
                    title={showSelectedValue ? doctorFullName ?? addLabel : ''}
                    size={AvatarSizes.SMALL}
                    image={
                      currDoctor.pictureId
                        ? getAvatarUrl(currDoctor?.id, UserTypes.staff)
                        : staffDefaultAvatar
                    }
                    defaultAvatar={
                      <SvgIcon>
                        <AddPersonIcon />
                      </SvgIcon>
                    }
                  />
                );
              })
            ) : (
              <Avatar
                fillColor="emperor"
                backgroundColor="dawnPink"
                image={
                  selectedDoctors?.[0]?.pictureId &&
                  getAvatarUrl(selectedDoctors?.[0]?.id, UserTypes.staff)
                }
                alignAvatar="left"
                size={AvatarSizes.SMALL}
                defaultAvatar={
                  <SvgIcon>
                    <AddPersonIcon />
                  </SvgIcon>
                }
              />
            )}
          </Flex>
        }
        onAddChip={onAddChip}
        options={filteredDoctors?.map(
          ({ id, jobTitle, pictureId, ...rest }) => ({
            value: id,
            label: (
              <StyledDoctorOptionContainer isSelected={value?.includes(id)}>
                <Avatar
                  alignAvatar="left"
                  size={AvatarSizes.SMALL}
                  title={getFullName(rest)}
                  subtitle={t(jobTitle)}
                  image={
                    pictureId
                      ? getAvatarUrl(id, UserTypes.staff)
                      : staffDefaultAvatar
                  }
                />
              </StyledDoctorOptionContainer>
            )
          })
        )}
      />
    </Box>
  );
};
