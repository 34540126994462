import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState
} from 'react';
import {
  CycleWizard,
  CycleWizardProps
} from '../../modules/patients/cycleWizard/CycleWizard';

type CycleWizardProviderProps = Omit<CycleWizardProps, 'onClose'>;

type ProviderContext = {
  openWizard: (data: CycleWizardProviderProps) => void;
  closeWizard: () => void;
};

const CycleWizardContext = createContext<ProviderContext>(null);

function CycleWizardProvider({
  children = ''
}: {
  children?: ReactNode;
}): ReactElement {
  const [wizardProps, setWizardProps] =
    useState<CycleWizardProviderProps | null>();

  const openWizard = (data: CycleWizardProviderProps) => {
    setWizardProps(data);
  };

  const closeWizard = () => {
    setWizardProps(null);
  };

  return (
    <CycleWizardContext.Provider value={{ openWizard, closeWizard }}>
      {children}
      {wizardProps && <CycleWizard {...wizardProps} onClose={closeWizard} />}
    </CycleWizardContext.Provider>
  );
}

export function useCycleWizard(): ProviderContext {
  const result = useContext(CycleWizardContext);
  if (!result) {
    throw new Error(
      'Cycle wizard context is only available inside CycleWizardContextProvider'
    );
  }
  return result;
}

export default CycleWizardProvider;
