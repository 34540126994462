import { formatDateToString } from '../modules/patients/utils/conversions';
import { PatientInsurance } from '../types/patientInsurance';
import { PatientInsuranceFieldKeys } from './types';

export const PatientInsuranceFields: PatientInsuranceFieldKeys = {
  id: 'id',
  patientId: 'patientId',
  relationshipToInsured: 'relationshipToInsured',
  registeredPartnerForTreatment: 'registeredPartnerForTreatment',
  insuranceId: 'insuranceId',
  payerId: 'payerId',
  insurancePolicyGroupOrFecaNumber: 'insurancePolicyGroupOrFecaNumber',
  employerName: 'employerName',
  insuredFirstName: 'insuredFirstName',
  insuredLastName: 'insuredLastName',
  insuredGender: 'insuredGender',
  insuredDob: 'insuredDob',
  address: 'address',
  city: 'city',
  state: 'state',
  zip: 'zip',
  imageIdCardFrontFile: 'imageIdCardFrontFile',
  imageIdCardBackFile: 'imageIdCardBackFile',
  imageIdCardFront: 'imageIdCardFront',
  imageIdCardBack: 'imageIdCardBack',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  isUninsured: 'isUninsured',
  insurancePriority: 'insurancePriority'
};

export const getPatientInsuranceFormData = ({
  id,
  patientId,
  insurancePriority,
  relationshipToInsured,
  registeredPartnerForTreatment,
  insuranceId,
  payerId,
  insurancePolicyGroupOrFecaNumber,
  employerName,
  insuredFirstName,
  insuredLastName,
  insuredGender,
  insuredDob,
  address,
  city,
  zip,
  state,
  imageIdCardFrontFile,
  imageIdCardBackFile,
  imageIdCardFront,
  imageIdCardBack,
  createdAt,
  updatedAt
}: PatientInsurance): FormData => {
  const formData = new FormData();

  if (id) {
    formData.append(PatientInsuranceFields.id, id);
  }

  if (patientId) {
    formData.append(PatientInsuranceFields.patientId, patientId);
  }

  if (insurancePriority) {
    formData.append(
      PatientInsuranceFields.insurancePriority,
      insurancePriority
    );
  }

  if (relationshipToInsured) {
    formData.append(
      PatientInsuranceFields.relationshipToInsured,
      relationshipToInsured
    );
  }

  formData.append(
    PatientInsuranceFields.registeredPartnerForTreatment,
    String(!!registeredPartnerForTreatment)
  );

  if (insuranceId) {
    formData.append(PatientInsuranceFields.insuranceId, insuranceId);
  }

  if (payerId) {
    formData.append(PatientInsuranceFields.payerId, payerId);
  }

  if (insurancePolicyGroupOrFecaNumber) {
    formData.append(
      PatientInsuranceFields.insurancePolicyGroupOrFecaNumber,
      insurancePolicyGroupOrFecaNumber
    );
  }

  if (employerName) {
    formData.append(PatientInsuranceFields.employerName, employerName);
  }

  if (insuredFirstName) {
    formData.append(PatientInsuranceFields.insuredFirstName, insuredFirstName);
  }

  if (insuredLastName) {
    formData.append(PatientInsuranceFields.insuredLastName, insuredLastName);
  }

  if (insuredGender) {
    formData.append(PatientInsuranceFields.insuredGender, insuredGender);
  }

  if (address) {
    formData.append(PatientInsuranceFields.address, address);
  }

  if (city) {
    formData.append(PatientInsuranceFields.city, city);
  }

  if (zip) {
    formData.append(PatientInsuranceFields.zip, zip);
  }

  if (state) {
    formData.append(PatientInsuranceFields.state, state.toUpperCase());
  }

  if (imageIdCardBackFile) {
    formData.append(
      PatientInsuranceFields.imageIdCardBackFile,
      imageIdCardBackFile[0]
    );
  }

  if (imageIdCardFrontFile) {
    formData.append(
      PatientInsuranceFields.imageIdCardFrontFile,
      imageIdCardFrontFile[0]
    );
  }

  if (imageIdCardFront) {
    formData.append(PatientInsuranceFields.imageIdCardFront, imageIdCardFront);
  }

  if (imageIdCardBack) {
    formData.append(PatientInsuranceFields.imageIdCardBack, imageIdCardBack);
  }

  if (insuredDob) {
    formData.append(
      PatientInsuranceFields.insuredDob,
      formatDateToString(insuredDob)
    );
  }

  if (createdAt) {
    formData.append(
      PatientInsuranceFields.createdAt,
      formatDateToString(createdAt)
    );
  }

  if (updatedAt) {
    formData.append(
      PatientInsuranceFields.updatedAt,
      formatDateToString(updatedAt)
    );
  }

  return formData;
};
