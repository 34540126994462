import { Core } from '@pdftron/webviewer';

export const getTemplateAsDocxFile = async ({
  documentViewer,
  annotationManager,
  templateName
}: {
  documentViewer: Core.DocumentViewer;
  annotationManager: Core.AnnotationManager;
  templateName: string;
  key: string;
}): Promise<{ file: File; xfdfString: string }> => {
  const doc = documentViewer.getDocument();
  const xfdfString = await annotationManager.exportAnnotations();

  const data = await doc.getFileData({
    includeAnnotations: false,
    downloadType: 'docx'
  });

  const arr = new Uint8Array(data);
  const blob = new Blob([arr], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  const file = new File([blob], templateName, {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  return { file, xfdfString };
};
