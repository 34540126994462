import { WebViewerInstance } from '@pdftron/webviewer';

export const getWebViewerAuthSignatureAction = async ({
  event,
  webViewerInstance,
  callback
}: {
  event: any;
  webViewerInstance: WebViewerInstance;
  callback: ({
    openElements,
    closeElements
  }: {
    openElements: (dataElements: string[]) => void;
    closeElements: (dataElements: string[]) => void;
  }) => void;
}) => {
  const { UI } = webViewerInstance;
  const { ToolbarGroup, closeElements, openElements } = UI;

  if (event.detail === ToolbarGroup.FILL_AND_SIGN) {
    callback({ openElements, closeElements });
  }
};
