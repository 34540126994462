import { styled, tooltipClasses, Tooltip, TooltipProps } from '@mui/material';
import { spacings } from 'src/components/styles/constants';

export const TooltipWrapper = styled(
  ({ className, title, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} title={title} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    padding: spacings.medium,
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));
