import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import { css, styled } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import { zIndices } from 'src/components/styles/constants';

export enum ToastType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

const StyledMuiAlert = styled(MuiAlert)`
  padding: 0px 10px;
  box-shadow: none;
  border: 1px solid ${Colors.darkGray};
  min-width: 440px;

  .MuiAlert-message {
    padding: 10px 0;
    font: ${fonts.body};
  }

  .MuiAlert-icon {
    margin-right: 18px;

    .MuiSvgIcon-root {
      font-size: 28px;
      padding-top: 10px;
    }
  }

  ${({ severity }) =>
    severity === ToastType.ERROR &&
    css`
      background-color: ${Colors.red};
      .MuiAlert-icon {
        color: ${Colors.red};
      }
    `}

  ${({ severity }) =>
    severity === ToastType.SUCCESS &&
    css`
      background-color: ${Colors.vistaBlue};
      .MuiAlert-icon {
        color: ${Colors.vistaBlue};
      }
    `}

  ${({ severity }) =>
    severity === ToastType.WARNING &&
    css`
      background-color: ${Colors.webOrange};
      .MuiAlert-icon {
        color: ${Colors.webOrange};
      }
    `}

  ${({ severity }) =>
    severity === ToastType.INFO &&
    css`
      background-color: ${Colors.easternBlue};
      .MuiAlert-icon {
        color: ${Colors.easternBlue};
      }
    `}
`;
const StyledAlertTitle = styled(AlertTitle)`
  margin-bottom: 2px;
`;

const StyledSnackBar = styled(Snackbar)`
  z-index: ${zIndices.highest};
`;

export const ToastContent = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(
    {
      sx: _sx,
      severity = 'info',
      title,
      children,
      onClose = () => {},
      ...props
    },
    ref
  ) {
    return (
      <StyledMuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        onClose={onClose}
        severity={severity}
        iconMapping={{
          info: <InfoIcon />,
          success: <CheckIcon />,
          warning: <ErrorIcon />,
          error: <ErrorIcon />
        }}
        {...props}
      >
        {title && <StyledAlertTitle>{title}</StyledAlertTitle>}
        {children}
      </StyledMuiAlert>
    );
  }
);

const Toast = ({
  open,
  handleClose,
  title,
  children,
  toastType = ToastType.INFO
}) => {
  return (
    <StyledSnackBar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <ToastContent title={title} onClose={handleClose} severity={toastType}>
        {children}
      </ToastContent>
    </StyledSnackBar>
  );
};

export default Toast;
