import { QueryParams } from '../types/pagination';

export const withQueryParams = <
  T extends (
    queryParams: URLSearchParams,
    ...args: any[]
  ) => Promise<Awaited<ReturnType<T>>>
>(
  fn: T
) => {
  return async (
    queryParams: QueryParams,
    ...args: any[]
  ): Promise<Awaited<ReturnType<T>>> => {
    const { pageSize, page } = queryParams.pagination || {};
    const pagination = new URLSearchParams({
      pageSize: pageSize?.toString(),
      pageNumber: page?.toString()
    });

    return fn(pagination, ...args);
  };
};
