import { FC, useEffect } from 'react';
import usePatientsApi from '../../../hooks/usePatientsApi';
import useExams from '../../../hooks/useExams';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ExamReason,
  Observations,
  ExamReport,
  UltrasoundExaminationTypes
} from 'src/types/exam';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import { spacings, spacingsPercentage } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Flex from 'src/components/layout/Flex/Flex';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import Button from 'src/components/display/Button/Button';
import ImageUpload, {
  ImageFile
} from 'src/components/data-entry/ImageUpload/ImageUpload';
import { getFullName } from 'src/utils/general';
import { DoctorChips } from '../common/DoctorChips';
import dayjs from 'dayjs';
import useGeneralApi from '../../../hooks/useGeneralApi';
import Select from 'src/components/data-entry/Select';
import TextArea from 'src/components/data-entry/TextArea';
import FormActionsContainer from 'src/components/display/FormActionsContainer/FormActionsContainer';

interface UltrasoundReportForm {
  id?: string;
  dateOfExamination: Date;
  observations: Observations[];
  images: ImageFile[];
  notes: string;
  patientId: string;
  patientName: string;
  performedById: string;
  requestingPhysicianId: string;
  signedOffById: string;
  type: UltrasoundExaminationTypes;
  reason: ExamReason;
  imagesToDelete?: string[];
  fetalHeartbeat?: number;
  ctrl?: number;
}

export const AddEditUltrasoundReportForm: FC<{
  patientId: string;
  examinationId?: string;
}> = ({ patientId, examinationId }) => {
  const isEdit = !!examinationId;
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport } = useExams();
  const { getExaminationById } = useGeneralApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: examination } = getExaminationById(examinationId, {
    enabled: isEdit
  });

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: UltrasoundReportForm = {
    dateOfExamination: dayjs().toDate(),
    observations: [],
    images: [],
    notes: '',
    patientId,
    patientName: '',
    performedById: null,
    requestingPhysicianId: null,
    signedOffById: null,
    type: null,
    reason: null,
    fetalHeartbeat: null,
    ctrl: null
  };

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch
  } = useForm<UltrasoundReportForm>({
    mode: 'onChange',
    defaultValues
  });

  const { reason } = watch();

  useEffect(() => {
    if (examination) {
      reset({
        id: examination?.id,
        dateOfExamination: dayjs(examination?.examDate).toDate(),
        observations: examination.findings?.map(({ name }) => name),
        images:
          examination?.images.map(
            ({ url, id }) => ({ url, id } as ImageFile)
          ) || [],
        notes: examination?.notes || '',
        patientId,
        patientName: '',
        performedById: examination?.performedBy,
        requestingPhysicianId: examination?.requestedPhysician,
        signedOffById: examination?.signedOffBy,
        reason: examination?.reason as ExamReason,
        imagesToDelete: [],
        fetalHeartbeat: examination.fetalHeartbeat,
        ctrl: examination.ctrl,
        type: examination.type as UltrasoundExaminationTypes
      });
    }

    if (patient) {
      setValue('patientName', getFullName(patient?.personalInfo));
    }
  }, [patient, examination]);

  const { errors } = formState;

  const onSubmit = async (details: ExamReport) => {
    if (isEdit) {
      await updateReport(details);
    } else {
      await createReport(details);
    }
  };

  if (isLoadingPatient) return <Loader />;

  const formActions = [
    <Button
      key="action-save"
      width={spacingsPercentage.large}
      disabled={isCreatingReport || isUpdatingReport}
      onClick={handleSubmit(onSubmit)}
    >
      {t('SAVE')}
    </Button>
  ];

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex>
          <Flex flex={1} gap={spacings.large} marginBottom={spacings.medium}>
            <Box flex={1}>
              <Controller
                name="requestingPhysicianId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-ultrasound-report-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="performedById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-ultrasound-report-performed-by"
                    showSelectedValue
                    label={t('PERFORMED_BY').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="signedOffById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-ultrasound-report-signed-off-by"
                    showSelectedValue
                    label={t('SIGNED_OFF_BY').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          </Flex>
          <Box flex={1} />
        </Flex>
        <Flex marginBottom={spacings.large} gap={spacings.large}>
          <Box flex={1}>
            <Box marginBottom={spacings.medium}>
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    disabled
                    inputRef={ref}
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box marginBottom={spacings.large}>
              <Controller
                name="dateOfExamination"
                control={control}
                rules={{
                  required: t('DATE_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_EXAMINATION')}
                    inputRef={ref}
                    error={!!errors?.dateOfExamination}
                    helperText={errors.dateOfExamination?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Box>

          <Box flex={1} height="100%">
            <Controller
              name="observations"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  shouldSortOptions
                  variant={ChipsVariants.EXPANDED}
                  allowAddingNewOptions
                  value={value}
                  options={Object.values(Observations).map((value) => ({
                    label: t(value),
                    value,
                    labelText: t(value)
                  }))}
                  title={t('FINDINGS')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValue) => {
                    const updatedFindings = value.filter(
                      (finding) => finding !== chipValue
                    );
                    onChange(updatedFindings);
                  }}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex flex={1} gap={spacings.large}>
          <Box flex={1}>
            <Box marginBottom={spacings.large}>
              <Controller
                name="type"
                control={control}
                rules={{ required: t('EXAM_TYPE_REQUIRED') }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    required
                    label={t('EXAM_TYPE')}
                    inputRef={ref}
                    error={!!errors?.type}
                    helperText={errors?.type?.message}
                    defaultOption={t('EXAM_TYPE')}
                    options={Object.entries(UltrasoundExaminationTypes).map(
                      ([key, value]) => ({
                        label: t(key),
                        value
                      })
                    )}
                  />
                )}
              />
            </Box>
            <Box marginBottom={spacings.large}>
              <Controller
                name="reason"
                control={control}
                rules={{ required: t('EXAM_REASON_REQUIRED') }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    required
                    label={t('EXAM_REASON')}
                    inputRef={ref}
                    error={!!errors?.reason}
                    helperText={errors?.reason?.message}
                    defaultOption={t('EXAM_REASON')}
                    options={Object.entries(ExamReason).map(([key, value]) => ({
                      label: t(key),
                      value
                    }))}
                  />
                )}
              />
            </Box>
          </Box>
          <Box flex={1}>
            <Controller
              name="notes"
              control={control}
              render={({ field: { ...field } }) => (
                <TextArea
                  {...field}
                  label={t('PROCEDURE_NOTES')}
                  placeholder={t('ENTER_COMMENT')}
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                  required
                  fullWidth
                  minRows={6}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} minHeight={70} marginBottom={spacings.large}>
          {reason === ExamReason.PREGNANCY && (
            <>
              <Box flex={1}>
                <Controller
                  name="fetalHeartbeat"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('FETAL_HEARTBEAT')}
                      placeholder={t('#')}
                      type="number"
                      error={!!errors.fetalHeartbeat}
                      helperText={errors?.fetalHeartbeat?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="ctrl"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('CTRL')}
                      placeholder={t('#')}
                      error={!!errors?.ctrl}
                      helperText={errors?.ctrl?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Flex>
        <Controller
          name="images"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ImageUpload
              value={value}
              onAddNewFiles={(newFiles) => {
                onChange([...value, ...newFiles]);
              }}
              onRemoveFile={(imageIndexToRemove) => {
                const existingImage = value[imageIndexToRemove];

                if (existingImage.id) {
                  const existingImagesToDelete = getValues('imagesToDelete');
                  setValue('imagesToDelete', [
                    ...existingImagesToDelete,
                    existingImage.id
                  ]);
                }
                onChange(
                  value.filter((_, index) => index !== imageIndexToRemove)
                );
              }}
            />
          )}
        />
        <FormActionsContainer actions={formActions} />
      </form>
    </Box>
  );
};
