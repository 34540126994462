import { styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import Card, { CardProps } from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import { BasicsForm } from './BasicsForm';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { Colors } from 'src/components/styles/colors';
import Box from 'src/components/layout/Box/Box';
import NumberPreview from 'src/components/display/NumberPreview/NumberPreview';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import {
  getCycleLengthText,
  getFullName,
  getNumberPreviewSizeByValue,
  getUserAge
} from 'src/utils/general';
import { UserTypes } from 'src/types/user';
import { BasicInfo, PatientProperties } from 'src/types/patient';
import { useParams } from 'react-router-dom';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';
import { longDateFormat } from 'src/utils/dateAndTIme';
import dayjs from 'dayjs';

const StyledSpecsKeyTypography = styled(Typography)`
  font: ${fonts.pill};
  flex: 1;
`;
const StyledSpecsValueTypography = styled(Typography)`
  font: ${fonts.pill};
  font-weight: ${fontWeights.extraBold};
  flex: 2;
`;

interface PatientCardProps extends CardProps {
  header: string;
  patientId: string;
  patientDisplayId?: string;
  basicInfo?: BasicInfo;
  properties?: PatientProperties;
  isPartner?: boolean;
}

export const PatientMiniCard: FC<PatientCardProps> = ({
  header,
  patientId,
  patientDisplayId,
  basicInfo,
  properties,
  isPartner = false,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { openPopover, closePopover } = usePopover();
  const { patientId: viewedPatientId } = useParams();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { age, dateOfBirth, maritalStatus, sex, phoneNumber, pictureId } =
    basicInfo || {};
  const { bmi, height, weight, cycle, ttc } = properties || {};
  const { months, years } = age || {};

  const bmiToDisplay = Math.floor(bmi);

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: pictureId
  });

  return (
    <Card shadow padding={spacings.xlarge} {...otherProps}>
      {header && <Typography variant="h1">{header}</Typography>}
      <Flex
        flexDirection={{
          xs: 'column',
          lg: 'row'
        }}
        gap={spacings.large}
        paddingTop={spacings.xlarge}
      >
        <Flex flex={1} gap={spacings.medium} paddingBottom={spacings.medium}>
          <PatientAvatar
            size={AvatarSizes.XLARGE}
            image={avatar}
            patientId={patientId}
          />

          <Flex flex={1.5} flexDirection="column" gap={spacings.small}>
            <Typography variant="h2" marginBottom={spacings.large}>
              {getFullName(basicInfo)}
            </Typography>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>{t('AGE')}</StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>
                {getUserAge({ years, months })}
              </StyledSpecsValueTypography>
            </Flex>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>{t('DOB')}</StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>
                {dayjs(dateOfBirth).format(longDateFormat)}
              </StyledSpecsValueTypography>
            </Flex>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>{t('STATUS')}</StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>
                {t(maritalStatus)}
              </StyledSpecsValueTypography>
            </Flex>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>{t('SEX')}</StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>{t(sex)}</StyledSpecsValueTypography>
            </Flex>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>{t('PHONE')}</StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>
                {phoneNumber}
              </StyledSpecsValueTypography>
            </Flex>
            <Flex gap={spacings.small}>
              <StyledSpecsKeyTypography>
                {t('PATIENT_ID')}
              </StyledSpecsKeyTypography>
              <StyledSpecsValueTypography>
                {patientDisplayId?.toUpperCase()}
              </StyledSpecsValueTypography>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          gap={spacings.medium}
          marginLeft={spacings.medium}
          flex={1}
        >
          <Flex
            justifyContent="space-between"
            gap={spacings.small}
            alignItems="center"
          >
            <Typography sx={{ fontWeight: fontWeights.extraBold }} variant="h4">
              {t('THE_BASICS')}
            </Typography>
            <MiniIconButton
              icon={<EditIcon style={{ fill: 'black' }} />}
              onClick={(ev) =>
                openPopover({
                  header: t('THE_BASICS'),
                  anchorEl: ev.currentTarget,
                  paperPadding: spacings.xlarge,
                  children: (
                    <BasicsForm
                      width={300}
                      basics={{
                        patientId,
                        cycle,
                        height,
                        ttcMonths: ttc?.months,
                        ttcYears: ttc?.years,
                        weight
                      }}
                      sex={sex}
                      invalidatePartnerId={isPartner ? viewedPatientId : null}
                      onSuccess={() => {
                        closePopover();
                      }}
                    />
                  )
                })
              }
            />
          </Flex>
          <Box border={`1px solid ${Colors.mercury}`} />
          <Flex gap={spacings.small}>
            {bmi && <NumberPreview value={bmiToDisplay} topText={t('BMI')} />}
            {ttc && (
              <NumberPreview
                value={ttc.years}
                topText={t('TTC')}
                bottomText={t('YEARS')}
              />
            )}
            {cycle && (
              <NumberPreview
                size={getNumberPreviewSizeByValue(getCycleLengthText(cycle))}
                value={getCycleLengthText(cycle)}
                topText={t('CYCLE').toUpperCase()}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
