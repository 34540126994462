import { Option } from 'src/types/option';

export const getOptionValue = (option: string | Option): string => {
  if (typeof option === 'string') {
    return option;
  } else {
    return option?.value;
  }
};

export const isOptionEqualToValue = (
  newValue: string | Option,
  valueIterator: string | Option
) => {
  const option = getOptionValue(newValue);
  const value = getOptionValue(valueIterator);

  return option === value;
};
