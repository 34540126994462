import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
  styled,
  Badge,
  BadgeProps
} from '@mui/material';
import { FC } from 'react';
import { Colors } from '../../styles/colors';
import { radii, spacings } from '../../styles/constants';
import { fonts } from '../../styles/fonts';
import { makeShouldForwardProps } from '../../utils';

type ButtonColors = keyof typeof Colors;

export interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'variant'> {
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  bgColor?: ButtonColors;
  alert?: boolean;
  alertOverlap?: 'circular' | 'rectangular';
  textColor?: ButtonColors;
  width?: string;
}

const shouldForwardButtonProp = makeShouldForwardProps([
  'bgColor',
  'textColor'
]);
const StyledButton = styled(MuiButton, {
  shouldForwardProp: shouldForwardButtonProp
})<ButtonProps>`
  width: ${({ width }) => width};
  border-radius: ${radii.full};
  background: ${({ bgColor }) => Colors[bgColor]};
  color: ${({ textColor }) => Colors[textColor]};
  text-transform: none;
  font: ${fonts.button};
  padding: ${({ startIcon, endIcon }) =>
    startIcon || endIcon
      ? `${spacings.medium} ${spacings.xlarge}`
      : `calc(${spacings.large} - 2px) ${spacings.xlarge}`};
  &:hover {
    background: ${({ bgColor }) => Colors[bgColor]};
  }
`;

const shouldForwardBadgeProp = makeShouldForwardProps(['fullWidth', 'width']);
const StyledBadge = styled(Badge, {
  shouldForwardProp: shouldForwardBadgeProp
})<BadgeProps & { fullWidth: boolean; width?: string }>`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ width }) => width && `width: ${width}`}
`;

const Button: FC<ButtonProps> = ({
  startIcon,
  endIcon,
  bgColor = 'darkGray',
  textColor = 'white',
  alertOverlap = 'rectangular',
  children,
  alert,
  fullWidth,
  width,
  ...otherProps
}) => {
  return (
    <StyledBadge
      color="error"
      overlap={alertOverlap}
      badgeContent=""
      variant="dot"
      invisible={!alert}
      fullWidth={fullWidth}
      width={width}
    >
      <StyledButton
        startIcon={startIcon}
        endIcon={endIcon}
        variant="contained"
        bgColor={bgColor}
        fullWidth={fullWidth}
        width={width ? '100%' : ''}
        textColor={textColor}
        {...otherProps}
      >
        {children}
      </StyledButton>
    </StyledBadge>
  );
};

export default Button;
