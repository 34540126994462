import { FC } from 'react';
import { Document, Page, pdfjs, PageProps, DocumentProps } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface PdfPreviewProps {
  pageProps?: PageProps;
  documentProps?: DocumentProps;
}

export const PdfPreview: FC<PdfPreviewProps> = ({
  documentProps = {},
  pageProps = {}
}) => {
  return (
    <Document {...documentProps}>
      <Page {...pageProps} />
    </Document>
  );
};
