import { Trend } from './global';
import { PatientPersonalInfo } from './patient';
import { PatientPersonalInfoServer } from './patient-server';
import { StaffMember } from './staff';

interface BillingStat {
  amount: number;
  trend?: Trend;
  trendPercentage?: number;
}

export interface BillingStats {
  paid: BillingStat;
  quotes: BillingStat;
  pending: BillingStat;
}

export type StatusesOptions = AllStatusOptions | InvoiceStatus;

export enum AllStatusOptions {
  ALL_STATUS = 'ALL_STATUS'
}

export enum InvoiceStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  QUOTE = 'QUOTE',
  REFUNDED = 'REFUNDED',
  DRAFT = 'DRAFT',
  CANCELED = 'CANCELED'
}

export enum InvoiceMethod {
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  WIRE = 'WIRE'
}

export interface InvoiceItem {
  id?: string;
  invoiceId?: string;
  code?: string;
  description: string;
  cycleId?: string;
  appointmentId?: string;
  price: number;
  quantity: number;
  hasVat: boolean;
  discount?: number;
  currency: string;
}

export interface Invoice {
  id: string;
  patientId: string;
  patient?: Partial<PatientPersonalInfo>;
  paidDate?: Date;
  dueDate: Date;
  issueDate: Date;
  status: InvoiceStatus;
  method?: InvoiceMethod;
  createdByStaffId: string;
  staff?: StaffMember;
  notes?: string;
  invoiceItems?: InvoiceItem[];
  itemsToDelete?: string[];
}

export interface InvoiceItemServer {
  id?: string;
  invoiceId?: string;
  code?: string;
  description: string;
  cycleId?: string;
  appointmentId?: string;
  price: number;
  quantity: number;
  hasVat: boolean;
  discount?: number;
  currency: string;
}
export interface InvoiceServer {
  id: string;
  patientId: string;
  patient?: Partial<PatientPersonalInfoServer>;
  paidAt?: string;
  dueDate: string;
  issueDate: string;
  status: InvoiceStatus;
  method?: InvoiceMethod;
  createdByStaffId: string;
  staff?: StaffMember;
  notes?: string;
  invoiceItems?: InvoiceItemServer[];
  itemsToDelete?: number[];
}

export interface HealthProvider {
  name: string;
  memberNumber: string;
  groupNumber: string;
  effectiveDate: Date;
}

export interface BillingProfile {
  patientId: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  paymentProfile: string;
  currency: string;
  healthProvider: HealthProvider;
  secondHealthProvider?: HealthProvider;
}
