import { AxiosError } from 'axios';

export type Optional<T> = T | undefined;

export enum YesOrNo {
  YES = 'YES',
  NO = 'NO'
}

export const yesOrNoEntries = Object.entries(YesOrNo);

export enum Trend {
  NONE = 'NONE',
  ABOVE = 'ABOVE',
  BELOW = 'BELOW'
}

export interface ValueAndTrend {
  value: number;
  trend?: Trend;
}

interface AppErrorData {
  message: string;
  path: string;
  statusCode: number;
  timestamp: string;
}

export enum ServerStatus {
  OK = 'ok',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface ServerReturnPayload {
  message?: string;
  status: ServerStatus;
}

export type AppError = AxiosError<AppErrorData>;

export enum Degrees {
  CELSIUS = 'Celsius',
  FAHRENHEIT = 'Fahrenheit'
}

export enum NormalOrAbnormal {
  NORMAL = 'normal',
  ABNORMAL = 'abnormal'
}

export interface EmbieError {
  message?: string;
  path: string;
  statusCode: number;
  timestamp: string;
}

export interface InfiniteQueryResponse<T> {
  results: T[];
  totalResults: number;
}

export interface InfiniteQueryRequestParams {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
}
