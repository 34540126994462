import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth } from 'firebase/auth';
import { envConfig, firebaseConfig } from 'src/config';
import { getFirestore } from 'firebase/firestore';

export let firebaseAuth: ReturnType<typeof getAuth>;
export let firestore: ReturnType<typeof getFirestore>;

if (!firebase.apps.length && !envConfig.isStorybookActive) {
  firebase.initializeApp(firebaseConfig);
  firebaseAuth = getAuth(firebase.app());
  firestore = getFirestore(firebase.app());
}

export default firebase;
