import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  addPrescriptionRequest,
  getPrescriptionByCycleIdRequest,
  getPrescriptionByIdRequest,
  updatePrescriptionDosageRequest,
  updatePrescriptionRequest
} from 'src/api/patients.api';
import { AppError } from 'src/types/global';
import {
  MedicalForm,
  Prescription,
  UpdatePrescriptionDosage
} from 'src/types/prescription';
import { CreatePatientDocumentPayload } from '../types/documents';
import { queryKeys, querySubKeys } from './queryKeys';

function usePrescription() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    addPrescription: () =>
      useMutation<
        { prescriptionsDocumentId: string },
        AppError,
        {
          cycle: MedicalForm;
          prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
        }
      >(addPrescriptionRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_PRESCRIPTION')
          }),

        onSuccess: (_, { cycle: { patientId, cycleId } }) => {
          openToast({
            title: t('CREATE_PRESCRIPTION_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].DOCUMENTS
          ]);
          queryClient.invalidateQueries([
            queryKeys.CYCLES,
            cycleId,
            querySubKeys[queryKeys.CYCLES].GRAPH_DATA
          ]);
          queryClient.invalidateQueries([queryKeys.CYCLES, cycleId]);
        }
      }),
    updatePrescription: () =>
      useMutation<void, AppError, Partial<Prescription>>(
        updatePrescriptionRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PRESCRIPTION')
            }),

          onSuccess: (_, { cycleId, id }) => {
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
            queryClient.invalidateQueries([queryKeys.PRESCRIPTIONS, id]);
          }
        }
      ),
    getPrescriptionById: (
      prescriptionId: string,
      options?: UseQueryOptions<Prescription, AppError>
    ) =>
      useQuery<Prescription, AppError>(
        [queryKeys.PRESCRIPTIONS, prescriptionId],
        () => getPrescriptionByIdRequest(prescriptionId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PRESCRIPTION')
            }),

          enabled: !!prescriptionId,
          ...options
        }
      ),
    updatePrescriptionDosage: () =>
      useMutation<void, AppError, UpdatePrescriptionDosage>(
        updatePrescriptionDosageRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATING_PRESCTIPTION_DOSAGE')
            }),

          onSuccess: (_, { cycleId, prescriptionId }) => {
            openToast({
              title: t('UPDATE_PRESCRIPTION_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
            queryClient.invalidateQueries([
              queryKeys.PRESCRIPTIONS,
              prescriptionId
            ]);
            queryClient.invalidateQueries([queryKeys.CYCLES, cycleId]);
          }
        }
      ),
    getPrescriptionsByCycleId: (
      cycleId: string,
      options?: UseQueryOptions<Prescription[], AppError>
    ) =>
      useQuery<Prescription[], AppError>(
        [
          queryKeys.PRESCRIPTIONS,
          querySubKeys[queryKeys.PRESCRIPTIONS].CYCLE,
          cycleId
        ],
        () => getPrescriptionByCycleIdRequest(cycleId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PRESCRIPTIONS')
            }),

          enabled: !!cycleId,
          ...options
        }
      )
  };
}

export default usePrescription;
