import { FC } from 'react';
import { Colors } from '../../../components/styles/colors';
import { DotProps, DotWithLabel } from './DotWithLabel';

const folliclesDots: DotProps[] = [
  {
    fill: Colors.viola,
    label: 'GROWING'
  },
  {
    fill: Colors.poloBlue,
    label: 'FINAL_BLASTOCYST'
  },
  {
    fill: Colors.mustard,
    label: 'EUPLOID'
  }
];

interface EmbryosBarGraphMapProps {
  chartLeftMargin: number;
  yAxisWidth: number;
  columnWidth: number;
  dayOneIndex: number;
}

export const EmbryosBarGraphMap: FC<EmbryosBarGraphMapProps> = ({
  chartLeftMargin,
  columnWidth,
  dayOneIndex,
  yAxisWidth
}) => {
  const startingYPosition = 550;
  const xPositionPadding = columnWidth / 4;

  // 9 in the number of defferent phases of embryos that are represented in the chart
  const startingXPosition =
    chartLeftMargin +
    yAxisWidth +
    xPositionPadding +
    columnWidth * (dayOneIndex + 9);
  const dotRadius = 5;
  const dotMargin = 15;
  return (
    <g transform={`translate(${startingXPosition}, ${startingYPosition})`}>
      {folliclesDots.map((dot, index) => (
        <DotWithLabel
          key={`dot-embryos-${index}`}
          dot={dot}
          dotMargin={dotMargin}
          startingXPosition={0}
          startingYPosition={0}
          dotRadius={dotRadius}
          index={index}
          labsSpacing={20}
        />
      ))}
    </g>
  );
};
