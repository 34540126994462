import { FC } from 'react';
import Flex from 'src/components/layout/Flex';
import { radii, spacings } from 'src/components/styles/constants';
import { CycleNote } from 'src/types/cycle';
import Typography from '../Typography';
import dayjs from 'dayjs';
import Avatar from '../Avatar';
import { getFullName, getValidUrl } from 'src/utils/general';
import { UserTypes } from 'src/types/user';
import { Box, styled } from '@mui/material';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { longDateTimeFormat } from 'src/utils/dateAndTIme';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import ClearIcon from '@mui/icons-material/Clear';
import { TooltipWrapper } from '../Tooltip';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import { AvatarSizes } from '../Avatar/Avatar';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';

const StyledGraphNoteComponent = styled(Flex)`
  border-radius: ${radii.xlarge};
  padding: ${spacings.small};
  padding-right: ${spacings.large};
  background-color: ${Colors.alto2};
  font: ${fonts.input};
  .MuiBox-root {
    .MuiTypography-root + .MuiTypography-root {
      font: ${fonts.badge};
      text-align: end;
    }
  }

  .MuiSvgIcon-root {
    font: ${fonts.button};
    color: ${Colors.emperor};
    cursor: pointer;
  }
`;

const Note: FC<{
  note: CycleNote;
  onRemoveNote?: (_, index) => void;
  index: number;
}> = ({ note, onRemoveNote, index }) => {
  const { t } = useTranslation();
  const { getStaffMemberById } = useStaffMembers();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: staff, isLoading: isLoadingStaff } = getStaffMemberById(
    note?.createdByStaffId,
    { enabled: note?.createdByStaffId !== null }
  );

  const noteValue = note?.value;

  const avatar = getDefaultAvatar({
    userId: note?.createdByStaffId,
    userType: UserTypes.staff,
    pictureId: staff?.pictureId
  });

  if (isLoadingStaff) {
    return <Loader />;
  }

  const isValidUrl = !!getValidUrl(noteValue);

  return (
    <StyledGraphNoteComponent
      gap={spacings.medium}
      alignContent="flex-start"
      justifyContent="space-between"
    >
      <TooltipWrapper
        title={staff ? getFullName(staff) : t('UNKNOWN')}
        arrow
        placement="left"
      >
        <Box height="40px">
          <Avatar size={AvatarSizes.MEDIUM} image={avatar} />
        </Box>
      </TooltipWrapper>
      <Flex flexDirection="column" gap={spacings.small} flex={3}>
        {isValidUrl ? (
          <a
            href={getValidUrl(noteValue)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>{noteValue}</Typography>
          </a>
        ) : (
          <Typography>{noteValue}</Typography>
        )}

        <Typography>
          {note?.createdAt
            ? dayjs(note.createdAt).format(longDateTimeFormat)
            : t('UNKNOWN')}
        </Typography>
      </Flex>
      <Box onClick={() => onRemoveNote?.(null, index)}>
        <ClearIcon />
      </Box>
    </StyledGraphNoteComponent>
  );
};

export default Note;
