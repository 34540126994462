import { DefaultIcon } from 'src/constants';
import useClinicSettingsApi from 'src/hooks/useClinicSettingsApi';
import { UserTypes } from 'src/types/user';
import { getAvatarUrl } from 'src/utils/general';

interface GetDefaultAvatarProps {
  userId: string;
  userType: UserTypes;
  pictureId?: string;
}

export const getDefaultClinicLogo = ({
  organizationStringId,
  isForDocument = false
}: {
  organizationStringId: string;
  isForDocument?: boolean;
}): string => {
  const base_url = window.location.origin;

  switch (organizationStringId) {
    case 'ovom':
      return `${isForDocument ? base_url : ''}/static/images/logo/ovom_logo.${
        isForDocument ? 'png' : 'svg'
      }`;
    default:
      return `${isForDocument ? base_url : ''}/static/images/logo/embie_logo.${
        isForDocument ? 'png' : 'svg'
      }`;
  }
};

export function useGetDefaultAvatar() {
  const { getClinicSettings } = useClinicSettingsApi();
  const { data: clinicSettings, isLoading } = getClinicSettings();

  return {
    getDefaultAvatar: ({
      userId,
      userType,
      pictureId
    }: GetDefaultAvatarProps): string => {
      if (pictureId) {
        return getAvatarUrl(userId, userType);
      } else if (!isLoading) {
        return clinicSettings?.clinicIcon || DefaultIcon;
      }

      return DefaultIcon;
    }
  };
}
