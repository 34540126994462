import { FC } from 'react';
import { styled } from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import IconButton from '../IconButton';
import { IconButtonProps } from '../IconButton/IconButton';

const StyledIconButton = styled(IconButton)`
  svg {
    fill: ${Colors.mineShaft};
  }
`;

const MiniIconButton: FC<IconButtonProps> = (props) => {
  return <StyledIconButton iconSize="xsmall" bgColor="alto2" {...props} />;
};

export default MiniIconButton;
