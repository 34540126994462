import useClinicsApi from './useClinicsApi';
import useDocumentsApi from './useDocumentsApi';
import { NewCycleFormValues } from 'src/types/cycle';
import { getTemplateFieldsData } from '../modules/documents/getTemplateFieldsData';
import {
  Document,
  PatientDocument,
  TemplateImageProps
} from 'src/types/documents';
import useCycle from './useCycle';
import useCareTeam from './useCareTeam';
import useStaffMembers from './useStaffMembers';
import usePatientsApi from './usePatientsApi';
import useMeApi from './useMeApi';

interface UseGetPatientData {
  templateData: Record<
    string,
    string | Record<string, string>[] | TemplateImageProps
  >;
  documentToEdit: PatientDocument;
  templates: Document[];
  isLoading: boolean;
}

function useGetPatientDocumentData({
  cycleId,
  documentId,
  orderId,
  patientId,
  templateId,
  labOrdersId,
  enabled = true
}: {
  patientId: string;
  documentId?: string;
  templateId?: string;
  cycleId?: string;
  orderId?: string;
  newCycleValues?: NewCycleFormValues;
  labOrdersId?: string[];
  enabled?: boolean;
}): UseGetPatientData {
  const { getPatientById, getLabOrder, getPanels } = usePatientsApi();
  const { getStaffMemberById } = useStaffMembers();
  const { getPatientPrimaryCareTeamMember } = useCareTeam();
  const { getCycleById } = useCycle();
  const { getMe } = useMeApi();
  const { getClinicInfo } = useClinicsApi();
  const { getDocumentsTemplates, getDocumentById } = useDocumentsApi();
  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    patientId,
    { enabled: enabled && !!patientId }
  );

  const { data: cycle, isLoading: isLoadingCycle } = getCycleById(cycleId, {
    enabled: !!cycleId && enabled
  });

  const { data: loggedStaffMember, isLoading: isLoadingMe } = getMe();

  const prescriptionStaffCreatorId =
    cycle?.medicationProtocols?.[0]?.createdByStaffId ||
    cycle?.prescriptionsRx?.[0]?.createdByStaffId;

  const { data: patientLabOrder, isLoading: isLoadingLabOrder } = getLabOrder(
    patientId,
    orderId,
    {
      enabled: !!orderId && enabled
    }
  );

  const { data: panels, isLoading: isLoadingPanels } = getPanels({
    enabled: !!patientLabOrder
  });

  const {
    data: patientPrimaryCareTeamMember,
    isLoading: isLoadingPrimaryCareTeamMember
  } = getPatientPrimaryCareTeamMember(patientId, {
    enabled: enabled && !!patientId
  });

  const { data: clinicInfo, isLoading: isLoadingClinic } = getClinicInfo(
    patient?.clinicId || patientPrimaryCareTeamMember?.clinicId,
    {
      enabled: !!patient?.clinicId && enabled
    }
  );

  const { data: templates, isLoading: isLoadingTemplates } =
    getDocumentsTemplates({ enabled: enabled && !!patientId });

  const { data: documentToEdit, isLoading: isLoadingDocumentToEdit } =
    getDocumentById(patientId, documentId, {
      enabled: !!documentId && enabled
    });

  const { isLoading: isLoadingPrescriptionStaffCreator } = getStaffMemberById(
    prescriptionStaffCreatorId,
    {
      enabled: !!prescriptionStaffCreatorId && enabled
    }
  );

  const isLoading =
    isLoadingPatient ||
    isLoadingCycle ||
    isLoadingMe ||
    isLoadingLabOrder ||
    isLoadingPanels ||
    isLoadingPrimaryCareTeamMember ||
    isLoadingClinic ||
    isLoadingTemplates ||
    isLoadingDocumentToEdit ||
    isLoadingPrescriptionStaffCreator;

  if (enabled) {
    const chosenTemplate = templates?.find(({ id }) => id === templateId);

    const templateData = getTemplateFieldsData({
      patient,
      templateType: chosenTemplate?.type,
      clinic: clinicInfo,
      patientPrimaryCareTeamMember,
      patientLabOrder,
      labOrdersId,
      panels,
      loggedStaffMember
    });

    return {
      templateData,
      documentToEdit,
      templates,
      isLoading
    };
  } else {
    return {
      templateData: {},
      documentToEdit: {},
      templates: [],
      isLoading
    };
  }
}

export default useGetPatientDocumentData;
