import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import usePatientsInsurancesApi from '../../../hooks/usePatientsInsurancesApi';
import { getDateFormat } from '../../../utils/dateAndTIme';
import { getFullAddress } from '../../../utils/general';
import {
  PatientInsurance,
  RelationshipToEnsured
} from '../../../types/patientInsurance';
import Box from '../../../components/layout/Box/Box';
import Flex from '../../../components/layout/Flex/Flex';
import Typography from '../../../components/display/Typography/Typography';
import { spacings } from '../../../components/styles/constants';
import { fontWeights } from '../../../components/styles/fonts';
import { Colors } from '../../../components/styles/colors';
import MiniIconButton from '../../../components/display/MiniIconButton/MiniIconButton';
import { useDialog } from '../../../components/components-api/GlobalProvider/GlobalProvider';
import Switch from '../../../components/data-entry/Switch';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { priorityToDataIndexMap } from './types';
import { EditPatientInsurancesInfo } from './EditPatientInsurancesInfo';
import { getInsuranceLabel } from '../utils/getInsuranceLabel';

const RowContainer: FC<{
  title: string;
  value?: string;
}> = ({ title, value }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" gap={3}>
      <Typography>{title}</Typography>
      {value && <Typography>{value}</Typography>}
    </Flex>
  );
};

export const PatientInsurancesInfoCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientInsurances, updatePatientIsUninsured } =
    usePatientsInsurancesApi();
  const { getPatientById } = usePatientsApi();

  const { openDialog } = useDialog();
  const { mutate: updateIsUninsured } = updatePatientIsUninsured();
  const { data: patient } = getPatientById(patientId);
  const {
    data: insurances,
    isLoading,
    isFetching
  } = getPatientInsurances(patientId);

  const [isPatientInsured, setIsPatientInsured] = useState<boolean>(
    !patient.isUninsured
  );
  const showAddInsuranceInfoButton =
    isPatientInsured && insurances && insurances.length < 3;

  const patientInsurances = insurances?.sort(
    (a, b) =>
      priorityToDataIndexMap[a.insurancePriority] -
      priorityToDataIndexMap[b.insurancePriority]
  );

  const onChangeIsUninsured = async (checked) => {
    setIsPatientInsured(checked);

    await updateIsUninsured({ patientId, isUninsured: !checked });
  };

  const InsuranceInfo: FC<{ insurance: PatientInsurance; index: number }> = ({
    insurance,
    index
  }) => {
    const {
      id,
      relationshipToInsured,
      registeredPartnerForTreatment,
      insuranceId,
      insurancePolicyGroupOrFecaNumber,
      employerName,
      insuredFirstName,
      insuredLastName,
      insuredGender,
      insuredDob,
      address,
      city,
      zip,
      state,
      insurancePriority,
      payerName
    } = insurance;

    const isNotSelfInsured =
      relationshipToInsured &&
      relationshipToInsured !== RelationshipToEnsured.SELF;
    const isRegisteredPartner = registeredPartnerForTreatment;

    const insuranceLabel = getInsuranceLabel(insurancePriority);

    const fullAddress = useMemo(
      () =>
        getFullAddress({
          city,
          address,
          state,
          zip
        }),
      [city, address, state, zip]
    );

    return (
      <Flex
        flexDirection="column"
        gap={spacings.small}
        key={id}
        marginBottom={index !== insurances?.length - 1 && spacings.large}
      >
        <Flex flex={1} justifyContent="space-between" alignItems="center">
          {insuranceLabel && (
            <Typography fontWeight={fontWeights.extraBold}>
              {`${t(insuranceLabel)}:`}
            </Typography>
          )}
          <Flex gap={1} alignItems="center">
            <MiniIconButton
              icon={
                <EditIcon
                  fill={Colors.black}
                  onClick={() =>
                    openDialog({
                      header: t('INSURANCE_INFORMATION'),
                      children: (
                        <EditPatientInsurancesInfo
                          patientId={patientId}
                          insuranceId={id}
                        />
                      ),
                      fullWidth: true,
                      maxWidth: 'lg'
                    })
                  }
                />
              }
            />
          </Flex>
        </Flex>
        <RowContainer
          title={t('RELATION_TO_INSURED')}
          value={relationshipToInsured}
        />
        {isNotSelfInsured && (
          <RowContainer
            title={t('IS_THIS_YOUR_REGISTER_PARTNER')}
            value={registeredPartnerForTreatment ? 'Yes' : 'No'}
          />
        )}
        <RowContainer title={t('INSURANCE_ID')} value={insuranceId} />
        <RowContainer title={t('INSURANCE_NAME')} value={payerName} />
        <RowContainer
          title={t('INSURANCE_POLICY_OR_FECA_NUMBER')}
          value={insurancePolicyGroupOrFecaNumber}
        />
        <RowContainer title={t('EMPLOYER_NAME')} value={employerName} />
        {isNotSelfInsured && !isRegisteredPartner && (
          <>
            <RowContainer title={t('IF_INSURED_IS_NOT_SELF_OR_PARTNER')} />
            <RowContainer
              title={t('INSURED_NAME')}
              value={`${insuredFirstName} ${insuredLastName}`}
            />
            <RowContainer title={t('INSURED_GENDER')} value={insuredGender} />
            <RowContainer
              title={t('INSURED_DBO')}
              value={dayjs(insuredDob).format(getDateFormat())}
            />
            <RowContainer title={t('ADDRESS')} value={fullAddress} />
          </>
        )}
      </Flex>
    );
  };

  if (isLoading || isFetching) return null;

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('INSURANCE_INFORMATION').toUpperCase()}
        </Typography>
        <Flex gap={1} alignItems="center">
          {showAddInsuranceInfoButton && (
            <MiniIconButton
              icon={
                <AddIcon
                  fill={Colors.black}
                  onClick={() =>
                    openDialog({
                      header: t('INSURANCE_INFORMATION'),
                      children: (
                        <EditPatientInsurancesInfo patientId={patientId} />
                      ),
                      fullWidth: true,
                      maxWidth: 'lg'
                    })
                  }
                />
              }
            />
          )}
        </Flex>
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      {patientInsurances?.length ? (
        <>
          {patientInsurances.map((insurance, index) => (
            <InsuranceInfo
              key={`${insurance.id}-${index}`}
              insurance={insurance}
              index={index}
            />
          ))}
        </>
      ) : (
        <Flex flex={1} justifyContent="space-between" alignItems="center">
          <Typography fontWeight={fontWeights.extraBold}>
            {t('DOES_PATIENT_HAVE_INSURANCE')}
          </Typography>
          <Switch
            checked={isPatientInsured}
            switchOnText={t('YES')}
            switchOffText={t('NO')}
            onChange={(_, checked) => onChangeIsUninsured(checked)}
            width={80}
            defaultValue={t('YES')}
          />
        </Flex>
      )}
    </Flex>
  );
};
