import { SelectOption } from '@mui/base';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { MouseEvent, ReactElement, ReactNode } from 'react';
import { PopoverProps } from 'src/components/display/Popover/Popover';
import { spacings } from 'src/components/styles/constants';
import i18n from 'src/i18n/i18n';
import { GridFilterOperator } from '@mui/x-data-grid-premium';

export const popoverWidth = 425;

const popoverLeft = 400;
const popoverLeftMargin = 425;

export const getTableHeaderPopoverProps = ({
  children,
  ev
}: {
  ev: MouseEvent<HTMLElement>;
  children: ReactNode;
}): Omit<PopoverProps, 'open'> => {
  return {
    hideToolbar: true,
    anchorPosition: {
      top: ev.currentTarget?.getBoundingClientRect().bottom || 0,
      left:
        (ev.currentTarget?.getBoundingClientRect().left || popoverLeft) -
        popoverLeftMargin
    },
    anchorReference: 'anchorPosition',
    children,
    paperPadding: spacings.xxlarge,
    anchorEl: ev.currentTarget
  };
};

export const getTypedFilterOperators = (
  enumType: Record<string, string | number>
): GridFilterOperator[] =>
  getOptionsFilterOperators(
    Object.values(enumType).map((value) => ({
      value,
      label: i18n.t(`${value}`)
    }))
  );

export const getOptionsFilterOperators = (
  options: Partial<SelectOption<any>>[]
): GridFilterOperator[] => {
  const getApplyFilterFn = (filterItem): null | ((params: any) => boolean) => {
    if (!filterItem.value || filterItem.value.length === 0) {
      return null;
    }
    return (params) => params.value === filterItem.value;
  };

  const InputComponent = (props): ReactElement => {
    const { item, applyValue } = props;

    return (
      <>
        <InputLabel />
        <Select
          {...props}
          label=""
          options={options}
          value={item.value || ''}
          onChange={(ev) => applyValue({ ...item, value: ev.target.value })}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return [
    { label: 'EQUALS', value: 'equals' },
    { label: 'DOES_NOT_EQUAL', value: 'doesNotEqual' }
  ].map((operator) => {
    return {
      label: i18n.t(operator.label),
      value: operator.value,
      getApplyFilterFn,
      InputComponent
    };
  });
};
