import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { Colors } from 'src/components/styles/colors';
import EditIcon from '@mui/icons-material/Edit';
import { ReactComponent as PrescriptionIcon } from '../../../assets/icons/medication.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import AddEditAppointment from '../../calendar/AddEditAppointment';
import useCycle from 'src/hooks/useCycle';
import Menu, { MenuItemProps } from '../../../components/display/Menu/Menu';
import { useCycleWizard } from '../../../components/components-api/CycleWizardProvider';
import { CycleWizardVariant } from '../../../types/cycle';

export const EditTreatmentMenu: FC<{
  patientId: string;
  cycleId: string;
}> = ({ cycleId, patientId }) => {
  const { openDialog } = useDialog();
  const { openWizard } = useCycleWizard();
  const { t } = useTranslation();
  const { getCycleById } = useCycle();

  const {
    data: cycle,
    isLoading: isCycleLoading,
    isFetching: isCycleFetching
  } = getCycleById(cycleId);

  const isLoading = isCycleLoading || isCycleFetching;

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items: MenuItemProps[] = [
      {
        label: t('EDIT_CYCLE'),
        icon: <EditIcon sx={{ color: Colors.mineShaft }} />,
        action: () => {
          openWizard({
            patientId,
            cycleId,
            variant: CycleWizardVariant.EDIT_CYCLE_INFO
          });
        }
      }
    ];

    if (!cycle?.isHistorical) {
      items.push(
        {
          label: t('ADD_APPOINTMENT'),
          icon: <CalendarIcon color={Colors.mineShaft} />,
          action: () =>
            openDialog({
              header: t('ADD_APPOINTMENT'),
              children: (
                <AddEditAppointment cycleId={cycleId} patientId={patientId} />
              ),
              fullWidth: true,
              maxWidth: 'lg'
            })
        },
        {
          label: t('ADD_PRESCRIPTION'),
          icon: <PrescriptionIcon color={Colors.mineShaft} />,
          action: () => {
            openWizard({
              variant: CycleWizardVariant.ADD_PRESCRIPTION,
              cycleId,
              patientId
            });
          }
        }
      );
    }

    return items;
  }, [cycle]);

  return <Menu items={menuItems} loading={isLoading} />;
};
