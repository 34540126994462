import { Examination } from 'src/types/exam';
import { ExaminationServer } from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';
import { convertExaminationServerToClient } from '../modules/patients/utils/conversions';
import { OrderVendor } from 'src/types/hl7messages';

export const getConstValuesRequest = async (): Promise<Record<string, any>> => {
  const consts = {
    totalMotileNormalRange: '≥42',
    marNormalRange: '<25',
    roundCellsNormalRange: '<1'
  };
  return consts;
};

export async function getExaminationIdRequest(
  examinationId: string
): Promise<Examination> {
  const response = await axiosApiInstance.get<ExaminationServer>(
    `/examinations/${examinationId}`
  );
  return convertExaminationServerToClient(response.data);
}

export async function getOrdersVendors(): Promise<OrderVendor[]> {
  const response = await axiosApiInstance.get<OrderVendor[]>(
    '/billing-services/vendors'
  );
  return response.data;
}

export async function getPatientData(patientId: string): Promise<Blob> {
  const response = await axiosApiInstance.post<Blob>(
    '/export-patient-data',
    { patientId },
    { responseType: 'blob' }
  );
  return response.data;
}
