import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'src/components/layout/Box/Box';
import Loader from 'src/components/display/Loader/Loader';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { patientRoutes } from 'src/router/routes';
import { getFullAddress } from 'src/utils/general';

export const ContactInfo: FC<{ patientId: string }> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const navigate = useNavigate();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  if (isLoadingPatient) return <Loader />;

  const { phoneNumber, email, address, state, city, country, postalCode } =
    patient?.personalInfo || {};
  const { phoneNumber: partnerPhone } = patient?.partnerInfo || {};
  return (
    <Flex marginTop={spacings.medium} gap={spacings.xlarge}>
      <Box>
        <Flex gap={spacings.small}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('PHONE')}:
          </Typography>
          <Typography>{phoneNumber}</Typography>
        </Flex>
        <Flex gap={spacings.small}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('PARTNER_PHONE')}:
          </Typography>
          <Typography>{partnerPhone}</Typography>
        </Flex>
        <Flex gap={spacings.small}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('EMAIL')}:
          </Typography>
          <Typography>{email}</Typography>
        </Flex>
      </Box>
      <Flex gap={spacings.small}>
        <Typography fontWeight={fontWeights.extraBold}>
          {t('ADDRESS')}:
        </Typography>
        <Typography>
          {getFullAddress({
            country,
            city,
            address,
            state,
            zip: postalCode
          })}
        </Typography>
      </Flex>
      <Box
        alignSelf="flex-end"
        onClick={() => {
          navigate(patientRoutes.getDetailsLink(patientId));
        }}
        sx={{
          cursor: 'pointer'
        }}
        id="contant-see-more"
      >
        <Typography fontWeight={fontWeights.extraBold}>
          {t('SEE_MORE_LABEL').toUpperCase()}
        </Typography>
      </Box>
    </Flex>
  );
};
