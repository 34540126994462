import { styled } from '@mui/material';
import { FC, useState } from 'react';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import SearchField, {
  SearchFieldVariants
} from 'src/components/data-entry/SearchField/SearchField';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { makeShouldForwardProps } from 'src/components/utils';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { PatientPersonalInfo } from 'src/types/patient';
import { getFullName } from 'src/utils/general';

interface PatientsSearchBarProps {
  onSelectPatient: (patientId: string) => void;
  startIcon?: React.ReactElement;
  variant?: SearchFieldVariants;
  onSearchTermClear?: () => void;
}

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: makeShouldForwardProps(['variant'])
})<{ variant: SearchFieldVariants }>`
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding: ${({ variant }) =>
      variant === SearchFieldVariants.STYLED ? spacings.medium : 0};
  }
`;

const StyledListItem = styled('li')`
  &.Mui-focused {
    && {
      background-color: ${Colors.vistaWhite};
    }
  }
`;

export const PatientsSearchBar: FC<PatientsSearchBarProps> = ({
  onSelectPatient,
  startIcon,
  variant,
  onSearchTermClear
}) => {
  const { getPatients } = usePatientsApi();
  const [chosenPatient, setChosenPatient] = useState<PatientPersonalInfo | {}>(
    {}
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const { data: patients } = getPatients();

  if (!chosenPatient) {
    return <></>;
  }

  const handleSelectPatient = (patient: PatientPersonalInfo) => {
    setSearchTerm(getFullName(patient));
    setChosenPatient(patient);
    setIsAutocompleteOpen(false);
    onSelectPatient(patient.id);
  };

  return (
    <StyledAutocomplete
      variant={variant}
      fullWidth
      open={isAutocompleteOpen}
      options={patients || []}
      shouldSortOptions
      role="list-box"
      disableClearable
      value={chosenPatient}
      filterOptions={(options, state) => {
        if (state.inputValue.length < 2) return [];
        return options.filter((item: PatientPersonalInfo) =>
          getFullName(item)
            .toLowerCase()
            .includes(state.inputValue.toLowerCase())
        );
      }}
      onChange={(_, patient: PatientPersonalInfo) =>
        handleSelectPatient(patient)
      }
      getOptionLabel={(option: PatientPersonalInfo) => getFullName(option)}
      renderOption={(props, patient: PatientPersonalInfo) => {
        return (
          <StyledListItem
            {...props}
            onClick={() => handleSelectPatient(patient)}
          >
            {getFullName(patient)}
          </StyledListItem>
        );
      }}
      renderInput={(params) => (
        <SearchField
          inputRef={params.InputProps.ref}
          inputProps={{ ...params.inputProps, id: 'patients-search-bar' }}
          startIcon={startIcon}
          searchFieldVariant={variant}
          onChange={(value) => {
            if (value.length >= 2) {
              setIsAutocompleteOpen(true);
            } else {
              setIsAutocompleteOpen(false);
            }
            setSearchTerm(value);
          }}
          onSearchTermClear={() => {
            setChosenPatient({});
            setSearchTerm('');
            onSearchTermClear?.();
          }}
          value={searchTerm}
        />
      )}
    />
  );
};
