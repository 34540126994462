import { SchemaProps } from './types';
import { FieldValues } from 'react-hook-form';

export function isFieldVisible(
  schema: SchemaProps,
  formValues: FieldValues
): boolean {
  return schema.show
    ? Object.keys(schema.show).every(
        (key) => schema.show && formValues[key] === schema.show[key]
      )
    : true;
}
