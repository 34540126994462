import { convertStringIdToNumber } from './../utils/general';
import { dashSeparatedDateFormat } from './../utils/dateAndTIme';
import { BusyTimeServer } from './../types/patient-server';
import { convertBusyTimeServerToClient } from './../modules/patients/utils/conversions';
import {
  convertAppointmentServerToClient,
  convertAppointmentWithStatusPayloadToServer
} from 'src/modules/patients/utils/conversions';
import {
  Appointment,
  AppointmentCodingTemplate,
  BusyTime,
  ClincRoomsBusyTimesRequest,
  ClinicRoom,
  Panel,
  RequestRange,
  StaffBusyTimesRequest
} from 'src/types/appointment';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import {
  AppointmentServer,
  CreateAppointmentDetails,
  PanelServer
} from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';

export const getPatientAppointmentsRequest = async (
  patientId: string,
  { includeCanceled = false }
): Promise<Appointment[]> => {
  const response = await axiosApiInstance.get<AppointmentServer[]>(
    `/appointments?patientId=${patientId}&includeCanceled=${includeCanceled}`
  );
  return response.data.map((appointment) =>
    convertAppointmentServerToClient(appointment)
  );
};

export const getAggregatedAppointmentsRequest = async ({
  minDate,
  maxDate
}: RequestRange): Promise<Appointment[]> => {
  const response = await axiosApiInstance.get<AppointmentServer[]>(
    `/appointments/aggregated?minDate=${minDate.format(
      dashSeparatedDateFormat
    )}&maxDate=${maxDate.format(dashSeparatedDateFormat)}`
  );
  return response.data.map((appointment) =>
    convertAppointmentServerToClient(appointment)
  );
};

export const getAppointmentByIdRequest = async (
  appointmentId: string
): Promise<Appointment> => {
  const response = await axiosApiInstance.get<AppointmentServer>(
    `/appointments/${appointmentId}`
  );
  return convertAppointmentServerToClient(response.data);
};

export const cancelAppointmentRequest = async (
  appointmentId: string,
  cancellationReason: string
) => {
  const response = await axiosApiInstance.post<AppointmentServer>(
    `/appointments/${appointmentId}/cancel`,
    { cancellationReason }
  );
  return response.data[0];
};

export const createAppointmentRequest = async ({
  appointment,
  ordersDocumentPayload
}: {
  appointment: CreateAppointmentDetails;
  ordersDocumentPayload: CreatePatientDocumentPayload;
}) => {
  const formData = new FormData();

  if (ordersDocumentPayload) {
    const { file, patientId, templateId, isCompleted, metadata } =
      ordersDocumentPayload;

    formData.append('ordersDocumentFile', file);
    formData.append(
      'ordersDocumentData',
      JSON.stringify({
        patientId: appointment?.patientId || patientId,
        templateId,
        metadata,
        isCompleted
      })
    );
  }

  for (const key in appointment) {
    const value = appointment[key];
    if (typeof value !== 'string') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }

  const response = await axiosApiInstance.post<AppointmentServer>(
    `/appointments/${appointment.patientId}`,
    formData
  );

  const newlyCreatedAppointment = convertAppointmentServerToClient(
    response.data
  );

  return newlyCreatedAppointment;
};

export const updateAppointmentRequest = async ({
  appointment
}: {
  appointment: Partial<CreateAppointmentDetails>;
}): Promise<void> => {
  const response = await axiosApiInstance.put<AppointmentServer>(
    `/appointments/${appointment.id}/staff`,
    appointment
  );
  return response.data[0];
};

export async function updateAppointmentStatusRequest(
  appointmentId: string,
  updatedAppointment: Appointment
) {
  const response = await axiosApiInstance.put<AppointmentServer>(
    `/appointments/${appointmentId}/status`,
    convertAppointmentWithStatusPayloadToServer(updatedAppointment)
  );

  return response.data;
}

export async function getPanelsRequest(): Promise<Panel[]> {
  const response = await axiosApiInstance.get<PanelServer[]>('/panels');
  return response.data;
}

export async function getClinicRoomsRequest(): Promise<ClinicRoom[]> {
  const response = await axiosApiInstance.get<ClinicRoom[]>('/clinic-rooms');

  return response.data;
}

export async function getClinicRoomBusyTimesRequest({
  roomId,
  minDate,
  maxDate
}: ClincRoomsBusyTimesRequest): Promise<BusyTime[]> {
  const response = await axiosApiInstance.post<BusyTimeServer[]>(
    'appointments/rooms/busy',
    {
      minDate: minDate.format(dashSeparatedDateFormat),
      maxDate: maxDate.format(dashSeparatedDateFormat),
      roomId: convertStringIdToNumber(roomId)
    }
  );

  const busyTimesToReturn = response?.data?.map((busyTimeServer) =>
    convertBusyTimeServerToClient(busyTimeServer)
  );

  return busyTimesToReturn;
}

export async function getStaffBusyTimesRequest({
  staffIds,
  minDate,
  maxDate
}: StaffBusyTimesRequest): Promise<BusyTime[] | undefined> {
  const response = await axiosApiInstance.post<BusyTimeServer[]>(
    'appointments/staff/busy',
    {
      minDate: minDate.format(dashSeparatedDateFormat),
      maxDate: maxDate.format(dashSeparatedDateFormat),
      staffIds
    }
  );

  const busyTimesToReturn =
    response?.data?.map((busyTimeServer) =>
      convertBusyTimeServerToClient(busyTimeServer)
    ) || [];

  return busyTimesToReturn;
}

export async function getAppointmentCodingTemplatesRequest(): Promise<
  AppointmentCodingTemplate[]
> {
  const response = await axiosApiInstance.get<AppointmentCodingTemplate[]>(
    'appointments/coding-templates'
  );

  return response.data;
}
