import { ExternalOrderBundle } from 'src/types/appointment';
import axiosApiInstance from '../utils/axios';
import { Clinic } from 'src/types/clinic';

export async function getClinicInfoById(): Promise<Clinic> {
  const response = await axiosApiInstance.get<Clinic>(`clinic/info`);
  return response.data;
}

export async function getClinicExternalOrderBundles(): Promise<
  ExternalOrderBundle[]
> {
  const response = await axiosApiInstance.get<ExternalOrderBundle[]>(
    `clinic/external-order-bundles`
  );
  return response.data;
}
