import { FC, useEffect, useMemo } from 'react';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePatientsInsurancesApi from '../../../hooks/usePatientsInsurancesApi';
import {
  PatientInsurance,
  RelationshipToEnsured,
  relationshipToEnsuredEntries
} from '../../../types/patientInsurance';
import { YesOrNo, yesOrNoEntries } from '../../../types/global';
import Typography from '../../../components/display/Typography/Typography';
import InputField from '../../../components/data-entry/InputField/InputField';
import Select from '../../../components/data-entry/Select/Select';
import ImageUpload, {
  ImageFile
} from '../../../components/data-entry/ImageUpload/ImageUpload';
import Box from '../../../components/layout/Box/Box';
import Button from '../../../components/display/Button/Button';
import { spacings } from '../../../components/styles/constants';
import Loader from '../../../components/display/Loader';
import Center from '../../../components/layout/Center';
import Flex from '../../../components/layout/Flex';
import { fontWeights } from '../../../components/styles/fonts';
import DatePicker from '../../../components/data-entry/DatePicker/DatePicker';
import { useDialog } from '../../../components/components-api/GlobalProvider/GlobalProvider';
import { personSexEntries } from '../../../types/patient';
import styled from '@emotion/styled';
import { priorityEntries } from '../../../types/priority';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import { SearchOutlined } from '@mui/icons-material';
import { getFileExtensionFromFileName, getUSStateCode } from 'src/utils/general';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { DocumentExtension } from 'src/types/documents';

const StyledBox = styled(Box)`
  margin-bottom: ${spacings.xlarge};
  margin-top: ${spacings.xlarge};

  // reduce the width of the gap between two columns to keep StyledBox width equal to the columns width
  width: calc(50% - ${spacings.medium});
`;

const StyledFlexColumn = styled(Flex)`
  flex-direction: column;
  gap: ${spacings.xlarge};
`;

const StyledFlexRow = styled(Flex)<{
  gap?: string;
}>`
  gap: ${({ gap }) => gap ?? spacings.xlarge};
`;

const InsuredsDetails: FC<{
  control: Control<PatientInsurance, any, PatientInsurance>;
  errors: FieldErrors<PatientInsurance>;
}> = ({ control, errors }) => {
  const { t } = useTranslation();

  const personSexOptions = personSexEntries.map(([_, value]) => ({
    value,
    label: value
  }));

  return (
    <>
      <Typography fontWeight={fontWeights.extraBold} variant="h2">
        {t('IF_INSURED_IS_NOT_SELF_OR_PARTNER')}
      </Typography>
      <StyledFlexRow marginTop={spacings.xlarge} marginBottom={spacings.xlarge}>
        <StyledFlexColumn flex={1}>
          <Controller
            name="insuredFirstName"
            control={control}
            rules={{
              required: t('INSURED_NAME_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('INSURED_FIRST_NAME').toUpperCase()}
                placeholder={t('NAME_OF_INSURANCE_POLICY_HOLDER')}
                error={!!errors.insuredFirstName}
                helperText={errors.insuredFirstName?.message}
                fullWidth
                required
              />
            )}
          />
          <Controller
            name="insuredDob"
            control={control}
            rules={{
              required: t('INSURED_DOB_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => {
              const value = field.value as Date;
              return (
                <DatePicker
                  {...field}
                  value={value}
                  label={t('INSURED_DBO').toUpperCase()}
                  inputRef={ref}
                  error={!!errors.insuredDob}
                  helperText={errors.insuredDob?.message}
                  fullWidth
                  required
                />
              );
            }}
          />
        </StyledFlexColumn>
        <StyledFlexColumn flex={1}>
          <Controller
            name="insuredLastName"
            control={control}
            rules={{
              required: t('INSURED_NAME_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('INSURED_LAST_NAME').toUpperCase()}
                placeholder={t('NAME_OF_INSURANCE_POLICY_HOLDER')}
                error={!!errors.insuredLastName}
                helperText={errors.insuredLastName?.message}
                fullWidth
                required
              />
            )}
          />
          <Controller
            name="insuredGender"
            control={control}
            rules={{
              required: t('INSURED_GENDER_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                defaultOption={t('INSURED_GENDER_PLACEHOLDER')}
                inputRef={ref}
                label={t('INSURED_GENDER').toUpperCase()}
                error={!!errors.insuredGender}
                helperText={errors.insuredGender?.message}
                onChange={(event) => field.onChange(event.target.value)}
                options={personSexOptions}
              />
            )}
          />
        </StyledFlexColumn>
      </StyledFlexRow>
      <StyledFlexRow flex={1} marginBottom={spacings.xxlarge}>
        <Controller
          name="address"
          control={control}
          rules={{
            required: t('INSURED_ADDRESS_REQUIRED')
          }}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              label={t('ADDRESS').toUpperCase()}
              placeholder={t('ADDRESS_PLACEHOLDER')}
              error={!!errors.address}
              helperText={errors.address?.message}
              fullWidth
              required
            />
          )}
        />
        <StyledFlexRow gap={spacings.small}>
          <StyledFlexColumn flex={2}>
            <Controller
              name="city"
              control={control}
              rules={{
                required: t('INSURED_CITY_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('CITY_LABEL')}
                  placeholder={t('CITY_PLACEHOLDER')}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
          <StyledFlexColumn flex={1}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: t('INSURED_STATE_REQUIRED'),
                validate: (value) => {
                  const stateCode = getUSStateCode(value.toUpperCase());

                  return !stateCode ? t('INSURED_STATE_INVALID') : true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('STATE_LABEL')}
                  placeholder={t('STATE_PLACEHOLDER')}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
          <StyledFlexColumn flex={1}>
            <Controller
              name="zip"
              control={control}
              rules={{
                required: t('INSURED_ZIP_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('ZIP_LABEL')}
                  placeholder={t('ZIP_PLACEHOLDER')}
                  error={!!errors.zip}
                  helperText={errors.zip?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
        </StyledFlexRow>
      </StyledFlexRow>
    </>
  );
};

export const EditPatientInsurancesInfo: FC<{
  patientId: string;
  insuranceId?: string;
}> = ({ patientId, insuranceId }) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const {
    getInsurancePayers,
    getPatientInsurance,
    getPatientInsurances,
    createPatientInsurance,
    updatePatientInsurance
  } = usePatientsInsurancesApi();

  const { getPatientById } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);

  const { data: patientInsurance, isLoading } = getPatientInsurance(
    patientId,
    insuranceId
  );

  const { data: insurancePayers, isLoading: isLoadingInsurancePayers } =
    getInsurancePayers(patientId);

  const { data: insurances, refetch } = getPatientInsurances(patientId);

  const {
    mutate,
    isLoading: isCreatingPatientInsurance,
    isSuccess: isCreatedSuccessfully
  } = createPatientInsurance();
  const {
    mutate: updateInsurance,
    isSuccess: isUpdatedSuccessfully,
    isLoading: isUpdatingPatientInsurance
  } = updatePatientInsurance();

  const relationshipToEnsuredOptions = relationshipToEnsuredEntries.map(
    ([_, value]) => ({
      value,
      label: t(value)
    })
  );

  const registeredPartnerOptions = yesOrNoEntries.map(([label, value]) => ({
    label: t(label),
    value
  }));

  const priorityOptions = useMemo(
    () =>
      priorityEntries.map(([_, value]) => {
        return {
          value: value,
          label: value,
          disabled:
            insurances.length &&
            !!insurances.find(
              (insurance) => insurance.insurancePriority === value
            )
        };
      }),
    [insurances]
  );

  const priorityDefaultValue = priorityOptions.filter(
    (option) => !option.disabled
  )[0]?.value;

  const imageIdCardFrontFileExtension = getFileExtensionFromFileName(
    (patientInsurance?.imageIdCardFrontFile as ImageFile)?.name
  );
  const imageIdCardBackFileExtension = getFileExtensionFromFileName(
    (patientInsurance?.imageIdCardBackFile as ImageFile)?.name
  );

  const imageIdCardFrontMimeType =
  imageIdCardFrontFileExtension === DocumentExtension.PDF
    ? 'application/pdf'
    : `image/${imageIdCardFrontFileExtension}`;

  const imageIdCardBackMimeType =
  imageIdCardBackFileExtension === DocumentExtension.PDF
    ? 'application/pdf'
    : `image/${imageIdCardBackFileExtension}`;

  const defaultValues: PatientInsurance = {
    id: patientInsurance?.id ?? '',
    patientId: patientInsurance?.patientId ?? patientId,
    relationshipToInsured: patientInsurance?.relationshipToInsured ?? null,
    registeredPartnerForTreatment:
      patientInsurance?.registeredPartnerForTreatment ?? null,
    payerId: patientInsurance?.payerId ?? '',
    insuranceId: patientInsurance?.insuranceId ?? '',
    insurancePolicyGroupOrFecaNumber:
      patientInsurance?.insurancePolicyGroupOrFecaNumber ?? '',
    employerName: patientInsurance?.employerName ?? '',
    insuredFirstName: patientInsurance?.insuredFirstName ?? '',
    insuredLastName: patientInsurance?.insuredLastName ?? '',
    insuredGender: patientInsurance?.insuredGender ?? null,
    insuredDob: patientInsurance?.insuredDob ?? null,
    address: patientInsurance?.address ?? '',
    city: patientInsurance?.city ?? '',
    zip: patientInsurance?.zip ?? '',
    state: patientInsurance?.state ?? '',
    imageIdCardFrontFile:
      patientInsurance?.imageIdCardFrontFile ??
      patientInsurance?.imageIdCardFront
        ? ([
            {
              url: patientInsurance?.imageIdCardFront,
              type: imageIdCardFrontMimeType
            }
          ] as ImageFile[])
        : null,
    imageIdCardBackFile:
      patientInsurance?.imageIdCardBackFile ?? patientInsurance?.imageIdCardBack
        ? ([
            {
              url: patientInsurance?.imageIdCardBack,
              type: imageIdCardBackMimeType
            }
          ] as ImageFile[])
        : null,
    imageIdCardFront: patientInsurance?.imageIdCardFront ?? '',
    imageIdCardBack: patientInsurance?.imageIdCardBack ?? '',
    isUninsured: !!patientInsurance?.isUninsured,
    insurancePriority:
      patientInsurance?.insurancePriority ?? priorityDefaultValue
  };

  const hasPartner = !!patient?.partnerInfo;

  const { control, handleSubmit, watch, formState, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const { errors } = formState;
  const updatedProps = watch();

  const isNotSelfInsured =
    updatedProps.relationshipToInsured &&
    updatedProps.relationshipToInsured !== RelationshipToEnsured.SELF;
  const isRegisteredPartner = updatedProps.registeredPartnerForTreatment;
  const showRegisteredPartnerSelect = isNotSelfInsured && hasPartner;

  const onSubmit = async (details: PatientInsurance) => {
    if (insuranceId) {
      await updateInsurance(details);
    } else {
      await mutate(details);
    }
  };

  useEffect(() => {
    const isSuccess = isCreatedSuccessfully || isUpdatedSuccessfully;

    if (isSuccess) {
      closeDialog();
      refetch();
    }
  }, [isCreatedSuccessfully, isUpdatedSuccessfully]);

  useEffect(() => {
    reset(defaultValues);
  }, [patientInsurance]);

  if (isLoading || isLoadingInsurancePayers) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <>
          <StyledBox>
            <Controller
              name="insurancePriority"
              control={control}
              rules={{
                required: t('INSURANCE_PRIMARY_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('SELECT_PRIMARY_INSURANCE_LABEL').toUpperCase()}
                  error={!!errors.insurancePriority}
                  helperText={errors.insurancePriority?.message}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={priorityOptions}
                  defaultValue={priorityDefaultValue}
                />
              )}
            />
          </StyledBox>
          <StyledFlexRow>
            <StyledFlexColumn flex={1} marginBottom={spacings.xxlarge}>
              <Controller
                name="relationshipToInsured"
                control={control}
                rules={{
                  required: t('RELATIONSHIP_TO_INSURED_PLACEHOLDER')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    defaultOption={t('RELATIONSHIP_TO_INSURED_PLACEHOLDER')}
                    inputRef={ref}
                    label={t('RELATION_TO_INSURED').toUpperCase()}
                    error={!!errors.relationshipToInsured}
                    helperText={errors.relationshipToInsured?.message}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    options={relationshipToEnsuredOptions}
                  />
                )}
              />
              <Controller
                name="payerId"
                control={control}
                rules={{
                  required: t('INSURANCE_NAME_PLACEHOLDER')
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={insurancePayers?.map(({ id }) => `${id}`)}
                    shouldSortOptions
                    getOptionLabel={(option) => {
                      const payer = insurancePayers?.find(
                        ({ id }) => id === option
                      );
                      return payer?.name ?? '';
                    }}
                    onChange={(_, value: string) => setValue('payerId', value)}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label={t('INSURANCE_NAME').toUpperCase()}
                        placeholder={t('INSURANCE_NAME_PLACEHOLDER')}
                        endIcon={<SearchOutlined />}
                        error={!!errors.payerId}
                        helperText={errors.payerId?.message}
                        fullWidth
                        required
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="insurancePolicyGroupOrFecaNumber"
                control={control}
                rules={{
                  required: t('INSURANCE_POLICY_OR_FECA_NUMBER_PLACEHOLDER')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('INSURANCE_POLICY_OR_FECA_NUMBER').toUpperCase()}
                    placeholder={t(
                      'INSURANCE_POLICY_OR_FECA_NUMBER_PLACEHOLDER'
                    )}
                    error={!!errors.insurancePolicyGroupOrFecaNumber}
                    helperText={
                      errors.insurancePolicyGroupOrFecaNumber?.message
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </StyledFlexColumn>
            <StyledFlexColumn flex={1} marginBottom={spacings.xxlarge}>
              {showRegisteredPartnerSelect && (
                <Controller
                  name="registeredPartnerForTreatment"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      defaultOption={t('IS_THIS_YOUR_REGISTER_PARTNER')}
                      inputRef={ref}
                      label={t('IS_THIS_YOUR_REGISTER_PARTNER').toUpperCase()}
                      error={!!errors.registeredPartnerForTreatment}
                      helperText={errors.registeredPartnerForTreatment?.message}
                      value={field.value ? YesOrNo.YES : YesOrNo.NO}
                      onChange={(event) => {
                        const eventValue: string = event.target.value;
                        field.onChange(eventValue === YesOrNo.YES);
                      }}
                      options={registeredPartnerOptions}
                    />
                  )}
                />
              )}
              <Controller
                name="insuranceId"
                control={control}
                rules={{
                  required: t('INSURANCE_ID_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('INSURANCE_ID').toUpperCase()}
                    placeholder={t('INSURANCE_ID_PLACEHOLDER')}
                    error={!!errors.insuranceId}
                    helperText={errors.insuranceId?.message}
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="employerName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('EMPLOYER_NAME').toUpperCase()}
                    placeholder={t('EMPLOYERS_NAME_PLACEHOLDER')}
                    error={!!errors.employerName}
                    helperText={errors.employerName?.message}
                    fullWidth
                  />
                )}
              />
            </StyledFlexColumn>
          </StyledFlexRow>
          {isNotSelfInsured && !isRegisteredPartner && (
            <InsuredsDetails control={control} errors={errors} />
          )}
          <Typography fontWeight={fontWeights.extraBold} variant="h2">
            {t('UPLOAD_INSURANCE_CARD')}
          </Typography>
          <StyledFlexRow
            marginBottom={spacings.xlarge}
            marginTop={spacings.xxlarge}
          >
            <StyledFlexColumn flex={1}>
              <Typography fontWeight={fontWeights.extraBold}>
                {t('FRONT_OF_CARD').toUpperCase()}
              </Typography>
              <Controller
                name="imageIdCardFrontFile"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ImageUpload
                    isDirectSelectMode
                    limit={1}
                    value={value as ImageFile[]}
                    accept={{
                      'image/png': ['.png'],
                      'image/jpeg': ['.jpeg', '.jpg'],
                      pdf: ['.pdf']
                    }}
                    onAddNewFiles={(newFiles) => {
                      onChange([...newFiles]);
                    }}
                    onRemoveFile={(imageIndexToRemove) => {
                      onChange(
                        (value as ImageFile[]).filter(
                          (_, index) => index !== imageIndexToRemove
                        )
                      );
                    }}
                  />
                )}
              />
            </StyledFlexColumn>
            <StyledFlexColumn flex={1}>
              <Typography fontWeight={fontWeights.extraBold}>
                {t('BACK_OF_CARD').toUpperCase()}
              </Typography>
              <Controller
                name="imageIdCardBackFile"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <ImageUpload
                      isDirectSelectMode
                      limit={1}
                      value={value as ImageFile[]}
                      accept={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpeg', '.jpg'],
                        pdf: ['.pdf']
                      }}
                      onAddNewFiles={(newFiles) => onChange([...newFiles])}
                      onRemoveFile={(imageIndexToRemove) => {
                        onChange(
                          (value as ImageFile[]).filter(
                            (_, index) => index !== imageIndexToRemove
                          )
                        );
                      }}
                    />
                  );
                }}
              />
            </StyledFlexColumn>
          </StyledFlexRow>
        </>
        <Button
          fullWidth
          disabled={isCreatingPatientInsurance || isUpdatingPatientInsurance}
          type="submit"
        >
          {t('SUBMIT')}
        </Button>
      </form>
    </Box>
  );
};
