const enStaffTranslations = {
  SCHEDULE_OR_ORDERS: 'Schedule / Orders',
  VITALS: 'Vitals',
  ENCOUNTER_NOTE: 'Encounter Note',
  CODING: 'Coding',
  UNSAVED_CHANGES_HEADER: 'Unsaved changes',
  UNSAVED_CHANGES_CONTENT:
    'You have unsaved changes. Are you sure you want to leave this tab?',
  DISCARD_CHANGES: 'Discard changes',
  ADD_NEW_STAFF: 'Add new staff',
  EDIT_STAFF: 'Edit staff',
  CLINIC_LABEL: 'CHOOSE CLINIC',
  ROLE_LABEL: 'PERMISSION ROLE',
  PERMISSION_ROLE_INFO: 'PERMISSIONS BY ROLE WHICH YOU CAN CHOOSE:',
  STAFF_DETAILS: 'Staff Details',
  STAFF_ROLE_REQUIRED: 'Please enter a role',
  STAFF_NAME_REQUIRED: 'Please enter a name',
  FIRST_NAME_LABEL: 'FIRST NAME',
  MIDDLE_INITIAL_LABEL: 'MIDDLE INITIAL',
  NAME_LABEL: 'NAME',
  NAME_PLACEHOLDER: 'Please enter a name',
  STAFF_TITLE_REQUIRED: 'Please enter a title',
  FIRST_NAME_PLACEHOLDER: 'First Name',
  MIDDLE_INITIAL_PLACEHOLDER: 'Middle Initial',
  LAST_NAME_LABEL: 'LAST NAME',
  LAST_NAME_PLACEHOLDER: 'Last Name',
  STAFF_EMAIL_REQUIRED: 'Please enter a valid email',
  EMAIL_LABEL: 'EMAIL',
  EMAIL_PLACEHOLDER: 'E-mail',
  PHONE_NUMBER_LABEL: 'PHONE NUMBER',
  PHONE_NUMBER_PLACEHOLDER: '888 888 8888',
  ADDRESS_PLACEHOLDER: 'Street Address and Number',
  CITY_LABEL: 'CITY',
  CITY_PLACEHOLDER: 'City',
  STATE_LABEL: 'STATE',
  STATE_PLACEHOLDER: 'State',
  ZIP_LABEL: 'ZIP',
  ZIP_PLACEHOLDER: '12345',
  COUNTRY_LABEL: 'COUNTRY',
  COUNTRY_PLACEHOLDER: 'Country',
  DATE_OF_BIRTH_LABEL: 'DATE OF BIRTH',
  LICENSE_INFO: 'License Information',
  LICENSE_NUMBER_LABEL: 'LICENSE ID #',
  LICENSE_NUMBER_PLACEHOLDER: 'Please add License ID #',
  NPI_LABEL: 'NPI #',
  NPI_PLACEHOLDER: 'Please add your National Provider Identifier',
  STAFF_DASHBOARD_SETTINGS: 'Staff Dashboard Settings',
  JOB_TITLE_LABEL: 'TITLE TO DISPLAY',
  JOB_TITLE_PLACEHOLDER: 'Type the title',
  NEXT: 'Next',
  PERMISSIONS_PAGE: 'Staff - Permission Settings',
  PERMISSIONS_PAGE_DESCRIPTION: `For ease of use, select a common Role Preset from the dropdown. You will be edit the permissions as needed for the \n
    role  by selecting or deselecting each permission. Alternatively, you may select the permissions as you wish.`,
  SYSTEM_ADMIN: 'System Admin',
  PRESET_ROLE_TITLE: 'CHOOSE ROLE PRESETS',
  SYSTEM_ADMIN_DESCRIPTION:
    'Staff with this permission can access the staff list and manage staff members and their permissions.',
  BILLING: 'Billing',
  BILLING_DESCRIPTION:
    'Grants access to billing and claims features within the application.',
  ADD_PATIENT: 'Add New Patient',
  ADD_PATIENT_DESCRIPTION:
    'Allows staff to add a new patient but not view or edit their chart afterward.',
  CLINICAL_CARE: 'Clinical Care',
  CLINICAL_CARE_DESCRIPTION:
    'Provides full access to patient charts, medical records, and patient health information actions. As well as locking/unlocking encounters.',
  COORDINATION: 'Coordination',
  COORDINATION_DESCRIPTION:
    'Enables access to scheduling, messaging (inbox), tasks, calendar, documents and telehealth features.',
  KPI_REPORTS: 'KPI/Reports',
  KPI_REPORTS_DESCRIPTION:
    'Allows the generation and viewing of various multi-patient reports.',
  PRESCRIBE: 'Can Prescribe',
  PRESCRIBE_DESCRIPTION:
    'Gives the ability to prescribe medications and add Intended Treatments.',
  SIGN_DOCUMENTS: 'Can Sign Documents',
  SIGN_DOCUMENTS_DESCRIPTION:
    'Enables the signing of consent and clinical documents.',
  ACTIVITY_FEED: 'Activity Feed/Progress Notes',
  ACTIVITY_FEED_DESCRIPTION:
    'Permits adding, viewing, and editing of patient progress notes.',
  PSYCH_NOTES: 'Psych Notes',
  PSYCH_NOTES_DESCRIPTION:
    'Provides access to psychotherapy or therapist notes on patients.',
  CARE_TEAM: 'Care Team',
  CARE_TEAM_DESCRIPTION:
    'Provides access to the chosen permissions settings, only for patients where the staff member is assigned as a care team member.',
  DISPLAY_NAME_LABEL: 'NAME TO DISPLAY',
  DISPLAY_NAME_PLACEHOLDER: 'Type the name to display staff and patients',
  ADD_STAFF_CTA: 'Add staff member',
  SAVE_STAFF_CTA: 'Save',
  STAFF_LIST_HEADER: 'Staff List',
  STAFF_NAME: 'STAFF NAME',
  UPLOAD_AVATAR: 'UPLOAD AVATAR/PROFILE IMAGE',
  ACTIVE: 'ACTIVE',
  SELECT_ACTIVE: 'Active',
  SELECT_INACTIVE: 'Inactive',
  TITLE: 'TITLE',
  ROLE: 'ROLE',
  CLINIC: 'Clinic',
  ROLE_TITLE: 'role',
  EMAIL_TITLE: 'email',
  SUPER_ADMIN:
    'Super admin - Has access to all add new staff, set permissions, roles.',
  OPERATIONS: 'Operations',
  FINANCE: 'Finance',
  PRACTITIONER: 'Practitioner',
  ADMIN_COORDINATION: 'Admin/Coordinator',
  CONUSELOR_THERAPIST: 'Counselor/Therapist',
  adminCoordinator: 'Admin/Coordinator',
  systemAdmin: 'System Admin',
  counselorTherapist: 'Counselor/Therapist',
  operations: 'Operations',
  finance: 'Finance',
  practitioner: 'Practitioner',
  embryology: 'Embryology',
  SR_DOCTOR:
    'Sr Doctor - has access to all patients, can view by clinic or filter by provider. Can add, view, edit and delete.',
  SR_NURSE:
    'Sr Nurse - has access to all patients, can view by clinic or filter by provider. Can add, view, edit and delete(?).',
  DOCTOR:
    'Doctor - has access to only their patients, can view, add, edit, delete.',
  NURSE:
    'Nurse - has access to only their patients, can view, add, edit, delete.',
  ADMIN_SECRETARY:
    'Admin/Secretary - view only access to all patients, calendar features, inbox, telehealth, chat, billing.',
  EMBRYOLOGIST:
    'Embryologist - view access to all patients, can only add, edit egg and embryo related information.',
  COUNSELOR_THERAPIST:
    'Counselor/Therapist - view only access to patients, access to inbox, telehealth, calendar and chat.',
  ANESTHESIOLOGIST:
    'Anesthesiologist - view only of patient information, can only add OR reports to the big chart.',
  SEND_A_MESSAGE: 'Send a message',
  READ_AT: 'Read at',
  RESEND_WELCOME_EMAIL: 'Resend welcome email',
  RESEND_WELCOME_EMAIL_SUCCESS_TOAST_TITLE: 'Welcome email resent'
};

export default enStaffTranslations;
