import { GridSortDirection } from '@mui/x-data-grid-premium';
import convert from 'convert-units';
import { NumberPreviewSize } from 'src/components/display/NumberPreview/types';
import { PREVIEW_FONT_SIZE_LENGTH_BREAKPOINT } from 'src/components/styles/constants';
import i18n from 'src/i18n/i18n';
import { IrregularMenstrual } from 'src/types/cycle';
import {
  DocumentExtension,
  UpperCaseDocumentExtension
} from 'src/types/documents';
import { Option, OptionExtended } from 'src/types/option';
import { UserTypes } from 'src/types/user';
import { USStateCode } from 'src/types/USStateCode';

interface StaffOrPatientNameInfo extends Record<string, any> {
  firstName?: string;
  lastName?: string;
  middleInitial?: string;
}

enum FullNameVariants {
  FirstNameOnly = 'firstNameOnly',
  LastNameOnly = 'lastNameOnly',
  NoMiddleName = 'noMiddleName',
  FullName = 'fullName'
}

export type Nullable<T> = T | null | undefined;

export function validateGuid(guid: string): boolean {
  const guidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(guid);
}

export function validateIsNumberOrStringNumber(number: unknown): boolean {
  if (number === null || number === undefined) return false;
  return typeof +number === 'number' && !isNaN(+number);
}

export function getNumericValue(number: number | string): number {
  return number === null || number === undefined || number === ''
    ? null
    : (number as number);
}

export function getDisplayValue(number: number): number | string {
  return isNaN(number) ? '-' : number;
}

export function getFullName(
  nameDataObject?: StaffOrPatientNameInfo,
  options?: {
    variant?: FullNameVariants;
  }
): string {
  const { variant = FullNameVariants.FullName } = options || {};
  let nameToReturn = '';

  if (nameDataObject?.firstName && variant !== FullNameVariants.LastNameOnly) {
    nameToReturn = nameDataObject.firstName.trim();
  }
  if (nameDataObject?.middleInitial && variant === FullNameVariants.FullName) {
    nameToReturn += ` ${nameDataObject.middleInitial.trim()}`;
  }
  if (nameDataObject?.lastName && variant !== FullNameVariants.FirstNameOnly) {
    nameToReturn += ` ${nameDataObject.lastName.trim()}`;
  }
  return nameToReturn.trim();
}

interface GetFullAddressArgs {
  country?: string;
  city?: string;
  address?: string;
  state?: string;
  zip?: string;
}

export function getFullAddress({
  country,
  city,
  address,
  state,
  zip
}: GetFullAddressArgs): string {
  let addressToReturn = '';

  if (address) {
    addressToReturn += `${addressToReturn ? ', ' : ''}${address}`;
  }
  if (city) {
    addressToReturn += `${addressToReturn ? ', ' : ''}${city}`;
  }
  if (state) {
    addressToReturn += `${addressToReturn ? ', ' : ''}${state}`;
  }
  if (zip) {
    addressToReturn += `${addressToReturn ? ', ' : ''}${zip}`;
  }
  if (country) {
    addressToReturn += `${addressToReturn ? ', ' : ''}${country}`;
  }

  return addressToReturn.trim();
}

export const getUserAge = ({
  years,
  months,
  isShort
}: {
  years?: number;
  months?: number;
  isShort?: boolean;
}): string => {
  if (!years && !months) {
    return i18n.t('NOT_PROVIDED');
  }

  const age = isShort
    ? `${years || 0}.${months || 0}`
    : `${years || 0} ${i18n.t('YEARS').toLowerCase()}, ${months || 0} ${i18n
        .t('MONTHS')
        .toLowerCase()}`;

  return age;
};

export const DEFAULT_CACHE_STALE_TIME = 1000 * 60 * 5; // 5 minutes

export const taskNamesSuggestions = [
  'CONFIRM_ORDERS',
  'CONFIRM_RESULTS',
  'SCHEDULE_SECOND_CONSULTATION_APPOINTMENT',
  'ADD_CRYOTRANSPORT_LIST',
  'CONFIRM_THAW_INSTRUCTIONS_FOR_EMBRYO_TRANSFER',
  'COUNSELLING_RECEIVED',
  'DONOR_CLEARED_FOR_USE',
  'EGG_RECIPIENT_SCREENS_PERFORMED',
  'EXTENDED_SCREENING_CARRIER_CONDITIONS',
  'FAMILY_LIMIT_CHECKED_AND_COMPLIANT',
  'FEMALE_AND_MALE_QUESTIONNAIRE',
  'HFEA_CD_CONSENT_TO_DISCLOSURE_FEMALE',
  'HFEA_CD_CONSENT_TO_DISCLOSURE_MALE',
  'HFEA_DONOR_INFORMATION_DI_FORM',
  'HFEA_MT_USE_AND_STORAGE_OF_SPERM_OR_EMBRYOS_MALE',
  'HFEA_WD_FORM',
  'ICSI_CONSENT',
  'IVF_EMBRYO_FREEZING_CONSENT',
  'IVF_RECIPIENT_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR',
  'IVF_EGG_RETRIEVAL_EMBRYO_REPLACEMENT_CONSENT',
  'KARYOTYOPE_NORMAL',
  'EDI_ENTERED_NEW_PATIENT_DETAILS',
  'ENSURE_PROPER_CONSENTS_SIGNED',
  'ENSURE_PATIENT_HAS_SELECTED_A_DONOR_AND_ARRANGED_FOR_SAMPLE_DELIVERY_TO_LAB',
  'ENSURE_PATIENT_HAS_HAD_GENETIC_SCREENING_COMPLETED_AND_RESULTS_RECEIVED',
  'ENSURE_PATIENT_HAS_HAD_IMPLICATIONS_COUNSELLING',
  'IF_DOING_IUI_ONCE_IUI_SCHEDULED_PATIENT_OR_CLINICAL_STAFF_NEEDS_TO_GIVE_GREEN_LIGHT_TO_LAB_TO_THAW_SPERM',
  'INFO_REGARDING_DONOR_SPERM_IF_RELEVANT_HOW_TO_CHOOSE_ORDER_SHIP_CONSENT_FORMS_CONTACT_INFO_FOR_LAB_TO_COORDINATE_SHIPMENT',
  'OPERATIVE_CARE_PLAN_AND_CHECKLIST_UPLOADED_TO_EMR',
  'CONFIRM_ALLERGIES',
  'VERIFY_SIGNED_CONSENT_ON_FILE',
  'ENSURE_PATIENT_HAS_ALL_PROCEDURE_INSTRUCTIONS_INCLUDING_TIME_TO_ARRIVE_PARTNER_TIME',
  'POST_OP_INSTRUCTIONS_BOTH_WHAT_TO_EXPECT_MEDS_WHEN_TO_RETURN_TO_OFFICE',
  'ADD_EGG_REPORT_TO_CYCLE_CHART',
  'VERIFY_CONSENTS_ON_FILE',
  'ENSURE_PATIENT_HAS_ALL_PROCEDURE_INSTRUCTIONS_INCLUDING_TIME_TO_ARRIVE_AND_INSTRUCTIONS_TO_FILL_BLADDER',
  'POST_TRANSFER_INSTRUCTIONS_WITH_MEDICATION_INSTRUCTIONS_AS_WELL_AS_PREGNANCY_TEST_DATE',
  'FILL_OUT_PROCEDURE_REPORT',
  'UPDATE_PATIENT_WITH_FERT_REPORT',
  'UPDATE_PATIENT_WITH_BLAST_REPORT',
  'UPDATE_PATIENT_WITH_PGT_RESULT_IF_APPLICABLE',
  'ADD_CHECKLIST_FOR_CARRIER_MATCH_TEST',
  'ADD_DONOR_GAMETES_CHECKLIST',
  'ADD_EGG_DONOR_CHECKLIST',
  'COMPLETED_EGG_DONOR_QUESTIONAIRE',
  'DONOR_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR',
  'DONOR_PATIENT_HISTORY_RECEIVED',
  'EGG_DONOR_LETTER_TO_PCP',
  'HAVE_SEEN_COUNSELLORS',
  'HFEA_CD_CONSENT_TO_DISCLOSURE',
  'HFEA_WD_CONSENT_TO_USE_AND_STORAGE_OF_DONATED_EGGS',
  'ICSI_CONSENT_2',
  'ANY_PREVIOUS_GENETIC_TEST_RESULT',
  'CONSANGUINITY',
  'CONSENT_FORMS_SIGNED_BY_COUPLE_AND_CLINICIAN',
  'CONSENT_TO_RECEIVE_RESULTS_BY_EMAIL',
  'CONSENT_TO_SHARE_EACH_OF_THEIR_RESULTS_WITH_THE_OTHER_PARTNER',
  'COUPLE_INFORMED_THAT_RESULTS_ARE_GIVEN_FOR_A_COUPLE_TOGETHER',
  'DEVELOPMENT_OF_PGD_TEST_MAY_DELAY_PLANNED_TREATMENT',
  'DISCUSSED_ACTIONS_TO_TAKE',
  'DISCUSSION_OF_IMPLICATIONS',
  'EXPLAINED_AUTOSOMAL_RECESSIVE_INHERITANCE',
  'EXPLAINED_X_LINKED_INHERITANCE',
  'LIMITATIONS_OF_TEST_TO_THESE_600_GENES_AND_NO_OTHERS',
  'NO_INCREASED_RISK_IF_THEY_CARRY_DIFFERENT_GENES',
  'OPTION_OF_PGD_IF_AT_RISK',
  'PATIENT_BROCHURE_GIVEN',
  'RECESSIVE_CARRIER_COUPLE_25_PERCENT_AFFECTED_CHILD',
  'RELATIVES_MAY_BE_CARRIERS_THEY_CAN_INFORM_SIBLINGS_AND_OTHER_FAMILY_MEMBERS_OF_RESULTS',
  'REQUISITION_FORM_COMPLETED_AND_SIGNED_BY_COUPLE_AND_CLINICIAN',
  'REVIEWED_FAMILY_HISTORY',
  'SMALL_CHANCE_OF_INCIDENTAL_FINDING_MALE_X_LINKED_RESULT_BRCA2',
  'SMALL_RESIDUAL_RISK_OF_AN_UNDETECTABLE_MUTATION_IN_ANY_OF_THESE_GENES',
  'TIMEFRAME_FOR_RESULTS',
  'X_L_CARRIER_25_PERCENT_RISK_OF_AFFECTED_SON',
  'TRIGGER_INSTRUCTIONS_INFORMATION',
  'ENSURE_PATIENTS_HAVE_TRIGGER_MEDICATION_AND_REMEMBER_HOW_TO_INJECT',
  'ENSURE_BLOOD_TEST_ORDERS_PLACED_AND_APPOINTMENT_SET_FOR_BLOOD_PREGNANCY_TEST',
  'WHEN_PATIENTS_CALL_WITH_DAY_1_ENSURE_ALL_PRE_TREATMENT_TESTING_AND_PAPERWORK_COMPLETE_CONSENTS_IN_PLACE_AND_INITIATE_TREATMENT_CYCLE',
  'ENSURE_PATIENTS_HAVE_ALL_MEDICATIONS',
  'PLACE_PROPER_ORDERS_FOR_MONITORING_APPT_AND_ENSURE_PT_HAS_SCHEDULED_MONITORING_APPTS_CONVERT_INTENDED_TREATMENT_TO_CYCLE',
  'INFORMATION_ABOUT_NEXT_STEPS_TREATMENT_PROTOCOL_INFORMATION',
  'CONSENTS_FOR_TREATMENT_TYPE',
  'ORDER_MEDS_FOR_TREATMENT',
  'MEDICATION_EDUCATION_STORAGE_GUIDELINES_INJECTION_TEACHING',
  'ENSURE_ALL_DIAGNOSTIC_TESTING_CONSENTS_FINANCIAL_AUTH_HAS_BEEN_COMPLETED_SO_TREATMENT_CAN_START_W_O_DELAY',
  'PROVIDE_INFO_ABOUT_PATIENT_EDUCATION_CLASSES_MODULES_VIDEOS_ETC',
  'ENSURE_RECEIPT_OF_COMPLETE_CONSENTS',
  'WHEN_PATIENTS_COME_IN_FOR_MONITORING_ENSURE_ANY_QUESTIONS_CONCERNS_ARE_ADDRESSED',
  'REVIEW_MONITORING_RESULTS_W_MD_ENSURE_PROPER_INSTRUCTIONS_ARE_INPUT_INTO_THE_SYSTEM',
  'CONTACT_PATIENT_WITH_MONITORING_RESULTS_DOSING_AND_RETURN_TO_OFFICE_INSTRUCTIONS_ENSURE_PROPER_ORDERS_PLACED_AND_APPT_MADE_FOR_THE_NEXT_MONITORING_APPT',
  'SCHEDULE_APPT_WITH_MD_TO_DISCUSS_FAILED_CYCLE_NEXT_STEPS_TREATMENT_OPTIONS',
  'END_CYCLE_ADD_OUTCOME_REPORT',
  'SCHEDULE_FOLLOWUP_CALL_WITH_DR',
  'PATIENT_INSTRUCTED_TO_STOP_MEDS_AND_CALL_WITH_THE_START_OF_THE_NEXT_CYCLE',
  'ORDERS_FOR_PATIENTS_INFECTIOUS_DISEASE_AND_GENERAL_HEALTH_SCREENING',
  'ORDERS_FOR_PARTNER_IDBW',
  'ORDERS_FOR_PATIENTS_DAY_3',
  'GENETIC_SCREENING_BLOODWORK_FOR_PATIENTS',
  'ORDER_ULTRASOUND_AFC_FOR_PATIENTS',
  'ORDER_AND_GIVE_INFO_SHEET_FOR_HSG_OR_SALINE_SONOGRAM',
  'PROVIDE_INFORMATION_FOR_FINANCIAL_SERVICES',
  'INFO_SHEET_AND_ORDER_FOR_SEMEN_ANALYSIS_FOR_THE_PARTNER',
  'NECESSARY_CONSENTS_HSG_SEMEN_ANALYSIS',
  'INFO_AS_NEEDED_FOR_A_UROLOGIST_FOR_THE_PARTNER',
  'ENTER_ORDER_RESULTS_INTO_EMR',
  'FOLLOW_UP_WITH_PATIENTS_FOR_DAY_1_TO_SCHEDULE_A_DIAGNOSTIC_APPOINTMENT',
  'GENETIC_SCREENING_BLOODWORK_FOR_THE_PARTNER_IF_NECESSARY',
  'PRESCRIPTION_FOR_ANTIBIOTIC_FOR_HSG_IF_NEEDED',
  'ADD_ALLERGIES_TO_THE_FLAGS_SECTION_IF_NECESSARY',
  'CONFIRM_PATIENT_ONBOARDING_MEDICAL_HISTORY',
  'ENSURE_ANY_BLOODWORK_TESTING_THAT_NEEDS_TO_BE_UPDATED_REGULARLY_IS_DONE_OFTEN_RELATES_TO_INSURANCE_REQUIREMENTS',
  'IF_THE_PREGNANCY_TEST_IS_POSITIVE_SCHEDULE_BETA_PLACE_ORDERS_AND_MAKE_AN_APPT_REVIEW_MEDICATION_INSTRUCTIONS',
  'IF_SUFFICIENT_LEVELS_REPEAT_BETA_APPROXIMATELY_2_DAYS_LATER',
  'IF_SUFFICIENT_RISE_SCHEDULE_OB_SCAN_FOR_6_WEEKS',
  'IF_SUB_OPTIMAL_HCG_RISE_60_PERCENT_ANY_PAIN_OR_SPOTTING_REFER_EPAU_FOR_SUSPICION_OF_ECTOPIC',
  'SIX_WEEK_SCAN_IS_CLEAR',
  'LEVELS_RISE_BUT_ABNORMALLY',
  'GIVE_DISCHARGE_INSTRUCTIONS_AND_REFER',
  'PRISM_ENTERED',
  'HAVE_SEEN_COUNSELLORS',
  'CONSENT_FORMS',
  'SURROGATE_QUESTIONNAIRE',
  'PCP_LETTER',
  'SURROGACY_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR',
  'HFEA_WSG_USE_AND_STORAGE_OF_EGGS_OR_EMBRYOS_ONLY_IF_PROVIDING_EGGS',
  'HFEA_CD_CONSENT_TO_DISCLOSURE',
  'ICSI_CONSENT_ONLY_IF_PROVIDING_EGGS',
  'EDI_ENTERED_NEW_INFORMATION',
  'EGG_DONOR_QUESTIONNAIRE',
  'SPERM_DONOR_QUESTIONNAIRE',
  'IVF_HAMMERSMITH_SURROGACY_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR',
  'IVF_HAMMERSMITH_TVOR_ET_CONSENT_IF_HAVING_TVOR',
  'IVF_HAMMERSMITH_CONSENT_TO_FREEZE_EMBRYOS_IF_HAVING_TVOR',
  'HFEA_WT_IF_HAVING_TVOR_AND_LEAVING_EMBRYOS_TO_MALE_FOR_USE_IN_SURROGACY_OR_IF_EMBRYOS_WERE_ORIGINALLY_FROZEN_FOR_OWN_USE',
  'HFEA_WSG_USE_AND_STORAGE_OF_EGGS_OR_EMBRYOS_FEMALE',
  'HFEA_WD_IF_LEAVING_EMBRYOS_TO_MALE_FOR_USE_IN_SURROGACY',
  'HFEA_MSG_FORM_USE_AND_STORAGE_OF_SPERM_OR_EMBRYOS_MALE',
  'HFEA_MD_IF_LEAVING_EMBRYOS_TO_FEMALE_FOR_USE_IN_SURROGACY',
  'HFEA_MT_IF_LEAVING_EMBRYOS_TO_FEMALE_FOR_USE_IN_SURROGACY',
  'HFEA_CD_FORM_CONSENT_TO_DISCLOSURE_FEMALE',
  'HFEA_CD_FORM_CONSENT_TO_DISCLOSURE_MALE',
  'EDI_NEW_PATIENT_INFO_IF_QUARANTINING_EMBRYOS_FOR_SURROGACY',
  'EDI_ENTERED_IF_WHEN_USING_OWN_EGGS_NEW_DONOR_INFORMATION',
  'EDI_ENTERED_IF_WHEN_USING_OWN_SPERM_NEW_DONOR_INFORMATION'
];

export const getAvatarUrl = (userId: string, userType: UserTypes) => {
  return `${process.env.REACT_APP_SERVER_BASE_URL}/${userType}/${userId}/picture`;
};

export const getCycleLengthText = (
  cycleLength?: number | IrregularMenstrual
): string => {
  if (!cycleLength) return '';
  if (cycleLength === IrregularMenstrual.IRREGULAR_CYCLE) {
    return 'Irreg';
  } else {
    return isNaN(cycleLength) ? '' : `${cycleLength}`;
  }
};

export const convertStringIdToNumber = (
  id: Nullable<string>
): Nullable<number> => {
  const parsedId = parseInt(id);

  if (isNaN(parsedId)) {
    return null;
  }

  return parsedId;
};

export const convertNumberIdToString = (
  id: Nullable<number>
): Nullable<string> => {
  if (!id && id !== 0) {
    return null;
  }

  return `${id}`;
};

export const getHeightFtInFromCm = (
  heightCm: number
): {
  heightFt: number;
  heightIn: number;
} => {
  const heightCmToFt = Math.floor(convert(heightCm).from('cm').to('ft'));
  const heightCmToIn = convert(heightCm).from('cm').to('in');

  return {
    heightFt: Number(heightCmToFt.toFixed(0)),
    heightIn: Number((heightCmToIn % 12).toFixed(0))
  };
};

export const getHeightCmFromFtIn = (
  heightFt: number,
  heightIn: number
): number => {
  const heightFtToCm = convert(heightFt).from('ft').to('cm');
  const heightInToCm = convert(heightIn).from('in').to('cm');

  return Number((heightFtToCm + heightInToCm).toFixed(1));
};

const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%_.~+]*)*' + // path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i' // fragment locator
);

export const getValidUrl = (url: string) => {
  if (!urlRegex.test(url)) return null;
  return url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `http://${url}`;
};

export const supportEmailAddress = 'support@embieclinic.com';

export const capitalize = (s: string): string => {
  const lowerCaseString = s.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};

export const kebabCase = (s: string): string => {
  return s.replace(' ', '-').toLowerCase();
};

export const getNumberPreviewSizeByValue = (
  value: string
): NumberPreviewSize => {
  if (value.length > PREVIEW_FONT_SIZE_LENGTH_BREAKPOINT)
    return NumberPreviewSize.small;
  return NumberPreviewSize.medium;
};

export const getNextPageParamFromInfo = ({
  totalResults,
  pageSize,
  allPagesLength
}: {
  totalResults: number;
  pageSize: number;
  allPagesLength: number;
}): number | undefined => {
  const numOfPages = Math.ceil(totalResults / pageSize) || 0;
  return allPagesLength < numOfPages ? allPagesLength : undefined;
};

export const isOptionExtended = (option: Option): option is OptionExtended => {
  return 'labelText' in option;
};

export const getExtension = (url?: string): DocumentExtension => {
  // Default extension
  let extension: DocumentExtension = DocumentExtension.PDF;

  if (url) {
    const lowerUrl = url.toLowerCase();

    // Create a regular expression for matching file extensions
    const extensionRegex = new RegExp(
      `\\.${Object.values(DocumentExtension).join('|\\.')}`,
      'i'
    );

    // Find the first match in the URL
    const match = lowerUrl.match(extensionRegex);
    if (match) {
      // Extract the extension and map it to DocumentExtension
      const foundExtension = match[0].substring(1); // Remove the leading dot

      extension = DocumentExtension[foundExtension.toUpperCase()] || extension;

      // Use DOCX for DOC if found
      if (extension === DocumentExtension.DOC) {
        extension = DocumentExtension.DOCX;
      }
    }
  }

  return extension;
};

export const compareStringDateValues = (
  a: Nullable<string>,
  b: Nullable<string>,
  sortDirection: GridSortDirection
): number => {
  const dateA = a ? Date.parse(a) : 0;
  const dateB = b ? Date.parse(b) : 0;

  return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
};

export const compareStringValues = (
  a: Nullable<string>,
  b: Nullable<string>,
  sortDirection: GridSortDirection
): number => {
  const stringA = a || '';
  const stringB = b || '';
  return sortDirection === 'asc'
    ? stringA.localeCompare(stringB)
    : stringB.localeCompare(stringA);
};

export const compareNumberValues = (
  a: Nullable<number>,
  b: Nullable<number>,
  sortDirection: GridSortDirection
): number => {
  const numA = a || 0;
  const numB = b || 0;

  return sortDirection === 'asc' ? numA - numB : numB - numA;
};

export const prescriptionTemplateId = '8';

export const getUSStateCode = (stateCode: string): USStateCode | undefined => {
  return USStateCode[stateCode] || undefined;
};

// Mapping from string to DocumentExtension
const documentExtensionMap: Record<string, DocumentExtension> = {
  pdf: DocumentExtension.PDF,
  docx: DocumentExtension.DOCX,
  doc: DocumentExtension.DOC,
  jpg: DocumentExtension.JPG,
  jpeg: DocumentExtension.JPEG,
  png: DocumentExtension.PNG
};

// Mapping from DocumentExtension to UpperCaseDocumentExtension
const upperCaseDocumentExtensionMap: Record<
  DocumentExtension,
  UpperCaseDocumentExtension
> = {
  [DocumentExtension.PDF]: UpperCaseDocumentExtension.PDF,
  [DocumentExtension.DOCX]: UpperCaseDocumentExtension.DOCX,
  [DocumentExtension.DOC]: UpperCaseDocumentExtension.DOC,
  [DocumentExtension.JPG]: UpperCaseDocumentExtension.JPG,
  [DocumentExtension.JPEG]: UpperCaseDocumentExtension.JPEG,
  [DocumentExtension.PNG]: UpperCaseDocumentExtension.PNG
};

export const getDocumentExtension = (
  documentType: string
): DocumentExtension => {
  return documentExtensionMap[documentType] || DocumentExtension.PDF;
};

export const getUpperCaseDocumentExtension = (
  documentExtension: DocumentExtension
): UpperCaseDocumentExtension => {
  return (
    upperCaseDocumentExtensionMap[documentExtension] ||
    UpperCaseDocumentExtension.PDF
  );
};

export const getFileExtensionFromFileName = (
  filename?: string
): DocumentExtension => {
  const lastDotIndex = filename?.lastIndexOf('.');

  // If there's no dot or the dot is the last character, return null
  if (!filename || lastDotIndex === -1 || lastDotIndex === filename?.length - 1)
    return null;

  // Extract and return the extension, converted to lowercase
  const documentExtension = getDocumentExtension(
    filename?.substring(lastDotIndex + 1).toLowerCase()
  );

  return documentExtension;
};
