import { DbUser, DbUserServer } from 'src/types/user';
import axiosApiInstance from 'src/utils/axios';
import { convertDbUserServerToClient } from '../modules/patients/utils/conversions';

export async function getMeRequest(): Promise<DbUser> {
  const response = await axiosApiInstance.get<DbUserServer>('/auth/me');
  const { data } = response;

  return convertDbUserServerToClient(data);
}

export async function checkPendingOtpRequest(): Promise<{
  pendingOtp: boolean;
  verified: boolean;
}> {
  const response = await axiosApiInstance.get('/auth-codes/pending');
  const { data } = response;

  return data;
}

export const sendOtpCodeRequest = async (): Promise<{
  status: string;
  message: string;
}> => {
  const response = await axiosApiInstance.post('/auth-codes/send-otp');

  return response.data;
};
export const verifyOtpCodeRequest = async ({
  staffId,
  otpCode
}: {
  staffId: string;
  otpCode: string;
}): Promise<{
  status: string;
  message: string;
}> => {
  const response = await axiosApiInstance.post('/auth-codes/verify-otp', {
    staffId,
    otpCode
  });
  return response.data;
};
