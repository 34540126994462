import { Colors } from '../../../components/styles/colors';
import { InvoiceMethod, InvoiceStatus } from '../../../types/billing';

interface ColorsReturnType {
  backgroundColor: string;
  color: string;
}

export const getMethodColors = (method: InvoiceMethod): ColorsReturnType => {
  switch (method) {
    case InvoiceMethod.CREDIT:
      return {
        backgroundColor: Colors.emperor,
        color: Colors.white
      };
    case InvoiceMethod.WIRE:
      return {
        backgroundColor: Colors.poloBlue,
        color: Colors.white
      };
    default:
      return {
        backgroundColor: Colors.alto2,
        color: Colors.emperor
      };
  }
};

export const getStatusColors = (status: InvoiceStatus): ColorsReturnType => {
  switch (status) {
    case InvoiceStatus.PAID:
      return {
        backgroundColor: Colors.riptide,
        color: Colors.emperor
      };
    case InvoiceStatus.PENDING:
      return {
        backgroundColor: Colors.mauvelous,
        color: Colors.emperor
      };
    case InvoiceStatus.QUOTE:
      return {
        backgroundColor: Colors.cupid,
        color: Colors.emperor
      };
    case InvoiceStatus.DRAFT:
      return {
        backgroundColor: Colors.halfBaked,
        color: Colors.emperor
      };
    case InvoiceStatus.CANCELED:
      return {
        backgroundColor: Colors.mercury,
        color: Colors.emperor
      };
    default:
      return {
        backgroundColor: Colors.whiteSand,
        color: Colors.emperor
      };
  }
};
