import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import Button from 'src/components/display/Button/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AddNewReportMenu } from '../reportForms/AddNewReportMenu';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { EditTreatmentMenu } from './EditTreatmentMenu';
import IconButton from '../../../components/display/IconButton';

export const TreatmentChartActions: FC<{ cycleId: string }> = ({ cycleId }) => {
  const { openPopover } = usePopover();
  const { patientId } = useParams();
  const { t } = useTranslation();

  const onClickEdit = (ev: React.MouseEvent) => {
    openPopover({
      anchorEl: ev.currentTarget,
      hideToolbar: true,
      children: <EditTreatmentMenu patientId={patientId} cycleId={cycleId} />,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top'
      }
    });
  };

  const onClickAddReport = (ev: React.MouseEvent) => {
    openPopover({
      anchorEl: ev.currentTarget,
      hideToolbar: true,
      children: <AddNewReportMenu patientId={patientId} cycleId={cycleId} />,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top'
      }
    });
  };

  return (
    <Flex gap={spacings.medium}>
      <IconButton
        sx={{ display: { xs: 'block', lg: 'none' } }}
        icon={<EditIcon />}
        onClick={onClickEdit}
      />
      <Button
        sx={{ display: { xs: 'none', lg: 'flex' } }}
        startIcon={<EditIcon />}
        onClick={onClickEdit}
      >
        {t('EDIT_TREATMENT')}
      </Button>
      <IconButton
        sx={{ display: { xs: 'block', lg: 'none' } }}
        icon={<AddIcon />}
        onClick={onClickAddReport}
      />
      <Button
        sx={{ display: { xs: 'none', lg: 'flex' } }}
        startIcon={<AddIcon />}
        onClick={onClickAddReport}
      >
        {t('ADD_NEW_REPORT')}
      </Button>
    </Flex>
  );
};
