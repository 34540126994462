import { Core, UI, WebViewerInstance } from '@pdftron/webviewer';
import saveButtonIcon from 'src/assets/icons/saveButtonIcon.svg';
import i18n from 'src/i18n/i18n';
import { DbUser } from 'src/types/user';
import {
  CreatePatientDocumentPayload,
  CreatePatientDocumentPayloadServer,
  Document,
  PatientDocument,
  annotationsSubjects,
  orderTemplateId
} from 'src/types/documents';
import { getDocumentAsPdfFileFromEditor } from './getDocumentAsPdfFileFromEditor';
import { getUpdatedSignatureTemplateKeys } from './getUpdatedSignatureTemplateKeys';
import { UseMutateFunction } from 'react-query';

export const getWebViewerSaveDocumentAction = async ({
  header,
  webViewerInstance,
  templateName,
  patientId,
  templateId,
  documentId,
  templateMetadata,
  createDocument,
  updateDocument,
  isUpdate,
  documentToEdit,
  loggedStaffMember,
  initialAnnotations,
  chosenTemplate,
  documentIsBeingSavedLoader,
  closeDialog
}: {
  header: UI.Header;
  webViewerInstance: WebViewerInstance;
  templateName: string;
  patientId: string;
  templateId: string;
  documentId?: string;
  templateMetadata: string;
  createDocument: UseMutateFunction<
    CreatePatientDocumentPayloadServer,
    Error,
    CreatePatientDocumentPayload,
    unknown
  >;
  updateDocument: UseMutateFunction<
    string,
    Error,
    { documentId: string; document: CreatePatientDocumentPayload },
    unknown
  >;
  isUpdate: boolean;
  documentToEdit: PatientDocument;
  loggedStaffMember: DbUser;
  initialAnnotations: Core.Annotations.Annotation[];
  chosenTemplate: Document;
  documentIsBeingSavedLoader: () => void;
  closeDialog: () => void;
}) => {
  const { documentViewer, annotationManager } = webViewerInstance.Core;
  const { key } = documentToEdit || {};

  // If `templateMetadata` is a string (metadata stored as a string), parse it back into a JSON object.
  // If `templateMetadata` is already an object, use it directly without parsing.
  // If `templateMetadata` is neither a string nor an object, set `parsedMetadata` to `null`.
  const parsedMetadata = JSON.parse(templateMetadata || '{}');

  header
    .getHeader('default')
    .get('menuButton')
    .insertAfter({
      type: 'actionButton',
      img: saveButtonIcon,
      title: i18n.t('SAVE_DOCUMENT'),
      dataElement: 'saveDocumentButton',
      onClick: async () => {
        const isDocSigned = annotationManager
          .getAnnotationsList()
          .some(({ Subject }) => Subject === annotationsSubjects.signature);

        const initialSignatures = initialAnnotations.filter(
          ({ Subject }) => Subject === annotationsSubjects.signature
        );

        const currentSignatures = annotationManager
          .getAnnotationsList()
          .filter(({ Subject }) => Subject === annotationsSubjects.signature);

        const isNewSignatureAdded =
          currentSignatures?.length >= initialSignatures?.length + 1;

        const updatedMetadata = { ...parsedMetadata };

        if (isNewSignatureAdded) {
          const updatedKeysToUse = getUpdatedSignatureTemplateKeys({
            loggedStaffMember: loggedStaffMember?.user,
            isLabOrderTemplate:
              (chosenTemplate?.id || templateId) === orderTemplateId
          });

          Object.keys(parsedMetadata).forEach((key) => {
            if (updatedKeysToUse[key]) {
              updatedMetadata[key] = updatedKeysToUse[key];
            }
          });
        }

        const { file, xfdfString } = await getDocumentAsPdfFileFromEditor({
          annotationManager,
          documentViewer,
          templateName,
          key,
          originalFileName: documentToEdit?.name
        });

        const documentPayload: CreatePatientDocumentPayload = {
          file,
          patientId,
          templateId,
          annotations: xfdfString,
          isCompleted:
            chosenTemplate?.requiresSignature || templateId === orderTemplateId
              ? isDocSigned
              : true,
          metadata: JSON.stringify(updatedMetadata)
        };

        await documentViewer.getDocument().applyTemplateValues(updatedMetadata);

        if (!isUpdate) {
          documentIsBeingSavedLoader();
          await createDocument(documentPayload, {
            onSuccess: () => {
              closeDialog();
            }
          });
        } else {
          documentIsBeingSavedLoader();
          await updateDocument(
            {
              document: documentPayload,
              documentId
            },
            {
              onSuccess: () => {
                closeDialog();
              }
            }
          );
        }
      }
    });
};
