import { FC } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/display/Table';
import { RowHeights, TableProps } from 'src/components/display/Table/Table';
import Typography from 'src/components/display/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { Degrees } from 'src/types/global';
import { VitalsFormProps } from './appointmentTabs/VitalsTab';

interface VitalsDisplayTableRow {
  title: string;
  reading: string;
  status?: string;
  id: number;
}
const VitalsDisplayTable: FC<VitalsFormProps & Omit<TableProps, 'columns'>> = ({
  heartRate,
  bloodPressure,
  pulseOximeter,
  respiratoryRate,
  temperature,
  degrees,
  ...rest
}) => {
  const { t } = useTranslation();
  const columns: GridColDef<VitalsDisplayTableRow>[] = [
    {
      headerName: t('TEST'),
      renderHeader: () => (
        <Typography fontWeight={fontWeights.bold}>{t('TEST')}</Typography>
      ),
      renderCell: ({ row: { title } }) => title,
      flex: 1,
      field: 'title',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      headerName: t('READING'),
      renderHeader: () => (
        <Typography fontWeight={fontWeights.bold}>{t('READING')}</Typography>
      ),
      renderCell: ({ row: { reading } }) => reading,
      flex: 1,
      field: 'reading',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      headerName: t('STATUS'),
      renderHeader: () => (
        <Typography fontWeight={fontWeights.bold}>{t('STATUS')}</Typography>
      ),
      renderCell: ({ row }) => row?.status,
      flex: 1,
      field: 'status',
      filterable: false,
      sortable: false,
      disableColumnMenu: true
    }
  ];

  const rows: VitalsDisplayTableRow[] = [
    {
      id: 0,
      title: t('HEART_RATE'),
      reading: heartRate?.toString() || '#'
    },
    {
      id: 1,
      title: t('BLOOD_PRESSURE'),
      reading: bloodPressure?.toString() || '#'
    },
    {
      id: 2,
      title: t('PULSE_OXIMETER'),
      reading: pulseOximeter?.toString() || '#'
    },
    {
      id: 3,
      title: t('RESPIRATORY_RATE'),
      reading: respiratoryRate?.toString() || '#'
    },
    {
      id: 4,
      title: t('TEMPERATURE'),
      reading:
        (temperature?.toString() || '#') +
        (degrees === Degrees.CELSIUS ? ' \u00B0C' : ' \u00B0F')
    }
  ];

  return (
    <Table
      columnHeaderHeight={RowHeights.sm}
      columns={columns}
      rows={rows}
      hideFooter
      hideFilterToolbar
      variant="default-compact"
      {...rest}
    />
  );
};

export default VitalsDisplayTable;
