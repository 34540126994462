import { FC, ReactElement, useCallback, useState } from 'react';
import { styled, TableProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Colors } from 'src/components/styles/colors';
import Table from '../../components/display/Table';
import useFeed from '../../hooks/useFeed';
import { usePopover } from '../../components/components-api/PopoverProvider';
import { paginationDefaultParams } from '../../constants';
import useStaffMembers from '../../hooks/useStaffMembers';
import { handleCellClick } from './utils';
import useGetFeedColumns from './useGetFeedColumns';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';
import useDebounce from 'src/hooks/useDebounce';

const StyledTable = styled(Table)`
  height: 100%;
  .tampered {
    background-color: ${Colors.tampered};

    :hover {
      background-color: ${Colors.tampered};
    }
  }
`;

type ClinicFeedActivityProps = TableProps & {
  byClinic?: boolean;
  patientId?: string;
  toolbar?: ReactElement;
};

export const FeedActivityTable: FC<ClinicFeedActivityProps> = ({
  byClinic,
  patientId,
  ...rest
}) => {
  const { t } = useTranslation();
  const { getFeedActivities } = useFeed();
  const { openPopover } = usePopover();
  const [paginationModel, setPaginationModel] = useState(
    paginationDefaultParams
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And
  });

  const handleFilterChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel]
  );
  const debouncedFilterModel = useDebounce(filterModel);

  const { data, isFetching, isLoading } = getFeedActivities({
    byClinic,
    patientId,
    params: {
      pagination: paginationModel
    },
    filters: debouncedFilterModel
  });

  const rows = data?.rows ?? [];
  const totalRowCount = data?.count ?? 0;

  const { getStaffMembersNames } = useStaffMembers();
  const { data: doctors } = getStaffMembersNames();

  const getColumns = useGetFeedColumns(doctors);
  const columns = getColumns();

  return (
    <StyledTable
      filterModel={filterModel}
      filterMode="server"
      onFilterModelChange={handleFilterChange}
      loading={isFetching || isLoading}
      columns={columns}
      columnHeaderHeight={50}
      rows={rows}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      rowCount={totalRowCount}
      getRowClassName={(params) => (params.row.isTampered ? 'tampered' : '')}
      onCellClick={(params, event) => {
        handleCellClick({
          params,
          event,
          t,
          openPopover
        });
      }}
      {...rest}
    />
  );
};
