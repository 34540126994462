const enCalendarTranslations = {
  SCHEDULE_AND_ORDERS: 'Schedule and Orders',
  CALENDAR_PAGE_TITLE: 'Schedule',
  CALENDAR_APPOINTMENT: 'Appointment',
  ADD_NEW_PATIENT: 'Add new Patient',
  PICK_APPOINTMENT_TYPE: 'Pick Appointment Type',
  PICK_PROVIDERS: 'Pick Provider/s',
  EMBRYO_TRANSFER: 'Embryo transfer',
  FITLER_BY_APPOINTMENT: 'Filter by Appointment Type',
  ALL_ROOMS: 'All Rooms',
  FILTER_BY_ROOM: 'Filter by Room',
  FILTER_BY_STAFF: 'Filter by Staff',
  ALL_TYPES: 'All Appointment types',
  VIRTUAL: 'Virtual',
  CHOOSE_PANELS: 'Choose Panels',
  DATE_OF_SERVICE: 'Date of Service',
  ENCOUNTER_ID: 'Encounter ID',
  PRIMARY_INSURANCE: 'Primary Insurance',
  SECONDARY_INSURANCE: 'Secondary Insurance',
  ELIGIBILITY: 'Eligibility',
  ELIGIBLE: 'Eligible',
  INELIGIBLE: 'Ineligible',
  VITALS: 'Vitals',
  VITALS_SAVED: 'Vitals Saved',
  TIME_PERFORMED: 'Time Performed',
  HEART_RATE: 'Heart Rate',
  BLOOD_PRESSURE: 'Blood Pressure',
  TEMPERATURE: 'Temperature',
  RESPIRATORY_RATE: 'Respiratory Rate',
  PULSE_OXIMETER: 'Pulse Oximeter',
  DEGREES: 'Degrees',
  READING: 'Reading',
  RENDERING_PROVIDER: 'Rendering Provider',
  ENCOUNTER_NOTES: 'Encounter Notes',
  HISTORICAL_NOTES: 'Historical Notes',
  ACTIONS_TAKEN: 'Actions Taken',
  RESULTS: 'Results',
  COMMENT: 'Comment',
  DIAGNOSTICS: 'Diagnostics',
  AMENDMENTS: 'Amendments',
  SAVE_DRAFT: 'Save Draft',
  LOCK_NOTE: 'Save & Lock Note',
  UNLOCK_NOTE: 'Unlock Note',
  PRIMARY_OBSERVATIONS_AND_DIAGNOSES: 'Primary Observations & Diagnoses',
  ENTER_OBSERVATION: 'Enter Observation',
  ENTER_DIAGNOSTIC_CODE: 'Enter Diagnostic Code',
  OBSERVATION_EXPLANATION:
    'General History and Primary Observation are based on ICD-10-CM codes. Pregnancy History and Cycle observations are free text. If pregnancy or cycle observations should be considered primary, or overall, enter it as an ICD-10 Code as well. ',
  SERVICE_CODING: 'Service Coding',
  SERVICE_LINE: 'Service Line',
  CPT_OR_HCPCS: 'CPT/HCPCS',
  SEARCH_DIAGNOSIS: 'Search Diagnosis',
  DESCRIPTION: 'Description',
  MODIFIER: 'Modifier',
  DX_POINTER: 'DX Pointer',
  SERVICE_UNIT: 'Service Unit',
  NDC: 'NDC',
  DOSAGE: 'Dosage',
  DOSAGE_UNIT: 'Dosage Unit',
  ACTIONS: 'Actions',
  PICK_MODIFIER: 'Pick Modifier',
  PICK_UNIT: 'Pick Unit',
  PICK_DX: 'Pick DX',
  NO_NULL_DIAGNOSES_ERROR: 'Diagnosis fields cannot be empty',
  PICK_MODIFIERS: 'Pick Modifiers',
  NO_RESULTS: 'No results',
  END_OF_RESULTS: 'End of results',
  NO_OPTIONS: 'No options',
  CANCEL_APPOINTMENT: 'Cancel Appointment',
  CONFIRM_CANCEL_APPOINTMENT: 'Confirm Appointment Cancelation',
  MUST_SPECIFIY_REASON: 'Must specify reason',
  CANCEL_ACTION: 'Cancel Action',
  CANCELLATION_REASON: 'Reason for Cancelation'
};

export default enCalendarTranslations;
