import { useRoutes } from 'react-router-dom';

import useScrollTop from 'src/hooks/useScrollTop';
import router from 'src/router';
import './utils/registerCountries';
import AppInit from './components/layout/AppInit';
import { useAuth } from './components/components-api/GlobalProvider/GlobalProvider';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  useScrollTop();

  return auth.isInitialized ? content : <AppInit />;
}
export default App;
