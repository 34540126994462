import { Box, Button, styled, InputBase, css } from '@mui/material';
import { useAuth } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { useTranslation } from 'react-i18next';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { useState } from 'react';
import useMeApi from 'src/hooks/useMeApi';
import { UserTypes } from 'src/types/user';
import { spacings } from 'src/components/styles/constants';
import Loader from 'src/components/display/Loader/Loader';
import Avatar from 'src/components/display/Avatar';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { Colors } from 'src/components/styles/colors';
import { makeShouldForwardProps } from 'src/components/utils';

const StyledBox = styled(Box)`
  background: ${Colors.white};
  display: flex;
  align-items: center;
  padding-top: 1rem;
`;

const shouldForwardProp = makeShouldForwardProps(['expanded']);
const MessageInputWrapper = styled(InputBase, {
  shouldForwardProp
})<{
  expanded?: boolean;
}>`
  ${({ expanded }) => css`
    padding: ${spacings.small};
    width: 100%;
    display: flex;
    flex-grow: 1;
    position: relative;
    padding: 0;

    #message-input-textarea {
      max-height: ${expanded ? '250px' : '150px'};
      overflow: auto !important;
      padding: 0;
      min-height: 25px;
    }
  `}
`;

function BottomBarContent({
  sendMessage,
  isSending,
  expanded
}: {
  sendMessage: (message: string) => void | Promise<void>;
  isSending?: boolean;
  expanded?: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { getMe } = useMeApi();
  const { data: me, isFetching } = getMe();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const [value, setValue] = useState('');

  const send = () => {
    if (!value) {
      return;
    }
    sendMessage(value);
    setValue('');
  };

  const avatar = getDefaultAvatar({
    userId: me?.user.id,
    userType: UserTypes.staff,
    pictureId: me?.user.pictureId
  });

  return (
    <StyledBox>
      <Box flexGrow={1} display="flex" alignItems="center" padding={0}>
        {!isFetching && (
          <Avatar
            sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
            alt={user?.name}
            image={avatar}
          />
        )}
        <MessageInputWrapper
          id="message-input-textarea"
          placeholder={t('SEND_A_MESSAGE')}
          fullWidth
          value={value}
          multiline
          expanded={expanded}
          onChange={(ev) => setValue(ev.target.value)}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
              send();
              ev.preventDefault();
            }
          }}
        />
      </Box>
      <Box padding={0}>
        <Button
          id="send-message-button"
          disabled={isSending}
          startIcon={
            isSending ? (
              <Loader />
            ) : (
              <SendTwoToneIcon style={{ fill: 'black' }} />
            )
          }
          onClick={send}
        />
      </Box>
    </StyledBox>
  );
}

export default BottomBarContent;
