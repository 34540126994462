import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import usePatientsApi from '../../../hooks/usePatientsApi';

export const PatientMaritalStatusCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);

  const { maritalStatus, personalInfo } = patient;

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('MARITAL_STATUS_LABEL')}
        </Typography>
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      <Flex flexDirection="column" gap={spacings.small}>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography>{t('STATUS')}</Typography>
          <Typography id="patient-marital-status">
            {t(maritalStatus)}
          </Typography>
        </Flex>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Typography maxWidth="50%">
            {t('MARRIED_TO_PARTNER_QUESTION')}
          </Typography>
          <Typography>{t(personalInfo.marriedToPartner)}</Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};
