import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/data-entry/Select';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { Appointment } from 'src/types/appointment';
import { AddProgressNoteForm } from 'src/types/feed';
import {
  MINIMUM_CHARS_FOR_PATIENT_SEARCH,
  Patient,
  PatientPersonalInfo
} from 'src/types/patient';
import { getFullName } from 'src/utils/general';
import InputField from 'src/components/data-entry/InputField/InputField';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import { useState } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import Loader from 'src/components/display/Loader';

interface Props {
  patientId: string;
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<Appointment | AddProgressNoteForm>;
}
const PatientParterSelection = ({
  patientId,
  control,
  getValues,
  setValue,
  errors
}: Props) => {
  const { t } = useTranslation();
  const { getPatientById, getPatients } = usePatientsApi();
  const {
    data: patients,
    isLoading: isLoadingPatients,
    isFetching: isFetchingPatients
  } = getPatients();

  const [patientSearchTerm, setPatientSearchTerm] = useState('');
  const [chosenPatient, setChosenPatient] = useState<Patient | {}>({});

  const getPatientOrPartnerPersonalInfo = (
    patientId: string
  ): PatientPersonalInfo | undefined =>
    patientId === patientFullData.personalInfo.id
      ? patientFullData.personalInfo
      : patientFullData.partnerInfo;

  const {
    data: patientFullData,
    isLoading: isLoadingPatient,
    isFetching: isFetchingPatient
  } = getPatientById(patientId);

  const isLoading = isLoadingPatient || isFetchingPatient;

  const handlePatientSelectAutocompleteChange = (
    patientPersonalInfo: PatientPersonalInfo
  ) => {
    setPatientSearchTerm(getFullName(patientPersonalInfo));
    setChosenPatient(patientPersonalInfo);

    setValue('patientId', patientPersonalInfo.id);
  };
  if (isLoadingPatients || isFetchingPatients) return <Loader />;
  return (
    <Controller
      name="patient"
      control={control}
      rules={{
        required: t('PATIENT_REQUIRED')
      }}
      render={({ field: { ref, onChange, ...field } }) =>
        patientFullData ? (
          patientFullData.hasPartner ? (
            <Select
              {...field}
              isLoading={isLoading}
              inputRef={ref}
              label={t('PATIENT_LABEL')}
              value={getValues('patientId')}
              options={[
                {
                  label: getFullName(patientFullData.personalInfo),
                  value: patientFullData.personalInfo.id
                },
                {
                  label: getFullName(patientFullData.partnerInfo),
                  value: patientFullData.partnerInfo.id
                }
              ]}
              onChange={(ev) => {
                const patientId = ev.target.value;
                const chosenPatient =
                  getPatientOrPartnerPersonalInfo(patientId);
                onChange(chosenPatient);
                setValue('patientId', chosenPatient.id);
              }}
            />
          ) : (
            <InputField
              isLoading={isLoading}
              id="edit-appointment-selected-patient"
              {...field}
              inputRef={ref}
              disabled
              label={t('PATIENT_LABEL')}
              value={getFullName(patientFullData.personalInfo)}
            />
          )
        ) : (
          <Autocomplete
            {...field}
            id="edit-appointment-patient-autocomplete"
            fullWidth
            options={patients}
            shouldSortOptions
            role="list-box"
            freeSolo
            disableClearable
            value={chosenPatient}
            filterOptions={(options, state) => {
              if (state.inputValue.length < MINIMUM_CHARS_FOR_PATIENT_SEARCH)
                return [];
              return options.filter((item: PatientPersonalInfo) =>
                getFullName(item)
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            onChange={(_, patientPersonalInfo: PatientPersonalInfo) => {
              handlePatientSelectAutocompleteChange(patientPersonalInfo);
              onChange(patientPersonalInfo);
            }}
            getOptionLabel={(option: PatientPersonalInfo) =>
              getFullName(option)
            }
            renderInput={(params) => (
              <InputField
                error={!!errors.patient}
                helperText={errors.patient?.message}
                label={t('PATIENT_LABEL')}
                placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
                inputRef={params.InputProps.ref}
                inputProps={params.inputProps}
                onChange={(ev) => setPatientSearchTerm(ev.target.value)}
                value={patientSearchTerm}
                endIcon={<SearchOutlined />}
              />
            )}
          />
        )
      }
    />
  );
};
export default PatientParterSelection;
