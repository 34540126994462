import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState
} from 'react';
import Popover from '../display/Popover';
import { PopoverProps } from '../display/Popover/Popover';

type ProviderContext = {
  openPopover: ({}: Omit<PopoverProps, 'open'>) => void;
  closePopover: () => void;
  closeAllPopovers: () => void;
};

const PopoverContext = createContext<ProviderContext>(null);

function PopoverProvider({
  children = ''
}: {
  children?: ReactNode;
}): ReactElement {
  const [popovers, setPopovers] = useState<PopoverProps[]>([]);

  const openPopover = (props: Omit<PopoverProps, 'open'>) => {
    setPopovers((prevState) => [
      ...prevState,
      { ...props, open: true, disableScrollLock: true }
    ]);
  };

  const closePopover = () => {
    setPopovers((prevState) => prevState.slice(0, prevState.length - 1));
  };

  const closeAllPopovers = () => {
    setPopovers([]);
  };

  return (
    <PopoverContext.Provider
      value={{ openPopover, closePopover, closeAllPopovers }}
    >
      {children}
      {popovers.map((popoverProps, index) => (
        <Popover
          key={`dialog-${index}`}
          {...popoverProps}
          onClose={closePopover}
        />
      ))}
    </PopoverContext.Provider>
  );
}

export function usePopover(): ProviderContext {
  const result = useContext(PopoverContext);
  if (!result) {
    throw new Error(
      'Popover context is only available inside PopoverContextProvider'
    );
  }
  return result;
}

export default PopoverProvider;
