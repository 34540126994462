import { StaffMember, StaffMemberFormValues } from 'src/types/staff';
import axiosApiInstance from 'src/utils/axios';

export async function createStaffMemberRequest(
  staff: StaffMemberFormValues
): Promise<string> {
  const response = await axiosApiInstance.post<string>('/staff', staff);
  const { data: staffId } = response;

  return staffId;
}

export async function updateStaffMemberRequest(
  staff: Partial<StaffMemberFormValues>,
  staffId: string
): Promise<string> {
  try {
    const response = await axiosApiInstance.put<{ id: string }>(
      `/staff/${staffId}`,
      staff
    );
    const { data } = response;
    return data.id;
  } catch (e) {
    throw new Error(e);
  }
}
export const getStaffMembersNamesRequest = async (): Promise<StaffMember[]> => {
  try {
    const { data } = await axiosApiInstance.get<StaffMember[]>('/staff/names');

    return data;
  } catch (error) {
    throw new Error('Failed to fetch doctors list');
  }
};

export const getStaffMembersRequest = async (): Promise<StaffMember[]> => {
  try {
    const { data } = await axiosApiInstance.get<StaffMember[]>('/staff');

    return data;
  } catch (error) {
    throw new Error('Failed to fetch doctors list');
  }
};

export async function resendWelcomeEmailRequest(
  staffId: string
): Promise<void> {
  try {
    await axiosApiInstance.post(`/staff/${staffId}/resend-welcome-email`);
  } catch (error) {
    throw new Error('Failed to resend welcome email');
  }
}
