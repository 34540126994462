import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  addCareTeamMemberRequest,
  removePatientCareTeamMemberRequest,
  getPatientCareTeamMembersRequest,
  getPatientPrimaryCareTeamMemberRequest
} from 'src/api/patients.api';
import { AppError } from 'src/types/global';
import { CareTeamMember } from 'src/types/staff';
import { StaffMember } from 'src/types/staff';
import { queryKeys, querySubKeys } from './queryKeys';

function useCareTeam() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    removePatientCareTeamMember: () =>
      useMutation<void, AppError, { patientId: string; staffId: string }>(
        ({ patientId, staffId }) =>
          removePatientCareTeamMemberRequest({ patientId, staffId }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_REMOVE_CARE_TEAM_MEMBER')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('REMOVED_PATIENT_CARE_TEAM_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });

            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_CARE_TEAM
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CARE_TEAM_PRIMARY
            ]);
            queryClient.invalidateQueries([queryKeys.PATIENTS, , patientId]);
          }
        }
      ),
    getPatientCareTeamMembers: (
      patientId: string,
      options?: UseQueryOptions<StaffMember[], AppError>
    ) =>
      useQuery<StaffMember[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].PATIENT_CARE_TEAM
        ],
        () => getPatientCareTeamMembersRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CARE_TEAM_MEMBERS')
            }),
          ...options
        }
      ),
    getPatientPrimaryCareTeamMember: (
      patientId: string,
      options?: UseQueryOptions<StaffMember, AppError>
    ) =>
      useQuery<StaffMember, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].CARE_TEAM_PRIMARY
        ],
        () => getPatientPrimaryCareTeamMemberRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PRIMARY_CARE_TEAM_MEMBER')
            }),
          ...options
        }
      ),
    addPatientCareTeamMember: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; careTeamMember: CareTeamMember }
      >(
        ({ patientId, careTeamMember }) =>
          addCareTeamMemberRequest({ patientId, careTeamMember }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_ADD_CARE_TEAM_MEMBER')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('SAVED_PATIENT_CARE_TEAM_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_CARE_TEAM
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CARE_TEAM_PRIMARY
            ]);
            queryClient.invalidateQueries([queryKeys.PATIENTS, , patientId]);
          }
        }
      )
  };
}

export default useCareTeam;
