import { FC } from 'react';
import Box from '../../../components/layout/Box';
import Typography from '../../../components/display/Typography';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { PatientsSearchBar } from '../../../layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import Button from '../../../components/display/Button';
import { fontWeights } from '../../../components/styles/fonts';
import { spacings } from '../../../components/styles/constants';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

interface ResendEmailFormType {
  patientId: string;
}

export const ResendEmailForm: FC = () => {
  const { t } = useTranslation();
  const { resendPatientConfirmationEmail } = usePatientsApi();
  const { closeDialog } = useDialog();

  const { control, handleSubmit } = useForm<ResendEmailFormType>({
    mode: 'onChange',
    defaultValues: {
      patientId: ''
    }
  });

  const { mutate: handleSendEmail, isLoading: isSendingEmail } =
    resendPatientConfirmationEmail();

  const onSubmit = async (data: ResendEmailFormType) => {
    await handleSendEmail(data.patientId, {
      onSuccess: () => {
        closeDialog();
      }
    });
  };

  return (
    <Box paddingTop={spacings.large}>
      <Box marginBottom={spacings.medium}>
        <Typography variant="h3" fontWeight={fontWeights.bold}>
          {t('RESEND_WELCOME_EMAIL_REQUEST').toUpperCase()}
        </Typography>
      </Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.medium}>
          <Controller
            name="patientId"
            control={control}
            rules={{
              required: t('PLEAD_CHOOSE_PATIENT')
            }}
            render={({ field: { onChange } }) => (
              <PatientsSearchBar
                onSelectPatient={(patientId) => onChange(patientId)}
              />
            )}
          />
        </Box>
        <Box width="30%">
          <Button disabled={isSendingEmail} fullWidth type="submit">
            {t('SEND')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
