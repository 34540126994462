export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
} as const;

export const fontFamilies = {
  primary: 'Nunito',
  chat: 'Trebuchet MS'
};

export const fonts = {
  body: `${fontWeights.regular} 14px/21px ${fontFamilies.primary}, serif`,
  button: `${fontWeights.bold} 14px/14px ${fontFamilies.primary}, sans-serif`,
  header1: `${fontWeights.bold} 34px/34px ${fontFamilies.primary}, serif`,
  header2: `${fontWeights.bold} 21px/21px ${fontFamilies.primary}, serif`,
  header3: `${fontWeights.bold} 16px/16px ${fontFamilies.primary}, serif`,
  header4: `${fontWeights.bold} 14px/14px ${fontFamilies.primary}, serif`,
  input: `${fontWeights.regular} 14px/18px ${fontFamilies.primary}, serif`,
  label: `${fontWeights.bold} 14px/14px ${fontFamilies.primary}, sans-serif`,
  caption: `${fontWeights.regular} 10px/12px ${fontFamilies.primary}, serif`,
  pill: `${fontWeights.regular} 12px/14px ${fontFamilies.primary}, serif`,
  tooltip: `${fontWeights.semiBold} 12px/12px ${fontFamilies.primary}, serif`,
  modalTitle: `${fontWeights.bold} 24px/24px ${fontFamilies.primary}, sans-serif`,
  scheduleTitle: `${fontWeights.extraBold} 21px/24px ${fontFamilies.primary}, sans-serif`,
  tableColumnHeader: `${fontWeights.regular} 12px/18px ${fontFamilies.primary}, serif`,
  tableCellTitle: `${fontWeights.regular} 12px/18px ${fontFamilies.primary}, serif`,
  calendarContent: `${fontWeights.regular} 18px/22px ${fontFamilies.primary}, serif`,
  calendarHeader: `${fontWeights.regular} 24px/16px ${fontFamilies.primary}, serif`,
  badge: `${fontWeights.regular} 12px/15px ${fontFamilies.primary}, sans-serif`,
  calendarSidebarTitle: `${fontWeights.extraBold} 16px/16px ${fontFamilies.primary}, sans-serif`,
  bigErrorNumber: `${fontWeights.extraBold} 300px/400px ${fontFamilies.primary}, sans-serif`
} as const;
