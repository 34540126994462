import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import useExams from 'src/hooks/useExams';

export const TreatmentUltrasoundDialog: FC<{ examId: string }> = ({
  examId
}) => {
  const { t } = useTranslation();
  const { getExaminationById } = useExams();
  const { data: exam } = getExaminationById(examId);

  return (
    <Flex
      flexDirection="column"
      minWidth="250px"
      maxWidth="550px"
      gap={spacings.medium}
      marginTop={spacings.medium}
    >
      <Flex>
        <Box flex={1}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('LEFT_AFC')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>{exam?.afcLeft || 0}</Typography>
        </Box>
      </Flex>
      <Flex>
        <Box flex={1}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('RIGHT_AFC')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>{exam?.afcRight || 0}</Typography>
        </Box>
      </Flex>
      <Flex>
        <Box flex={1}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('LEFT_FOLLICLES')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>{exam?.folliclesSizeLeft?.join(', ') || '-'}</Typography>
        </Box>
      </Flex>

      <Flex>
        <Box flex={1}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('RIGHT_FOLLICLES')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>{exam?.folliclesSizeRight?.join(', ') || '-'}</Typography>
        </Box>
      </Flex>

      <Flex alignItems="center">
        <Box flex={1}>
          <Typography lineHeight={0} fontWeight={fontWeights.extraBold}>
            {t('ENDOMETRIUM')}
          </Typography>
        </Box>
        <Flex flex={1}>
          <Box flex={1}>
            <Typography>{exam?.endometrialThickness || 0}</Typography>
          </Box>
        </Flex>
      </Flex>

      <Flex alignItems="center">
        <Box flex={1}>
          <Typography fontWeight={fontWeights.extraBold}>
            {t('OBSERVATIONS')}
          </Typography>
        </Box>
        <Flex flex={1} alignItems="center" gap={spacings.small} flexWrap="wrap">
          {exam?.findings?.length
            ? exam?.findings?.map((finding, index) => (
                <Chip key={index} label={finding.name} />
              ))
            : '-'}
        </Flex>
      </Flex>
    </Flex>
  );
};
