const enFeedTranslations = {
  PATIENT_FEED: 'Patient Feed',
  PARTNER: 'Partner',
  EVENT_TYPE: 'Event Type',
  SUMMARY: 'Summary',
  UPDATED_ON: 'Updated On',
  UPDATED_BY: 'Updated By',
  FLAG: 'Flag',
  APPOINTMENT: 'Appointment',
  CYCLE: 'Cycle',
  DOCUMENTS: 'Documents',
  BILLING: 'Billing',
  MEDICAL_CHART: 'Medical chart',
  ENCOUNTER: 'Encounter',
  PATIENT_INFORMATION: 'Patient information',
  PROGRESS_NOTE: 'Progress note',
  ACTIVITY_FEED: 'Activity Feed',
  NOTE_SUBJECT_REQUIRED: 'Note subject is required',
  NOTE_SUBJECT: 'Note subject',
  ADD_NOTE_SUBJECT: 'Add note subject',
  NOTE_SUMMARY_REQUIRED: 'Note summary is required',
  TYPE_NOTE: 'Type note',
  ADD_PROGRESS_NOTE: 'Add progress note',
  ACTIVITY_DETAILS: 'Activity Details',
  ACTIVITY: 'Activity',
  CLINIC_FEED_TABS_TITLE: 'Clinic Feed - {{currentTab}}'
};

export default enFeedTranslations;
