import { FC, ReactNode } from 'react';
import { spacings } from 'src/components/styles/constants';
import { Box, styled } from '@mui/material';

interface FormActionsContainerProps {
  actions: ReactNode[];
  marginTop?: string;
}

const StyledFormActionsContainer = styled(Box)<{ marginTop: string }>`
  display: flex;
  gap: ${spacings.large};
  justify-content: flex-end;
  margin-top: ${({ marginTop }) => marginTop ?? spacings.large};
`;

const FormActionsContainer: FC<FormActionsContainerProps> = ({
  actions,
  marginTop
}) => {
  return (
    <StyledFormActionsContainer marginTop={marginTop}>
      {actions}
    </StyledFormActionsContainer>
  );
};

export default FormActionsContainer;
