import { Dayjs } from 'dayjs';
import { EncounterServiceCode, Modifier } from './codes';
import { INote } from './cycle';

export interface Encounter {
  id: string;
  displayId: string;
  encounterDate: Dayjs;
  claimId?: string;
  encounterNotes?: string;
  actionsTaken?: string;
  results?: string;
  amendments?: INote[];
  appointmentId: string;
  patientId: string;
  createdByStaffId?: string;
  diagnoses?: EncounterDiagnosis[];
  isLocked?: boolean;
  lockedByStaffId?: string;
  lockedAt?: Dayjs;
  unlockedByStaffId?: string;
  unlockedAt?: Dayjs;
}

export interface EncounterDiagnosis {
  icd10Code: string;
  description: string;
  index: number;
  label: string;
}

export interface EncounterCodeRow {
  id?: string;
  encounterId?: string;
  billingService: EncounterServiceCode;
  modifiers: Modifier[];
  ndcCode?: string;
  dxPointers: EncounterDiagnosis[];
  dosage?: number;
  dosageUnit?: string;
  serviceUnit?: number;
}

interface EncounterCodeRowToSend
  extends Omit<
    EncounterCodeRow,
    'billingService' | 'modifiers' | 'dxPointers'
  > {
  billingServiceId: string;
  modifiers: string[];
  dxPointers: number[];
}

export interface UpdateEncounterCodeRowsRequestDto {
  encounterId: string;
  appointmentId: string;
  codeRows: EncounterCodeRowToSend[];
  encounter: Partial<Encounter>;
}

export interface EncounterInsuranceInfo {
  encounterId?: string;
  primaryInsurance: string;
  secondaryInsurance?: string;
  eligibility?: boolean;
}

export enum EncounterLockStatusActions {
  LOCK = 'lock',
  UNLOCK = 'unlock'
}

export enum EncounterLockStatus {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked'
}
