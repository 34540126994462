import { AxiosResponse } from 'axios';
import {
  AddProgressNoteForm,
  FeedActivity,
  FlagActivityRequest
} from '../types/feed';
import axiosApiInstance from '../utils/axios';
import { withQueryParams } from '../utils/pagination';
import { PaginatedData } from '../types/pagination';
import { convertFeedActivitiesServerToClient } from '../modules/patients/utils/conversions';
import { GridFilterModel } from '@mui/x-data-grid-premium';

const getFeedActivitiesByClinicRequest = async (
  queryParams?: URLSearchParams,
  filters?: GridFilterModel
): Promise<PaginatedData<FeedActivity>> => {
  const response = await axiosApiInstance.post<PaginatedData<FeedActivity>>(
    '/feed-activities/getClinicActivities',
    { filters },
    {
      params: queryParams
    }
  );

  const data: PaginatedData<FeedActivity> = {
    ...response.data,
    rows: convertFeedActivitiesServerToClient(response.data.rows, true)
  };
  return data;
};

export const getFeedActivitiesByClinicPaginatedRequest = withQueryParams(
  getFeedActivitiesByClinicRequest
);

const getFeedActivitiesRequest = async (
  queryParams?: URLSearchParams,
  patientId?: string,
  filters?: GridFilterModel
): Promise<PaginatedData<FeedActivity>> => {
  let response: AxiosResponse<PaginatedData<FeedActivity>>;

  if (patientId) {
    response = await axiosApiInstance.post<PaginatedData<FeedActivity>>(
      `/feed-activities/getPatientActivities/${patientId}`,
      { filters },
      {
        params: queryParams
      }
    );
  } else {
    response = await axiosApiInstance.post<PaginatedData<FeedActivity>>(
      `/feed-activities/getClinicActivities`,
      { filters },
      {
        params: queryParams
      }
    );
  }

  const data: PaginatedData<FeedActivity> = {
    ...response.data,
    rows: convertFeedActivitiesServerToClient(response.data.rows, true)
  };
  return data;
};

export const getFeedActivitiesPaginatedRequest = withQueryParams(
  getFeedActivitiesRequest
);

export const getActivityByIdRequest = async (
  activityId?: string
): Promise<FeedActivity> => {
  const response = await axiosApiInstance.get<FeedActivity>(
    `/feed-activities/${activityId}`
  );

  return response.data;
};

export const createProgressNoteRequest = async (
  data: AddProgressNoteForm
): Promise<FeedActivity> => {
  const response = await axiosApiInstance.post<FeedActivity>(
    '/feed-activities',
    data
  );

  return response.data;
};

export const flagActivityRequest = async ({
  activityId,
  ...restData
}: FlagActivityRequest): Promise<number> => {
  const response = await axiosApiInstance.patch<number>(
    `/feed-activities/${activityId}/flag`,
    restData
  );

  return response.data;
};
