import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import { LabResultForm } from 'src/modules/patients/medicalHistory/EditPatientLabResult';
import {
  createLabReportRequest,
  createLabResultRequest,
  deleteLabResultRequest,
  getLabResultByIdRequest,
  getPatientLabResultsRequest,
  updateLabResultRequest
} from 'src/api/patients.api';
import { AppError } from 'src/types/global';
import { LabReport, LabResult } from 'src/types/appointment';
import { LabResultServer } from 'src/types/patient-server';
import { queryKeys, querySubKeys } from './queryKeys';

function useLabResults() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    createLabsReport: () =>
      useMutation<LabResultServer[], AppError, LabReport>(
        (report: LabReport) => createLabReportRequest(report),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_LAB_REPORTS')
            }),
          onSuccess: (labResults, { cycleId }) => {
            openToast({
              title: t('CREATE_LAB_REPORT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              labResults[0].patientId,
              querySubKeys[queryKeys.PATIENTS].REPORTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
          }
        }
      ),
    getPatientLabResults: (
      patientId: string,
      options?: UseQueryOptions<LabResult[], AppError>
    ) =>
      useQuery<LabResult[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].LAB_RESULTS
        ],
        () => getPatientLabResultsRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_LAB_RESULTS')
            }),
          ...options
        }
      ),
    getLabResultById: (
      resultId: string,
      options?: UseQueryOptions<LabResult, AppError>
    ) =>
      useQuery<LabResult, AppError>(
        [queryKeys.LAB, querySubKeys[queryKeys.LAB].RESULTS, resultId],
        () => getLabResultByIdRequest(resultId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_LAB_RESULT')
            }),
          ...options
        }
      ),
    createLabResult: () =>
      useMutation<LabResultServer[], AppError, LabResultForm>(
        (labResult: LabResultForm) => createLabResultRequest(labResult),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_LAB_RESULT')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('CREATE_LAB_RESULT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].LAB_RESULTS
            ]);
          }
        }
      ),
    updateLabResult: () =>
      useMutation<LabResultServer[], AppError, Partial<LabResultForm>>(
        (labResult: Partial<LabResultForm>) =>
          updateLabResultRequest(labResult),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_LAB_RESULT')
            }),
          onSuccess: (_, { id, patientId, cycleId }) => {
            openToast({
              title: t('UPDATE_LAB_RESULT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].LAB_RESULTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.LAB,
              querySubKeys[queryKeys.LAB].RESULTS,
              id
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
          }
        }
      ),
    deleteLabResult: () =>
      useMutation<void, AppError, Partial<LabResult>>(
        ({ id }: Partial<LabResult>) => deleteLabResultRequest(id),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_DELETE_LAB_RESULT')
            }),
          onSuccess: (_, { patientId, cycleId }) => {
            openToast({
              title: t('DELETE_LAB_RESULT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].LAB_RESULTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
          }
        }
      )
  };
}

export default useLabResults;
