import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  styled,
  DialogProps as MuiDialogProps,
  css
} from '@mui/material';
import { Colors } from '../../styles/colors';
import { iconSizes, spacings, shadows, zIndices } from '../../styles/constants';
import { fonts } from '../../styles/fonts';
import Typography from '../Typography';
import Flex from 'src/components/layout/Flex/Flex';
import MiniIconButton from '../MiniIconButton';
import { makeShouldForwardProps } from 'src/components/utils';

type DialogCloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButton';

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  header?: string;
  headerIcon?: ReactNode;
  closable?: boolean;
  onClose?: (reason: DialogCloseReason) => void;
  hideCloseButton?: boolean;
  removeDefaultPadding?: boolean;
  disableFullScreen?: boolean;
  closeButtonId?: string;
}

interface StyledDialogProps extends MuiDialogProps {
  top?: number;
  left?: number;
  removeDefaultPadding?: boolean;
  disableFullScreen?: boolean;
}

const shouldForwardProp = makeShouldForwardProps([
  'removeDefaultPadding',
  'disableFullScreen'
]);
const StyledDialog = styled(MuiDialog, {
  shouldForwardProp
})<StyledDialogProps>`
  .MuiBackdrop-root {
    display: ${({ disableFullScreen }) =>
      disableFullScreen ? 'none' : 'flex'};
  }
  & .MuiDialog-paper {
    max-height: 80vh;
    ${({ removeDefaultPadding }) =>
      !removeDefaultPadding &&
      css`
        padding: ${spacings.xxlarge};
      `}
    box-shadow: ${shadows.default};
    z-index: ${zIndices.higher};
    & .MuiBadge-root {
      align-self: end;
    }
  }
`;

const StyledDialogTitle = styled(MuiDialogTitle)`
  padding: ${spacings.none};
  display: flex;
  align-items: center;
  gap: ${spacings.xlarge};
  .MuiTypography-root {
    font: ${fonts.modalTitle};
  }
  .MuiSvgIcon-root {
    margin-inline-end: ${spacings.medium};
    font-size: ${iconSizes.xlarge};
  }
  .MuiButtonBase-root {
    color: ${Colors.gray};
    .MuiButton-root {
      padding: 2px;
    }
  }
`;

const StyledDialogContent = styled(MuiDialogContent)`
  padding: ${spacings.none};
  font: ${fonts.body};
  overflow: unset;
`;

const Dialog: FC<DialogProps> = ({
  header,
  headerIcon,
  children,
  closable = true,
  hideCloseButton,
  disableScrollLock = true,
  onClose,
  closeButtonId,
  ...otherProps
}) => {
  return (
    <StyledDialog
      disableScrollLock={disableScrollLock}
      disableEscapeKeyDown={!closable}
      onClose={(_, reason) => {
        if (!closable && reason === 'backdropClick') return;
        onClose(reason);
      }}
      {...otherProps}
      scroll="paper"
    >
      <Flex flexDirection="row" alignItems="center">
        {headerIcon && <>{headerIcon}</>}
        {header && (
          <StyledDialogTitle>
            <Typography color="black">{header}</Typography>
          </StyledDialogTitle>
        )}
        <Flex flexGrow={1} justifyContent="flex-end">
          {closable && !hideCloseButton && (
            <MiniIconButton
              id={closeButtonId}
              icon={<CloseIcon />}
              onClick={() => onClose('closeButton')}
            />
          )}
        </Flex>
      </Flex>
      <StyledDialogContent>{children}</StyledDialogContent>
    </StyledDialog>
  );
};

export default Dialog;
