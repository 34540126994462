import { FC, useState, useRef } from 'react';
import dayjs from 'dayjs';
import {
  DateRangePicker as ReactDateRangePicker,
  Range,
  DateRangePickerProps as reactDateRangePickerProps
} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Flex from 'src/components/layout/Flex';
import { getDateFormat } from 'src/utils/dateAndTIme';
import Popover from 'src/components/display/Popover';
import InputField, { InputFieldProps } from '../InputField';
import { styled } from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import { fonts } from 'src/components/styles/fonts';
import { radii } from 'src/components/styles/constants';

interface DateRangePickerProps
  extends Omit<reactDateRangePickerProps, 'onChange' | 'ranges'>,
    Pick<InputFieldProps, 'helperText' | 'error' | 'fullWidth'> {
  ranges: Range;
  required?: boolean;
  onChange?: (ranges: Range) => void;
  label?: string;
}

const StyledDateRangePicker = styled(ReactDateRangePicker)`
  .rdrStaticRangeLabel {
    &:hover {
      background-color: ${Colors.cupidAlpha25};
    }
    font: ${fonts.tableColumnHeader};
    color: ${Colors.emperor};
  }
  .rdrDateDisplayItem input {
    font: ${fonts.header4};
  }

  .rdrDateDisplayWrapper {
    background-color: ${Colors.cupidAlpha25};
  }

  .rdrNextPrevButton {
    color: ${Colors.black};
    background-color: ${Colors.cupid};
    &:hover {
      background-color: ${Colors.mauvelous};
    }
    border-radius: ${radii.full};
  }

  font: ${fonts.tableColumnHeader};

  .rdrWeekDay {
    color: ${Colors.emperor};
  }

  .rdrDay.rdrDayPassive .rdrDayNumber {
    span {
      color: ${Colors.alto};
    }
  }

  .rdrDay:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
      & ~ .rdrDayNumber span {
        color: ${Colors.emperor};
      }
    }
  }

  .rdrDay {
    .rdrDayNumber {
      span {
        color: ${Colors.emperor};
      }
    }

    &.rdrDayToday .rdrDayNumber {
      span::after {
        background-color: ${Colors.mauvelous};
      }
    }
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrInRange {
      background-color: ${Colors.cupid};
    }
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      border-color: ${Colors.cupid};
    }
  }

  .rdrDateDisplayItem {
    border-radius: ${radii.full};
  }
  .rdrInputRangeInput {
    border-radius: ${radii.medium};
    font: ${fonts.input};
  }
  .rdrDateDisplayItemActive {
    border-color: ${Colors.emperor};
  }
`;

const DateRangePicker: FC<DateRangePickerProps> = ({
  label,
  error,
  fullWidth,
  required = false,
  ranges,
  minDate,
  onChange
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [shouldShowDateRangePicker, setShouldShowDateRangePicker] =
    useState<boolean>(false);

  const formatDates = () => {
    if (ranges && ranges?.startDate && ranges?.endDate) {
      return `${dayjs(ranges.startDate).format(getDateFormat())} - ${dayjs(
        ranges.endDate
      ).format(getDateFormat())}`;
    } else {
      const currentDate = dayjs().format(getDateFormat());

      return `${currentDate} - ${currentDate}`;
    }
  };

  const chosenRangeLabel = formatDates();

  return (
    <Flex
      width={fullWidth ? '100%' : 'auto'}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <InputField
        type="text"
        label={label}
        error={error}
        required={required}
        inputRef={inputRef}
        value={chosenRangeLabel}
        onClick={() => setShouldShowDateRangePicker(!shouldShowDateRangePicker)}
      />
      <Popover
        onClose={() => setShouldShowDateRangePicker(false)}
        open={shouldShowDateRangePicker}
        anchorEl={inputRef.current}
        hideToolbar
      >
        <DateRangePickerWrapper
          onChange={onChange}
          ranges={ranges}
          minDate={minDate}
        />
      </Popover>
    </Flex>
  );
};

export default DateRangePicker;

const DateRangePickerWrapper: FC<DateRangePickerProps> = ({
  ranges,
  minDate,
  onChange
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<Range[]>(
    [ranges] || [
      {
        startDate: dayjs().toDate(),
        endDate: dayjs().toDate(),
        key: 'selection'
      }
    ]
  );

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setSelectedDateRange([selection]);
  };

  return (
    <StyledDateRangePicker
      editableDateInputs={true}
      onChange={handleOnChange}
      moveRangeOnFirstSelection={false}
      ranges={selectedDateRange || undefined}
      minDate={minDate}
    />
  );
};
