import { FC } from 'react';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  styled
} from '@mui/material';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import { makeShouldForwardProps } from 'src/components/utils';

interface SwitchProps extends MuiSwitchProps {
  switchOnText?: string;
  switchOffText?: string;
  width?: number;
}

const shouldForwardProp = makeShouldForwardProps([
  'switchOnText',
  'switchOffText'
]);
const StyledSwitch = styled(MuiSwitch, { shouldForwardProp })<SwitchProps>`
  padding: 5px;
  width: ${({ width }) => width}px;
  height: 40px;
  .MuiSwitch-switchBase {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translate(${({ width }) => width - 50}px, -50%);
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: ${Colors.dawnPink};
  }
  .MuiSwitch-track {
    border-radius: 50px;
    background-color: ${Colors.dawnPink};
    border: 0;
    box-shadow: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font: ${fonts.label};
    }

    ${({ checked, switchOnText, switchOffText }) => {
      return checked
        ? `&:before {
            content: '${switchOnText}';
            left: 20px;
          }`
        : `&:after {
            content: '${switchOffText}';
            right: 0;
            padding-inline-end: 20px
          }`;
    }}
  }

  .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-color: ${Colors.white};
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    width: 20px;
    height: 20px;
    background-color: ${Colors.white};
    padding-inline: 0;
  }
`;

const Switch: FC<SwitchProps> = ({
  switchOnText = 'ON',
  switchOffText = 'OFF',
  width = 110,
  ...otherProps
}) => {
  return (
    <StyledSwitch
      {...otherProps}
      switchOffText={switchOffText}
      switchOnText={switchOnText}
      width={width}
    />
  );
};

export default Switch;
