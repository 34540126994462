import { FC } from 'react';
import Center from 'src/components/layout/Center/Center';
import useCycle from 'src/hooks/useCycle';
import { CycleNote } from 'src/types/cycle';
import useMeApi from 'src/hooks/useMeApi';
import Loader from 'src/components/display/Loader';
import NotesWrapper from './NotesWrapper';

export const CycleDayNotes: FC<{
  date: string;
  initialNotes?: CycleNote[];
}> = ({ initialNotes, date }) => {
  const { getMe } = useMeApi();
  const { isLoading } = getMe();

  const { upsertCycleNotes } = useCycle();

  const { mutateAsync: handleUpsertCycleNotes } = upsertCycleNotes();

  if (isLoading) return <Loader />;

  return (
    <Center
      sx={{
        cursor: 'pointer'
      }}
      width="100%"
      height="100%"
    >
      <NotesWrapper
        initialNotes={initialNotes}
        onUpsertNotes={({
          cycleId,
          date,
          patientId,
          notesToCreate,
          notesToDelete
        }) =>
          handleUpsertCycleNotes({
            cycleId,
            date,
            patientId,
            notesToCreate,
            notesToDelete
          })
        }
        date={date}
      />
    </Center>
  );
};
