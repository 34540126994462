import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  checkPendingOtpRequest,
  sendOtpCodeRequest,
  verifyOtpCodeRequest
} from '../api/me.api';

import { AppError } from 'src/types/global';
import {
  useAuth,
  useDialog
} from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { createGetMeFunction } from '../components/components-api/GlobalProvider/utils/createGetMeFunction';
import { queryKeys } from './queryKeys';

function useMeApi() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { openToast, handleQueryResultToast } = useToast();
  const { closeDialog } = useDialog();

  const queryClient = useQueryClient();

  return {
    getMe: createGetMeFunction({ t, openToast, isAuthenticated }),
    checkPendingOtp: (
      openSignatureToolbar?: () => void,
      options?: UseQueryOptions<
        {
          pendingOtp: boolean;
          verified: boolean;
        },
        AppError
      >
    ) =>
      useQuery<{ pendingOtp: boolean; verified: boolean }, AppError>(
        [queryKeys.PENDING_OTP],
        checkPendingOtpRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CHECK_PENDING_OTP')
            }),
          onSuccess: ({ pendingOtp, verified }) => {
            if (pendingOtp && verified) {
              openSignatureToolbar?.();
              closeDialog();
            }
          },
          ...options
        }
      ),
    sendOtpCode: () =>
      useMutation<
        {
          status: string;
          message: string;
        },
        AppError
      >(sendOtpCodeRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_SEND_OTP_CODE')
          }),
        onSuccess: () => {
          openToast({
            title: t('SEND_OTP_CODE_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([queryKeys.PENDING_OTP]);
        }
      }),
    verifyOtpCode: () =>
      useMutation<
        {
          status: string;
          message: string;
        },
        AppError,
        {
          staffId: string;
          otpCode: string;
        }
      >(({ staffId, otpCode }) => verifyOtpCodeRequest({ staffId, otpCode }), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_VERIFYING_OTP_CODE')
          }),
        onSuccess: () => {
          openToast({
            title: t('VERIFY_OTP_CODE_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([queryKeys.PENDING_OTP]);
          closeDialog();
        }
      })
  };
}

export default useMeApi;
