import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Box from '../../components/layout/Box';
import Card from '../../components/display/Card';
import { FeedActivityTable } from './FeedActivityTable';
import { spacings } from 'src/components/styles/constants';

export const ClinicFeedActivity: FC = () => {
  const { t } = useTranslation();

  return (
    <Box padding={spacings.small}>
      <Helmet>
        <title>{t('CLINIC_FEED_PAGE_TITLE')}</title>
      </Helmet>

      <Box>
        <Card height="calc(100vh - 260px)">
          <FeedActivityTable byClinic />
        </Card>
      </Box>
    </Box>
  );
};
