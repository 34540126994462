import { useContext, useEffect, useState } from 'react';

import usePatientsApi from '../../../hooks/usePatientsApi';
import { getTelehealthLinkRequest } from '../../../api/patients.api';

import { Box, styled } from '@mui/material';
import IconButton from 'src/components/display/IconButton';

import { ToastType } from 'src/components/display/Toast/Toast';
import { useToast } from 'src/components/components-api/ToastProvider';
import Card from 'src/components/display/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from 'src/components/styles/colors';
import Loader from 'src/components/display/Loader';
import { fontWeights } from 'src/components/styles/fonts';
import { getFullName } from 'src/utils/general';
import { SidebarContext } from 'src/contexts/SidebarContext';

const RootWrapper = styled(Box)`
  height: calc(100vh - 420px);
  display: flex;
`;

const TelehealthWindow = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;
`;

const StyledIframe = styled('iframe')`
  width: 100%;
  height: 100%;
  border: none;
`;

function Telehealth({
  patientId,
  onClose,
  width = '500px'
}: {
  patientId?: string;
  onClose?: () => void;
  width?: string;
}) {
  const { openToast } = useToast();
  const { isTelehealthOpen } = useContext(SidebarContext);
  const [patientName, setPatientName] = useState('');
  const [telehealthSrc, setTelehealthSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const { getPatientById } = usePatientsApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  useEffect(() => {
    if (!isTelehealthOpen) {
      setTelehealthSrc('');
      setIsLoading(true);
      return;
    }

    if (telehealthSrc) {
      return;
    }

    setPatientName(getFullName(patient.personalInfo));
    const getTelehealthLink = async () => {
      const telehealthRes = await getTelehealthLinkRequest(patientId);
      if (!telehealthRes?.token) {
        setIsLoading(false);
        openToast({
          title: t('ERROR_STARTING_TELEHEALTH'),
          children: t('TRY_AGAIN_LATER'),
          type: ToastType.ERROR
        });
        return;
      }

      setTelehealthSrc(
        `${process.env.REACT_APP_MEETINGS_URL}?id=${telehealthRes.id}&token=${telehealthRes.token}`
      );
      setIsLoading(false);
    };

    getTelehealthLink();
  }, [patientId, isTelehealthOpen]);

  return (
    <>
      <Card shadow padding={spacings.xlarge} width={width}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom={spacings.small}
        >
          <Typography variant="h2">{t('TELEHEALTH')}</Typography>
          {onClose && (
            <IconButton
              icon={<CloseIcon />}
              iconSize="xsmall"
              bgColor="gray"
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Flex>
        {!isLoadingPatient && (
          <Typography
            variant="h4"
            marginBottom={spacings.small}
            fontWeight={fontWeights.extraBold}
          >
            With {patientName}
          </Typography>
        )}
        <Box
          borderBottom={`1px solid ${Colors.alto2}`}
          width="100%"
          marginY={spacings.medium}
        />
        <RootWrapper className="Mui-FixedWrapper">
          <TelehealthWindow>
            {isLoading ? (
              <Loader />
            ) : (
              <StyledIframe
                id="iframe-meetings"
                allow="autoplay; camera; microphone; fullscreen; display-capture;"
                src={telehealthSrc}
              />
            )}
          </TelehealthWindow>
        </RootWrapper>
      </Card>
    </>
  );
}

export default Telehealth;
