import axiosApiInstance from '../utils/axios';
import { SendFaxDetails } from 'src/modules/patients/forms/types';

export async function sendEFaxRequest({
  faxDetails,
  patientId,
  documentId
}: {
  faxDetails: SendFaxDetails;
  patientId: string;
  documentId: string;
}): Promise<{ faxId: string }> {
  const response = await axiosApiInstance.post<{ faxId: string }>(
    `/e-fax/${patientId}/documents/${documentId}`,
    faxDetails
  );

  const { data } = response;

  return data;
}
