import { FC, useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Appointment } from 'src/types/appointment';
import { UserTypes } from 'src/types/user';
import usePatientsApi from 'src/hooks/usePatientsApi';

import { fonts, fontWeights } from 'src/components/styles/fonts';
import { spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { getFullName, getUserAge } from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { longDateFormat } from 'src/utils/dateAndTIme';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';
import Typography from 'src/components/display/Typography';
import IconButton from 'src/components/display/IconButton';
import Loader from 'src/components/display/Loader';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';

import { PatientFlags } from 'src/modules/patients/medicalHistory/PatientFlags';
import Tabs from 'src/components/display/Tabs';

import { DrawerContainerCard } from './DailyWorkListDrawer';
import { TabContentsProps } from 'src/components/display/Tabs/Tabs';
import { PatientDiagnosisDetails } from 'src/modules/patients/overview/PatientDiagnosis';
import { TasksTable } from 'src/modules/tasksTable/TasksTable';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import { AddChecklistMenu } from 'src/modules/patients/overview/AddChecklistMenu';

export enum PatientQuickViewTabs {
  HISTORY = 'history',
  ENCOUNTER_NOTES = 'encounterNotes',
  HISTORICAL_NOTES = 'historicalNotes',
  TASKS = 'tasks'
}

const StyledArrowBack = styled(ArrowBack)`
  color: ${Colors.emperor};
`;
const StyledSpecsKeyTypography = styled(Typography)`
  font: ${fonts.pill};
  flex: 1;
`;
const StyledSpecsValueTypography = styled(Typography)`
  font: ${fonts.pill};
  font-weight: ${fontWeights.extraBold};
  flex: 2;
`;

export const PatientQuickView: FC<{
  patientId: string;
  appointment?: Appointment | null;
  onBackClick: () => void;
}> = ({ patientId, appointment, onBackClick }) => {
  const { t } = useTranslation();
  const { getPatientById, getPatientOverview, getPatientTasks } =
    usePatientsApi();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { openPopover } = usePopover();

  const [selectedTab, setSelectedTab] = useState<PatientQuickViewTabs>(
    PatientQuickViewTabs.HISTORY
  );

  const { data: patient, isLoading: isPatientLoading } = getPatientById(
    patientId,
    {
      enabled: !!patientId
    }
  );
  const { data: patientOverview, isLoading: isPatientOverviewLoading } =
    getPatientOverview(patientId);
  const {
    data: tasks,
    isLoading: isTasksLoading,
    isFetching: isTasksFetching
  } = getPatientTasks({
    patientId
  });

  const isPageLoading = isPatientLoading || isPatientOverviewLoading;
  const isTasksTableLoading = isTasksLoading || isTasksFetching;

  const { appointmentType } = appointment || {};
  const { maritalStatus, personalInfo, partnerInfo } = patient || {};
  const { patientBasicInfo } = patientOverview || {};
  const { age, displayId } = patientBasicInfo || {};
  const { years, months } = age || {};
  const { dateOfBirth, phoneNumber, sexAtBirth } = personalInfo || {};

  const patientName = getFullName(personalInfo);
  const partnerName = getFullName(partnerInfo);
  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: personalInfo?.pictureId
  });
  const partnerAvatar = getDefaultAvatar({
    userId: partnerInfo?.id,
    userType: UserTypes.patients,
    pictureId: partnerInfo?.pictureId
  });

  const handleTabChange = (value: PatientQuickViewTabs) => {
    setSelectedTab(value);
  };

  const tabContents: TabContentsProps<PatientQuickViewTabs>[] = [
    {
      value: PatientQuickViewTabs.HISTORY,
      label: t('HISTORY'),
      children: (
        <Flex flexDirection="column">
          <PatientDiagnosisDetails patientId={patientId} />
        </Flex>
      )
    },
    {
      value: PatientQuickViewTabs.ENCOUNTER_NOTES,
      label: t('ENCOUNTER_NOTES'),
      disabled: true
    },
    {
      value: PatientQuickViewTabs.HISTORICAL_NOTES,
      label: t('HISTORICAL_NOTES'),
      disabled: true
    },
    {
      value: PatientQuickViewTabs.TASKS,
      label: (
        <Flex gap={spacings.medium} alignItems="center">
          {t('TASKS')}
          <MiniIconButton
            id="add-tasks-button"
            icon={<AddIcon />}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              openPopover({
                children: (
                  <AddChecklistMenu
                    patientId={patientId}
                    anchorEl={ev.currentTarget}
                  />
                ),
                anchorEl: ev.currentTarget,
                hideToolbar: true,
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom'
                },
                transformOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                }
              });
            }}
          />
        </Flex>
      ),
      children: (
        <TasksTable
          standalone
          pageSize={10}
          tasks={tasks || []}
          loading={isTasksTableLoading}
        />
      )
    }
  ];

  return (
    <DrawerContainerCard backgroundColor={Colors.white}>
      <Flex justifyContent="space-between">
        <Flex gap={spacings.small}>
          <IconButton
            icon={<StyledArrowBack />}
            onClick={onBackClick}
            iconSize="medium"
            bgColor="transparent"
          />
          <Avatar
            alignAvatar="left"
            image={avatar}
            title={patientName}
            subtitle={appointmentType}
          />
        </Flex>
        <Box></Box>
      </Flex>
      {isPageLoading ? (
        <Flex
          maxWidth="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
        </Flex>
      ) : (
        <Flex flexDirection="column" gap={spacings.xlarge} width="100%">
          <PatientFlags patientId={patientId} />
          <Flex gap={spacings.large}>
            <Flex flex={1} gap={spacings.small} flexDirection="column">
              <Typography variant="h4" marginBottom={spacings.medium}>
                {t('PATIENT_DETAILS').toLocaleUpperCase()}
              </Typography>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('AGE')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {getUserAge({ years, months })}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('DOB')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {dayjs(dateOfBirth).format(longDateFormat)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('STATUS')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {t(maritalStatus)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('SEX')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {t(sexAtBirth)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('PHONE')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {phoneNumber}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('PATIENT_ID')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {displayId?.toUpperCase()}
                </StyledSpecsValueTypography>
              </Flex>
            </Flex>
            <Flex flex={1} gap={spacings.large} flexDirection="column">
              {partnerInfo && (
                <>
                  <Typography variant="h4">
                    {t('PARTNER').toLocaleUpperCase()}
                  </Typography>
                  <Flex>
                    <PatientAvatar
                      size={AvatarSizes.MEDIUM}
                      image={partnerAvatar}
                      patientId={partnerInfo.id}
                      title={partnerName}
                      alignAvatar="left"
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
          <Flex gap={spacings.large}>
            <Tabs<PatientQuickViewTabs>
              onTabChange={handleTabChange}
              selectedTab={selectedTab}
              tabContents={tabContents}
            />
          </Flex>
        </Flex>
      )}
    </DrawerContainerCard>
  );
};
