import { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { ToolbarProps } from 'react-big-calendar';
import { Colors } from 'src/components/styles/colors';

import { fontFamilies, fonts, fontWeights } from 'src/components/styles/fonts';
import { getDateFormat, getWeekRange } from 'src/utils/dateAndTIme';
import Flex from '../../../components/layout/Flex/Flex';
import { spacings } from '../../../components/styles/constants';
import Button from '../Button';

import Typography from '../Typography/Typography';

const StyledToolbar = styled(Box)`
  font: ${fonts.calendarHeader};
  padding: ${spacings.medium} ${spacings.xlarge};
  font-weight: ${fontWeights.bold};

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  font-family: ${fontFamilies.primary};
  color: ${Colors.emperor};
  text-decoration: none solid rgb(84, 84, 84);
`;

const StyledDayButton = styled(Button)`
  && {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100px;
  }
`;

const StyledWeekButton = styled(Button)`
  && {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100px;
  }
`;

const CustomToolbar = ({
  toolbar,
  dayView,
  currDate,
  setDayView,
  smallHeader
}: {
  toolbar: ToolbarProps;
  dayView?: boolean;
  currDate?: Dayjs;
  setDayView: (dayView: boolean) => void;
  smallHeader?: boolean;
}) => {
  if (dayView && smallHeader) {
    return (
      <Typography variant="h2" fontWeight={fontWeights.extraBold}>
        {currDate.format(getDateFormat())}
      </Typography>
    );
  }

  useEffect(() => {
    if (dayView) {
      toolbar.onView('day');
    } else {
      toolbar.onView('week');
    }
  }, [dayView]);

  const onDayView = () => {
    setDayView(true);
    toolbar.onView('day');
  };

  const onWeekView = () => {
    setDayView(false);
    toolbar.onView('week');
  };

  const date = dayjs(toolbar.date).day(0);

  return (
    <StyledToolbar>
      {dayView ? (
        <label>{dayjs(toolbar.date).format(getDateFormat())}</label>
      ) : (
        <label>Week of {getWeekRange(date)}</label>
      )}

      <Flex gap={spacings.xsmall}>
        <StyledDayButton onClick={onDayView}>Day</StyledDayButton>
        <StyledWeekButton onClick={onWeekView}>Week</StyledWeekButton>
      </Flex>
    </StyledToolbar>
  );
};

export default CustomToolbar;
