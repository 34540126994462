import { Priority } from '../../../types/priority';
import { PatientInsurance } from '../../../types/patientInsurance';

export type PatientInsurancesForm = {
  insurances: PatientInsurance[];
};

export enum DataIndex {
  NONE = 0,
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3
}

// Mapping from Priority to DataIndex
export const priorityToDataIndexMap: Record<Priority, DataIndex> = {
  [Priority.PRIMARY]: DataIndex.PRIMARY,
  [Priority.SECONDARY]: DataIndex.SECONDARY,
  [Priority.TERTIARY]: DataIndex.TERTIARY
};
