import { GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import { PageSizes, spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import { TreatmentHistory } from 'src/types/patient';
import { useNavigate, useParams } from 'react-router-dom';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { patientRoutes } from 'src/router/routes';
import { CycleWizardVariant, CycleStatus } from 'src/types/cycle';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { useCycleWizard } from '../../../components/components-api/CycleWizardProvider';

const StyledTable = styled(Table)`
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    cursor: pointer;
    padding: ${spacings.small};
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${Colors.whiteSand};
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${fontWeights.extraBold};
  }
`;

export const PatientTreatmentHistoryTable: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { getPatientHistory } = usePatientsApi();
  const { openWizard } = useCycleWizard();

  const {
    data: history,
    isLoading: isPatientHistoryLoading,
    isFetching: isPatientHistoryFetching
  } = getPatientHistory(patientId);

  const isLoading = isPatientHistoryLoading || isPatientHistoryFetching;

  const { previousCycles } = history || {};

  const columns: GridColDef<TreatmentHistory>[] = [
    {
      field: 'date',
      align: 'left',
      headerName: t('DATE').toUpperCase(),
      type: 'date' as const,
      valueGetter: (value) => new Date(value),
      renderCell: ({ row: { date } }) => dayjs(date).format(getDateFormat())
    },
    {
      field: 'treatment',
      align: 'left',
      headerName: t('TREATMENT').toUpperCase(),
      valueGetter: (value) => t(value),
      renderCell: ({ value }) => value
    },
    {
      field: 'protocol',
      align: 'left',
      headerName: t('PROTOCOL').toUpperCase(),
      valueGetter: (value) => t(value),
      renderCell: ({ value }) => value
    },
    {
      field: 'beta',
      align: 'left',
      headerName: t('BETA').toUpperCase(),
      filterable: false
    },
    {
      field: 'outcome',
      align: 'left',
      valueGetter: (_, row) => row.outcome || row.status || null,
      renderCell: ({ value }) => value || '--',
      headerName: t('OUTCOME').toUpperCase()
    },
    {
      field: 'displayId',
      align: 'left',
      headerName: t('CYCLE_ID').toUpperCase(),
      filterable: false
    }
  ];
  return (
    <Card maxWidth="100%" height={400}>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('TREATMENT_HISTORY')}
        </Typography>
        <MiniIconButton
          icon={<AddIcon />}
          onClick={() =>
            openWizard({
              variant: CycleWizardVariant.PREVIOUS_CYCLE,
              patientId
            })
          }
        />
      </Flex>
      <StyledTable
        paginationModel={{ page: 0, pageSize: PageSizes.XSMALL }}
        onRowClick={({ id }: GridRowParams<TreatmentHistory>) =>
          navigate(
            patientRoutes.getCurrentTreatmentByCycleLink(patientId, `${id}`)
          )
        }
        columns={columns}
        rows={
          previousCycles?.filter(
            ({ status }) =>
              status !== CycleStatus.ONGOING && status !== CycleStatus.PENDING
          ) || []
        }
        loading={isLoading}
        autoHeight
      />
    </Card>
  );
};
