import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box/Box';
import { useParams } from 'react-router-dom';
import Typography from 'src/components/display/Typography/Typography';
import { PatientMiniCard } from '../common/PatientMiniCard';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { TasksTable } from '../../tasksTable/TasksTable';
import { PatientFlags } from '../medicalHistory/PatientFlags';

export const PatientTasks: FC = () => {
  const { patientId } = useParams();
  const { getPatientOverview, getPatientTasks } = usePatientsApi();
  const { t } = useTranslation();

  const { data: patientOverview } = getPatientOverview(patientId);
  const {
    data: patientTasks,
    isLoading: isPatientTasksLoading,
    isFetching: isPatientTasksFetching
  } = getPatientTasks({
    patientId,
    showDone: false
  });
  const {
    data: partnerTasks,
    isLoading: isPartnerTasksLoading,
    isFetching: isPartnerTasksFetching
  } = getPatientTasks(
    { patientId: patientOverview?.partnerInfo?.id, showDone: false },
    {
      enabled: !!patientOverview?.partnerInfo?.id
    }
  );

  const {
    data: patientChecklistDone,
    isLoading: isPatientChecklistDoneLoading,
    isFetching: isPatientChecklistDoneFetching
  } = getPatientTasks({
    patientId,
    showDone: true
  });

  const isTasksLoading =
    isPatientTasksLoading ||
    isPatientTasksFetching ||
    isPartnerTasksLoading ||
    isPartnerTasksFetching;
  const isDoneTasksLoading =
    isPatientChecklistDoneLoading || isPatientChecklistDoneFetching;

  const { data: partnerChecklistDone } = getPatientTasks(
    { patientId: patientOverview?.partnerInfo?.id, showDone: true },
    {
      enabled: !!patientOverview?.partnerInfo?.id
    }
  );
  const tasks = [...(patientTasks || []), ...(partnerTasks || [])];

  const doneTasks = [
    ...(patientChecklistDone || []),
    ...(partnerChecklistDone || [])
  ];

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_TASKS_PAGE_TITLE')}</title>
      </Helmet>
      <Box>
        <Box marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>

        <Flex gap={spacings.large} marginBottom={spacings.large} width="100%">
          <PatientMiniCard
            flex={1}
            patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
            patientId={patientId}
            properties={patientOverview?.patientProperties}
            basicInfo={patientOverview?.patientBasicInfo}
            header={t('PATIENT_CHECKLIST')}
          />
          {patientOverview?.partnerInfo && (
            <PatientMiniCard
              flex={1}
              isPartner
              patientId={patientOverview?.partnerInfo?.id}
              patientDisplayId={
                patientOverview?.partnerInfo?.basicInfo?.displayId
              }
              properties={patientOverview?.partnerInfo?.properties}
              basicInfo={patientOverview?.partnerInfo?.basicInfo}
              header={t('PARTNER_CHECKLIST')}
            />
          )}
        </Flex>
        <Flex
          gap={spacings.large}
          marginBottom={spacings.large}
          flexDirection="column"
          width="100%"
        >
          <TasksTable
            tasks={tasks}
            loading={isTasksLoading}
            toolbar={
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2">{t('OPEN_TASKS')}</Typography>
              </Flex>
            }
          />
          <TasksTable
            historyTasks
            tasks={doneTasks}
            loading={isDoneTasksLoading}
            toolbar={
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2">{t('CLOSED_TASKS')}</Typography>
              </Flex>
            }
          />
        </Flex>
      </Box>
    </>
  );
};
