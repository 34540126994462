import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/data-entry/InputField/InputField';
import { GeneralPhysician } from 'src/types/patient';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import Flex from 'src/components/layout/Flex/Flex';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { emailRegex, phoneRegex } from '../utils/validations-regex';

export const EditGeneralPractitionerInfo: FC<{
  patientId: string;
  generalPhysicianInfo: GeneralPhysician;
}> = ({ patientId, generalPhysicianInfo }) => {
  const { t } = useTranslation();
  const { updatePatientGeneralPhysicianInfo } = usePatientsApi();
  const { closePopover } = usePopover();

  const { mutate: updatePatient, isSuccess: isUpdatedSuccessfuly } =
    updatePatientGeneralPhysicianInfo();

  const defaultValues: GeneralPhysician = {
    gpAddress: '',
    gpEmail: '',
    gpName: '',
    gpPhoneNumber: ''
  };

  const { control, formState, handleSubmit, reset } = useForm<GeneralPhysician>(
    {
      mode: 'onChange',
      defaultValues
    }
  );

  const { errors } = formState;

  useEffect(() => {
    if (isUpdatedSuccessfuly) closePopover();
  }, [isUpdatedSuccessfuly]);

  useEffect(() => {
    if (generalPhysicianInfo) {
      const { gpAddress, gpEmail, gpName, gpPhoneNumber, referringDoctor } =
        generalPhysicianInfo || {};

      reset({
        gpAddress: gpAddress || '',
        gpEmail: gpEmail || '',
        gpName: gpName || '',
        gpPhoneNumber: gpPhoneNumber || '',
        referringDoctor: referringDoctor || ''
      });
    }
  }, []);

  const onSubmit = async (details: GeneralPhysician) => {
    await updatePatient({
      patientId: `${patientId}`,
      generalPhysicianInfo: { ...details }
    });
  };

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection="column"
          gap={spacings.large}
          marginBottom={spacings.large}
          flex={1}
        >
          <Controller
            name="gpName"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PRIMARY_CARE_PROVIDER_NAME_LABEL')}
                placeholder={t('NAME_PLACEHOLDER')}
                error={!!errors.gpName}
                helperText={errors?.gpName?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="gpAddress"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('ADDRESS')}
                placeholder={t('ADDRESS_PLACEHOLDER')}
                error={!!errors.gpAddress}
                helperText={errors?.gpAddress?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="gpPhoneNumber"
            control={control}
            rules={{
              pattern: {
                value: phoneRegex,
                message: t('PHONE_NUMBER_INVALID')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PHONE_NUMBER_LABEL')}
                placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                error={!!errors.gpPhoneNumber}
                helperText={errors?.gpPhoneNumber?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="gpEmail"
            control={control}
            rules={{
              pattern: {
                value: emailRegex,
                message: t('EMAIL_INVALID')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('EMAIL')}
                placeholder={t('EMAIL_PLACEHOLDER')}
                error={!!errors.gpEmail}
                helperText={errors?.gpEmail?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="referringDoctor"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('REFERRING_DOCTOR_LABEL')}
                placeholder={t('REFERRING_DOCTOR_PLACEHOLDER')}
                error={!!errors.referringDoctor}
                helperText={errors?.referringDoctor?.message}
                fullWidth
              />
            )}
          />
        </Flex>
        <Button fullWidth type="submit">
          {t('SUBMIT')}
        </Button>
      </form>
    </Box>
  );
};
