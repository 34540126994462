import { FC, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { css, styled, SxProps } from '@mui/material';
import { Box } from '@mui/material';
import Button from 'src/components/display/Button';
import { Colors } from 'src/components/styles/colors';
import {
  iconSizes,
  navbarWidth,
  radii,
  spacings,
  zIndices
} from 'src/components/styles/constants';
import { TooltipWrapper } from '../Tooltip';

interface NavbarProps {
  logo?: ReactElement;
  menuItems: {
    icon: ReactElement;
    title: string;
    path: string;
  }[];
  avatar?: string;
  sx?: SxProps;
}

const StyledNavbar = styled(Box)`
  box-sizing: border-box;
  height: 100%;
  width: ${navbarWidth}px;
  ${({ theme }) => ({
    [theme.breakpoints.up('lg')]: css`
      position: fixed;
    `
  })}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.darkGray};
  padding-inline-end: ${spacings.large};
  padding-top: ${spacings.large};
  border-top-right-radius: ${radii.full};
  z-index: ${zIndices.high};

  & .MuiBox-root.menu-button {
    padding-inline: ${spacings.medium};
  }

  .MuiAvatar-root {
    width: ${iconSizes.xlarge};
    height: ${iconSizes.xlarge};
    background: ${Colors.gray};
  }
`;

const TopSection = styled(Box)`
  display: flex;
  height: 80px;
  align-items: center;
  margin: ${spacings.medium};
  margin-bottom: ${spacings.xlarge};
`;

const StyledNavbarMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;

  & .MuiSvgIcon-root {
    font-size: 2.2rem;
  }

  & .MuiBox-root {
    border-end-end-radius: ${radii.full};
    border-start-end-radius: ${radii.full};
  }

  svg {
    color: ${Colors.white};
  }

  & > .active {
    background: ${Colors.white};

    & svg {
      color: ${Colors.mineShaft};
    }
  }
`;

const NavbarMenu: FC<{
  menuItems: {
    icon: ReactElement;
    title: string;
    path: string;
  }[];
}> = ({ menuItems }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <StyledNavbarMenu>
      {menuItems.map(({ icon, path, title }) => {
        return (
          <TooltipWrapper
            key={`${path} - ${title}`}
            title={title}
            arrow
            placement="top"
          >
            <Box
              className={`menu-button ${
                pathname.startsWith(path) ? 'active' : ''
              }`}
            >
              <Button bgColor="transparent" onClick={() => navigate(path)}>
                {icon}
              </Button>
            </Box>
          </TooltipWrapper>
        );
      })}
    </StyledNavbarMenu>
  );
};

const Navbar: FC<NavbarProps> = ({ logo, menuItems, sx }) => {
  return (
    <StyledNavbar sx={sx}>
      <Box>
        <TopSection>{logo}</TopSection>
        <NavbarMenu menuItems={menuItems} />
      </Box>
    </StyledNavbar>
  );
};

export default Navbar;
