import { FC, forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAutocomplete,
  UseAutocompleteProps
} from '@mui/base/useAutocomplete';
import { Popper } from '@mui/base/Popper';
import { styled } from '@mui/material';
import useForkRef from '@mui/utils/useForkRef';

import { Colors } from 'src/components/styles/colors';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import InputField, { InputFieldProps } from '../InputField';
import {
  iconSizes,
  radii,
  shadows,
  spacings,
  zIndices
} from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';

interface InfiniteAutocompleteProps<T>
  extends UseAutocompleteProps<T, boolean, boolean, boolean> {
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  renderOption?: (option: T, index: number) => ReactNode;
  freeListWidth?: boolean;
  InputProps?: InputFieldProps;
}

const Root = styled('div')`
  display: flex;
  overflow: hidden;
`;

const StyledPopper = styled('div')`
  position: relative;
  z-index: ${zIndices.highest};
  box-shadow: ${shadows.default};
  background: ${Colors.white};
  border-radius: ${radii.large};
`;

const StyledUnorderedList = styled('ul')`
  margin: 0;
  padding: 0;
  text-wrap: wrap;
  overflow: auto;
  max-height: 300px;
  border-radius: ${radii.large};
`;

const StylesListItem = styled('li')`
  cursor: pointer;
  padding: ${spacings.small} ${spacings.medium};
  &:hover {
    background: ${Colors.cupidAlpha50};
  }
`;

const ListLoader: FC = () => (
  <Flex justifyContent="center" padding={spacings.medium}>
    <Loader size={iconSizes.small} colorBase={Colors.emperor} />
  </Flex>
);

const PopperMessage: FC = ({ children }) => (
  <Box padding={spacings.medium}>
    <Typography color={Colors.alto}>{children}</Typography>
  </Box>
);

function InfiniteAutocomplete<T>(props: InfiniteAutocompleteProps<T>) {
  return <InfiniteAutocompleteComponent {...props} />;
}

const InfiniteAutocompleteComponent = forwardRef(function Autocomplete<T>(
  props: InfiniteAutocompleteProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    hasNextPage: _hasNextPage,
    fetchNextPage: _fetchNextPage,
    isLoading: _isLoading,
    renderOption: _renderOption,
    isFetchingNextPage,
    freeListWidth: freeListWidth = false,
    InputProps,
    ...rest
  } = props;

  const {
    getRootProps,
    getInputProps,
    getOptionProps,
    groupedOptions,
    getListboxProps,
    focused,
    popupOpen,
    anchorEl,
    setAnchorEl
  } = useAutocomplete({
    ...rest,
    // this is here so that filtering is done in the server instead of the component
    // source - https://mui.com/material-ui/react-autocomplete/#search-as-you-type
    filterOptions: (x) => x
  });
  const { t } = useTranslation();
  const rootRef = useForkRef(ref, setAnchorEl);

  return (
    <>
      <Root
        {...getRootProps()}
        ref={rootRef}
        className={focused ? 'Mui-focused' : ''}
      >
        <InputField ref={ref} inputProps={getInputProps()} {...InputProps} />
      </Root>
      {anchorEl && (
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          slots={{
            root: StyledPopper
          }}
          placement="bottom-start"
          style={
            !freeListWidth && {
              width: anchorEl ? anchorEl.clientWidth : null
            }
          }
        >
          {props.isLoading ? (
            <ListLoader />
          ) : !isFetchingNextPage && groupedOptions.length === 0 ? (
            <PopperMessage>{t('NO_OPTIONS')}</PopperMessage>
          ) : (
            <StyledUnorderedList {...getListboxProps()} id="scrollableDiv">
              <InfiniteScroll
                dataLength={groupedOptions.length}
                hasMore={props.hasNextPage}
                next={props.fetchNextPage}
                loader={<ListLoader />}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <PopperMessage>{t('END_OF_RESULTS')}</PopperMessage>
                }
                scrollThreshold={0.9}
              >
                {groupedOptions.map((option, index) => (
                  <StylesListItem
                    {...getOptionProps({ option, index })}
                    key={index}
                  >
                    {props?.renderOption ? (
                      props.renderOption(option, index)
                    ) : (
                      <Typography>{props.getOptionLabel(option)}</Typography>
                    )}
                  </StylesListItem>
                ))}
              </InfiniteScroll>
            </StyledUnorderedList>
          )}
        </Popper>
      )}
    </>
  );
});

export default InfiniteAutocomplete;
