import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';

export enum StaffMemberRoles {
  SYSTEM_ADMIN = 'systemAdmin',
  OPERATIONS = 'operations',
  FINANCE = 'finance',
  PRACTITIONER = 'practitioner',
  ADMIN_COORDINATION = 'adminCoordinator',
  EMBRYOLOGIST = 'embryology',
  CONUSELOR_THERAPIST = 'counselorTherapist'
}

export enum StaffMemberPermissions {
  SYSTEM_ADMIN = 'systemAdmin',
  BILLING = 'billing',
  ADD_NEW_PATIENT = 'addNewPatient',
  CLINICAL_CARE = 'clinicalCare',
  COORDINATION = 'coordination',
  ACTIVITY_FEED = 'activityFeed'
}
export interface CareTeamMember {
  staffId: string;
  isPrimary: boolean;
}

export interface StaffMember {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  authId?: string;
  role?: StaffMemberPermissions;
  image?: string;
  jobTitle: string;
  address?: string;
  dateOfBirth?: Date;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  licenseNumber?: string;
  displayName?: string;
  clinicId: string;
  isActive?: boolean;
  pictureId?: string;
  permissions?: string[];
  npi?: string;
}

export interface StaffMemberFormValues {
  firstName: string;
  lastName: string;
  role?: StaffMemberPermissions;
  email: string;
  clinicId: string;
  jobTitle?: string;
  address?: string;
  phone?: string;
  dateOfBirth?: Date;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  licenseNumber?: string;
  displayName?: string;
  connectedToCareTeam?: boolean;
  pictureId?: ImageFile[];
  isActive?: boolean;
  permissions: string[];
  npi?: string;
}
