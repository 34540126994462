import { Colors } from 'src/components/styles/colors';

export interface AddDocumentFormValues {
  documentType?: DocumentTypes;
  templateName?: string;
  templateId?: string;
  patientName?: string;
  fileName?: string;
  file?: File;
}

export enum DocumentTypes {
  ORDERS = 'Orders',
  GENERAL = 'General',
  CONSENT = 'Consent',
  RECORD = 'Record'
}

export interface TemplateFile {
  file?: File;
  type?: DocumentTypes;
  name?: string;
  defaultAnnotations?: string;
}

export interface TemplateFileServer {
  file?: File;
  type?: DocumentTypes;
  name?: string;
  defaultAnnotations?: string;
}

export interface CreatePatientDocumentPayload {
  file: File;
  templateId?: string;
  patientId: string;
  annotations?: string;
  metadata?: string;
  isCompleted?: boolean;
  documentType?: DocumentTypes;
  externalId?: string;
}

export interface CreatePatientDocumentPayloadServer {
  file: File;
  templateId: number;
  patientId: string;
  annotations?: string;
  metadata?: string;
  id?: number;
  isCompleted?: boolean;
}

export interface Document {
  key?: string;
  type?: DocumentTypes;
  name?: string;
  url?: string;
  id?: string;
  requiresSignature?: boolean;
  defaultAnnotations?: string;
}

export interface DocumentServer {
  key?: string;
  type?: DocumentTypes;
  name?: string;
  url?: string;
  id?: string;
  defaultAnnotations?: string;
}

export interface PatientDocument {
  createdAt?: Date;
  createdByStaff?: string;
  id?: string;
  key?: string;
  name?: string;
  patientId?: string;
  templateId?: string;
  updatedAt?: Date;
  url?: string;
  annotations?: string;
  metadata?: string;
  isCompleted?: boolean;
  documentType?: DocumentTypes;
  externalId?: string;
  isInitialVendorEmailSent?: boolean;
}

export interface PatientDocumentServer {
  createdAt?: Date;
  createdByStaff?: number;
  id?: string;
  key?: string;
  patientId?: string;
  templateId?: number;
  updatedAt?: Date;
  url?: string;
  annotations?: string;
  metadata?: string;
  isCompleted?: boolean;
  externalId?: string;
  isInitialVendorEmailSent?: boolean;
}

export const orderTemplateId = '20';
export const proceduresTemplateId = '21';

export enum annotationsSubjects {
  signature = 'Signature'
}

export interface TemplateImageProps {
  image_url: string;
  width: number;
  height: number;
}

export enum DocumentStatus {
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  PENDING = 'PENDING'
}

export interface DocumentSignatureStatus {
  status: DocumentStatus;
  color: Colors;
}

export interface DocumentEditorParams {
  patientId?: string;
  templateId?: string;
  cycleId?: string;
  documentId?: string;
  orderId?: string;
  labOrdersId?: string[];
  isForceSave?: boolean;
  isDocumentClosable?: boolean;
}

export enum DocumentExtension {
  PNG = 'png',
  PDF = 'pdf',
  DOCX = 'docx',
  DOC = 'doc',
  JPG = 'jpg',
  JPEG = 'jpeg'
}

export enum UpperCaseDocumentExtension {
  PNG = 'PNG',
  PDF = 'PDF',
  DOCX = 'DOCX',
  DOC = 'DOC',
  JPG = 'JPG',
  JPEG = 'JPEG',
  TXT = 'TXT'
}
