import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from '../components/components-api/ToastProvider';
import { AppError } from '../types/global';
import {
  AddProgressNoteForm,
  FeedActivity,
  FlagActivityRequest
} from '../types/feed';
import {
  createProgressNoteRequest,
  flagActivityRequest,
  getActivityByIdRequest,
  getFeedActivitiesByClinicPaginatedRequest,
  getFeedActivitiesPaginatedRequest
} from '../api/feed.api';
import { queryKeys, querySubKeys } from './queryKeys';

import { QueryParams, PaginatedData } from '../types/pagination';
import { GridFilterModel } from '@mui/x-data-grid-premium';

function useFeed() {
  const { handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    getFeedActivities: ({
      patientId,
      byClinic,
      filters,
      params,
      options
    }: {
      patientId?: string;
      byClinic?: boolean;
      filters?: GridFilterModel;
      params?: QueryParams;
      options?: UseQueryOptions<PaginatedData<FeedActivity>, AppError>;
    }) =>
      useQuery<PaginatedData<FeedActivity>, AppError>(
        [
          queryKeys.FEED,
          querySubKeys[queryKeys.FEED].ACTIVITIES,
          { patientId, byClinic, ...params.pagination, ...filters }
        ],
        () => {
          if (byClinic) {
            return getFeedActivitiesByClinicPaginatedRequest(params, filters);
          } else {
            return getFeedActivitiesPaginatedRequest(
              params,
              patientId,
              filters
            );
          }
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_FEED_ACTIVITIES')
            }),
          ...options
        }
      ),
    getActivityById: (
      activityId?: string,
      options?: UseQueryOptions<FeedActivity, AppError>
    ) =>
      useQuery<FeedActivity, AppError>(
        [queryKeys.FEED, querySubKeys[queryKeys.FEED].ACTIVITIES, activityId],
        () => getActivityByIdRequest(activityId),
        {
          ...options
        }
      ),
    createProgressNote: () =>
      useMutation<FeedActivity, AppError, AddProgressNoteForm>(
        (note: AddProgressNoteForm) => createProgressNoteRequest(note),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_PROGRESS_NOTE')
            }),
          onSuccess: () => queryClient.invalidateQueries([queryKeys.FEED])
        }
      ),
    flagActivity: () =>
      useMutation<number, AppError, FlagActivityRequest>(
        (data: FlagActivityRequest) => flagActivityRequest(data),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_ACTIVITY_FLAG')
            }),
          onSuccess: (_, { activityId }) => {
            queryClient.invalidateQueries([queryKeys.FEED]);
            queryClient.invalidateQueries([
              queryKeys.FEED,
              querySubKeys[queryKeys.FEED].ACTIVITIES,
              activityId
            ]);
          }
        }
      )
  };
}

export default useFeed;
