import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import { LabResult } from 'src/types/appointment';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import dayjs from 'dayjs';
import useLabResults from 'src/hooks/useLabResults';
import ImageUpload from 'src/components/data-entry/ImageUpload';
import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import Loader from 'src/components/display/Loader';

export interface LabResultForm {
  id?: string;
  cycleId?: string;
  patientName: string;
  patientId: string;
  date: Date;
  testId: string;
  value: string;
  measurementType: string;
  comment: string;
  externalLabImage?: ImageFile[];
  deleteImage?: boolean;
}

interface PatientLabResultDialogProps {
  patientId: string;
  labResult?: LabResult;
}

const EditPatientLabResult: FC<PatientLabResultDialogProps> = ({
  patientId,
  labResult
}) => {
  const { getLabTests, getPatientById } = usePatientsApi();
  const { createLabResult, updateLabResult } = useLabResults();
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const isEdit = !!labResult;

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const { data: labTests, isLoading: isLoadingLabTest } = getLabTests();

  const { mutate: handleCreateLabResult } = createLabResult();
  const { mutate: handleUpdateLabResult } = updateLabResult();

  const defaultValues: LabResultForm = {
    patientName: '',
    patientId,
    date: dayjs(labResult?.date).toDate() || dayjs().toDate(),
    testId: labResult?.testId || null,
    value: labResult?.value || '',
    measurementType: labResult?.measurementType || '',
    comment: labResult?.comment || '',
    externalLabImage: labResult?.externalLabImage
      ? [labResult.externalLabImage]
      : [],
    deleteImage: false
  };

  const { control, formState, handleSubmit, setValue } = useForm<LabResultForm>(
    {
      mode: 'onChange',
      defaultValues
    }
  );

  const { errors } = formState;

  useEffect(() => {
    if (patient) {
      setValue('patientName', getFullName(patient.personalInfo));
    }
  }, [patient]);

  const onSubmit = async (details: LabResultForm) => {
    if (isEdit) {
      await handleUpdateLabResult(
        { id: labResult.id, ...details },
        {
          onSuccess: () => closeDialog()
        }
      );
    } else {
      await handleCreateLabResult(details, {
        onSuccess: () => closeDialog()
      });
    }
  };

  const isLoading = isLoadingPatient || isLoadingLabTest;

  if (isLoading) return <Loader />;

  return (
    <Box marginTop={spacings.xlarge} maxWidth={550}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" gap={spacings.xxlarge}>
          <Box>
            <Controller
              name="patientName"
              control={control}
              rules={{
                required: t('PATIENT_NAME_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  disabled
                  label={t('PATIENT_NAME_LABEL')}
                  placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="date"
              control={control}
              rules={{
                required: t('DATE_TAKEN_REQUIRED'),
                validate: (value) =>
                  dayjs(value).isBefore(dayjs())
                    ? true
                    : t('DATE_MUST_NOT_BE_IN_PAST')
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('DATE_TAKEN_LABEL')}
                  error={!!errors?.date}
                  helperText={errors?.date?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={4}>
            <Controller
              name="testId"
              control={control}
              rules={{
                required: t('TEST_NAME_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('TEST_NAME_LABEL')}
                  error={!!errors?.testId}
                  disabled={isEdit}
                  helperText={errors?.testId?.message}
                  defaultOption={t('PLEASE_CHOOSE_TEST_NAME')}
                  options={
                    labTests?.map(({ id, name }) => ({
                      label: t(name),
                      value: id
                    })) || []
                  }
                />
              )}
            />
          </Box>
          <Flex gap={spacings.large}>
            <Box>
              <Controller
                name="value"
                control={control}
                rules={{
                  required: t('TEST_RESULT_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    placeholder={t('RESULTS')}
                    label={t('TEST_RESULT_LABEL')}
                    error={!!errors?.value}
                    helperText={errors?.value?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="measurementType"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('MEASUREMENT_TYPE_LABEL')}
                    placeholder={t('MEASUREMENT_TYPE')}
                    error={!!errors?.measurementType}
                    helperText={errors?.measurementType?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Flex>
          <Box marginBottom={spacings.large}>
            <Controller
              name="externalLabImage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ImageUpload
                  label={t('EXTERNAL_LAB_RESULT')}
                  limit={1}
                  value={value}
                  accept={{
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpeg', '.jpg'],
                    pdf: ['.pdf']
                  }}
                  onAddNewFiles={(newFiles) => {
                    onChange([...newFiles]);
                  }}
                  onRemoveFile={(imageIndexToRemove) => {
                    onChange(
                      value.filter((_, index) => index !== imageIndexToRemove)
                    );

                    setValue('deleteImage', true);
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="comment"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('LAB_RESULT_COMMENT_LABEL')}
                  error={!!errors?.comment}
                  helperText={errors?.comment?.message}
                  fullWidth
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          justifyContent="center"
          mt={spacings.xxlarge}
          paddingX={spacings.xxlarge}
        >
          <Button fullWidth type="submit">
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EditPatientLabResult;
