import useClinicsApi from './useClinicsApi';
import { fillOrdersTemplateFields } from '../modules/documents/getTemplateFieldsData';
import useCareTeam from './useCareTeam';
import usePatientsApi from './usePatientsApi';
import useMeApi from './useMeApi';
import {
  Appointment,
  requiredAppointmentOrderKeys
} from 'src/types/appointment';
import { AppointmentWithOrderDocumentPayload } from 'src/types/cycle';
import { orderTemplateId } from 'src/types/documents';
import useDocumentsApi from './useDocumentsApi';

interface UseGetPatientCycleOrdersDocumentMeta {
  appointmentsWithOrderDocumentPayload: AppointmentWithOrderDocumentPayload[];
  isLoading: boolean;
}

function useCycleAppointmentsOrders({
  patientId,
  isEnabled = true,
  appointments
}: {
  patientId: string;
  isEnabled?: boolean;
  appointments: Appointment[];
}): UseGetPatientCycleOrdersDocumentMeta {
  const { getPatientById, getPanels } = usePatientsApi();
  const { getTemplateById } = useDocumentsApi();
  const { getPatientPrimaryCareTeamMember } = useCareTeam();
  const { getMe } = useMeApi();
  const { getClinicInfo } = useClinicsApi();

  const { data: orderTemplate, isLoading: isLoadingOrderTemplate } =
    getTemplateById(orderTemplateId, { enabled: isEnabled });

  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    patientId,
    { enabled: isEnabled }
  );

  const {
    data: patientPrimaryCareTeamMember,
    isLoading: isLoadingPrimaryCareTeamMember
  } = getPatientPrimaryCareTeamMember(patientId);

  const clinicId = patient?.clinicId || patientPrimaryCareTeamMember?.clinicId;

  const { data: clinicInfo, isLoading: isLoadingClinic } = getClinicInfo(
    clinicId,
    {
      enabled: !!clinicId && isEnabled
    }
  );

  const { data: loggedStaffMember, isLoading: isLoadingMe } = getMe();

  const { data: panels, isLoading: isLoadingPanels } = getPanels({
    enabled: isEnabled
  });

  const isLoading =
    isLoadingPatient ||
    isLoadingMe ||
    isLoadingPanels ||
    isLoadingPrimaryCareTeamMember ||
    isLoadingClinic ||
    isLoadingOrderTemplate;

  if (isEnabled) {
    const labOrderInfo =
      appointments?.find(
        (appointment) =>
          appointment.labOrderInfo &&
          requiredAppointmentOrderKeys.every(
            (key) => key in appointment.labOrderInfo
          )
      )?.labOrderInfo || {};

    const appointmentsWithOrderDocumentPayload = appointments?.map(
      (appointment) => {
        const templateMetadata = fillOrdersTemplateFields({
          patient,
          clinic: clinicInfo,
          appointment,
          panels,
          patientPrimaryCareTeamMember,
          loggedStaffMember
        });

        return {
          ...appointment,
          metadata: JSON.stringify(templateMetadata),
          isCompleted: false,
          templateKey: orderTemplate?.key,
          patientId,
          labOrderInfo
        };
      }
    );

    return {
      appointmentsWithOrderDocumentPayload,
      isLoading
    };
  }
}

export default useCycleAppointmentsOrders;
