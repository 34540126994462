import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import { iconSizes, spacings } from 'src/components/styles/constants';

export const YourDocumentIsSavingLoader: FC = () => {
  const { t } = useTranslation();

  return (
    <Card display="flex" alignItems="center" gap={spacings.medium}>
      <Loader size={iconSizes.large} />
      <Typography variant="h2" color={Colors.emperor}>
        {t('YOUR_DOCUMENT_IS_SAVING')}
      </Typography>
    </Card>
  );
};
