import { FC } from 'react';
import { css, styled } from '@mui/material';
import Button from '../Button/Button';
import { ButtonProps } from '../Button/Button';
import { iconSizes, radii, spacings } from '../../styles/constants';
import { makeShouldForwardProps } from 'src/components/utils';
import { Colors } from 'src/components/styles/colors';

export interface IconButtonProps
  extends Omit<ButtonProps, 'endIcon' | 'startIcon'> {
  iconSize?: keyof typeof iconSizes;
  icon?: React.ReactElement;
  iconColor?: keyof typeof Colors;
}

const shouldForwardProp = makeShouldForwardProps(['iconSize', 'iconColor']);
const StyledButton = styled(Button, { shouldForwardProp })<IconButtonProps>`
  && {
    padding: ${spacings.small};
    border-radius: ${radii.full};
    min-width: 1px;
    ${({ iconColor }) =>
      iconColor &&
      css`
        color: ${Colors[iconColor]};
      `};
    .MuiButton-startIcon {
      margin: 0;
      > *:nth-of-type(1) {
        font-size: ${({ iconSize }) => iconSizes[iconSize]};
      }
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const IconButton: FC<IconButtonProps> = ({
  iconSize = 'medium',
  icon,
  iconColor,
  ...otherProps
}) => {
  return (
    <StyledButton
      alertOverlap="circular"
      iconSize={iconSize}
      iconColor={iconColor}
      startIcon={icon}
      {...otherProps}
    />
  );
};

export default IconButton;
