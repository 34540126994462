import { FC } from 'react';
import i18n from 'src/i18n/i18n';
import { CyclePhases, GraphDataPhase } from 'src/types/cycle';
import { TimelinePhase } from './TimelinePhase';

interface GraphPhasesTimelineProps {
  phases: GraphDataPhase[];
  chartDayColumnWidth: number;
  chartStartingXPosition: number;
  yAxisWidth: number;
  maxDayIndex: number;
}

const getPhaseLabel = (phase: GraphDataPhase): string => {
  switch (phase.name) {
    case CyclePhases.AGONIST:
      return i18n.t('AGONIST');
    case CyclePhases.DOWN_REGULATION:
      return i18n.t('DOWN_REGULATION');
    case CyclePhases.EGGS_COLLECTED:
      return `${phase.eggsCollected || 0} ${i18n.t('EGGS_COLLECTED')}`;
    case CyclePhases.LUTEAL_SUPPORT:
      return i18n.t('LUTEAL_SUPPORT');
    case CyclePhases.STIMULATION:
      return i18n.t('STIMULATION');
    case CyclePhases.TRIGGER:
      return i18n.t('TRIGGER');
    case CyclePhases.EMBRYO_TRANSFER:
      return `${phase.embryosTransferred || 0} ${i18n.t(
        'EMBRYOS_TRANSFERRED'
      )}`;
    case CyclePhases.OUTCOME_NEGATIVE:
      return i18n.t('BETA -');
    case CyclePhases.OUTCOME_POSITIVE:
      return i18n.t('BETA +');
    case CyclePhases.IUI:
      return i18n.t('IUI');
    case CyclePhases.THAW:
      return i18n.t('EMBRYO THAW REPORT');
    default:
      return phase.name;
  }
};

const getPhaseEndIndex = (phase: GraphDataPhase): number => {
  const shortPhases = [
    CyclePhases.TRIGGER,
    CyclePhases.OUTCOME_NEGATIVE,
    CyclePhases.OUTCOME_POSITIVE
  ];
  if (shortPhases.includes(phase.name)) {
    return phase.startDateIndex + 1;
  } else {
    return phase.endDateIndex ?? phase.startDateIndex + 5;
  }
};

export const GraphPhasesTimeline: FC<GraphPhasesTimelineProps> = ({
  phases,
  chartStartingXPosition,
  chartDayColumnWidth,
  yAxisWidth,
  maxDayIndex
}) => {
  let currentLayer = 0;
  return (
    <>
      {phases?.map((phase, index) => {
        const { name, startDateIndex } = phase;
        let endDateToDisplayIndex = getPhaseEndIndex(phase);

        if (endDateToDisplayIndex > maxDayIndex) {
          endDateToDisplayIndex = maxDayIndex;
        }

        if (index > 0) {
          const prevPhase = phases[index - 1];
          const prevPhaseEndDateToCalc =
            prevPhase.endDateIndex || prevPhase.startDateIndex + 5;

          if (startDateIndex < prevPhaseEndDateToCalc + 1) {
            currentLayer += 1;
          }
        }

        const daysCount = endDateToDisplayIndex - startDateIndex + 1;
        const phaseXPostion =
          startDateIndex * chartDayColumnWidth +
          chartStartingXPosition +
          yAxisWidth +
          chartDayColumnWidth / 6;
        const phaseYPosition = 10 + currentLayer * 35;
        const phaseWidth =
          daysCount * chartDayColumnWidth - chartDayColumnWidth / 3;

        return (
          <TimelinePhase
            key={`${name}-${index}`}
            phase={phase}
            x={phaseXPostion}
            y={phaseYPosition}
            width={phaseWidth}
            label={getPhaseLabel(phase)}
          />
        );
      })}
    </>
  );
};
