export const makeShouldForwardProps =
  (propsToSkip: PropertyKey[]) => (propName: PropertyKey) =>
    !propsToSkip.includes(propName);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Debounce = <T extends any[]>(
  fn: (...args: T) => void,
  ms?: number
) => (...args: T) => ReturnType<typeof setTimeout>;
export const debounce: Debounce = (fn, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);

    return timeoutId;
  };
};

export function getKeyByValue<
  E extends { [key: string]: V },
  V extends string | number
>(enumObject: E, valueToFind: V): keyof E | '' {
  return Object.entries(enumObject).find(
    ([_, value]) => value === valueToFind
  )?.[0] as keyof E | '';
}

export const downloadBlobData = (data: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(data);

  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(url);
};
