import { styled } from '@mui/material';
import React, { FC } from 'react';

import Flex, { FlexProps } from 'src/components/layout/Flex';

const StyledFlex = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Center: FC<FlexProps> = ({ children, ...otherProps }) => {
  return <StyledFlex {...otherProps}>{children}</StyledFlex>;
};

export default Center;
