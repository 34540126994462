import { useCallback, useLayoutEffect, useState } from 'react';

export const useRectMeasure = <T extends HTMLElement>() => {
  const [rect, setRect] = useState<T>();

  const [node, setNode] = useState<T>(null);

  const callbackRef = useCallback((node: T) => {
    if (node !== null) {
      setNode(node);
      setRect(node);
    }
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        setRect(node);
      };

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, [node]);

  return { rect, callbackRef };
};
