import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/components-api/ToastProvider';
import { convertStringIdToNumber } from 'src/utils/general';
import { MedicalQuestion, RecursiveMedicalAnswer } from 'src/types/qna';
import {
  deleteAnswerForQuestionRequest,
  getAllMedicalQuestionsRequest,
  getPatientNextQuestionByCategoryId,
  postAnswerForQuestion
} from '../api/qna.api';
import { AppError, ServerReturnPayload } from 'src/types/global';
import { queryKeys, querySubKeys } from './queryKeys';

function useQnaApi() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleQueryResultToast } = useToast();
  return {
    getPatientNextQuestionByCategoryId: ({
      patientId,
      categoryId,
      questionId,
      options
    }: {
      patientId: string;
      categoryId: number;
      questionId?: number;
      options?: UseQueryOptions<MedicalQuestion, AppError>;
    }) =>
      useQuery<MedicalQuestion, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].QUESTIONS,
          questionId,
          querySubKeys[queryKeys.PATIENTS].CATEGORY,
          categoryId
        ],
        () =>
          getPatientNextQuestionByCategoryId(
            patientId,
            convertStringIdToNumber(`${categoryId}`),
            questionId !== undefined
              ? convertStringIdToNumber(`${questionId}`)
              : null
          ),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_QUESTIONS_BY_CATEGORY_ID')
            }),

          ...options
        }
      ),
    postAnswerForQuestion: () =>
      useMutation<
        ServerReturnPayload,
        AppError,
        {
          patientId: string;
          primaryPatientId?: string;
          questionId: number;
          answer: RecursiveMedicalAnswer;
        }
      >(
        ({ patientId, questionId, answer }) =>
          postAnswerForQuestion(patientId, questionId, answer),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_POST_ANSWER_FOR_QUESTION')
            }),

          onSuccess: (_, { patientId, primaryPatientId, questionId }) => {
            return Promise.all([
              queryClient.invalidateQueries([
                queryKeys.PATIENTS,
                primaryPatientId || patientId
              ]),
              queryClient.invalidateQueries([
                [
                  queryKeys.PATIENTS,
                  patientId,
                  querySubKeys[queryKeys.PATIENTS].QUESTIONS,
                  questionId
                ]
              ])
            ]);
          }
        }
      ),
    getAllMedicalQuestions: (
      patientId: string,
      options?: UseQueryOptions<Record<string, MedicalQuestion[]>, AppError>
    ) =>
      useQuery<Record<string, MedicalQuestion[]>, AppError>(
        [queryKeys.MEDICAL_QUESTIONS, patientId],
        () => getAllMedicalQuestionsRequest(patientId),
        {
          enabled: !!patientId,
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_MEDICAL_QUESTIONS')
            }),

          ...options
        }
      ),
    deleteAnswerForQuestion: () =>
      useMutation<
        ServerReturnPayload,
        AppError,
        {
          patientId: string;
          questionId: number;
          primaryPatientId?: string;
          answerKey?: string;
        }
      >(
        ({ patientId, questionId, answerKey }) =>
          deleteAnswerForQuestionRequest({
            patientId,
            questionId,
            answerKey
          }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_DELETE_ANSWER_FOR_QUESTION')
            }),

          onSuccess: (_, { patientId, primaryPatientId, questionId }) => {
            return Promise.all([
              queryClient.invalidateQueries([
                queryKeys.PATIENTS,
                primaryPatientId || patientId
              ]),
              queryClient.invalidateQueries([
                [
                  queryKeys.PATIENTS,
                  patientId,
                  querySubKeys[queryKeys.PATIENTS].QUESTIONS,
                  questionId
                ]
              ])
            ]);
          }
        }
      )
  };
}

export default useQnaApi;
