import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePatientsApi from '../../../hooks/usePatientsApi';
import dayjs from 'dayjs';
import {
  Catheter,
  Difficulty,
  ExamReason,
  ExamReport,
  OtherExaminationTypes,
  PositionOfUterus,
  TranferGeneralRemarks
} from 'src/types/exam';
import { Controller, useForm } from 'react-hook-form';
import { getFullName } from 'src/utils/general';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Flex from 'src/components/layout/Flex/Flex';
import Select from 'src/components/data-entry/Select/Select';
import { DoctorChips } from '../common/DoctorChips';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import Button from 'src/components/display/Button/Button';
import TextArea from 'src/components/data-entry/TextArea';
import useExams from 'src/hooks/useExams';
import Loader from 'src/components/display/Loader';

export const AddEditIUIReportForm: FC<{
  patientId: string;
  cycleId?: string;
  examId?: string;
}> = ({ cycleId, patientId, examId }) => {
  const isEdit = !!examId;
  const { getPatientById, getMedications } = usePatientsApi();
  const { createExamReport, updateExamReport, getExaminationById } = useExams();
  const { t } = useTranslation();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: examination } = getExaminationById(examId, {
    enabled: !!examId
  });

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: ExamReport = {
    patientId: patientId,
    cycleId: cycleId,
    type: OtherExaminationTypes.IUI,
    reason: ExamReason.IN_CYCLE,
    patientName: '',
    dateOfExamination: dayjs().toDate(),
    catheter: null,
    easeOfIUI: null,
    positionOfUterus: null,
    medicationsAdministered: [],
    requestingPhysicianId: '',
    performedById: '',
    witnessedBy: '',
    cervixQuality: '',
    generalRemarks: [],
    notes: ''
  };

  const { data: medications, isLoading: isLoadingMedications } =
    getMedications();

  const { control, formState, handleSubmit, reset } = useForm<any>({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (examination || patient) {
      reset({
        id: examination?.id,
        patientName: getFullName(patient?.personalInfo),
        patientId: examination?.patientId || patientId,
        cycleId: examination?.cycleId || cycleId,
        type: OtherExaminationTypes.IUI,
        reason: examination?.reason || ExamReason.IN_CYCLE,
        dateOfExamination: dayjs(examination?.examDate).toDate(),
        catheter: examination?.catheter || null,
        easeOfIUI: examination?.easeOfIUI || null,
        positionOfUterus: examination?.positionOfUterus || null,
        medicationsAdministered:
          examination?.medicationsAdministered?.map(
            ({ medicationId }) => medicationId
          ) || [],
        requestingPhysicianId: examination?.requestedPhysician,
        performedById: examination?.performedBy,
        witnessedBy: examination?.witnessedBy,
        cervixQuality: examination?.cervixQuality,
        generalRemarks: examination?.generalRemarks || [],
        notes: examination?.notes || ''
      });
    }
  }, [examination, patient]);

  const { errors } = formState;

  const onSubmit = async (details: ExamReport) => {
    if (isEdit) {
      await updateReport(details);
    } else {
      await createReport(details);
    }
  };

  if (isLoadingMedications) {
    return <Loader />;
  }

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1} marginBottom={spacings.large}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  disabled
                  label={t('PATIENT_NAME')}
                  placeholder={t('PATIENT_NAME')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Flex gap={spacings.large} marginBottom={spacings.medium}>
              <Box flex={1} marginBottom={spacings.large}>
                <Controller
                  name="requestingPhysicianId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-iui-report-requesting-physician"
                      showSelectedValue
                      label={t('REQUESTING_PROVIDER')}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="performedById"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-iui-report-performed-by"
                      showSelectedValue
                      label={t('PERFORMED_BY')}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="witnessedBy"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-iui-report-witnessed-by"
                      showSelectedValue
                      label={t('WITNESSED_BY')}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Box marginBottom={spacings.large}>
              <Controller
                name="dateOfExamination"
                control={control}
                rules={{
                  required: t('DATE_REQUIRED'),
                  validate: (value) => {
                    const date = dayjs(value);
                    if (date.isAfter(dayjs())) {
                      return t('DATE_CANT_BE_IN_THE_FUTURE');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_PROCEDURE')}
                    inputRef={ref}
                    error={!!errors?.dateOfExamination}
                    helperText={errors.dateOfExamination?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Controller
                name="positionOfUterus"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('POSITION_OF_UTERUS')}
                    inputRef={ref}
                    error={!!errors?.positionOfUterus}
                    helperText={errors?.positionOfUterus?.message}
                    defaultOption={t('POSITION_OF_UTERUS')}
                    options={Object.keys(PositionOfUterus).map((position) => ({
                      label: t(position),
                      value: position
                    }))}
                  />
                )}
              />
              <Controller
                name="catheter"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('CATHETER')}
                    inputRef={ref}
                    error={!!errors?.catheter}
                    helperText={errors?.catheter?.message}
                    defaultOption={t('CATHETER')}
                    options={Object.keys(Catheter).map((catheter) => ({
                      label: t(catheter),
                      value: catheter
                    }))}
                  />
                )}
              />
            </Flex>
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Controller
                name="cervixQuality"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('CERVIX_QUALITY')}
                    placeholder={t('CERVIX_QUALITY')}
                    error={!!errors.cervixQuality}
                    helperText={errors?.cervixQuality?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="easeOfIUI"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('EASE_OF_IUI')}
                    inputRef={ref}
                    error={!!errors?.easeOfIUI}
                    helperText={errors?.easeOfIUI?.message}
                    defaultOption={t('EASE_OF_IUI')}
                    options={Object.keys(Difficulty).map((option) => ({
                      label: t(option),
                      value: option
                    }))}
                  />
                )}
              />
            </Flex>
          </Box>
          <Box flex={1}>
            <Controller
              name="generalRemarks"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  variant={ChipsVariants.EXPANDED}
                  chipsInputSx={{ height: '100%' }}
                  value={value}
                  allowAddingNewOptions
                  options={Object.keys(TranferGeneralRemarks).map((remark) => ({
                    value: remark,
                    label: t(remark),
                    labelText: t(remark)
                  }))}
                  title={t('GENERAL_REMARKS')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValueToDelete) => {
                    const updatedRemarks = value.filter(
                      (remark) => remark !== chipValueToDelete
                    );
                    onChange(updatedRemarks);
                  }}
                  shouldSortOptions
                />
              )}
            />
          </Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="medicationsAdministered"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Chips
                  chipsInputSx={{ height: '100%' }}
                  variant={ChipsVariants.EXPANDED}
                  options={medications?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                    labelText: name
                  }))}
                  value={value}
                  title={t('MEDICATIONS_ADMINISTERED')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValue) => {
                    const updatedFindings = value.filter(
                      (finding) => finding !== chipValue
                    );
                    onChange(updatedFindings);
                  }}
                  shouldSortOptions
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="notes"
              control={control}
              render={({ field: { ...field } }) => (
                <TextArea
                  {...field}
                  label={t('NOTES')}
                  placeholder={t('ENTER_COMMENT')}
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                  fullWidth
                  minRows={5}
                  maxRows={5}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex justifyContent="center" marginTop={spacings.large} width="20%">
          <Button
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={
              isCreatingReport ||
              isUpdatingReport ||
              isLoadingPatient ||
              isLoadingMedications
            }
            type="button"
          >
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
