import { FC, useMemo } from 'react';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Chips from 'src/components/data-entry/Chips';
import { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import { PreviousCycleObservations } from 'src/types/cycle';
import {
  DiagnosisCategory,
  PatientDiagnosis,
  PrimaryDiagnosis
} from 'src/types/patient';

interface ObservationViewProps {
  control: Control<PatientDiagnosis, any, PatientDiagnosis>;
  getValues: UseFormGetValues<PatientDiagnosis>;
}

const ObservationView: FC<ObservationViewProps> = ({ control, getValues }) => {
  const { t } = useTranslation();

  const getDiagnosisOptions = () => {
    const category = getValues('category');

    switch (category) {
      case DiagnosisCategory.PREVIOUS_CYCLE_OBSERVATIONS:
        return PreviousCycleObservations;
      case DiagnosisCategory.PRIMARY_DIAGNOSIS:
        return PrimaryDiagnosis;
      case DiagnosisCategory.GENERAL_HISTORY:
      case DiagnosisCategory.PREVIOUS_PREGNANCIES:
      default:
        return [];
    }
  };

  // use memo for options
  const options = useMemo(() => {
    return Object.entries(getDiagnosisOptions()).map(([label, value]) => ({
      value: value,
      label: t(label),
      labelText: t(label)
    }));
  }, [t]);

  return (
    <Controller
      name="values"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Chips
          id="patient-diagnosis-values"
          variant={ChipsVariants.EXPANDED}
          value={value}
          allowAddingNewOptions
          options={options}
          title={t('ENTER_OBSERVATION')}
          onAddChip={(chipValue) => onChange([...value, chipValue])}
          onRemoveChip={(chipValue) => {
            onChange(value.filter((order) => order !== chipValue));
          }}
          shouldSortOptions
        />
      )}
    />
  );
};

export default ObservationView;
