import { FC } from 'react';
import Center from 'src/components/layout/Center/Center';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import { RowContainer } from '../details/PatientAboutCard';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import useExams from 'src/hooks/useExams';

export const IUIReportSummary: FC<{ examId: string }> = ({ examId }) => {
  const { t } = useTranslation();
  const { getExaminationById } = useExams();

  const { data: exam, isLoading } = getExaminationById(examId);

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <Box>
      <RowContainer
        title={t('DATE')}
        value={dayjs(exam.examDate).format(getDateFormat()) || '-'}
      />
      <RowContainer
        title={t('POSITION')}
        value={t(exam.positionOfUterus) || '-'}
      />
      <RowContainer title={t('CATHETER')} value={t(exam.catheter) || '-'} />
      <RowContainer title={t('EASE')} value={t(exam.easeOfIUI) || '-'} />
    </Box>
  );
};
