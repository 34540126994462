import { FC } from 'react';
import Typography from 'src/components/display/Typography/Typography';
import Box from 'src/components/layout/Box/Box';
import Image from 'mui-image';
import { styled } from '@mui/material';
import Flex from 'src/components/layout/Flex/Flex';
import AddIcon from '@mui/icons-material/Add';
import IconButton from 'src/components/display/IconButton/IconButton';
import { radii, spacings } from 'src/components/styles/constants';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { fontWeights } from 'src/components/styles/fonts';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import FileUpload from '../FileUpload/FileUpload';
import { Accept } from 'react-dropzone';
import Loader from 'src/components/display/Loader';
import Center from 'src/components/layout/Center';
import { PdfPreview } from 'src/components/display/PdfPreview/PdfPreview';

export interface ImageFile extends File {
  id?: string;
  preview?: string;
  url?: string;
  key?: string;
}

interface ImageUploadProps {
  value: ImageFile[];
  onAddNewFiles: (files: ImageFile[]) => void;
  onRemoveFile: (fileIndex: number) => void;
  limit?: number;
  label?: string;
  accept?: Accept;
  isDirectSelectMode?: boolean;
}

const PdfImagePreview: FC<{ preview: string }> = ({ preview }) => {
  return (
    <PdfPreview
      documentProps={{
        file: preview,
        loading: (
          <Center>
            <Loader />
          </Center>
        )
      }}
      pageProps={{
        pageNumber: 1,
        renderAnnotationLayer: false,
        renderTextLayer: false
      }}
    />
  );
};

const StyledImageContainer = styled(Box)`
  position: relative;
  border-radius: ${radii.medium};
  width: 100%;
  .MuiBadge-root {
    position: absolute;
    top: 5%;
    right: 5%;
  }

  img {
    object-fit: cover;
  }
`;

const Preview: FC<{
  image: ImageFile;
  index: number;
  onRemoveFile: (fileIndex: number) => void;
}> = ({ image, index, onRemoveFile }) => {
  const { openDialog } = useDialog();

  return (
    <StyledImageContainer flex={1}>
      {image?.type?.includes('pdf') ? (
        <Box
          height={150}
          overflow="hidden"
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            openDialog({
              header: image?.name,
              children: (
                <Box padding={spacings.large}>
                  <PdfImagePreview preview={image.preview ?? image?.url} />
                </Box>
              )
            })
          }
        >
          <PdfImagePreview preview={image.preview ?? image?.url} />
        </Box>
      ) : (
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            openDialog({
              header: image?.name,
              children: (
                <Box padding={spacings.large}>
                  <Image src={image?.preview ?? image?.url} />
                </Box>
              )
            })
          }
        >
          <Image height={150} width="100%" src={image.preview ?? image?.url} />
        </Box>
      )}

      <IconButton
        onClick={(ev) => {
          ev.stopPropagation();
          onRemoveFile(index);
        }}
        iconSize="xsmall"
        icon={<RemoveIcon />}
      />
    </StyledImageContainer>
  );
};

const ImageUpload: FC<ImageUploadProps> = ({
  value,
  onAddNewFiles,
  onRemoveFile,
  limit,
  label,
  accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg']
  },
  isDirectSelectMode
}) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog } = useDialog();

  const DirectFileSelection: FC<{ existedFile: boolean }> = ({
    existedFile
  }) => {
    if (!existedFile) {
      return (
        <FileUpload
          hideChosenFile
          submitOnFileUpload
          maxFiles={limit}
          accept={accept}
          onUploadFiles={(newFiles) => {
            onAddNewFiles(
              newFiles.map((file) => {
                const imageFile: ImageFile = file;
                imageFile.preview = URL.createObjectURL(file);

                return imageFile;
              })
            );
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        gap={spacings.medium}
        marginBottom={spacings.medium}
      >
        {!isDirectSelectMode ? (
          <>
            <Typography fontWeight={fontWeights.extraBold}>
              {label || t('IMAGES')}
            </Typography>
            <IconButton
              onClick={() =>
                openDialog({
                  header: t('UPLOAD_FILES'),
                  children: (
                    <FileUpload
                      maxFiles={limit}
                      accept={accept}
                      onUploadFiles={(newFiles) => {
                        onAddNewFiles(
                          newFiles.map((file) => {
                            const imageFile: ImageFile = file;
                            imageFile.preview = URL.createObjectURL(file);

                            return imageFile;
                          })
                        );
                        closeDialog();
                      }}
                    />
                  )
                })
              }
              iconSize="xsmall"
              icon={<AddIcon />}
            />
          </>
        ) : (
          <DirectFileSelection existedFile={!!value?.length} />
        )}
      </Flex>
      {!!value?.length && (
        <Flex gap={spacings.large}>
          {value.map((image: ImageFile, index: number) => (
            <Preview
              key={`${image.name}-${index}`}
              image={image}
              index={index}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default ImageUpload;
