const enQnATranslations = {
  DELETE_MEDICAL_ANSWER: 'Delete medical answer',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_ANSWER:
    'Are you sure you want to delete this answer?',
  DELETE: 'Delete',
  QUESTION: 'Question',
  ANSWER: 'Answer',
  PICK_ANSWER_TO_DELETE: 'Pick answer to delete'
};

export default enQnATranslations;
