import {
  Invoice,
  InvoiceItem,
  InvoiceItemServer,
  InvoiceServer
} from '../../../types/billing';
import {
  convertNumberIdToString,
  convertStringIdToNumber
} from '../../../utils/general';
import { formatDateToString } from '../../patients/utils/conversions';

export const convertInvoiceServerToClient = ({
  invoiceItems,
  patient,
  dueDate,
  paidAt,
  issueDate,
  itemsToDelete: _itemsToDelete,
  ...rest
}: InvoiceServer): Invoice => ({
  invoiceItems: invoiceItems?.map((item) =>
    convertInvoiceItemServerToClient(item)
  ),
  dueDate: dueDate ? new Date(dueDate) : null,
  paidDate: paidAt ? new Date(paidAt) : null,
  issueDate: issueDate ? new Date(issueDate) : null,
  patient: patient
    ? {
        ...patient,
        id: convertNumberIdToString(patient.id),
        dateOfBirth: new Date(patient.dateOfBirth)
      }
    : null,
  ...rest
});

const convertInvoiceItemServerToClient = ({
  price,
  ...rest
}: InvoiceItemServer): InvoiceItem => ({
  price: convertAmountFromCents(price),
  ...rest
});

export const convertInvoiceClientToServer = ({
  invoiceItems,
  patient: _patient,
  dueDate,
  paidDate,
  issueDate,
  itemsToDelete,
  ...rest
}: Invoice): InvoiceServer => ({
  invoiceItems: invoiceItems?.map((item) =>
    convertInvoiceItemClientToServer(item)
  ),
  dueDate: formatDateToString(dueDate),
  paidAt: formatDateToString(paidDate),
  issueDate: formatDateToString(issueDate),
  itemsToDelete: itemsToDelete?.map((id) => convertStringIdToNumber(id)),
  ...rest
});

const convertInvoiceItemClientToServer = ({
  price,
  ...rest
}: InvoiceItem): InvoiceItemServer => {
  const itemToReturn: InvoiceItemServer = {
    price: convertAmountToCents(price),
    ...rest
  };

  return itemToReturn;
};

const convertAmountFromCents = (amount: number) => amount / 100;
const convertAmountToCents = (amount: number) => amount * 100;
