import { Dayjs } from 'dayjs';
export enum PatientTaskType {
  ORDER = 'order',
  CONVERSATION = 'conversation',
  SPERM_PREP = 'spermPrep',
  EGG_COLLECTION_ID = 'eggCollectionId',
  EGG_COLLECTION_DISHES = 'eggCollectionDishes',
  STRIPPING = 'stripping',
  MOVE_TO_EMBRYO_CULTURE_DISH = 'moveToEmbryoCultureDish',
  ASSISTED_HATCHING = 'assistedHatching',
  MOVE_TO_EMBRYO_TRANSFER_DISH = 'moveToEmbryoTransferDish',
  EMBRYO_TRANSFER_ID_AND_DISHES_MATCH = 'embryoTransferIdAndDishesMatch',
  TUBING = 'tubing',
  PGTA_MEDIA_COLLECTED_BY = 'pgtaMediaCollectedBy',
  LOAD_TO_BIOPSY_DISH = 'loadToBiopsyDish',
  CRYO_LOCATION = 'cryoLocation',
  EMBRYO_GLUE = 'embryoGlue'
}

export enum TaskPriority {
  NORMAL = 'Normal',
  HIGH = 'High'
}

export enum TaskCategory {
  EGG_RETRIEVAL = 'Egg retrieval',
  EMBRYO_TRANSFER = 'Embryo transfer',
  DONOR_RECEIPIENT = 'Donor Receipient',
  DONOR_SPERM = 'Donor Sperm',
  EMBRYO_UPDATE = 'Embryo Update',
  GAMET_DONOR = 'Gamet Donor',
  GENETIC_CARRIER_SCREENING = 'Genetic Carrier Screening',
  IN_CYCLE = 'In Cycle',
  ONGOING = 'Ongoing',
  POSITIVE_PREGNANCY = 'Positive Pregnancy',
  SURROGATE = 'Surrogate',
  BASELINE = 'Baseline',
  DIAGNOSTIC = 'Diagnostic',
  DISCHARGE_FROM_TREATMENT_PREGNANCY = 'Discharge from treatment (Pregnancy)',
  EGG_COLLECTION_DISCHARGE = 'Egg collection discharge',
  FOLLOW_UP = 'Follow-up',
  INITIAL = 'Initial',
  NURSE_CONSULTATION = 'Nurse Consultation',
  PREGNANCY_RESULT = 'Pregnancy result',
  PREGNANCY_SCAN = 'Pregnancy scan',
  REGISTRATION_ADMIN = 'Registration-Admin',
  TRIGGER = 'Trigger',
  FOR_PATIENT_INTENDED_TREATMENT = 'For Patient: intended treatment',
  FOR_PATIENT_FIRST_CONSULTATION = 'For Patient: First Consultation',
  NEW_PATIENT_ONBOARDING = 'New patient onboarding',
  FOR_PATIENT_DONOR_RECEPIENT = 'For Patient: Donor recipient'
}

export enum TaskStatus {
  DONE = 'Done',
  NOT_DONE = 'Not Done',
  NOT_APPLICABLE = 'Not Applicable'
}

interface Staff {
  firstName: string;
  lastName: string;
}

export interface Task {
  id?: string;
  name: {
    translationKey: string;
    value: string;
  };
  dueDate: Date;
  patientId?: string;
  assignedToStaffId?: string;
  priority?: TaskPriority;
  category?: TaskCategory;
  status?: TaskStatus;
  doneDate?: Date;
  doneByStaffId?: string;
  doneByPatientId?: string;
  assignedToPatientId?: string;
  lastAssignedAt?: Date;
  patientName?: string;
  comment?: string;
  assignedToStaff?: Staff;
  doneByStaff?: Staff;
}

export interface ChecklistTemplate {
  category: string;
  // add the rest of the types here
}

export interface AddChecklistTemplateType {
  patientId: string;
  category: string;
  dueDate?: Dayjs;
}

export interface PatientTask {
  id: string;
  patientId: string;
  type: PatientTaskType;
  taskDate: Date;
  taskTitle: string;
  patientName: string;

  extraData?: any; // TODO: remove this
}

export interface PatientTask {
  id: string;
  patientId: string;
  type: PatientTaskType;
  taskDate: Date;
  taskTitle: string;
  patientName: string;

  extraData?: any; // TODO: remove this
}

export const patientTaskTypeToTitleMap = {
  ORDER: 'Task',
  CONVERSATION: 'Conversation'
};
