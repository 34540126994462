import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/material';

import { Appointment } from 'src/types/appointment';
import { spacings } from 'src/components/styles/constants';
import Card from 'src/components/display/Card';
import { makeShouldForwardProps } from 'src/components/utils';

import { DailyWorkList } from './DailyWorkList';
import { PatientQuickView } from './PatientQuickView';

enum DrawerViews {
  DAILY = 'daily',
  PATIENT = 'patient'
}

const shouldForwardProp = makeShouldForwardProps(['backgroundColor']);
export const DrawerContainerCard = styled(Card, {
  shouldForwardProp
})<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  min-height: calc(100% - (${spacings.xlarge} * 2));
  padding: ${spacings.xlarge};
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
`;

export const DailyWorkListDrawer: FC = () => {
  const [date, setDate] = useState(dayjs());
  const [view, setView] = useState<DrawerViews>(DrawerViews.DAILY);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  const handlePatientSelect = ({
    patientId,
    appointment
  }: {
    patientId: string;
    appointment?: Appointment | null;
  }) => {
    setPatientId(patientId);
    setAppointment(appointment);
    setView(DrawerViews.PATIENT);
  };

  const handleBack = () => {
    setPatientId(null);
    setView(DrawerViews.DAILY);
  };

  switch (view) {
    case DrawerViews.DAILY:
      return (
        <DailyWorkList
          date={date}
          onDateChange={setDate}
          onRowClick={handlePatientSelect}
        />
      );
    case DrawerViews.PATIENT:
      return (
        <PatientQuickView
          patientId={patientId}
          appointment={appointment}
          onBackClick={handleBack}
        />
      );
  }
};
