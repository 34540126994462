import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Flex from '../../../components/layout/Flex';
import Typography from '../../../components/display/Typography';
import { fontWeights } from '../../../components/styles/fonts';
import { spacings } from '../../../components/styles/constants';
import { useTranslation } from 'react-i18next';
import NumberPreview from '../../../components/display/NumberPreview';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { Dayjs } from 'dayjs';
import Loader from '../../../components/display/Loader';
import { getInboxLink } from '../../../router/routes';
import { NumberPreviewSize } from 'src/components/display/NumberPreview/types';

export const TasksSummary: FC<{
  date: Dayjs;
}> = ({ date }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getDashboardSummary } = usePatientsApi();

  const [startOfDay, endOfDay] = useMemo(
    () => [date.startOf('week'), date.endOf('week')],
    [date]
  );

  const { data: summary, isLoading } = getDashboardSummary({
    minDate: startOfDay,
    maxDate: endOfDay
  });

  if (isLoading) return <Loader />;
  const { messages, tasks } = summary || {};
  return (
    <Flex flex={2} alignItems="center" justifyContent="space-between">
      <Flex flexDirection="column" gap={spacings.small}>
        <Typography variant="h2" fontWeight={fontWeights.extraBold}>
          {t('OUTSTANDING')}
        </Typography>
        <Typography variant="h2" fontWeight={fontWeights.extraBold}>
          {t('TASKS')}
        </Typography>
      </Flex>
      <Flex>
        <NumberPreview
          id="messages-summary"
          size={NumberPreviewSize.large}
          value={messages}
          bottomText={t('MESSAGES')}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(getInboxLink())}
        />
        <NumberPreview
          id="tasks-summary"
          size={NumberPreviewSize.large}
          value={tasks}
          bottomText={t('TASKS')}
        />
      </Flex>
    </Flex>
  );
};
