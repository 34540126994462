import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { getStaffMemberByIdRequest } from 'src/api/patients.api';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  createStaffMemberRequest,
  getStaffMembersNamesRequest,
  getStaffMembersRequest,
  updateStaffMemberRequest,
  resendWelcomeEmailRequest
} from 'src/api/staff.api';
import { AppError } from 'src/types/global';
import { StaffMember, StaffMemberFormValues } from 'src/types/staff';
import { queryKeys } from './queryKeys';

const useStaffMembers = () => {
  const { t } = useTranslation();
  const { openToast, handleQueryResultToast } = useToast();
  const queryClient = useQueryClient();

  return {
    updateStaffmember: () =>
      useMutation<
        string,
        AppError,
        { staffId: string; staff: Partial<StaffMemberFormValues> }
      >(({ staffId, staff }) => updateStaffMemberRequest(staff, staffId), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_STAFF_MEMBER')
          }),

        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.STAFF]);
        }
      }),
    createStaffMember: () =>
      useMutation<string, AppError, StaffMemberFormValues>(
        createStaffMemberRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_STAFF_MEMBER')
            }),

          onSuccess: () => {
            openToast({
              title: t('SAVED_STAFF_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.STAFF]);
          }
        }
      ),
    getStaffMembersNames: (
      options?: UseQueryOptions<StaffMember[], AppError>
    ) =>
      useQuery<StaffMember[], AppError>(
        [queryKeys.STAFF],
        getStaffMembersNamesRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_STAFF_NAMES')
            }),

          ...options
        }
      ),
    getStaffMembers: (options?: UseQueryOptions<StaffMember[], AppError>) =>
      useQuery<StaffMember[], AppError>(
        [queryKeys.STAFF],
        getStaffMembersRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_STAFFS')
            }),

          ...options
        }
      ),
    getStaffMemberById: (
      staffId: string,
      options?: UseQueryOptions<StaffMember, AppError>
    ) =>
      useQuery<StaffMember, AppError>(
        [queryKeys.STAFF, staffId],
        () => getStaffMemberByIdRequest(staffId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_STAFF')
            }),

          ...options
        }
      ),
    resendWelcomeEmail: () =>
      useMutation<void, AppError, string>(
        (staffId) => resendWelcomeEmailRequest(staffId),
        {
          onSettled: (data, error) => {
            handleQueryResultToast({
              data,
              error,
              actionName: t('RESEND_WELCOME_EMAIL')
            });
          },
          onSuccess: () => {
            openToast({
              title: t('RESEND_WELCOME_EMAIL_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.STAFF]);
          }
        }
      )
  };
};

export default useStaffMembers;
