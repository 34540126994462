export enum Colors {
  white = '#ffffff',
  white2 = '#fefefe',
  whiteSand = '#f5f5f5',
  vistaWhite = '#f9f1f1',
  dawnPink = '#f6eaea',
  whiteIce = '#dceff9',
  whiteIceAlpha50 = '#dceff980',
  scandal = '#d0fbd9',
  sandwisp = '#f6f19b',
  mercury = '#e8e8e8',
  pastelPink = '#ffd7e0',
  frangipani = '#ffdab8',
  pigPink = '#fcd4d9',
  chamois = '#efdcbe',
  alto = '#dedede',
  alto2 = '#dadada',
  alto3 = '#d8d8d8',
  alto4 = '#d3d3d3',
  melanie = '#ead1db',
  cupid = '#facaca',
  cupidAlpha25 = '#facaca40',
  cupidAlpha50 = '#facaca80',
  jungleMist = '#9fc9cc',
  vistaBlue = '#95d0af',
  careysPink = '#d5a6bd',
  poloBlue = '#97bdd0',
  webOrange = '#ffa400',
  silverChalice = '#aaaaaa',
  viola = '#c693b5',
  pelorous = '#46bdc6',
  baliHai = '#849dad',
  gray = '#8c8c8c',
  gray2 = '#8c8686',
  lightGray = '#eeeeee',
  mutedGray = '#b9b2aa',
  frenchRose = '#ec4090',
  easternBlue = '#17a2b8',
  roman = '#d95050',
  coralRed = '#ff3d3d',
  cadilac = '#a64d79',
  scorpion = '#72656b',
  alizarinCrimson = '#e62323',
  royalPurple = '#743c96',
  emperor = '#545454',
  redRibbon = '#e80c3b',
  thunderBird = '#d11a19',
  thunderBird2 = '#d11919',
  monza = '#db061c',
  mineShaft = '#121212',
  codGray = '#121212',
  black = '#000000',
  darkGray = '#222222',
  mustard = '#e0c394',
  red = '#f27c7c',
  platinumGray = '#f7f7f7',
  transparent = 'transparent',
  mauvelous = '#f28d92',
  halfBaked = '#90c7ce',
  brandy = '#dcc394',
  riptide = '#90e9ce',
  unread = '#ed3942',
  blushPink = '#e99090',
  tampered = '#ffa7a2',
  defaultMuiGridOverlay = '#f9f1f161'
}

// TODO: don't use alpha values
export const alpha = {
  0: '00',
  50: '80',
  100: 'ff'
};
