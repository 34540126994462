import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../components/styles/colors';
import { Rectangle } from 'recharts';

export interface DotProps {
  fill: string;
  label: string;
}

interface DotWithLabelProps {
  dot: DotProps;
  index: number;
  startingXPosition: number;
  startingYPosition: number;
  dotRadius: number;
  dotMargin: number;
  labsSpacing: number;
}

export const DotWithLabel: FC<DotWithLabelProps> = ({
  dot,
  dotMargin,
  dotRadius,
  index,
  labsSpacing,
  startingXPosition,
  startingYPosition
}) => {
  const { t } = useTranslation();
  return (
    <g
      transform={`translate(${startingXPosition}, ${
        startingYPosition + labsSpacing + index * labsSpacing
      })`}
    >
      <Rectangle
        width={dotRadius * 2}
        height={dotRadius * 2}
        rx={10}
        ry={10}
        y={-dotRadius * 2}
        radius={50}
        fill={dot.fill}
      />
      <text
        x={dotRadius * 2 + dotMargin}
        y={0}
        color={Colors.mineShaft}
        fontSize={14}
      >
        {t(dot.label).toUpperCase()}
      </text>
    </g>
  );
};
