enum OrderVendorType {
  LAB = 'Lab',
  PHARMACY = 'Pharmacy'
}

export enum VendorsKey {
  Other = 'OTHER',
  Labcorp = 'LABCORP'
}

export const VendorNames = {
  [VendorsKey.Other]: 'Other',
  [VendorsKey.Labcorp]: 'Labcorp'
};

export interface OrderVendor {
  id: string;
  type: OrderVendorType;
  diagnosticServiceId?: string;
  code?: string;
  name?: string;
  key?: VendorsKey;
  address?: string;
  city?: string;
  country?: string;
  zip?: string;
  email?: string;
}
