import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { SendFaxDetails } from 'src/modules/patients/forms/types';
import { useToast } from '../components/components-api/ToastProvider';
import { ToastType } from '../components/display/Toast/Toast';
import { AppError } from '../types/global';
import { sendEFaxRequest } from '../api/eFax.api';
import { queryKeys } from './queryKeys';

function useEFaxApi() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openToast, handleQueryResultToast } = useToast();

  return {
    sendEFax: () =>
      useMutation<
        { faxId: string },
        AppError,
        {
          faxDetails: SendFaxDetails;
          patientId: string;
          documentId: string;
        }
      >(sendEFaxRequest, {
        onSettled: (data, error) => {
          return handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_SEND_EFAX')
          });
        },
        onSuccess: (patientId) => {
          queryClient.invalidateQueries([queryKeys.EFAX, patientId]);
          openToast({
            title: t('SEND_EFAX_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
        }
      })
  };
}

export default useEFaxApi;
