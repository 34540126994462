import { Navigate } from 'react-router-dom';
import { getDashboardLink } from './routes';
import { NotFoundErrorPage } from 'src/components/notFoundErrorPage';

const baseRoutes = [
  {
    path: '/',
    element: <Navigate to={getDashboardLink()} replace />
  },
  {
    path: '*',
    element: <NotFoundErrorPage />
  }
];

export default baseRoutes;
