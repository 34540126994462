import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  Navigate
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'configcat-react';

import Tabs from 'src/components/display/Tabs';
import Typography from 'src/components/display/Typography';
import { spacings } from 'src/components/styles/constants';
import Card from 'src/components/display/Card';
import { TabContentsProps } from 'src/components/display/Tabs/Tabs';
import { FeatureFlags } from 'src/types/featureFlags';
import Loader from 'src/components/display/Loader';

import StaffList from '../staff/staffList/StaffList';
import AddEditStaffMemberForm from '../staff/AddEditStaffMemberForm';
import PermissionsPage from '../staff/staffList/PermissionsPage';
import DataExportTab from './DataExport/DataExportTab';
import Box from 'src/components/layout/Box';

export enum ClinicSettingsTabs {
  STAFF = 'staff',
  DATA_EXPORT = 'data-export'
}

const ClinicSettings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab?: ClinicSettingsTabs }>();
  const { value: enableExportView, loading: enableExportViewLoading } =
    useFeatureFlag(FeatureFlags.ENABLE_EXPORT_VIEW, false);

  const tabContents: TabContentsProps<ClinicSettingsTabs>[] = [
    { value: ClinicSettingsTabs.STAFF, label: t('STAFF') }
  ];

  if (enableExportView) {
    tabContents.push({
      value: ClinicSettingsTabs.DATA_EXPORT,
      label: t('DATA_EXPORT')
    });
  }

  const currentTab = useMemo(
    () =>
      tabContents.find((t) => t.value === tab)?.value ||
      ClinicSettingsTabs.STAFF,
    [tab]
  );

  const handleTabChange = (value: ClinicSettingsTabs) => {
    navigate(`/app/clinic-settings/${value}`);
  };

  if (enableExportViewLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{t('CLINIC_SETTINGS_PAGE_TITLE')}</title>
      </Helmet>
      <Card padding={spacings.large}>
        <Box marginBottom={spacings.xlarge}>
          <Typography variant="h1">{t('CLINIC_SETTINGS')}</Typography>
        </Box>
        <Tabs<ClinicSettingsTabs>
          tabContents={tabContents}
          onTabChange={handleTabChange}
          selectedTab={currentTab}
        />
        <Routes>
          <Route index element={<Navigate to="staff" replace />} />
          <Route path="staff">
            <Route index element={<StaffList />} />
            <Route path="add" element={<AddEditStaffMemberForm />} />
            <Route path="permissions" element={<PermissionsPage />} />
            <Route path=":staffId/edit" element={<AddEditStaffMemberForm />} />
          </Route>
          {enableExportView && (
            <Route path="data-export" element={<DataExportTab />} />
          )}
        </Routes>
      </Card>
    </>
  );
};

export default ClinicSettings;
