import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { Colors } from 'src/components/styles/colors';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { getFullAddress } from 'src/utils/general';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { patientRoutes } from 'src/router/routes';

export const RowContainer: FC<{
  valueElementId?: string;
  title: string;
  value: string;
}> = ({ valueElementId = '', title, value }) => {
  const valueTypographyProps: { id?: string } = {};

  if (valueElementId) {
    valueTypographyProps.id = valueElementId;
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Typography>{title}</Typography>
      <Typography {...valueTypographyProps}>{value}</Typography>
    </Flex>
  );
};

export const PatientAboutCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPatientById } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);

  const {
    city,
    country,
    state,
    postalCode,
    dateOfBirth,
    email,
    ethnicity,
    gender,
    phoneNumber,
    sexAtBirth,
    patientRole,
    address,
    id,
    lastNameAtBirth,
    nickname,
    hasDisability,
    language,
    ssn
  } = patient?.personalInfo || {};

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('ABOUT').toUpperCase()}
        </Typography>
        <MiniIconButton
          icon={<EditIcon />}
          onClick={() => {
            navigate(patientRoutes.getEditLink(id));
          }}
        />
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      <Flex flexDirection="column" gap={spacings.small}>
        <RowContainer
          valueElementId="patient-details-phone-number"
          title={t('PHONE')}
          value={phoneNumber}
        />
        <RowContainer
          valueElementId="patient-details-email"
          title={t('EMAIL')}
          value={email}
        />
        <RowContainer
          valueElementId="patient-details-address"
          title={t('ADDRESS')}
          value={getFullAddress({
            country,
            city,
            address,
            state,
            zip: postalCode
          })}
        />
        <RowContainer
          valueElementId="patient-details-date-of-birth"
          title={t('DATE_OF_BIRTH')}
          value={dateOfBirth && dayjs(dateOfBirth).format(getDateFormat())}
        />
        <RowContainer
          valueElementId="patient-details-sex-at-birth"
          title={t('SEX_AT_BIRTH')}
          value={t(sexAtBirth)}
        />
        <RowContainer title={t('GENDER_TODAY')} value={t(gender)} />
        <RowContainer
          valueElementId="patient-details-ethnicity"
          title={t('RACE')}
          value={t(ethnicity)}
        />
        <RowContainer
          valueElementId="patient-details-treatment-reason"
          title={t('REASON_FOR_TREATMENT')}
          value={t(patient?.mainReasonForTreatment)}
        />
        <RowContainer title={t('PATIENT_TYPE')} value={patientRole} />
        <RowContainer title={t('PATIENT_SSN')} value={ssn} />
        <RowContainer
          valueElementId="patient-details-last-name-at-birth"
          title={t('LAST_NAME_AT_BIRTH')}
          value={lastNameAtBirth}
        />
        <RowContainer
          valueElementId="patient-details-nickname"
          title={t('NAME_TO_BE_CALLED')}
          value={nickname}
        />
        <RowContainer
          valueElementId="patient-details-has-disability"
          title={t('IS_DISABLED')}
          value={hasDisability}
        />

        <RowContainer
          valueElementId="patient-details-language"
          title={t('LANGUAGE')}
          value={language}
        />
      </Flex>
    </Flex>
  );
};
