import axiosApiInstance from '../utils/axios';
import { InsurancePayer, PatientInsurance } from '../types/patientInsurance';
import { getPatientInsuranceFormData } from 'src/utils/patientInsurances';

export async function getInsurancePayersRequest(
  patientId: string
): Promise<InsurancePayer[]> {
  const response = await axiosApiInstance.get<InsurancePayer[]>(
    `/patients/${patientId}/patient-insurances/insurance-payers`
  );

  return response.data;
}

export async function getInsurancesByPatientIdRequest(
  patientId: string
): Promise<PatientInsurance[]> {
  const response = await axiosApiInstance.get<PatientInsurance[]>(
    `/patients/${patientId}/patient-insurances`
  );

  return response.data;
}

export async function getInsuranceRequest(
  patientId: string,
  id: string
): Promise<PatientInsurance> {
  const response = await axiosApiInstance.get<PatientInsurance>(
    `/patients/${patientId}/patient-insurances/${id}`
  );

  return response.data;
}

export async function createPatientInsuranceRequest(
  insurance: PatientInsurance
): Promise<string> {
  const formData = getPatientInsuranceFormData(insurance);

  const response = await axiosApiInstance.post<PatientInsurance>(
    `/patients/${insurance.patientId}/patient-insurances`,
    formData
  );

  const { data } = response;

  return data.id;
}

export async function updatePatientInsuranceRequest(
  insurance: PatientInsurance
): Promise<string> {
  const formData = getPatientInsuranceFormData(insurance);

  const response = await axiosApiInstance.patch<PatientInsurance>(
    `/patients/${insurance.patientId}/patient-insurances/${insurance.id}`,
    formData
  );

  const { data } = response;

  return data.id;
}

export async function updatePatientIsUninsuredRequest({
  patientId,
  isUninsured
}: {
  patientId: string;
  isUninsured: boolean;
}) {
  const response = await axiosApiInstance.patch(
    `/patients/${patientId}/patient-insurances/mark-uninsured`,
    { isUninsured }
  );

  const { data } = response;

  return data;
}
