import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/display/Button';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';

interface ConsentDialogTemplateProps {
  content: ReactNode;
  acceptContent?: ReactNode;
  declineContent?: ReactNode;
  onAccept: () => void;
  onDecline: () => void;
}
const ConsentDialogTemplate: FC<ConsentDialogTemplateProps> = ({
  content,
  acceptContent,
  declineContent,
  onAccept,
  onDecline
}) => {
  const { t } = useTranslation();
  return (
    <Box marginTop={spacings.large}>
      <Flex flexDirection="column">
        <Typography>{content}</Typography>
        <Flex marginTop={spacings.medium} justifyContent="space-between">
          <Button onClick={onDecline} bgColor="alto" textColor="emperor">
            {declineContent || t('NO')}
          </Button>
          <Button onClick={onAccept}>{acceptContent || t('YES')}</Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ConsentDialogTemplate;
