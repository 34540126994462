import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import Loader from 'src/components/display/Loader/Loader';
import Card from 'src/components/display/Card/Card';
import useCareTeam from 'src/hooks/useCareTeam';
import { DoctorChips } from '../common/DoctorChips';

export const PatientCareTeam: FC<{ patientId: string }> = ({ patientId }) => {
  const { t } = useTranslation();
  const {
    addPatientCareTeamMember,
    removePatientCareTeamMember,
    getPatientPrimaryCareTeamMember,
    getPatientCareTeamMembers
  } = useCareTeam();

  const { data: careTeamMembers, isLoading: isLoadingCareTeamMembers } =
    getPatientCareTeamMembers(patientId);

  const {
    data: careTeamPrimaryMember,
    isLoading: isLoadingCareTeamPrimaryMember
  } = getPatientPrimaryCareTeamMember(patientId);

  const { mutate: RemovePatientCareTeamMemberMutate } =
    removePatientCareTeamMember();

  const { mutate: AddPatientCareTeamMemberMutate } = addPatientCareTeamMember();

  const handleAddRemoveCareTeamMember = ({
    isPrimary = false,
    staffId
  }: {
    staffId: string;
    isPrimary?: boolean;
  }) => {
    const isExistingMember =
      staffId === careTeamPrimaryMember?.id ||
      careTeamMembers?.find(({ id }) => id === staffId);

    if (isExistingMember) {
      RemovePatientCareTeamMemberMutate({
        patientId,
        staffId
      });
    } else {
      AddPatientCareTeamMemberMutate({
        patientId,
        careTeamMember: {
          isPrimary,
          staffId
        }
      });
    }
  };

  const filteredMembers = careTeamPrimaryMember
    ? careTeamMembers?.filter(({ id }) => careTeamPrimaryMember?.id !== id)
    : careTeamMembers;

  const isLoading = isLoadingCareTeamMembers || isLoadingCareTeamPrimaryMember;

  return (
    <Box flex={1}>
      <Card shadow padding={spacings.xlarge}>
        <Box marginBottom={spacings.xlarge}>
          <Typography marginBottom={spacings.large} variant="h1">
            {t('CARE_TEAM')}
          </Typography>
        </Box>
        <Box>
          {isLoading ? (
            <Flex justifyContent="center" alignItems="center">
              <Loader />
            </Flex>
          ) : (
            <Flex gap={spacings.large} alignItems="center">
              <DoctorChips
                id="care-team-primary-member-chips"
                showSelectedValue
                addLabel={t('SELECT_CARE_TEAM_PRIMARY_MEMBER')}
                value={
                  careTeamPrimaryMember?.id ? [careTeamPrimaryMember?.id] : []
                }
                onAddChip={(newSelectedDoctorId) => {
                  handleAddRemoveCareTeamMember({
                    isPrimary: true,
                    staffId: newSelectedDoctorId
                  });
                }}
              />
              {filteredMembers?.length > 0 ? (
                filteredMembers.map(({ id }, index) => {
                  const isLastItem = index === filteredMembers.length - 1;

                  return (
                    <Fragment key={`patient-care-team-doctor-${id}`}>
                      <DoctorChips
                        id="care-team-member-chips"
                        showSelectedValue
                        addLabel={t('SELECT_CARE_TEAM_MEMBER')}
                        value={[id]}
                        onAddChip={(newSelectedDoctorId) => {
                          handleAddRemoveCareTeamMember({
                            staffId: newSelectedDoctorId
                          });
                        }}
                      />
                      {isLastItem && (
                        <DoctorChips
                          id="care-team-last-member-chip"
                          showSelectedValue
                          addLabel={t('SELECT_CARE_TEAM_MEMBER')}
                          value={['']}
                          onAddChip={(newSelectedDoctorId) => {
                            handleAddRemoveCareTeamMember({
                              staffId: newSelectedDoctorId
                            });
                          }}
                        />
                      )}
                    </Fragment>
                  );
                })
              ) : (
                <DoctorChips
                  id="add-care-team-member-chips"
                  showSelectedValue
                  addLabel={t('SELECT_CARE_TEAM_MEMBER')}
                  value={['']}
                  onAddChip={(newSelectedDoctorId) => {
                    handleAddRemoveCareTeamMember({
                      staffId: newSelectedDoctorId
                    });
                  }}
                />
              )}
            </Flex>
          )}
        </Box>
      </Card>
    </Box>
  );
};
