import { BoxProps, Box, styled, css } from '@mui/material';
import { FC } from 'react';
import { Colors } from '../../styles/colors';
import { radii, shadows } from '../../styles/constants';
import { makeShouldForwardProps } from 'src/components/utils';

export interface CardProps extends BoxProps {
  shadow?: boolean;
}

const shouldForwardProp = makeShouldForwardProps(['shadow']);
const StyledCard = styled(Box, { shouldForwardProp })<CardProps>`
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: ${shadows.default};
    `}
  border-radius: ${({ borderRadius }) =>
    `
      ${borderRadius || borderRadius === 0 ? borderRadius : radii.xlarge};
    `}
`;

const Card: FC<CardProps> = ({
  children,
  shadow = false,
  bgcolor = Colors.white,
  ...otherProps
}) => {
  return (
    <StyledCard shadow={shadow} bgcolor={bgcolor} {...otherProps}>
      {children}
    </StyledCard>
  );
};

export default Card;
