import { Box, Typography, Card, styled, Divider, css } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { validateGuid } from 'src/utils/general';
import { UserTypes } from 'src/types/user';
import { spacings } from 'src/components/styles/constants';
import { fontFamilies } from 'src/components/styles/fonts';
import Avatar from 'src/components/display/Avatar';
import Linkify from 'linkify-react';
import { Colors } from 'src/components/styles/colors';
import {
  getDateFormat,
  getTimeFormat,
  shortTimeDateFormat
} from 'src/utils/dateAndTIme';
import { Message } from 'src/types/inbox';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';

const DividerWrapper = styled(Divider)`
  ${({ theme }) => css`
    .MuiDivider-wrapper {
      border-radius: ${theme.general.borderRadiusSm};
      text-transform: none;
      background: ${theme.palette.background.default};
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.colors.alpha.black[50]};
    }
    margin-top: ${spacings.medium};
    margin-bottom: ${spacings.large};
  `}
`;

const StyledTypography = styled(Typography)`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: ${spacings.large};
  font-family: ${fontFamilies.chat};
  a {
    color: ${Colors.white};
  }
`;

const CardWrapperPrimary = styled(Card)`
  ${({ theme }) => css`
    background: black;
    color: white;
    padding: ${spacings.large};
    border-radius: ${theme.general.borderRadius};
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 60px;
    min-width: 65px;
    box-sizing: border-box;
  `}
`;

const CardWrapperSecondary = styled(Card)`
  ${({ theme }) => css`
    background: black;
    color: white;
    padding: ${spacings.large};
    border-radius: ${theme.general.borderRadius};
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 60px;
    min-width: 65px;
    box-sizing: border-box;
  `}
`;

const TimeWrapper = ({ date }) => (
  <Typography
    variant="subtitle1"
    sx={{
      pt: 1,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 14,
      bottom: 0,
      color: 'white',
      fontSize: '10px'
    }}
  >
    {dayjs(date).format(getTimeFormat({ isShort: true }))}
  </Typography>
);

const NameWrapper = ({
  fullName,
  variant
}: {
  fullName: string;
  variant: string;
}) => (
  <Typography
    variant="h6"
    sx={{
      color: variant === 'primary' ? Colors.darkGray : Colors.cupid,
      fontSize: '12px',
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      justifyContent: variant === 'primary' ? 'right' : 'left'
    }}
  >
    {variant === 'secondary' ? `@${fullName}` : fullName}
  </Typography>
);

const isDifferentDay = (date1: Dayjs, date2: Dayjs): boolean => {
  return (
    date1.format(getDateFormat({ isShort: true })) !==
    date2.format(getDateFormat({ isShort: true }))
  );
};

const TimeWrapperContainer = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.pxToRem(10)};
    color: ${theme.colors.alpha.black[50]};
    display: flex;
    flex-direction: row-reverse;
    padding-right: ${spacings.large};
    padding-top: ${spacings.small};
  `};
`;

const ReadAtDateWrapper: FC<{ date: Dayjs }> = ({ date }) => {
  const { t } = useTranslation();
  return (
    <TimeWrapperContainer>
      {t('READ_AT')} {date.format(shortTimeDateFormat)}
    </TimeWrapperContainer>
  );
};

const ChatContent: FC<{
  data: Message[];
  staffPictureId?: string;
  patientPictureId?: string;
}> = ({ data, staffPictureId, patientPictureId }) => {
  const { getDefaultAvatar } = useGetDefaultAvatar();

  return (
    <>
      {data?.map((item: Message, index) => {
        const isMessageFromPatient = validateGuid(item.senderPatientId);

        return (
          <Box mt={1} pt={1} pb={1} key={item.id}>
            {(index === 0 ||
              (index > 0 &&
                isDifferentDay(item.createdAt, data[index - 1].createdAt))) && (
              <DividerWrapper>
                {dayjs(item.createdAt).format(getDateFormat())}
              </DividerWrapper>
            )}
            <Box
              id={`text-message-container-${index}`}
              display="flex"
              alignItems="flex-start"
              justifyContent={isMessageFromPatient ? 'flex-start' : 'flex-end'}
            >
              <Box order={isMessageFromPatient ? 0 : 1}>
                <Avatar
                  sx={{
                    width: 55,
                    height: 55
                  }}
                  src={
                    isMessageFromPatient
                      ? getDefaultAvatar({
                          userId: item.senderPatientId,
                          userType: UserTypes.patients,
                          pictureId: patientPictureId
                        })
                      : getDefaultAvatar({
                          userId: item.senderStaffId,
                          userType: UserTypes.staff,
                          pictureId: staffPictureId
                        })
                  }
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent={
                  isMessageFromPatient ? 'flex-start' : 'flex-end'
                }
                mr={isMessageFromPatient ? 0 : 1}
                ml={!isMessageFromPatient ? 0 : 1}
              >
                {!isMessageFromPatient ? (
                  <>
                    <NameWrapper
                      fullName={item.senderStaffName}
                      variant="primary"
                    />
                    <CardWrapperPrimary>
                      <StyledTypography>
                        <Linkify options={{ target: '_blank' }}>
                          {item.text}
                        </Linkify>
                      </StyledTypography>
                      <TimeWrapper date={item.createdAt} />
                    </CardWrapperPrimary>
                    {item.readAt && <ReadAtDateWrapper date={item.readAt} />}
                  </>
                ) : (
                  <>
                    <CardWrapperSecondary>
                      <NameWrapper
                        fullName={
                          item.receiverStaffName || item.senderStaffName
                        }
                        variant="secondary"
                      />
                      <StyledTypography>
                        <Linkify>{item.text}</Linkify>
                      </StyledTypography>
                      <TimeWrapper date={item.createdAt} />
                    </CardWrapperSecondary>
                    {/* {item.readAt && <ReadAtDateWrapper date={item.readAt} />} */}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ChatContent;
