import axios from 'axios';
import { firebaseAuth } from './firebase';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const axiosApiInstance = axios.create({
  baseURL: serverBaseUrl
});

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const userToken = await firebaseAuth.currentUser?.getIdToken();
    config.headers.Authorization = `Bearer ${userToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
