import { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Drawer,
  SvgIcon,
  styled,
  css,
  useMediaQuery
} from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Navbar from 'src/components/display/navbar/Navbar';
import Logo from 'src/components/display/LogoSign';

import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as InboxIcon } from '../../assets/icons/Inbox.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/patient-list.svg';
import { ReactComponent as HeartRateIcon } from '../../assets/icons/heart-rate.svg';

import Header from './Header';
import {
  blackGradient,
  buttonsMenuWidth,
  navbarWidth,
  spacings,
  zIndices
} from 'src/components/styles/constants';
import { getAvatarUrl } from 'src/utils/general';
import useMeApi from 'src/hooks/useMeApi';
import { UserTypes } from 'src/types/user';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { firestore } from 'src/utils/firebase';
import { useQueryClient } from 'react-query';
import { Colors } from 'src/components/styles/colors';
import {
  getCalendarLink,
  getClinicFeedLink,
  getDashboardLink,
  getInboxLink,
  patientRoutes
} from 'src/router/routes';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useTheme } from '@mui/material/styles';
import { PatientButtonsSideBar } from 'src/modules/patients/sideBar/PatientButtonsSideBar';
import { useParams } from 'react-router-dom';
import InboxSidebar from 'src/modules/patients/inbox/InboxSidebar';
import Flex from 'src/components/layout/Flex';
import usePatientsApi from 'src/hooks/usePatientsApi';
import dayjs from 'dayjs';
import { queryKeys, querySubKeys } from 'src/hooks/queryKeys';
import { makeShouldForwardProps } from 'src/components/utils';

interface CollapsedSidebarLayoutProps {
  children?: ReactNode;
}

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${Colors.unread};
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root.MuiDrawer-paper {
    background-color: ${Colors.transparent};
  }
`;

const shouldForwardProp = makeShouldForwardProps(['showGradient']);
const StyledDrawerContainer = styled(Flex, {
  shouldForwardProp
})<{ showGradient?: boolean }>`
  ${({ showGradient }) =>
    showGradient
      ? css`
          background: ${blackGradient};
        `
      : css`
          background: ${Colors.transparent};
        `}
  height: 100%;
  gap: ${spacings.medium};
`;

const CollapsedSidebarLayout: FC<CollapsedSidebarLayoutProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const { setIsSidebarShown, isNavbarDrawerOpen, setIsNavbarDrawerOpen } =
    useContext(SidebarContext);
  const { patientId } = useParams();
  const { getMe } = useMeApi();
  const { getDashboardSummary } = usePatientsApi();
  const { data: me, isLoading } = getMe();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const { data: summary } = getDashboardSummary({
    minDate: dayjs().subtract(1, 'day'),
    maxDate: dayjs()
  });

  const { messages } = summary || {};

  useEffect(() => {
    let unsub = () => {};
    if (me?.user?.id) {
      const staffCollections = collection(firestore, 'staff');
      const staffDoc = doc(staffCollections, me.user.id);
      unsub = onSnapshot(staffDoc, () => {
        queryClient.invalidateQueries([
          queryKeys.CLINICS,
          querySubKeys[queryKeys.CLINICS].DASHBOARD_SUMMARY
        ]);
        queryClient.invalidateQueries([
          queryKeys.INBOX,
          querySubKeys[queryKeys.INBOX].RECENTS
        ]);
      });
    }
    return unsub;
  }, [me?.user?.id]);

  const menuItems = useMemo(() => {
    const items = [
      {
        icon: (
          <SvgIcon>
            <DashboardIcon color="transparent" />
          </SvgIcon>
        ),
        path: getDashboardLink(),
        title: t('DASHBOARD_NAVBAR_TITLE')
      },
      {
        icon: (
          <SvgIcon>
            <CalendarIcon color="transparent" />
          </SvgIcon>
        ),
        path: getCalendarLink(),
        title: t('CALENDAR_NAVBAR_TITLE')
      },
      {
        icon: (
          <StyledBadge badgeContent={messages} invisible={!messages}>
            <SvgIcon>
              <InboxIcon color="transparent" />
            </SvgIcon>
          </StyledBadge>
        ),
        path: getInboxLink(),
        title: t('INBOX_NAVBAR_TITLE')
      },
      {
        icon: (
          <SvgIcon>
            <ListIcon color="transparent" />
          </SvgIcon>
        ),
        path: patientRoutes.getPatientsLink(),
        title: t('PATIENTS_NAVBAR_TITLE')
      },
      {
        icon: (
          <SvgIcon>
            <HeartRateIcon />
          </SvgIcon>
        ),
        path: getClinicFeedLink(),
        title: t('CLINIC_FEED_TITLE')
      }
    ];

    return items;
  }, [messages]);

  const calculateBoxPaddingInlineStart = (
    patientId: string | undefined,
    isScreenLarge: boolean
  ) => {
    if (patientId) {
      if (isScreenLarge) {
        setIsSidebarShown(false);
        setIsNavbarDrawerOpen(false);
        return `${buttonsMenuWidth}px`;
      } else {
        return null;
      }
    } else {
      return isScreenLarge ? `${navbarWidth}px` : null;
    }
  };

  const boxPaddingInlineStart = calculateBoxPaddingInlineStart(
    patientId,
    isScreenLarge
  );

  return (
    <>
      <Box
        sx={{
          paddingInlineStart: boxPaddingInlineStart
        }}
        flex={1}
        height="100dvh"
        marginX={spacings.xlarge}
        paddingBottom={spacings.xxlarge}
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <Outlet />
      </Box>
      <Navbar
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          }
        }}
        avatar={
          !isLoading && me?.user?.pictureId
            ? getAvatarUrl(`${me.user.id}`, UserTypes.staff)
            : undefined
        }
        logo={<Logo />}
        menuItems={menuItems}
      />
      <StyledDrawer
        anchor="left"
        PaperProps={{ elevation: 0 }}
        open={isNavbarDrawerOpen}
        onClose={() => setIsNavbarDrawerOpen(false)}
        variant="temporary"
      >
        <StyledDrawerContainer showGradient={!!patientId}>
          <Navbar
            sx={{ zIndex: zIndices.higher }}
            logo={<Logo />}
            menuItems={menuItems}
          />
          {pathname.startsWith(getInboxLink()) && <InboxSidebar />}
          {patientId && <PatientButtonsSideBar />}
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
};

export default CollapsedSidebarLayout;
