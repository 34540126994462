import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import Loader from 'src/components/display/Loader/Loader';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import Card from 'src/components/display/Card/Card';
import { getFullName } from 'src/utils/general';
import { getDateFormat } from 'src/utils/dateAndTIme';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { PatientAboutCard } from './PatientAboutCard';
import { PatientMaritalStatusCard } from './PatientMaritalStatusCard';
import { PatientIdentificationCard } from './PatientIdentificationCard';
import { PatientGeneralPractitionerCard } from './PatientGeneralPractitionerCard';
import { PatientNextOfKinCard } from './PatientNextOfKinCard';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import { PatientCareTeam } from './PatientCareTeam';
import { UserTypes } from 'src/types/user';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { PatientInsurancesInfoCard } from './PatientInsurancesInfoCard';

const StyledSpecsKeyTypography = styled(Typography)`
  font: ${fonts.pill};
  flex: 1;
`;
const StyledSpecsValueTypography = styled(Typography)`
  font: ${fonts.pill};
  font-weight: ${fontWeights.extraBold};
  flex: 2;
`;

export const PatientDetails: FC<{}> = () => {
  const { patientId } = useParams();
  const { getPatientById } = usePatientsApi();
  const { t } = useTranslation();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: partner, isLoading: isLoadingPartner } = getPatientById(
    patient?.partnerInfo?.id,
    { enabled: !!patient?.partnerInfo?.id }
  );

  const avatarPatient = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patient?.personalInfo?.pictureId
  });

  const avatarPartner = getDefaultAvatar({
    userId: patient?.partnerInfo?.id,
    userType: UserTypes.patients,
    pictureId: patient?.partnerInfo?.pictureId
  });

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_DETAILS_PAGE_TITLE')}</title>
      </Helmet>
      <Box>
        <Box marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>

        <Flex width="100%" gap={spacings.large} marginBottom={spacings.large}>
          <Flex gap={spacings.large} flex={1}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              gap={spacings.large}
            >
              <Card shadow padding={spacings.xlarge} minHeight={150}>
                <Typography variant="h1">{t('PATIENT_DETAILS')}</Typography>
                {isLoadingPatient ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Loader />
                  </Flex>
                ) : (
                  <Flex
                    flex={1}
                    marginTop={spacings.xlarge}
                    gap={spacings.medium}
                    paddingBottom={spacings.medium}
                  >
                    <Avatar size={AvatarSizes.XLARGE} image={avatarPatient} />

                    <Flex
                      flex={1}
                      flexDirection="column"
                      gap={spacings.small}
                      maxWidth={250}
                    >
                      <Typography
                        variant="h2"
                        marginBottom={spacings.large}
                        id="patient-details-full-name"
                      >
                        {getFullName(patient?.personalInfo)}
                      </Typography>
                      <Flex gap={spacings.small}>
                        <StyledSpecsKeyTypography>
                          {t('PATIENT_SINCE')}
                        </StyledSpecsKeyTypography>
                        <StyledSpecsValueTypography>
                          {dayjs(patient?.patientSince).format(getDateFormat())}
                        </StyledSpecsValueTypography>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Card>
              {patient && (
                <Card
                  shadow
                  display="flex"
                  flexDirection="column"
                  gap={spacings.large}
                  padding={spacings.xlarge}
                >
                  <PatientAboutCard patientId={patient?.personalInfo?.id} />
                  <PatientGeneralPractitionerCard
                    patientId={patient?.personalInfo?.id}
                  />
                  <PatientMaritalStatusCard
                    patientId={patient?.personalInfo?.id}
                  />
                  <PatientIdentificationCard
                    patientId={patient?.personalInfo?.id}
                  />
                  <PatientNextOfKinCard patientId={patient?.personalInfo?.id} />
                  <PatientInsurancesInfoCard
                    patientId={patient?.personalInfo?.id}
                  />
                </Card>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={spacings.large}
              flex={1}
            >
              {patient?.partnerInfo && (
                <>
                  <Card shadow padding={spacings.xlarge}>
                    <Typography variant="h1">{t('PARTNER_DETAILS')}</Typography>
                    {isLoadingPartner ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                      >
                        <Loader />
                      </Flex>
                    ) : (
                      <Flex
                        flex={1}
                        marginTop={spacings.xlarge}
                        gap={spacings.medium}
                        paddingBottom={spacings.medium}
                      >
                        <Avatar
                          image={avatarPartner}
                          size={AvatarSizes.XLARGE}
                        />
                        <Flex
                          flex={1.5}
                          flexDirection="column"
                          gap={spacings.small}
                        >
                          <Typography
                            variant="h2"
                            marginBottom={spacings.large}
                          >
                            {getFullName(partner?.personalInfo) ||
                              t('NO_PARTNER')}
                          </Typography>
                          <Flex gap={spacings.small}>
                            <StyledSpecsKeyTypography>
                              {t('PATIENT_SINCE')}
                            </StyledSpecsKeyTypography>
                            <StyledSpecsValueTypography>
                              {dayjs(partner?.patientSince).isValid()
                                ? dayjs(partner?.patientSince).format(
                                    getDateFormat()
                                  )
                                : ''}
                            </StyledSpecsValueTypography>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Card>
                  {partner && (
                    <Card
                      shadow
                      display="flex"
                      flexDirection="column"
                      gap={spacings.large}
                      padding={spacings.xlarge}
                    >
                      <PatientAboutCard patientId={partner?.personalInfo?.id} />
                      <PatientGeneralPractitionerCard
                        patientId={partner?.personalInfo?.id}
                      />

                      <PatientMaritalStatusCard
                        patientId={partner?.personalInfo?.id}
                      />
                      <PatientIdentificationCard
                        patientId={partner?.personalInfo?.id}
                      />
                      <PatientNextOfKinCard
                        patientId={partner?.personalInfo?.id}
                      />
                    </Card>
                  )}
                </>
              )}
            </Box>
            <PatientCareTeam patientId={patientId} />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
