import { GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import MultiSelect, {
  MultiSelectOption
} from 'src/components/data-entry/MultiSelect/MultiSelect';

interface Props {
  gridId: GridRowId;
  value: string[];
  field: string;
  options: MultiSelectOption[];
}
const TableMultiSelect = ({ gridId, value, field, options }: Props) => {
  const apiRef = useGridApiContext();

  const handleChangeMultiSelect = (value: string[]) => {
    apiRef.current.setEditCellValue({
      id: gridId,
      field,
      value: value.filter((x) => x !== '')
    });
  };
  return (
    <MultiSelect
      onChange={handleChangeMultiSelect}
      value={value}
      options={options}
      renderValue={() => value.join(', ')}
    />
  );
};

export default TableMultiSelect;
