import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { radii, shadows, spacings } from 'src/components/styles/constants';
import { styled } from '@mui/material';
import Box from 'src/components/layout/Box/Box';
import dayjs from 'dayjs';
import { useFeatureFlag } from 'configcat-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { CycleTreatmentWitness, CycleViews } from 'src/types/cycle';
import Flex from 'src/components/layout/Flex/Flex';
import Select, { SelectOption } from 'src/components/data-entry/Select/Select';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import Popover from 'src/components/display/Popover/Popover';
import { EditWitnessTaskForm } from './EditWitnessTaskForm';
import { TreatmentOverviewTopCards } from './TreatmentOverviewTopCards';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import useCycle from 'src/hooks/useCycle';
import { patientRoutes } from 'src/router/routes';
import { Cycle, CycleStatus } from 'src/types/cycle';
import StandardView from './treatmentViews/StandardView';
import LabView from './treatmentViews/labView/LabView';
import Loader from 'src/components/display/Loader';
import { FeatureFlags } from 'src/types/featureFlags';

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    box-shadow: ${shadows.default};
    border-radius: ${radii.full};
  }
`;

export const TreatmentPage: FC = () => {
  const { patientId, cycleId } = useParams();
  const { t } = useTranslation();
  // TODO: remove feature flag as part of EMB-372
  const { value: enableLabView, loading: enableLabViewLoading } =
    useFeatureFlag(FeatureFlags.ENABLE_LAB_VIEW, false);

  const { getPatientOverview } = usePatientsApi();
  const { getPatientCycles } = useCycle();

  const { data: cycles } = getPatientCycles(patientId);

  const { data: patientOverview } = getPatientOverview(patientId);

  const viewsOptions: SelectOption<string>[] = Object.entries(CycleViews)
    .filter(([_, value]) => enableLabView || value !== CycleViews.LAB_VIEW) // Filter out LAB_VIEW if enableLabView is false
    .map(([key, value]) => ({
      label: t(key),
      value
    }));

  const [currentTab, setCurrentTab] = useState(viewsOptions[0].value);
  const [witnessPopoverAnchor, setWitnessPopoverAnchor] =
    useState<EventTarget>(null);
  const witnessedTaskToEdit: CycleTreatmentWitness | null = null;

  const cycleToDisplay: Cycle | null =
    cycles?.find(({ id }) => id === cycleId) || null;

  const navigate = useNavigate();

  useEffect(() => {
    if (cycles?.length && !cycleId) {
      const cycle = cycles?.find(
        ({ status }) =>
          status === CycleStatus.PENDING || status === CycleStatus.ONGOING
      );
      navigate(`${cycle?.id || cycles?.[0]?.id}`);
    }
  }, [cycles, cycleId]);

  const selectOptions: SelectOption<string>[] = useMemo(() => {
    return (
      cycles
        ?.sort(
          (
            { id: idA, startDate: startDateA },
            { id: idB, startDate: startDateB }
          ) => {
            if (dayjs(startDateA).isSame(startDateB, 'day')) {
              return +idA - +idB;
            }

            return dayjs(startDateA).isAfter(startDateB) ? 1 : -1;
          }
        )
        .map(({ startDate, treatmentType, id }) => ({
          label: `#${id} ${treatmentType} ${dayjs(startDate).format(
            'MMM YYYY'
          )}`,
          value: `${id}`
        })) || []
    );
  }, [cycles]);

  if (enableLabViewLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_TREATMENT_CHART_PAGE_TITLE')}</title>
      </Helmet>
      <Box marginBottom={spacings.large}>
        <Flex gap={spacings.large}>
          <Box flex={1}>
            <PatientFlags patientId={patientId} />
          </Box>
          <Box flex={1}>
            <StyledSelect
              noBorder
              value={selectOptions?.length ? cycleId : null}
              options={selectOptions || []}
              onChange={(ev) =>
                navigate(
                  patientRoutes.getCurrentTreatmentByCycleLink(
                    patientId,
                    ev.target.value
                  )
                )
              }
              defaultOption={
                selectOptions?.length ? t('CHOOSE_CYCLE') : t('NO_CYCLES')
              }
            />
          </Box>
          <Box flex={1}>
            <StyledSelect
              noBorder
              value={currentTab}
              options={viewsOptions}
              onChange={(ev) => {
                setCurrentTab(ev.target.value);
              }}
              defaultOption={viewsOptions[0].value}
            />
          </Box>
        </Flex>
      </Box>
      <Flex gap={spacings.xlarge} marginBottom={spacings.large}>
        <TreatmentOverviewTopCards cycleToDisplayId={cycleId} />
      </Flex>
      {currentTab === CycleViews.STANDARD_VIEW && (
        <StandardView cycleToDisplay={cycleToDisplay} />
      )}
      {currentTab === CycleViews.LAB_VIEW &&
        cycleToDisplay &&
        enableLabView && <LabView cycle={cycleToDisplay} />}
      <Popover
        paperPadding={spacings.xlarge}
        header={t('Edit Witnessing')}
        open={!!witnessPopoverAnchor}
        anchorEl={witnessPopoverAnchor as Element}
        onClose={() => setWitnessPopoverAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <EditWitnessTaskForm
          witnessedTask={witnessedTaskToEdit}
          patientName={getFullName(patientOverview?.patientBasicInfo)}
        />
      </Popover>
    </>
  );
};
