import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'configcat-react';
import { Box, css, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AppsIcon from '@mui/icons-material/Apps';

import { FeatureFlags } from 'src/types/featureFlags';
import { Colors } from 'src/components/styles/colors';
import { patientRoutes } from 'src/router/routes';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useDrawer } from 'src/components/components-api/DrawerProvider';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import IconButton from 'src/components/display/IconButton';
import Button from 'src/components/display/Button/Button';
import { DailyWorkListDrawer } from 'src/modules/me/DailyWorkListDrawer/DailyWorkListDrawer';

import HeaderUserbox from './Userbox';
import { PatientsSearchBar } from './PatientsSearchBar';

const HeaderWrapper = styled(Box)`
  ${({ theme }) => css`
    padding: ${spacings.medium} 0;
    justify-content: space-between;
    margin-bottom: ${spacings.large};
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      left: ${theme.spacing(12)};
      width: auto;
    }
  `}
`;

function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { value: enableWorkListDrawer } = useFeatureFlag(
    FeatureFlags.ENABLE_WORK_LIST_DRAWER,
    false
  );

  const { isNavbarDrawerOpen, setIsNavbarDrawerOpen } =
    useContext(SidebarContext);
  const { openDrawer } = useDrawer();

  return (
    <HeaderWrapper alignItems="center">
      <Flex alignItems="center" width="100%">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex gap={spacings.medium} width="50%">
            <PatientsSearchBar
              onSelectPatient={(patientId) =>
                navigate(patientRoutes.getByIdLink(patientId))
              }
              variant={SearchFieldVariants.STYLED}
            />
            <Button
              startIcon={<AddIcon />}
              onClick={() => navigate(patientRoutes.getAddLink())}
              id="add-new-patient-button"
            >
              {t('ADD_NEW_PATIENT')}
            </Button>
          </Flex>
          <Flex alignItems="center" gap={spacings.medium}>
            {enableWorkListDrawer && (
              <IconButton
                iconColor="emperor"
                bgColor="transparent"
                size="large"
                onClick={() =>
                  openDrawer({
                    children: <DailyWorkListDrawer />,
                    width: '600px',
                    backgroundColor: Colors.vistaWhite
                  })
                }
              >
                <AppsIcon fontSize="large" />
              </IconButton>
            )}
            <HeaderUserbox />
            <Box
              sx={{
                display: { lg: 'none', xs: 'inline-block' }
              }}
            >
              <IconButton
                iconColor="emperor"
                bgColor="transparent"
                size="large"
                onClick={() => setIsNavbarDrawerOpen((prevState) => !prevState)}
              >
                {!isNavbarDrawerOpen ? (
                  <MenuTwoToneIcon fontSize="large" />
                ) : (
                  <CloseTwoToneIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </HeaderWrapper>
  );
}

export default Header;
