import { Priority } from 'src/types/priority';

export const getInsuranceLabel = (
  insurancePriority?: Priority
): string | null => {
  switch (insurancePriority) {
    case Priority.PRIMARY:
    case Priority.SECONDARY:
    case Priority.TERTIARY:
      return `${insurancePriority.toUpperCase()}_INSURANCE`;
    default:
      return null;
  }
};
