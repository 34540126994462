import { FC } from 'react';
import { styled } from '@mui/material';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import { iconSizes } from '../../styles/constants';
import { Colors } from '../../styles/colors';
import { makeShouldForwardProps } from 'src/components/utils';

interface LoaderProps extends CircularProgressProps {
  size?: string;
  colorBase?: string;
}

const shouldForwardProp = makeShouldForwardProps(['size', 'colorBase']);
const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp
})<LoaderProps>`
  width: ${({ size }) => size} !important;
  height: ${({ size }) => size} !important;
  color: ${({ colorBase }) => colorBase};
`;

const Loader: FC<LoaderProps> = ({
  size = iconSizes.medium,
  colorBase = Colors.cupid,
  ...props
}) => {
  return (
    <StyledCircularProgress size={size} colorBase={colorBase} {...props} />
  );
};

export default Loader;
