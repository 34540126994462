import { convertMessageServerToClient } from 'src/modules/patients/utils/conversions';
import {
  Channel,
  ChannelServer,
  ChannelTypes,
  Message,
  MessageServer
} from 'src/types/inbox';
import axiosApiInstance from 'src/utils/axios';

export async function getMessagesRequest(
  channelId: string
): Promise<Message[]> {
  const response = await axiosApiInstance.get<MessageServer[]>(
    `/messages/chat/${channelId}`
  );
  return response.data.map((msg) => convertMessageServerToClient(msg));
}

export async function sendMessageRequest(
  patientId: string,
  text: string,
  channelId: string
): Promise<void> {
  await axiosApiInstance.post('/messages/staff', {
    patientId,
    text,
    channelId
  });
}

export async function getPatientChannel(
  patientId: string,
  type: ChannelTypes
): Promise<Channel> {
  const response = await axiosApiInstance.get<ChannelServer>(
    `/channels/${patientId}/${type}`
  );
  return response.data;
}
