import { UI, WebViewerInstance } from '@pdftron/webviewer';
import { UseMutateFunction } from 'react-query';
import saveButtonIcon from 'src/assets/icons/saveButtonIcon.svg';
import i18n from 'src/i18n/i18n';
import { Document, TemplateFile } from 'src/types/documents';
import { getTemplateAsDocxFile } from './getTemplateAsDocxFile';

export const getWebViewerSaveTemplateAction = async ({
  header,
  webViewerInstance,
  action,
  chosenTemplate,
  templateIsBeingSavedLoader,
  closeDialog
}: {
  header: UI.Header;
  webViewerInstance: WebViewerInstance;
  action: UseMutateFunction<
    string,
    Error,
    {
      templateId: string;
      template: TemplateFile;
    },
    unknown
  >;
  chosenTemplate: Document;
  templateIsBeingSavedLoader: () => void;
  closeDialog: () => void;
}) => {
  const { documentViewer, annotationManager } = webViewerInstance.Core;

  header
    .getHeader('default')
    .get('menuButton')
    .insertAfter({
      type: 'actionButton',
      img: saveButtonIcon,
      title: i18n.t('SAVE_DOCUMENT'),
      onClick: async () => {
        const { file, xfdfString } = await getTemplateAsDocxFile({
          documentViewer,
          annotationManager,
          templateName: chosenTemplate.name,
          key: chosenTemplate.key
        });

        const templatePayload: TemplateFile = {
          file,
          name: chosenTemplate.name,
          type: chosenTemplate.type,
          defaultAnnotations: xfdfString
        };

        templateIsBeingSavedLoader();
        await action({
          templateId: chosenTemplate.id,
          template: templatePayload
        });
        closeDialog();
      }
    });
};
