import { StaffMemberPermissions, StaffMemberRoles } from 'src/types/staff';

export const presetRoles: Record<
  StaffMemberRoles,
  Record<StaffMemberPermissions, boolean>
> = {
  [StaffMemberRoles.SYSTEM_ADMIN]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: true,
    [StaffMemberPermissions.BILLING]: true,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: true,
    [StaffMemberPermissions.CLINICAL_CARE]: true,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: false
  },
  [StaffMemberRoles.OPERATIONS]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: true,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: false,
    [StaffMemberPermissions.CLINICAL_CARE]: false,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: false
  },
  [StaffMemberRoles.FINANCE]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: true,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: false,
    [StaffMemberPermissions.CLINICAL_CARE]: false,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: false
  },
  [StaffMemberRoles.PRACTITIONER]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: false,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: true,
    [StaffMemberPermissions.CLINICAL_CARE]: true,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: true
  },
  [StaffMemberRoles.ADMIN_COORDINATION]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: false,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: true,
    [StaffMemberPermissions.CLINICAL_CARE]: false,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: true
  },
  [StaffMemberRoles.EMBRYOLOGIST]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: false,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: true,
    [StaffMemberPermissions.CLINICAL_CARE]: true,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: true
  },
  [StaffMemberRoles.CONUSELOR_THERAPIST]: {
    [StaffMemberPermissions.SYSTEM_ADMIN]: false,
    [StaffMemberPermissions.BILLING]: false,
    [StaffMemberPermissions.ADD_NEW_PATIENT]: false,
    [StaffMemberPermissions.CLINICAL_CARE]: false,
    [StaffMemberPermissions.COORDINATION]: true,
    [StaffMemberPermissions.ACTIVITY_FEED]: true
  }
};
