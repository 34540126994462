import { FC, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef } from '@mui/x-data-grid-premium';
import Box from '../../components/layout/Box';
import { Invoice, InvoiceMethod, InvoiceStatus } from '../../types/billing';
import MiniIconButton from '../../components/display/MiniIconButton';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/display/Typography';
import dayjs from 'dayjs';
import { getDateFormat } from '../../utils/dateAndTIme';
import { getFullName } from '../../utils/general';
import Table from '../../components/display/Table';
import { styled } from '@mui/material';
import { Colors } from '../../components/styles/colors';
import { fontWeights } from '../../components/styles/fonts';
import { parseAmount } from './utils/parseAmount';
import { DoctorChips } from '../patients/common/DoctorChips';
import Flex from '../../components/layout/Flex';
import { getMethodColors, getStatusColors } from './utils/invoiceTableColors';
import { useNavigate } from 'react-router-dom';
import { patientRoutes } from '../../router/routes';
import { usePopover } from '../../components/components-api/PopoverProvider';
import Menu, {
  MenuItemProps,
  MenuTheme
} from '../../components/display/Menu/Menu';
import useBilling from '../../hooks/useBilling';
import Loader from '../../components/display/Loader';
import { TableProps } from 'src/components/display/Table/Table';

interface InvoicesTableProps extends Omit<TableProps, 'columns' | 'rows'> {
  invoices: Invoice[];
  filter?: {
    status?: string;
    patientName?: string;
  };
}

const StyledTable = styled(Table)`
  .MuiDataGrid-cell:last-of-type,
  .MuiDataGrid-cell:first-of-type {
    padding: 0;
  }
`;

export const InvoicesTable: FC<InvoicesTableProps> = ({
  invoices,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openPopover } = usePopover();
  const { updateInvoiceStatus, updateInvoiceMethod } = useBilling();

  const { mutate: handleUpdateInvoiceStatus } = updateInvoiceStatus();

  const { mutate: handleUpdateInvoiceMethod } = updateInvoiceMethod();

  const columns: GridColDef<Invoice>[] = [
    {
      field: 'edit',
      headerName: '',
      align: 'center',
      width: 50,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (_, row) => row.id,
      renderCell: (params) => (
        <MiniIconButton
          icon={<EditIcon style={{ fill: 'black' }} />}
          onClick={() => {
            navigate(
              patientRoutes.getEditInvoiceLink(
                params.row.patientId,
                params.row.id
              )
            );
          }}
        />
      )
    },
    {
      field: 'id',
      flex: 1.5,
      headerName: t('INVOICE_NUMBER').toUpperCase(),
      renderCell: ({ value: id }) => {
        return <Typography>{id}</Typography>;
      }
    },
    {
      field: 'patientName',
      flex: 1.5,
      headerName: t('PATIENT_NAME').toUpperCase(),
      renderCell: ({ row }) => {
        const patientNameToDisplay = getFullName(row?.patient) || '-';
        return <Typography>{patientNameToDisplay}</Typography>;
      }
    },
    {
      field: 'amount',
      flex: 1,
      headerName: t('TOTAL').toUpperCase(),
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => {
        const total = row.invoiceItems.reduce<number>(
          (acc, { price, quantity, discount }) => {
            const currPrice = price * quantity;
            const currDiscount = currPrice * (discount / 100);
            return acc + currPrice - currDiscount;
          },
          0
        );
        return (
          <Typography fontWeight={fontWeights.black}>
            {total ? `${parseAmount(total)}` : '-'}
          </Typography>
        );
      }
    },
    {
      field: 'dueDate',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: t('DUE_DATE').toUpperCase(),
      renderCell: ({ value: dueDate, row: { paidDate } }) => {
        const isOverdue = dueDate
          ? dayjs().isAfter(dueDate) && !paidDate
          : false;
        return (
          <Flex flexDirection="column" alignItems="center">
            <Typography>
              {dueDate ? dayjs(dueDate).format(getDateFormat()) : '--'}
            </Typography>
            {isOverdue && (
              <Typography variant="caption" color={Colors.mauvelous}>
                {t('OVERDUE').toUpperCase()}
              </Typography>
            )}
          </Flex>
        );
      }
    },
    {
      field: 'paidDate',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: t('PAID_DATE').toUpperCase(),
      renderCell: ({ value: paidDate }) => {
        return (
          <Typography>
            {paidDate ? dayjs(paidDate).format(getDateFormat()) : '--'}
          </Typography>
        );
      }
    },
    {
      field: 'method',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: t('METHOD').toUpperCase(),
      renderCell: ({ value: currMethod, row }) => {
        const [isLoading, setIsLoading] = useState(false);
        const { color, backgroundColor } = getMethodColors(currMethod);
        const methodsOptions: MenuItemProps[] = Object.values(
          InvoiceMethod
        ).map((method) => ({
          label: t(method),
          justifyContent: 'center',
          action: () => {
            if (method !== currMethod) {
              setIsLoading(true);
              handleUpdateInvoiceMethod(
                { id: row.id, method },
                {
                  onSettled: () => setIsLoading(false)
                }
              );
            }
          }
        }));

        return (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor
            }}
            onClick={(ev) =>
              openPopover({
                anchorEl: ev.currentTarget,
                hideToolbar: true,
                children: (
                  <Menu items={methodsOptions} menuTheme={MenuTheme.Dark} />
                )
              })
            }
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Typography color={color} fontWeight={fontWeights.bold}>
                {currMethod || '--'}
              </Typography>
            )}
          </Flex>
        );
      }
    },
    {
      field: 'staffId',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: t('PHYSICIAN').toUpperCase(),
      filterable: false,
      renderCell: ({ value }) => (
        <DoctorChips
          id={`invoice-doctor-chips-${value}`}
          value={[value]}
          showSelectedValue
        />
      )
    },
    {
      field: 'status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: t('STATUS').toUpperCase(),
      renderCell: ({ value: currStatus, row }) => {
        const [isLoading, setIsLoading] = useState(false);
        const { color, backgroundColor } = getStatusColors(currStatus);
        const statusesOptions: MenuItemProps[] = Object.values(
          InvoiceStatus
        ).map((status) => ({
          label: t(status),
          justifyContent: 'center',
          action: () => {
            if (status !== currStatus) {
              setIsLoading(true);
              handleUpdateInvoiceStatus(
                { id: row.id, status },
                {
                  onSettled: () => setIsLoading(false)
                }
              );
            }
          }
        }));

        return (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor
            }}
            onClick={(ev) =>
              openPopover({
                anchorEl: ev.currentTarget,
                hideToolbar: true,
                children: (
                  <Menu items={statusesOptions} menuTheme={MenuTheme.Dark} />
                )
              })
            }
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Typography color={color} fontWeight={fontWeights.bold}>
                {currStatus}
              </Typography>
            )}
          </Flex>
        );
      }
    }
  ];

  return (
    <Box>
      <StyledTable
        freeHeight
        columns={columns}
        columnHeaderHeight={30}
        getRowHeight={() => 50}
        rows={invoices || []}
        autoHeight={true}
        getRowId={(row: Invoice) => row.id}
        paginationModel={{ page: 0, pageSize: 25 }}
        {...rest}
      />
    </Box>
  );
};
