import dayjs from 'dayjs';

const sevenThirtyAM = dayjs().hour(7).minute(30).toDate();
const sevenThirtyPM = dayjs().hour(19).minute(30).toDate();
const threeThirtyPM = dayjs().hour(15).minute(30).toDate();
const tenThirtyPM = dayjs().hour(22).minute(30).toDate();
const sixAM = dayjs().hour(6).minute(0).toDate();
const twelveAM = dayjs().hour(0).minute(0).toDate();
const twelvePM = dayjs().hour(12).minute(0).toDate();
const sixPM = dayjs().hour(18).minute(0).toDate();

export const getPrescriptionTimes = (timesPerDay: number) => {
  let times: Date[] = [];
  const diff = timesPerDay - 4;

  if (timesPerDay <= 4) {
    switch (timesPerDay) {
      case 4:
        times = [twelveAM, sixAM, twelvePM, sixPM];
        break;
      case 3:
        times = [sevenThirtyAM, tenThirtyPM, threeThirtyPM];
        break;
      case 2:
        times = [sevenThirtyAM, sevenThirtyPM];
        break;
      case 1:
        times = [sevenThirtyAM];
    }
  } else {
    times = [twelveAM, sixAM, twelvePM, sixPM];
    for (let i = 0; i < diff; i++) {
      times.push(sevenThirtyAM);
    }
  }

  return times;
};
