import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { styled, Tab, Tabs as MuiTabs } from '@mui/material';

import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';

const StyledTabs = styled(MuiTabs)`
  margin-bottom: ${spacings.large};
  border-bottom: 1px solid ${Colors.mercury};

  .MuiTabs-indicator {
    background-color: ${Colors.darkGray};
    border-width: 0;
    min-height: 2px;
    height: 2px;
    box-shadow: none;
  }
  .MuiTab-root {
    color: ${Colors.emperor};
    &.Mui-selected {
      color: ${Colors.darkGray};
      &:hover {
        color: ${Colors.darkGray};
      }
    }
  }
`;

export type TabContentsProps<T> = PropsWithChildren<{
  value: T;
  label?: ReactNode;
  disabled?: boolean;
}>;

interface TabsProps<T> {
  defaultTab?: T;
  tabContents: TabContentsProps<T>[];
  onTabChange?: (value: T) => void;
  selectedTab?: T;
}

export type TabPanelProps<T> = PropsWithChildren<{
  index: T;
  value: T;
}>;

const CustomTabPanel = <T,>({
  children,
  value,
  index,
  ...other
}: TabPanelProps<T>) => (
  <Box role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box>{children}</Box>}
  </Box>
);

const Tabs = <T,>({
  tabContents,
  defaultTab,
  onTabChange,
  selectedTab: selectedTabProp
}: TabsProps<T>) => {
  const [selectedTab, setSelectedTab] = useState<T | undefined>(
    defaultTab || tabContents[0]?.value
  );

  const handleChange = (event: React.SyntheticEvent, newValue: T): void => {
    if (onTabChange) {
      onTabChange(newValue);
    } else {
      setSelectedTab(newValue);
    }
  };

  const tabToDisplay = selectedTabProp || selectedTab;

  return (
    <Flex flexDirection="column" width="100%">
      <StyledTabs value={tabToDisplay} onChange={handleChange}>
        {tabContents.map(
          ({ value, label, children: _children, ...rest }, index) => (
            <Tab key={index} label={label} value={value} {...rest} />
          )
        )}
      </StyledTabs>
      {tabContents.map((tabContent, index) => {
        if (tabContent.value === tabToDisplay) {
          return (
            <CustomTabPanel
              key={index}
              value={tabToDisplay}
              index={tabContent.value}
            >
              {tabContent.children}
            </CustomTabPanel>
          );
        }
        return null;
      })}
    </Flex>
  );
};

export default Tabs;
