export enum FeatureFlags {
  ENABLE_ADD_PRESCRIPTION = 'enableAddPrescription',
  ENABLE_CREATE_ACTIVATE_CYCLE = 'enableCreateActivateCycle',
  ENABLE_CURRENT_CYCLE_NAVIGATION = 'enableCurrentCycleNavigation',
  ENABLE_LAB_VIEW = 'enableLabView',
  ENABLE_EXPORT_VIEW = 'enableexportview',
  ENABLE_CONSENT_FORMS = 'enableConsentForms',
  ENABLE_EFAX = 'enableEFax',
  ENABLE_WORK_LIST_DRAWER = 'enableWorkListDrawer'
}
